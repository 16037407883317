import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { adminLinks } from './links/adminLinks';
import { useAuthStore } from '../../../../store/hooks';
import { RoleFunction } from '../../../../constants/projectUsers';
import { observer } from 'mobx-react-lite';
import { GlobalLoader } from '../globalLoader/GlobalLoader';
import { publishLinks } from './links/publishLinks';
import { viewLinks } from './links/viewLinks';
import { expertAdminLinks } from './links/expertAdminLinks';
import { acceptingAdminLinks } from './links/acceptingAdminLinks';
import { guestLinks } from './links/guestLinks';

export const Router = observer(() => {
  const authStore = useAuthStore();


  switch (authStore.roleFunction) {
    case RoleFunction.ROLE_GUEST:
      return <RouterProvider router={createBrowserRouter(guestLinks)} fallbackElement={<GlobalLoader />} />;
    case RoleFunction.ROLE_ADMIN:
      return <RouterProvider router={createBrowserRouter(adminLinks)} fallbackElement={<GlobalLoader />} />;
    case RoleFunction.ROLE_PUBLISHING:
      return <RouterProvider router={createBrowserRouter(publishLinks)} fallbackElement={<GlobalLoader />} />;
    case RoleFunction.ROLE_VIEWING:
      return <RouterProvider router={createBrowserRouter(viewLinks)} fallbackElement={<GlobalLoader />} />;
    case RoleFunction.ROLE_ACCEPTING:
      return <RouterProvider router={createBrowserRouter(acceptingAdminLinks)} fallbackElement={<GlobalLoader />} />;
    case RoleFunction.ROLE_EXPERT:
      return <RouterProvider router={createBrowserRouter(expertAdminLinks)} fallbackElement={<GlobalLoader />} />;
    default:
      return <RouterProvider router={createBrowserRouter(guestLinks)} fallbackElement={<GlobalLoader />} />;
  }
});