import { GeneralLink, generateLink } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthStore } from '../../../store/hooks';

export const usePage404 = () => {
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const { roleFunction } = authStore;

  useEffect(() => {
    setTimeout(() => {
      navigate(generateLink(roleFunction, GeneralLink.REDIRECT_HOME));
    }, 3000);

  }, [navigate, roleFunction]);
};