import { CaseStatus, CaseStatusAccept, CaseStatusExpert } from '../../../../../interfaces/common/common';
import { RoleFunction } from '../../../../../constants/projectUsers';

export const generateOptions = (role: RoleFunction) => {
  switch (role) {
    case RoleFunction.ROLE_PUBLISHING:
      return [
        {
          value: 'wszystkie',
          label: 'wszystkie'
        },
        {
          value: 'DRAFT',
          label: CaseStatus.DRAFT
        },
        {
          value: 'SENT',
          label: CaseStatus.SENT
        },
        {
          value: 'PUBLISHED',
          label: CaseStatus.PUBLISHED
        },
        {
          value: 'HIDDEN',
          label: CaseStatus.HIDDEN
        }
      ];
    case RoleFunction.ROLE_ACCEPTING:
      return [
        {
          value: 'wszystkie',
          label: 'wszystkie'
        },
        {
          value: 'TO_ACCEPTING',
          label: CaseStatusAccept.TO_ACCEPTING
        },
        {
          value: 'SENT',
          label: CaseStatusAccept.SENT
        },
        {
          value: 'PUBLISHED',
          label: CaseStatusAccept.PUBLISHED
        },
        {
          value: 'HIDDEN',
          label: CaseStatusAccept.HIDDEN
        }
      ];
    case RoleFunction.ROLE_EXPERT:
      return [
        {
          value: 'wszystkie',
          label: 'wszystkie'
        },
        {
          value: 'TO_ACCEPTING',
          label: CaseStatusExpert.TO_ACCEPTING
        },
        {
          value: 'PUBLISHED',
          label: CaseStatusExpert.PUBLISHED
        },
        {
          value: 'HIDDEN',
          label: CaseStatusExpert.HIDDEN
        }
      ];
    default:
      return [
        {
          value: 'wszystkie',
          label: 'wszystkie'
        },
        {
          value: 'DRAFT',
          label: CaseStatus.DRAFT
        },
        {
          value: 'SENT',
          label: CaseStatus.SENT
        },
        {
          value: 'TO_ACCEPTING',
          label: CaseStatus.TO_ACCEPTING
        },
        {
          value: 'PUBLISHED',
          label: CaseStatus.PUBLISHED
        },

        {
          value: 'HIDDEN',
          label: CaseStatus.HIDDEN
        }
      ];
  }
};