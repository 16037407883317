import { Author, FooterStyled, GridFooter } from './Footer.styled';
import { ModalSideEffects } from './modalSideEffects/ModalSideEffects';
import { useModal } from '../../modal/useModal';
import { Link } from 'react-router-dom';
import React from 'react';

export const Footer = () => {
  const {
    open,
    handleClose,
    handleOpen
  } = useModal<string>();

  const handleSideEffectClick = () => {
    handleOpen('modal-side-effects');
  };

  return (<FooterStyled>
    <GridFooter container display={'flex'} justifyContent={'space-between'} pt={4} pb={4}>
      <ul>
        <li>
          <Link target={'_blank'} to={'https://www.recordatirarediseases.com/contact-us'}>Kontakt</Link>
        </li>
        <li>
          <a href={require('../../../../assets/files/regulamin.pdf')} target={'_blank'}
             rel='noreferrer'>Regulamin Serwisu</a>
        </li>
        <li>
          <a href={require('../../../../assets/files/politykaprywatnosci.pdf')} target={'_blank'}
             rel='noreferrer'>Polityka
            prywatności</a>
        </li>
        <li onClick={handleSideEffectClick}>
          Działania niepożądane
        </li>
      </ul>
      <Author>@ Recordati</Author>
    </GridFooter>
    {open && <ModalSideEffects handleClose={handleClose} open={open} />}
  </FooterStyled>);
};