import { Grid, Pagination } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { CaseLayout } from '../../shared/user/layout/caseLayout/CaseLayout';
import { AuthController } from '../../shared/appLayer/authController/AuthController';
import { CaseItem } from '../../shared/caseItem/CaseItem';
import { CaseFilter } from '../../shared/user/layout/caseFilter/CaseFilter';
import { useHome } from './useHome.vm';
import { EmptyList } from '../../shared/customTable/emptyList/EmptyList';

const Home = observer(() => {
  const {
    publishedCases,
    isLoaded,
    filter,
    handleChange,
    currentPage,
    totalPage,
    handlePageChange,
    search,
    handleSearch
  } = useHome();
  

  return (
    <AuthController>
      <CaseLayout filter={filter} handleChange={handleChange}>
        <CaseFilter filter={filter} isStatus={false} search={search} handleSearch={handleSearch} />
        <Grid container display={'flex'} flexWrap={'wrap'} mt={2}>
          {isLoaded && (publishedCases.length > 0 ?
            publishedCases.map(item => <CaseItem key={item.id} item={item}
                                                 type={filter}
                                                 isAuthor={true} />) : <EmptyList>{`Brak opublikowanego opisu przypadku medycznego dotyczącego ${filter === 'acromegaly' ? 'akromegalii' : 'zespołu cushinga'}.`}</EmptyList>)}
          {!!totalPage && <Grid item xs={12} display={'flex'} justifyContent={'right'}>
            <Pagination
              color={'primary'}
              size='small'
              count={totalPage}
              page={currentPage}
              onChange={(e, value: number) => {
                handlePageChange(value);
              }} showFirstButton showLastButton />
          </Grid>}
        </Grid>
      </CaseLayout>
    </AuthController>
  );
});

export default Home;