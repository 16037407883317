import { useFormik } from 'formik';
import { useEffect, useState } from 'react';


import { initialTreatment } from './Treatment.helper';
import { AddTreatmentFunction, ITreatment } from './Treatment.types';

export const useTreatmentVm = (initial?: ITreatment, handleAddTreatment?: AddTreatmentFunction) => {
  const [disabledAdd, setDisabledAdd] = useState(true);

  const formik = useFormik<ITreatment>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: initial ?? initialTreatment,
    onSubmit: async (values) => {}
  })

  useEffect(()=> {
    setDisabledAdd(!(formik.values.treatment !== "" && formik.values.date !== ""))
  }, [formik.values])

  const handleOnClick = () => {
    if(handleAddTreatment){
      handleAddTreatment(formik.values);
      formik.resetForm();
    }
  }


  return {formik, handleOnClick, disabledAdd
  }
}