import { useEffect, useState } from 'react';
import { useProgressBarStore } from '../../../../../../../store/hooks/useProgressBarStore';
import { useFormik } from 'formik';

import { IInputControllerSecondStage, ISecondStageValues } from './SecondStage.types';
import {
  checkboxInputControllerConfig, convertInputControllerSecondStage,
  initialSecondStage,
  validationSecondStageSchema
} from './SecondStage.helper';
import { useInputController } from '../../../../../../shared/ui/form/inputController/useInputController';
import { useDraftCaseApi } from '../../../../../../../apiHooks';
import { useParams } from 'react-router-dom';
import { DiseaseType } from '../../../../../../../interfaces/common/common';
import {Action} from "../../../../../../../store/progressBarStore";

export const useSecondStageVm = () => {
  const { id, type } = useParams();
  const [activeFieldset, setActiveFieldset] = useState<string>('');
  const [adenomaFieldsActive, setAdenomaFieldsActive] = useState(false);
  const { draftCaseSecondSage, draftCaseSecondStageSaved } = useDraftCaseApi();
  const progressBarStore = useProgressBarStore();

  const {
    inputControllerElements,
    setActive,
    setActiveBroadcast
  } = useInputController<IInputControllerSecondStage>(progressBarStore.secondStage ? convertInputControllerSecondStage(progressBarStore.secondStage as ISecondStageValues) : checkboxInputControllerConfig);

  const formik = useFormik<ISecondStageValues>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    initialValues: progressBarStore.secondStage as ISecondStageValues ?? initialSecondStage,
    validationSchema: validationSecondStageSchema,
    onSubmit: async (values) => {
      const {
        currentDiagnosisCushing,
        currentDiagnosisProductionACTHCheck,
        currentDiagnosisAdrenalCushingCheck
      } = values;

      const shouldClearCurrentDiagnosis =
        (currentDiagnosisCushing === 'zespół ektopowej produkcji ACTH' && !currentDiagnosisProductionACTHCheck) ||
        (currentDiagnosisCushing === 'zespół Cushinga pochodzenia nadnerczowego' && !currentDiagnosisAdrenalCushingCheck);

      const updatedValues = { ...values };

      if (shouldClearCurrentDiagnosis) {
        updatedValues.currentDiagnosisCushing = '';
      }

      await draftCaseSecondSage(updatedValues, Number(id), type as DiseaseType);
      progressBarStore.setSecondStage(updatedValues);
    }
  });

  const { setFieldValue, values } = formik;

  useEffect(() => {
    if (formik.errors.courseDateStart || formik.errors.courseWhoRecognized || formik.errors.courseDateStartOfTreatment) {
      setActiveFieldset('valid-fieldset-1');
    }

  }, [formik.errors]);

  useEffect(() => {
    if (formik.values.currentDiagnosisCushing !== 'zespół ektopowej produkcji ACTH') {
      setFieldValue('currentDiagnosisProductionACTHCheck', '');
    }
    if (formik.values.currentDiagnosisCushing !== 'zespół Cushinga pochodzenia nadnerczowego') {
      setFieldValue('currentDiagnosisAdrenalCushingCheck', '');
    }
  }, [setFieldValue, formik.values.currentDiagnosisCushing]);

  useEffect(() => {
    const isMacroOption = values.imagingResultMrTkMacroMicroadenoma === 'makrogruczolak';
    setAdenomaFieldsActive(isMacroOption);

    if (!isMacroOption) {
      setFieldValue('imagingResultMrTkMacroadenomaInfiltration', '');
      setFieldValue('imagingResultMrTkMacroadenomaInfiltrationRockyBay', '');
      setFieldValue('imagingResultMrTkMacroadenomaInfiltrationRockyScaleKnosp', '');
    }
  }, [setFieldValue, values.imagingResultMrTkMacroMicroadenoma]);


  useEffect(() => {
    const isMacroAdenomaTypeFirst = values.imagingResultMrTkMacroadenomaInfiltration !== 'naciekanie zatok jamistych';

    if (isMacroAdenomaTypeFirst) {
      setFieldValue('imagingResultMrTkMacroadenomaInfiltrationRockyBay', '');
      setFieldValue('imagingResultMrTkMacroadenomaInfiltrationRockyScaleKnosp', '');
    }
  }, [setFieldValue, values.imagingResultMrTkMacroadenomaInfiltration]);


  const handleSave = async () => {
    const errors = await formik.validateForm();

    if (Object.keys(errors).length === 0) {
      formik.handleSubmit();
    }
  };

  const handleResetFieldset = () => {
    setActiveFieldset('');
  };

  const handleDraftSave = async (action: Action) => {
    await draftCaseSecondStageSaved(formik.values, Number(id), type as DiseaseType);
    progressBarStore.setSecondStage(formik.values, action);
  };

  const handleChangeSwitchAndCheckbox = (name: (keyof ISecondStageValues), value: string | boolean) => {
    formik.setFieldValue(name, value);
  };

  return {
    activeFieldset,
    adenomaFieldsActive,
    setActiveBroadcast,
    inputControllerElements,
    setActive,
    formik,
    handleSave,
    handleResetFieldset,
    handleChangeSwitchAndCheckbox,
    handleDraftSave
  };
};