import { useEffect } from 'react';
import { IRegisterForm } from './Register.interfaces';
import { useAuthApi } from '../../../../apiHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from '../../../../hooks/useForm';
import { ObjectSchema } from 'yup';
import { useAuthStore } from '../../../../store/hooks';
import { GeneralLink, GuestLink } from '../../../../utils';

export const useRegister = (initialRegisterForm: IRegisterForm, validationRegisterSchema: ObjectSchema<IRegisterForm>) => {
  const { getEmailByToken, registerUser } = useAuthApi();
  const authStore = useAuthStore();
  const { tokenEmail } = authStore;
  const params = useParams();
  const navigate = useNavigate();
  const { token } = params;

  useEffect(() => {
    if (token) {
      void getEmailByToken(token);
    }
  }, [getEmailByToken, token]);

  const onSubmit = async (values: IRegisterForm) => {
    if (token) {
      const res = await registerUser(token, values);
      if (res) {
        navigate(GeneralLink.LOGIN_PUBLISHING);
      } else {
        navigate(GuestLink.REDIRECT_HOME);
      }
    }
  };

  const { formik } = useForm<IRegisterForm>(initialRegisterForm, validationRegisterSchema, onSubmit);

  const { setFieldValue } = formik;

  useEffect(() => {
    if (tokenEmail) {
      setFieldValue('email', tokenEmail);
    }
  }, [setFieldValue, tokenEmail]);

  return {
    onSubmit,
    formik
  };
};