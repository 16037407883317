import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { ICardItem } from '../components/shared/caseItem/CardItem.types';

export class CasesListStore {
  publishedCasesAcromegaly: ICardItem[] | null = null;
  publishedCasesCushing: ICardItem[] | null = null;

  myCasesAcromegaly: ICardItem[] | null = null;
  myCasesCushing: ICardItem[] | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setPublishedCasesAcromegaly(publishedCases: ICardItem[]) {
    this.publishedCasesAcromegaly = publishedCases;
  }

  setPublishedCasesCushing(publishedCases: ICardItem[]) {
    this.publishedCasesCushing = publishedCases;
  }

  setMyCasesAcromegaly(myCases: ICardItem[] ) {
    this.myCasesAcromegaly = myCases;
  }

  setMyCasesCushing(myCases: ICardItem[] ) {
    this.myCasesCushing = myCases;
  }

  clearAllCases() {
    this.publishedCasesAcromegaly = null;
  }

  clearStore() {
    this.publishedCasesAcromegaly = null;
    this.myCasesAcromegaly = null;
    this.myCasesCushing = null;
  }

}