import { useFormik } from 'formik';

import { useProgressBarStore } from '../../../../../../../store/hooks/useProgressBarStore';
import { IFourthStageValues } from './FourthStage.types';
import { initialFourthStage } from './FourthStage.helper';
import { useDraftCaseApi } from '../../../../../../../apiHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from '../../../../../../shared/modal/useModal';
import { useState } from 'react';
import { DiseaseType } from '../../../../../../../interfaces/common/common';
import {Action} from "../../../../../../../store/progressBarStore";

export const useFourthStageVm = () => {
  const { id, type } = useParams();
  const [finishMessage, setFinishMessage] = useState('');
  const { draftCaseFourthStageSaved } = useDraftCaseApi();
  const progressBarStore = useProgressBarStore();
  const navigate = useNavigate();
  const {
    open,
    handleClose,
    handleOpen
  } = useModal<string>();

  const handleModalOpen = () => {
    handleOpen('modal-published-case');
  };

  const formik = useFormik<IFourthStageValues>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    initialValues: progressBarStore.fourthStage ?? initialFourthStage,
    onSubmit: async (values) => {
      const res = await draftCaseFourthStageSaved(formik.values, Number(id), type as DiseaseType, 'SENT');
      if (res) {
        setFinishMessage('Opis przypadku został wysłany do akceptacji.');
        progressBarStore.setFourthStage(values);
        setTimeout(() => {
          handleClose();
          navigate(`/cases/${type}`);
        }, 3000);
      } else {
        setFinishMessage('Wysłanie przypadku nie powiodło się...');
      }
    }
  });

  const handleDraftSave = async (action: Action, isModalOpen?: boolean) => {
    const res = await draftCaseFourthStageSaved(formik.values, Number(id), type as DiseaseType, 'DRAFT');
    progressBarStore.setFourthStage(formik.values, action);
    if (!isModalOpen) {
      if (res) {
        setFinishMessage('Szkic został zapisany...');
        setTimeout(() => {
          handleClose();
          navigate(`/cases/${type}`);
        }, 3000);
      } else {
        setFinishMessage('Zapisanie szkicu nie powiodło się.');
      }
    }
  };
  const handleSave = async () => {
    const errors = await formik.validateForm();

    if (Object.keys(errors).length === 0) {
      formik.handleSubmit();
    }
  };

  return {
    formik,
    handleDraftSave,
    handleSave,
    handleModalOpen,
    open,
    handleClose,
    finishMessage
  };
};