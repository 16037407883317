import { useProgressBarStore } from '../../../../../../../store/hooks/useProgressBarStore';
import { useFormik } from 'formik';

import { IInputControllerThirdStage, IThirdStageValues } from './ThirdStage.types';
import {
  checkboxInputControllerConfig,
  convertInputControllerThirdStage,
  initialThirdStage,
  validationThirdStageSchema
} from './ThirdStage.helper';
import { useInputController } from '../../../../../../shared/ui/form/inputController/useInputController';
import { ISurgery } from './surgary/Surgary.types';
import { IDrug } from './drug/Drug.types';
import { useEffect, useState } from 'react';
import { dateIsStill } from '../../../../../../../utils/validateDateRange';
import { useDraftCaseApi } from '../../../../../../../apiHooks';
import { useParams } from 'react-router-dom';
import { DiseaseType } from '../../../../../../../interfaces/common/common';
import { ISomastatinAnalog } from './somastatinAnalog/SomastatinAnalog.types';
import { ICabergoline } from './cabergoline/Cabergoline.types';
import {
  ITreatmentOfChronicAcromegallyAnalogue
} from './treatmentOfChronicAcromegallyAnalogue/TreatmentOfChronicAcromegallyAnalogue.types';
import {
  ITreatmentOfChronicAcromegallyPasireotide
} from './treatmentOfChronicAcromegallyPasireotide/treatmentOfChronicAcromegallyPasireotide.types';
import {
  ITreatmentOfChronicAcromegallyPegvisomant
} from './treatmentOfChronicAcromegallyPegvisomant/TreatmentOfChronicAcromegallyPegvisomant.types';

import {
  ITreatmentOfChronicAcromegallyCabergoline
} from './treatmentOfChronicAcromegallyCabergoline/TreatmentOfChronicAcromegallyCabergoline.types';
import {Action} from "../../../../../../../store/progressBarStore";

export const useThirdStageVm = () => {
  const { id, type } = useParams();
  const [editableAddSomastatinAnalog, setEditableAddSomastatinAnalog] = useState(false)
  const [editableAddCabergoline, setEditableAddCabergoline] = useState(false)
  const [editableAddDrug, setEditableAddDrug] = useState(false)
  const [editableAddSurgery, setEditableAddSurgery] = useState(false)
  const [editableAddTreatmentOfChronicAcromegally, setEditableAddTreatmentOfChronicAcromegally] = useState(false)
  const [editableAddTreatmentOfChronicAcromegallyPegvisomant, setEditableAddTreatmentOfChronicAcromegallyPegvisomant] = useState(false)
  const [editableAddTreatmentOfChronicAcromegallyPasireotide, setEditableAddTreatmentOfChronicAcromegallyPasireotide] = useState(false)
  const [editableAddTreatmentOfChronicAcromegallyCabergoline, setEditableAddTreatmentOfChronicAcromegallyCabergoline] = useState(false)

  const [activeFieldset, setActiveFieldset] = useState<string>('');
  const { draftCaseThirdSage, draftCaseThirdStageSaved } = useDraftCaseApi();
  const progressBarStore = useProgressBarStore();

  const {
    inputControllerElements,
    setActive,
    setActiveBroadcast
  } = useInputController<IInputControllerThirdStage>(progressBarStore.thirdStage ? convertInputControllerThirdStage(progressBarStore.thirdStage as IThirdStageValues) : checkboxInputControllerConfig);
  const formik = useFormik<IThirdStageValues>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    initialValues: progressBarStore.thirdStage as IThirdStageValues ?? initialThirdStage,
    validationSchema: validationThirdStageSchema,
    onSubmit: async (values) => {
      const newValues = convertDate();
      await draftCaseThirdSage(newValues, Number(id), type as DiseaseType);
      progressBarStore.setThirdStage(values);
    }
  });

  const { setFieldValue } = formik;


  useEffect(() => {
    if (!inputControllerElements.numberOfMonthsFromDiagnosisToTreatment) {
      setFieldValue('numberOfMonthsFromDiagnosisToTreatment', '');
      setFieldValue('surgeries', []);
      setEditableAddSurgery(false);
    } else if (!inputControllerElements.reasonForNotTreating) {
      setFieldValue('reasonForNotTreating', '');
    }
  }, [setFieldValue, inputControllerElements.numberOfMonthsFromDiagnosisToTreatment, inputControllerElements.reasonForNotTreating]);

  useEffect(() => {
    if (formik.values.achievingHormonalNormalizationIgf1 !== 'Powyżej normy dla płci i wieku') {
      setFieldValue('achievingHormonalNormalizationIgf1Rate', '');
    }
  }, [setFieldValue, formik.values.achievingHormonalNormalizationIgf1]);

  useEffect(() => {
    if (formik.errors.achievingHormonalNormalizationGh || formik.errors.achievingHormonalNormalizationIgf1) {
      setActiveFieldset('valid-fieldset-3');
    }
  }, [formik.errors]);


  const handleEditableAddSomastatinAnalog= () => {
    setEditableAddSomastatinAnalog(true);
  }

  const handleAddSomastatinAnalog = (somastatinAnalog: ISomastatinAnalog) => {
    const somastatinAnalogs = [...formik.values.somastatinAnalogs];
    somastatinAnalogs.push(somastatinAnalog);
    setEditableAddSomastatinAnalog(false);
    formik.setFieldValue('somastatinAnalogs', somastatinAnalogs);
  }

  const handleDeleteSomastatinAnalog = (id: number) => {
    const somastatinAnalogs = [...formik.values.somastatinAnalogs].filter((obj, index) => index !== id);
    formik.setFieldValue('somastatinAnalogs', somastatinAnalogs);
  }

  const handleEditableAddCabergoline= () => {
    setEditableAddCabergoline(true);
  }

  const handleAddCabergoline = (cabergoline: ICabergoline) => {
    const cabergolines = [...formik.values.cabergolines];
    cabergolines.push(cabergoline);
    setEditableAddCabergoline(false);
    formik.setFieldValue('cabergolines', cabergolines);
  }

  const handleDeleteCabergoline = (id: number) => {
    const cabergolines = [...formik.values.cabergolines].filter((obj, index) => index !== id);
    formik.setFieldValue('cabergolines', cabergolines);
  }

  const handleEditableAddSurgery = () => {
    setEditableAddSurgery(true);
  }

  const handleAddSurgery = (surgery: ISurgery) => {
    const surgeries = [...formik.values.surgeries];
    surgeries.push(surgery);
    setEditableAddSurgery(false);
    formik.setFieldValue('surgeries', surgeries);
  };

  const handleDeleteSurgery = (id: number) => {
    const surgeries = [...formik.values.surgeries].filter((obj, index) => index !== id);
    formik.setFieldValue('surgeries', surgeries);
  };

  const handleEditableAddDrug = () => {
    setEditableAddDrug(true);
  }

  const handleAddDrug = (drug: IDrug) => {
    const treatmentOfComorbidities = [...formik.values.treatmentOfComorbidities];
    treatmentOfComorbidities.push(drug);
    setEditableAddDrug(false);
    formik.setFieldValue('treatmentOfComorbidities', treatmentOfComorbidities);
  };

  const handleDeleteDrug = (id: number) => {
    const treatmentOfComorbidities = [...formik.values.treatmentOfComorbidities].filter((obj, index) => index !== id);
    formik.setFieldValue('treatmentOfComorbidities', treatmentOfComorbidities);
  };

  const handleEditableAddTreatmentOfChronicAcromegallyAnalogue= () => {
    setEditableAddTreatmentOfChronicAcromegally(true);
  }

  const handleAddTreatmentOfChronicAcromegallyAnalogue = (treatmentOfChronicAcromegallyAnalogue: ITreatmentOfChronicAcromegallyAnalogue) => {
    const treatmentOfChronicAcromegallyAnalogues = [...formik.values.treatmentOfChronicAcromegallyAnalogues];
    treatmentOfChronicAcromegallyAnalogues.push(treatmentOfChronicAcromegallyAnalogue);
    setEditableAddTreatmentOfChronicAcromegally(false);
    formik.setFieldValue('treatmentOfChronicAcromegallyAnalogues', treatmentOfChronicAcromegallyAnalogues);
  };

  const handleDeleteTreatmentOfChronicAcromegalyAnalogue = (id: number) => {
    const treatmentOfChronicAcromegalyAnalogues = [...formik.values.treatmentOfChronicAcromegallyAnalogues].filter((obj, index) => index !== id);
    formik.setFieldValue('treatmentOfChronicAcromegallyAnalogues', treatmentOfChronicAcromegalyAnalogues);
  };


  const handleDeleteTreatmentOfChronicAcromegallyPasireotide = (id: number) => {
    const treatmentOfChronicAcromegallyPasireotides = [...formik.values.treatmentOfChronicAcromegallyPasireotides].filter((obj, index) => index !== id);
    formik.setFieldValue('treatmentOfChronicAcromegallyPasireotides', treatmentOfChronicAcromegallyPasireotides);
  };

  const handleEditableAddTreatmentOfChronicAcromegallyPasireotide = () => {
    setEditableAddTreatmentOfChronicAcromegallyPasireotide(true);
  }

  const handleAddTreatmentOfChronicAcromegallyPasireotide  = (treatmentOfChronicAcromegallyPasireotide: ITreatmentOfChronicAcromegallyPasireotide) => {
    const treatmentOfChronicAcromegallyPasireotides = [...formik.values.treatmentOfChronicAcromegallyPasireotides];
    treatmentOfChronicAcromegallyPasireotides.push(treatmentOfChronicAcromegallyPasireotide);
    setEditableAddTreatmentOfChronicAcromegallyPasireotide(false);
    formik.setFieldValue('treatmentOfChronicAcromegallyPasireotides', treatmentOfChronicAcromegallyPasireotides);
  };

  const handleDeleteTreatmentOfChronicAcromegallyPegvisomant = (id: number) => {
    const treatmentOfChronicAcromegallyPegvisomant = [...formik.values.treatmentOfChronicAcromegallyPegvisomants].filter((obj, index) => index !== id);
    formik.setFieldValue('treatmentOfChronicAcromegallyPegvisomants', treatmentOfChronicAcromegallyPegvisomant);
  };

  const handleEditableAddTreatmentOfChronicAcromegallyPegvisomant = () => {
    setEditableAddTreatmentOfChronicAcromegallyPegvisomant(true);
  }

  const handleAddTreatmentOfChronicAcromegallyPegvisomant  = (treatmentOfChronicAcromegallyPegvisomant: ITreatmentOfChronicAcromegallyPegvisomant) => {
    const treatmentOfChronicAcromegallyPegvisomants = [...formik.values.treatmentOfChronicAcromegallyPegvisomants];
    treatmentOfChronicAcromegallyPegvisomants.push(treatmentOfChronicAcromegallyPegvisomant);
    setEditableAddTreatmentOfChronicAcromegallyPegvisomant(false);
    formik.setFieldValue('treatmentOfChronicAcromegallyPegvisomants', treatmentOfChronicAcromegallyPegvisomants);
  };

  const handleDeleteTreatmentOfChronicAcromegallyCabergoline = (id: number) => {
    const treatmentOfChronicAcromegallyCabergolines = [...formik.values.treatmentOfChronicAcromegallyCabergolines].filter((obj, index) => index !== id);
    formik.setFieldValue('treatmentOfChronicAcromegallyCabergolines', treatmentOfChronicAcromegallyCabergolines);
  };

  const handleEditableAddTreatmentOfChronicAcromegallyCabergoline = () => {
    setEditableAddTreatmentOfChronicAcromegallyCabergoline(true);
  }

  const handleAddTreatmentOfChronicAcromegallyCabergoline  = (treatmentOfChronicAcromegallyCabergoline: ITreatmentOfChronicAcromegallyCabergoline) => {
    const treatmentOfChronicAcromegallyCabergolines = [...formik.values.treatmentOfChronicAcromegallyCabergolines];
    treatmentOfChronicAcromegallyCabergolines.push(treatmentOfChronicAcromegallyCabergoline);
    setEditableAddTreatmentOfChronicAcromegallyCabergoline(false);
    formik.setFieldValue('treatmentOfChronicAcromegallyCabergolines', treatmentOfChronicAcromegallyCabergolines);
  };


  const handleSave = async () => {
    const errors = await formik.validateForm();

    if ( Object.keys(errors).length === 0) {
      formik.handleSubmit();
    }
  };


  const handleDraftSave = async (action: Action) => {
    const newValues = convertDate();
    await draftCaseThirdStageSaved(newValues, Number(id), type as DiseaseType);
    progressBarStore.setThirdStage(newValues, action);
  };

  const handleChangeArray = (name: (keyof IThirdStageValues), value: string) => {
    let newValues = [...formik.values[name] as string[]];
    if (newValues.find(element => element === value)) {
      newValues = newValues.filter(element => element !== value);
    } else {
      newValues.push(value);
    }

    formik.setFieldValue(name, newValues);
  };
  const handleChangeSwitchAndCheckbox = (name: (keyof IThirdStageValues), value: string) => {
    formik.setFieldValue(name, value);
  };
  const handleResetFieldset = () => {
    setActiveFieldset('');
  };


  const convertDate = () => {
    let newValues = { ...formik.values };

    const newCabergolines = formik.values.cabergolines.map(cabergoline => {
      const newCabergoline = {...cabergoline};
      newCabergoline.dateEnd = dateIsStill(newCabergoline.dateEnd);
      return newCabergoline;
    })

    newValues.cabergolines = [...newCabergolines];

    const newSomastatinAnalogs = formik.values.somastatinAnalogs.map(somastatinAnalog => {
      const newSomastatinAnalog = {...somastatinAnalog};
      newSomastatinAnalog.dateEnd = dateIsStill(newSomastatinAnalog.dateEnd);
      return newSomastatinAnalog;
    })

    newValues.somastatinAnalogs = [...newSomastatinAnalogs];

    const newTreatmentOfChronicAcromegallyAnalogues = formik.values.treatmentOfChronicAcromegallyAnalogues.map(treatmentOfChronicAcromegallyAnalogue => {
      const newTreatmentOfChronicAcromegallyAnalogue = {...treatmentOfChronicAcromegallyAnalogue};
      newTreatmentOfChronicAcromegallyAnalogue.dateEnd = dateIsStill(newTreatmentOfChronicAcromegallyAnalogue.dateEnd);
      return newTreatmentOfChronicAcromegallyAnalogue;
    })

    newValues.treatmentOfChronicAcromegallyAnalogues = [...newTreatmentOfChronicAcromegallyAnalogues];

    const newTreatmentOfChronicAcromegallyPasireotides = formik.values.treatmentOfChronicAcromegallyPasireotides.map(treatmentOfChronicAcromegallyPasireotide => {
      const newTreatmentOfChronicAcromegallyPasireotide = {...treatmentOfChronicAcromegallyPasireotide};
      newTreatmentOfChronicAcromegallyPasireotide.dateEnd = dateIsStill(newTreatmentOfChronicAcromegallyPasireotide.dateEnd);
      return newTreatmentOfChronicAcromegallyPasireotide;
    })

    newValues.treatmentOfChronicAcromegallyPasireotides = [...newTreatmentOfChronicAcromegallyPasireotides];

    const newTreatmentOfChronicAcromegallyPegvisomants = formik.values.treatmentOfChronicAcromegallyPegvisomants.map(treatmentOfChronicAcromegallyPegvisomant => {
      const newTreatmentOfChronicAcromegallyPegvisomant = {...treatmentOfChronicAcromegallyPegvisomant};
      newTreatmentOfChronicAcromegallyPegvisomant.dateEnd = dateIsStill(newTreatmentOfChronicAcromegallyPegvisomant.dateEnd);
      return newTreatmentOfChronicAcromegallyPegvisomant;
    })

    newValues.treatmentOfChronicAcromegallyPegvisomants = [...newTreatmentOfChronicAcromegallyPegvisomants];

    const newTreatmentOfChronicAcromegallyCabergolines = formik.values.treatmentOfChronicAcromegallyCabergolines.map(treatmentOfChronicAcromegallyCabergoline => {
      const newTreatmentOfChronicAcromegallyCabergoline = {...treatmentOfChronicAcromegallyCabergoline};
      newTreatmentOfChronicAcromegallyCabergoline.dateEnd = dateIsStill(newTreatmentOfChronicAcromegallyCabergoline.dateEnd);
      return newTreatmentOfChronicAcromegallyCabergoline;
    })

    newValues.treatmentOfChronicAcromegallyCabergolines = [...newTreatmentOfChronicAcromegallyCabergolines];

    return newValues;
  };

  return {
    inputControllerElements,
    setActive,
    setActiveBroadcast,
    formik,
    activeFieldset,
    editableAddSomastatinAnalog,
    handleEditableAddSomastatinAnalog,
    handleAddSomastatinAnalog,
    handleDeleteSomastatinAnalog,
    editableAddCabergoline,
    handleEditableAddCabergoline,
    handleAddCabergoline,
    handleDeleteCabergoline,
    handleEditableAddSurgery,
    editableAddSurgery,
    handleAddSurgery,
    handleDeleteSurgery,
    handleAddDrug,
    editableAddDrug,
    handleEditableAddDrug,
    handleDeleteDrug,
    editableAddTreatmentOfChronicAcromegally,
    handleEditableAddTreatmentOfChronicAcromegallyAnalogue,
    handleAddTreatmentOfChronicAcromegallyAnalogue,
    handleDeleteTreatmentOfChronicAcromegalyAnalogue,
    editableAddTreatmentOfChronicAcromegallyPasireotide,
    handleDeleteTreatmentOfChronicAcromegallyPasireotide,
    handleEditableAddTreatmentOfChronicAcromegallyPasireotide,
    handleAddTreatmentOfChronicAcromegallyPasireotide,
    editableAddTreatmentOfChronicAcromegallyPegvisomant,
    handleDeleteTreatmentOfChronicAcromegallyPegvisomant,
    handleEditableAddTreatmentOfChronicAcromegallyPegvisomant,
    handleAddTreatmentOfChronicAcromegallyPegvisomant,
    editableAddTreatmentOfChronicAcromegallyCabergoline,
    handleDeleteTreatmentOfChronicAcromegallyCabergoline,
    handleEditableAddTreatmentOfChronicAcromegallyCabergoline,
    handleAddTreatmentOfChronicAcromegallyCabergoline,
    handleSave,
    handleDraftSave,
    handleChangeArray,
    handleChangeSwitchAndCheckbox,
    handleResetFieldset,
  };
};