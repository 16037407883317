import React from 'react';
import {observer} from "mobx-react-lite";
import { useParams } from 'react-router-dom';

import { Divider, FormControlLabel, Grid, Radio, Typography } from '@mui/material';
import { Input } from '../../../../../../shared/ui/form/input/Input';
import { RadioGroupStyled } from '../../../../../user/caseDraft/stages/common/fourthStage/FourthStage.styled';
import { CheckedIconContainer } from '../../../checkedIcon/CheckedIcon';
import { useCaseStore } from '../../../../../../../store/hooks';
import { Text } from '../../../Description.styled';

export const FirstStage = observer(() => {
  const params = useParams();
  const { type } = params;

  const caseStore = useCaseStore();
  const {
    firstStage,
  }= caseStore;

  if(firstStage){

    const cardiovascularDiseasesSection = firstStage.cardiovascularDiseases.length > 0;

    const metabolicDiseasesSection = Boolean(firstStage.metabolicDiseasesDiabetes) ||
      Boolean(firstStage.metabolicDiseasesAnotherDiseases) ||
      Boolean(firstStage.metabolicDiseasesAnotherMedicines) ||
      firstStage.metabolicDiseasesDiabetesMedicines.length> 0;

    const cancersSection = firstStage.cancers.length > 0 || Boolean(firstStage.cancersAnother);

    const endoctrineDisordersSection  = firstStage.endoctrineDisorders.length > 0 || Boolean(firstStage.endoctrineDisordersAnother);

    const movementElement = type === 'acromegaly' ? Boolean(firstStage.movementOrganDiseasesGallstones) : Boolean(firstStage.gastrointestinalDiseases);
    const movementOrganDiseasesSection = firstStage.movementOrganDiseases.length > 0;

    const gastrointestinalDiseaseSection = type === 'acromegaly' ? firstStage.movementOrganDiseasesGallstones : firstStage.gastrointestinalDiseases;

    const titleDiseasesSection  = metabolicDiseasesSection || cancersSection  || endoctrineDisordersSection || movementOrganDiseasesSection || gastrointestinalDiseaseSection;


    return (
        <Grid container justifyContent={'space-between'} width={'100%'} mt={6}>
          <Typography variant={'h4'} mb={2}>Dane demograficzne Pacjenta i podstawowe dane z wywiadów</Typography>
          <Divider sx={{ width: '100%' }} />
          <Typography variant={'h6'} mt={2}>Dane pacjenta</Typography>
          <Grid container display={'flex'} alignItems={'center'} mt={2}>
            <Grid item xs={4} pr={1}>
              <Input disabled variant={'standard'} fullWidth={true} required={true} name={'name'} label={'Iniciały'}
                     type={'text'}
                     maxLength={2}
                     value={firstStage.name} />
            </Grid>
            <Grid item xs={4} pr={1} pl={1}>
              <Input disabled variant={'standard'} fullWidth={true} required={true} name={'years'} label={'Wiek (lata)'}
                     type={'number'}
                     value={firstStage.years} />
            </Grid>
            <Grid item xs={4} pl={1} display={'flex'}>
              <RadioGroupStyled row={true} name={'gender'} value={firstStage.gender}>
                <FormControlLabel disabled value={firstStage.gender}
                                  control={<Radio />}
                                  label={firstStage.gender === 'F' ? 'Kobieta' : firstStage.gender === 'M' ? 'Mężczyzna' : 'Inna'} />
              </RadioGroupStyled>
            </Grid>
            <Grid item xs={4} pr={1} mt={2}>
              <Input disabled variant={'standard'} fullWidth={true} required={true} name={'weight'}
                     label={'Masa ciała (kg)'} type={'number'}
                     value={firstStage.weight} />
            </Grid>
            <Grid item xs={4} pr={1} pl={1} mt={2}>
              <Input disabled variant={'standard'}
                     fullWidth={true} required={true} name={'height'} label={'Wzrost (cm)'} type={'number'}
                     value={firstStage.height} />
            </Grid>
            <Grid item xs={4} pr={1} pl={1} mt={2}>
              <Input disabled variant={'standard'} fullWidth={true} name={'bmi'} label={'BMI'}
                     type={'text'}
                     value={firstStage.bmi} />
            </Grid>

            {titleDiseasesSection && <Typography variant={'h6'} mt={4}>Wywiad w zakresie chorób współistniejących</Typography>}
            {cardiovascularDiseasesSection && <Grid container display={'flex'} flexDirection={'column'} mt={2}>
              <Grid item xs={12} mb={1}>
                <Text>Choroby sercowo-naczyniowe:</Text>
              </Grid>
              <>{
                firstStage.cardiovascularDiseases.map(element=>
                  <Grid key={element} container display={'flex'}  alignItems={'center'} justifyItems={'center'}>
                    <CheckedIconContainer/><Text>{element}</Text>
                  </Grid>
                )
              }</>
            </Grid>}

            {metabolicDiseasesSection && <Grid container display={'flex'} flexDirection={'column'} mt={2}>
              <Grid item xs={12} mb={1}>
                <Text>Choroby metaboliczne:</Text>
              </Grid>
              <>{firstStage.metabolicDiseasesDiabetes &&
                <Grid container display={'flex'} flexDirection={'row'} flexWrap={'nowrap'}>
                  <Grid item xs={2} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                    <CheckedIconContainer/><Text>{'cukrzyca'}</Text>
                  </Grid>
                  <Grid item xs={3}>
                    <Input multiline disabled variant={'standard'} name={'metabolicDiseasesDiabetes'}
                           label={'Typ cukrzycy'} type={'text'}
                           value={firstStage.metabolicDiseasesDiabetes} />
                  </Grid>
                </Grid>
              }</>
              {firstStage.metabolicDiseasesDiabetesMedicines && <Grid container width={'100%'} gap={2}>
                <>{
                  firstStage.metabolicDiseasesDiabetesMedicines.map(element=>
                    <Grid key={element} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'} mt={2}>
                      <CheckedIconContainer/><Text>{element}</Text>
                    </Grid>)
                }</>
              </Grid>}
              {type === 'acromegaly' && firstStage.metabolicDiseasesAnotherMedicines && <><Grid item mt={1} xs={6} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                <CheckedIconContainer/><Text>{'inne leki (jakie?)'}</Text>
              </Grid>
              <Grid item ml={4} mt={2} mb={1} xs={12} >
                <Input multiline={true} disabled variant={'standard'} required={true} name={'metabolicDiseasesAnotherMedicines'}
                       type={'text'}
                       value={firstStage.metabolicDiseasesAnotherMedicines} />
              </Grid></>}
              {type === 'cushing' && firstStage.metabolicDiseasesDiabetesMedicinesAnother && <><Grid item mt={1} xs={6} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                <CheckedIconContainer/><Text>{'inne leki (jakie?)'}</Text>
              </Grid>
                <Grid item ml={4} mt={2} mb={1} xs={12} >
                  <Input multiline={true} disabled variant={'standard'} required={true} name={'metabolicDiseasesDiabetesMedicinesAnother'}
                         type={'text'}
                         value={firstStage.metabolicDiseasesDiabetesMedicinesAnother} />
                </Grid></>}

              {firstStage.metabolicDiseasesAnotherDiseases && <><Grid item mt={1} xs={6} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                <CheckedIconContainer/><Text>{'inne choroby (jakie?)'}</Text>
              </Grid>
              <Grid item ml={4} mt={2} mb={1} xs={12} >
                <Input multiline={true} disabled variant={'standard'} required={true} name={'metabolicDiseasesAnotherDiseases'}
                       type={'text'}
                       value={firstStage.metabolicDiseasesAnotherDiseases} />
              </Grid></>}
            </Grid>}

            {cancersSection && <Grid container display={'flex'} alignItems={'center'} mt={2}>
              <Grid item xs={12} mt={2}>
                <Text>Choroby nowotworowe:</Text>
              </Grid>
              {firstStage.cancers.length > 0 && <Grid container display={'flex'} flexDirection={'column'}  width={'100%'}>
                <>{
                  firstStage.cancers.map(element=>
                    <Grid key={element} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                      <CheckedIconContainer/><Text>{element}</Text>
                    </Grid>)
                }</>
              </Grid>}
              {firstStage.cancersAnother && <>
                <Grid item mt={1} xs={6} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                  <CheckedIconContainer/><Text>{'inne (jakie?)'}</Text>
                </Grid>
                <Grid item ml={4} mt={2} mb={1} xs={12} >
                  <Input multiline={true} disabled variant={'standard'} required={true} name={'cancersAnother'} type={'text'}
                         value={firstStage.cancersAnother} />
                </Grid> </>}
            </Grid>}

            {endoctrineDisordersSection && <Grid container display={'flex'} alignItems={'center'} mt={2}>
              <Grid item xs={12} mt={2}>
                <Text>Inne zaburzenia endokrynologiczne:</Text>
              </Grid>
              {firstStage.endoctrineDisorders.length > 0 && <Grid container display={'flex'} flexDirection={'column'}  width={'100%'}>
                <>{
                  firstStage.endoctrineDisorders.map(element=>
                      <Grid key={element} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                        <CheckedIconContainer/><Text>{element}</Text>
                      </Grid>)
                }</>
              </Grid>}
              {firstStage.endoctrineDisordersAnother && <>
                <Grid item mt={1} xs={6} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                  <CheckedIconContainer/><Text>{'inne (jakie?)'}</Text>
                </Grid>
                <Grid item ml={4} mt={2} mb={1} xs={12} >
                  <Input multiline={true} disabled variant={'standard'} required={true} name={'endoctrineDisordersAnother'} type={'text'}
                         value={firstStage.endoctrineDisordersAnother} />
                </Grid> </>}
            </Grid>}

            {type === 'acromegaly' && movementOrganDiseasesSection && <Grid container display={'flex'} alignItems={'center'} mt={2}>
              <Grid item xs={12} mt={2}>
                <Text>Choroby narządu ruchu:</Text>
              </Grid>
              {firstStage.movementOrganDiseases.length > 0 && <Grid container display={'flex'} flexDirection={'column'}  width={'100%'}>
                <>{
                  firstStage.movementOrganDiseases.map(element=>
                      <Grid key={element} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                        <CheckedIconContainer/><Text>{element}</Text>
                      </Grid>)
                }</>
              </Grid>}
              {firstStage.movementOrganDiseasesAnother && <>
                <Grid item mt={1} xs={6} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                  <CheckedIconContainer/><Text>{'inne (jakie?)'}</Text>
                </Grid>
                <Grid item ml={4} mt={2} mb={1} xs={12} >
                  <Input multiline={true} disabled variant={'standard'} required={true} name={'movementOrganDiseasesAnother'} type={'text'}
                         value={firstStage.movementOrganDiseasesAnother} />
                </Grid> </>}
            </Grid>}

            {gastrointestinalDiseaseSection && <Grid container display={'flex'} alignItems={'center'} mt={2}>
              <Grid item xs={12} mt={2}>
                <Text>Choroby układu pokarmowego:</Text>
              </Grid>
              {movementElement && <>
                <Grid item mt={1} xs={6} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                  <CheckedIconContainer/><Text>{'kamica pęcherzyka żółciowego'}</Text>
                </Grid>
                <Grid item ml={4} mt={2} mb={1} xs={12} >
                  <Input disabled variant={'standard'} required={true} name={type === 'acromegaly' ? 'movementOrganDiseasesGallstones' : 'gastrointestinalDiseases'} type={'text'}
                         value={type === 'acromegaly' ? firstStage.movementOrganDiseasesGallstones : firstStage.gastrointestinalDiseases} />
                </Grid> </>}
            </Grid>}

          </Grid>
        </Grid>)
  }
  return <></>

});