import { Header } from '../../shared/header/Header';
import { ImageHomeLayout, Section, TabsContainer } from '../../shared/user/layout/caseLayout/CaseLayout.styled';
import { BackgroundStyled } from '../../shared/background/Background.styled';
import { Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AuthController } from '../../shared/appLayer/authController/AuthController';
import { CaseViewTab } from './CaseView.types';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentContainer } from './CaseView.styled';
import { Description } from './description/Description';
import { Discussion } from './discussion/Discussion';
import { Forum } from './forum/Forum';
import { DiseaseType, ICaseStatus } from '../../../interfaces/common/common';
import { Comments } from './comments/Comments';
import { useCaseStore, usePoiStore } from '../../../store/hooks';
import { useCaseApi } from '../../../apiHooks';
import { observer } from 'mobx-react-lite';
import { Footer } from '../../shared/appLayer/footer/Footer';
import { GeneralLink } from '../../../utils';
import { isOfTypeCaseViewTab, isOfTypeDiseaseType } from './CaseView.helper';
import DownloadIcon from '@mui/icons-material/Download';

export const CaseView = observer(() => {
  const caseStore = useCaseStore();
  const { status } = caseStore;
  const params = useParams();
  const { type, id, caseTab } = params;
  const { getCase, downloadCaseViewPdf } = useCaseApi();
  const navigate = useNavigate();
  const poiStore = usePoiStore();
  const { errorCode } = poiStore;

  const [tab, setTab] = useState<CaseViewTab>(caseTab as CaseViewTab ?? 'description');

  useEffect(() => {

    if (errorCode === 404) {
      navigate(GeneralLink.PAGE_404);
    }
  }, [navigate, errorCode]);

  useEffect(() => {
    if (!isOfTypeCaseViewTab(String(caseTab)) || !isOfTypeDiseaseType(String(type))) {
      navigate(GeneralLink.PAGE_404);
    }
  }, [navigate, caseTab, type]);

  useEffect(() => {
    return () => caseStore.clearStore();
  }, [caseStore]);

  useEffect(() => {
    if (type && id) {
      void getCase(type, +id);
    }
  }, [getCase, id, type]);

  useEffect(() => {
    if (caseTab) {
      setTab(caseTab as CaseViewTab);
    }
  }, [caseTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: CaseViewTab) => {
    setTab(newValue);
    navigate(`/cases/${type}/${id}/${newValue}`);
  };

  const isDraftOrSentOrAccepting = status === ICaseStatus.DRAFT || status === ICaseStatus.SENT || status === ICaseStatus.TO_ACCEPTING;
  const isPublished = status === ICaseStatus.PUBLISHED;

  const handleOpen = async () => {
    if (id && type) {
      await downloadCaseViewPdf(id, type);
    }
  };
  return (
    <AuthController>
      <Header />
      <Section>
        <BackgroundStyled />
        <Grid container pr={4} pl={4}>
          <Grid item xs={4}>
            <ImageHomeLayout type={type as DiseaseType} />
          </Grid>
          <Grid item xs={8} position={'relative'}>
            <Grid display={'flex'}>
              <TabsContainer>
                <Tabs
                  className={String(type) === 'acromegaly' ? 'tabs-acromegaly' : 'tabs-cushing'}
                  value={tab}
                  onChange={handleChange}>
                  <Tab value='description' label='OPIS PRZYPADKU' />
                  {isPublished && <Tab value='discussion' label='DYSKUSJA Z EKSPERTEM' />}
                  {isPublished && <Tab value='forum' label='FORUM' />}
                  {isDraftOrSentOrAccepting && <Tab value='comments' label='UWAGI' />}
                </Tabs>
              </TabsContainer>
              {isPublished && <Button
                variant='contained'
                sx={{
                  margin: 'auto 0 auto auto',
                  height: '34px',
                  alignItem: 'center',
                  backgroundColor: `${String(type) === 'acromegaly' ? '#D60D47' : '#00843D'}`
                }}
                onClick={() => handleOpen()}
                startIcon={<DownloadIcon />}
              >
                <Typography variant='body1' sx={{ ml: '12px', fontWeight: '500' }}>
                  Pobierz pdf
                </Typography>
              </Button>}
            </Grid>
            <ContentContainer>
              {tab === 'description' && <Description />}
              {(isPublished && tab === 'discussion') && <Discussion />}
              {(isPublished && tab === 'forum') && <Forum />}
              {(isDraftOrSentOrAccepting && tab === 'comments') && <Comments />}
            </ContentContainer>
          </Grid>
        </Grid>
      </Section>
      <Footer />
    </AuthController>
  );
});