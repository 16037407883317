import { FormControlLabel, Grid, Radio } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react-lite';

import { Fieldset } from '../../../../../../shared/ui/form/fieldset/FieldsetContainer';
import { Input } from '../../../../../../shared/ui/form/input/Input';
import { Label } from '../../../../../../shared/ui/form/label/Label';
import { InputController } from '../../../../../../shared/ui/form/inputController/InputController';
import { useFirstStageVm } from './useFirstStage.vm';
import { IFirstStageValues, IInputControllerFirstStage } from './FirstStage.types';
import { CheckboxesGroup } from '../../../../../../shared/ui/form/checkboxesGroup/CheckboxesGroup';
import { SelectCustom } from '../../../../../../shared/ui/form/select/Select';
import {
  cancersDiseasesCheckboxesConfig,
  cardiovascularDiseasesCheckboxesConfig,
  endoctrineDisordersCheckboxesAcromegalyConfig,
  endoctrineDisordersCheckboxesCushingConfig,
  gallbladderStoneOptions,
  metabolicDiseasesDiabetesMedicinesSwitchesConfig,
  metabolicDiseasesDiabetesOptions,
  movementOrganDiseasesCheckboxesConfig
} from './FirstStage.helper';
import { LabelRadioGroup } from '../../../CaseDraft.styled';
import { RadioGroupStyled } from '../fourthStage/FourthStage.styled';
import { SwitchGroup } from '../../../../../../shared/ui/form/switchGroup/SwitchGroup';
import { checkEmptyNumber } from '../../../CaseDraft.helper';
import { ButtonsActions } from '../buttonsActions/ButtonsActions';

export const FirstStage = observer(() => {

  const {
    type,
    handleChangeArray,
    inputControllerElements,
    setActive,
    formik,
    handleSave,
    handleDraftSave,
    handleResetFieldset,
    activeFieldset
  } = useFirstStageVm();

  return (<Grid container justifyContent={'space-between'}>
    <Fieldset
      activeKey={activeFieldset}
      keyFieldset={'valid-fieldset-1'}
      legend={`Dotychczasowy przebieg ${type === 'cushing' ? 'zespołu Cushinga' : 'akromegalii'}`}
      handleReset={handleResetFieldset}>
      <Grid container display={'flex'} alignItems={'center'} mt={2}>
        <Grid item xs={3} pr={1}>
          <Input fullWidth={true} required={true} name={'name'} label={'Iniciały'} type={'text'} maxLength={3}
                 onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}
                 error={Boolean(formik.errors.name)} />
        </Grid>
        <Grid item xs={3} pr={1} pl={1}>
          <Input fullWidth={true} required={true} name={'years'} label={'Wiek (lata)'} type={'number'}
                 onChange={formik.handleChange} onBlur={formik.handleBlur} value={checkEmptyNumber(formik.values.years)}
                 error={Boolean(formik.errors.years)} />
        </Grid>
        <Grid item xs={6} pl={1} display={'flex'}>
          <RadioGroupStyled row={true} name={'gender'} value={formik.values.gender}>
            <LabelRadioGroup error={Boolean(formik.errors.gender)}>*Płeć:</LabelRadioGroup>
            <FormControlLabel onChange={formik.handleChange} value={'F'}
                              control={<Radio color={Boolean(formik.errors.gender) ? 'error' : 'primary'} />}
                              label='Kobieta' />
            <FormControlLabel onChange={formik.handleChange} value={'M'}
                              control={<Radio color={Boolean(formik.errors.gender) ? 'error' : 'primary'} />}
                              label='Mężczyzna' />
            <FormControlLabel onChange={formik.handleChange} value={'O'}
                              control={<Radio color={Boolean(formik.errors.gender) ? 'error' : 'primary'} />}
                              label='Inna' />
          </RadioGroupStyled>
        </Grid>
        <Grid item xs={4} pr={1} mt={2}>
          <Input fullWidth={true} required={true} name={'weight'} label={'Masa ciała (kg)'} type={'number'}
                 onChange={formik.handleChange} onBlur={formik.handleBlur}
                 value={checkEmptyNumber(formik.values.weight)}
                 error={Boolean(formik.errors.weight)} />
        </Grid>
        <Grid item xs={4} pr={1} pl={1} mt={2}>
          <Input fullWidth={true} required={true} name={'height'} label={'Wzrost (cm)'} type={'number'}
                 onChange={formik.handleChange} onBlur={formik.handleBlur}
                 value={checkEmptyNumber(formik.values.height)}
                 error={Boolean(formik.errors.height)} />
        </Grid>
        <Grid item xs={4} pr={1} pl={1} mt={2}>
          <Input fullWidth={true} disabled={true} name={'bmi'} label={'BMI'} type={'text'}
                 value={checkEmptyNumber(formik.values.bmi)} />
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Wywiad w zakresie chorób współistniejących'}>
      <Grid container>
        <Grid item xs={12} mt={1}>
          <Label>Choroby sercowo-naczyniowe:</Label>
        </Grid>
        <Grid item xs={12} mt={1}>
          <CheckboxesGroup<IFirstStageValues> name={'cardiovascularDiseases'}
                                              elements={cardiovascularDiseasesCheckboxesConfig}
                                              onChange={handleChangeArray}
                                              values={formik.values.cardiovascularDiseases} />
        </Grid>
        <Grid item xs={12} mt={4}>
          <Label>Choroby metaboliczne:</Label>
        </Grid>
        <Grid item xs={12} mt={1}>
          <InputController<IInputControllerFirstStage>
            setActive={setActive}
            active={inputControllerElements.metabolicDiseasesDiabetes}
            setField={formik.setFieldValue}
            inputName={'metabolicDiseasesDiabetes'}
            checkbox={{
              name: 'metabolicDiseasesDiabetesCheckbox',
              label: 'cukrzyca'
            }}>
            <SelectCustom disabled={!inputControllerElements.metabolicDiseasesDiabetes}
                          name={'metabolicDiseasesDiabetes'} label={'Typ cukrzycy'} onChange={formik.handleChange}
                          value={formik.values.metabolicDiseasesDiabetes} options={metabolicDiseasesDiabetesOptions} />
          </InputController>
        </Grid>
        {inputControllerElements.metabolicDiseasesDiabetes && <><Grid item xs={12} mt={2} mb={1}>
          <SwitchGroup<IFirstStageValues> name={'metabolicDiseasesDiabetesMedicines'}
                                          elements={metabolicDiseasesDiabetesMedicinesSwitchesConfig}
                                          onChange={handleChangeArray}
                                          values={formik.values.metabolicDiseasesDiabetesMedicines} />
        </Grid>

          {type === 'acromegaly' && <Grid item xs={12} mt={1}>
            <InputController<IInputControllerFirstStage>
              setActive={setActive}
              active={inputControllerElements.metabolicDiseasesAnotherMedicines}
              setField={formik.setFieldValue}
              switchComponent={{
                name: 'metabolicDiseasesAnotherMedicinesCheckbox',
                label: 'inne leki (jakie?)'
              }}
              inputName={'metabolicDiseasesAnotherMedicines'}>
              <Input disabled={!inputControllerElements.metabolicDiseasesAnotherMedicines}
                     name={'metabolicDiseasesAnotherMedicines'} type={'text'} onChange={formik.handleChange}
                     value={formik.values.metabolicDiseasesAnotherMedicines}
                     placeholder={'Wpisz jakie inne leki'} />
            </InputController>
          </Grid>}

          {type === 'cushing' && <Grid item xs={12} mt={1}>
            <InputController<IInputControllerFirstStage>
              setActive={setActive}
              active={inputControllerElements.metabolicDiseasesDiabetesMedicinesAnother}
              setField={formik.setFieldValue}
              switchComponent={{
                name: 'metabolicDiseasesDiabetesMedicinesAnotherCheckbox',
                label: 'inne leki (jakie?)'
              }}
              inputName={'metabolicDiseasesDiabetesMedicinesAnother'}>
              <Input disabled={!inputControllerElements.metabolicDiseasesDiabetesMedicinesAnother}
                     name={'metabolicDiseasesDiabetesMedicinesAnother'} type={'text'} onChange={formik.handleChange}
                     value={formik.values.metabolicDiseasesDiabetesMedicinesAnother}
                     placeholder={'Wpisz jakie inne leki'} />
            </InputController>
          </Grid>}
        </>}
        <Grid item xs={12} mt={1}>
          <InputController<IInputControllerFirstStage>
            setActive={setActive}
            active={inputControllerElements.metabolicDiseasesAnotherDiseases}
            setField={formik.setFieldValue}
            checkbox={{
              name: 'metabolicDiseasesAnotherDiseasesCheckbox',
              label: 'inne choroby (jakie?)'
            }}
            inputName={'metabolicDiseasesAnotherDiseases'}>
            <Input disabled={!inputControllerElements.metabolicDiseasesAnotherDiseases}
                   name={'metabolicDiseasesAnotherDiseases'} type={'text'} onChange={formik.handleChange}
                   value={formik.values.metabolicDiseasesAnotherDiseases}
                   placeholder={'Wpisz jakie inne choroby metaboliczne'} />
          </InputController>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Label>Choroby nowotworowe:</Label>
        </Grid>
        <Grid item xs={12} mt={1}>
          <CheckboxesGroup<IFirstStageValues> name={'cancers'} elements={cancersDiseasesCheckboxesConfig}
                                              onChange={handleChangeArray} values={formik.values.cancers} />

          <InputController<IInputControllerFirstStage>
            setActive={setActive}
            active={inputControllerElements.cancersAnother}
            setField={formik.setFieldValue}
            checkbox={{
              name: 'cancersAnotherCheckbox',
              label: 'inne (jakie?)'
            }}
            inputName={'cancersAnother'}>
            <Input disabled={!inputControllerElements.cancersAnother}
                   name={'cancersAnother'} type={'text'} onChange={formik.handleChange}
                   value={formik.values.cancersAnother}
                   placeholder={'Wpisz inne choroby nowotworowe'} />
          </InputController>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Label>Inne zaburzenia endokrynologiczne:</Label>
        </Grid>
        <Grid item xs={12} mt={1}>
          <CheckboxesGroup<IFirstStageValues> name={'endoctrineDisorders'}
                                              elements={type === 'acromegaly' ? endoctrineDisordersCheckboxesAcromegalyConfig : endoctrineDisordersCheckboxesCushingConfig}
                                              onChange={handleChangeArray} values={formik.values.endoctrineDisorders} />

          <InputController<IInputControllerFirstStage>
            setActive={setActive}
            active={inputControllerElements.endoctrineDisordersAnother}
            setField={formik.setFieldValue}
            checkbox={{
              name: 'endoctrineDisordersAnotherCheckbox',
              label: 'inne (jakie?)'
            }}
            inputName={'endoctrineDisordersAnother'}>
            <Input disabled={!inputControllerElements.endoctrineDisordersAnother}
                   name={'endoctrineDisordersAnother'} type={'text'} onChange={formik.handleChange}
                   value={formik.values.endoctrineDisordersAnother}
                   placeholder={'Wpisz jakie inne zaburzenia endokrynologiczne'} />
          </InputController>
        </Grid>
        {type === 'acromegaly' && <><Grid item xs={12} mt={4}>
          <Label>Choroby narządu ruchu:</Label>
        </Grid>
          <Grid item xs={12} mt={1}>
            <CheckboxesGroup<IFirstStageValues> name={'movementOrganDiseases'}
                                                elements={movementOrganDiseasesCheckboxesConfig}
                                                onChange={handleChangeArray}
                                                values={formik.values.movementOrganDiseases} />
          </Grid>
          <Grid item xs={12} mt={1}>
            <InputController<IInputControllerFirstStage>
              setActive={setActive}
              active={inputControllerElements.movementOrganDiseasesAnother}
              setField={formik.setFieldValue}
              checkbox={{
                name: 'movementOrganDiseasesAnotherCheckbox',
                label: 'inne (jakie?)'
              }}
              inputName={'movementOrganDiseasesAnother'}>
              <Input disabled={!inputControllerElements.movementOrganDiseasesAnother}
                     name={'movementOrganDiseasesAnother'} type={'text'} onChange={formik.handleChange}
                     value={formik.values.movementOrganDiseasesAnother}
                     placeholder={'Wpisz jakie inne choroby narządu ruchu'} />
            </InputController>
          </Grid></>}
        <Grid item xs={12} mt={4}>
          <Label>Choroby układu pokarmowego:</Label>
        </Grid>
        <Grid item xs={12} mt={1}>
          <InputController<IInputControllerFirstStage>
            setActive={setActive}
            active={type === 'acromegaly' ? inputControllerElements.movementOrganDiseasesGallstones : inputControllerElements.gastrointestinalDiseases}
            setField={formik.setFieldValue}
            checkbox={{
              name: 'movementOrganDiseasesGallstonesCheckbox',
              label: 'kamica pęcherzyka żółciowego'
            }}
            inputName={type === 'acromegaly' ? 'movementOrganDiseasesGallstones' : 'gastrointestinalDiseases'}>
            <SelectCustom
              disabled={type === 'acromegaly' ? !inputControllerElements.movementOrganDiseasesGallstones : !inputControllerElements.gastrointestinalDiseases}
              name={type === 'acromegaly' ? 'movementOrganDiseasesGallstones' : 'gastrointestinalDiseases'}
              placeholder={'Wybierz'} label={'Wybierz'} onChange={formik.handleChange}
              value={type === 'acromegaly' ? formik.values.movementOrganDiseasesGallstones : formik.values.gastrointestinalDiseases}
              options={gallbladderStoneOptions} />
          </InputController>
        </Grid>
      </Grid>
    </Fieldset>
    <ButtonsActions
      handleDraftSave={handleDraftSave}
      handleSave={handleSave} />
  </Grid>);
});



