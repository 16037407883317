import { Chpl } from './tabs/chpl/Chpl';
import { Programs } from './tabs/programs/Programs';
import { Materials } from './tabs/materials/Materials';
import { Publications } from './tabs/publications/Publications';
import { Links } from './tabs/links/Links';
import { IModalType } from '../../../interfaces/library/library';

export const libraryData = [
  {
    id: 1,
    title: 'chpl',
    component: <Chpl />
  },
  {
    id: 2,
    title: 'programs',
    component: <Programs />

  },
  {
    id: 3,
    title: 'materials',
    component: <Materials />
  },

  {
    id: 4,
    title: 'publications',
    component: <Publications />
  },
  {
    id: 5,
    title: 'links',
    component: <Links />
  }
];

export const titleType: IModalType = {
  add: 'DODAJ PLIK',
  edit: 'EDYCJA ELEMENTU',
  delete: 'USUWANIE PLIKU'
};

export const titleTypeLink: IModalType = {
  add: 'DODAJ ELEMENT',
  edit: 'EDYCJA ELEMENTU',
  delete: 'USUWANIE ELEMENTU'
};