import styled from 'styled-components';
import { themeComponentStyled, themeMUI } from '../../../../theme/theme';

export const CardContainer = styled.div<{ position: string }>`
  background-color: ${themeComponentStyled.colors.gray};

  & h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-height: 32px;
    min-height: 96px
  }

  & div {
    background-color: ${themeComponentStyled.colors.gray};
    cursor: pointer
  }

  & img {
    object-position: ${props => props.position};
  }

  & .MuiCardHeader-root {
    background-color: ${themeComponentStyled.colors.gray};
  }

  & .MuiCardActions-root {
    padding: 15px;
    gap: 8px;
    display: flex;
    justify-content: flex-end;
    background-color: ${themeComponentStyled.colors.gray} !important;
    cursor: pointer;

    & svg {
      cursor: pointer
    }
  }

  & .MuiCardMedia-root {
    opacity: 0.6;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }

  & .MuiCardContent-root {
    background-color: ${themeComponentStyled.colors.gray};
    padding-bottom: 16px !important;

    & h6 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-height: 32px;
      min-height: 96px
    }
  }

  & .MuiCardContent-root . card__content {
    height: 80px;
  }

  & hr {
    border-color: rgba(218, 218, 218, 0.12);
  }

  & a {
    text-decoration: none;
    color: ${themeMUI.palette.text.primary};
  }
`;