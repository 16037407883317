import styled from 'styled-components';
import { Accordion } from '@mui/material';
import { IAccordionType } from './AccordionCase.types';
import { themeMUI } from '../../../theme/theme';

export const AccordionCustom = styled(Accordion)<IAccordionType>`
  div {
    background: #DADADA;
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 157%;
    color: ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : (props.type === 'cushing' ? props.theme.colors.cushing1 : themeMUI.palette.primary.main)};
    letter-spacing: 0.1px;
    margin-bottom: 8px;

    rect {
      margin-right: 8px;
      fill: ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : (props.type === 'cushing' ? props.theme.colors.cushing1 : themeMUI.palette.primary.main)};
    }
  }

  > p {
    color: #575757;
    margin-bottom: 8px;
  }

  p {
    overflow-wrap: anywhere;
    white-space: pre-line
  }

  ${props => props.defaultExpanded && `
    svg.MuiSvgIcon-root {
      display: none !important;
    }
    
    div{
      cursor: default !important;
    }
  `}
`;

export const AccordionHeader = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    display: flex;
    gap: 12px;
    align-items: center;
  }
`;

export const Answear = styled.div`
  padding-top: 16px;
  padding-left: 48px;

  p {

    color: #575757;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
  }
`;

export const AccordionActionsContainer = styled.div`
  margin: 8px;
  width: 100%;
  padding-left: 48px;
`;

export const DividerContainer = styled.div`
  margin: 0 16px;
`;
