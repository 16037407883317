import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {pl} from "date-fns/locale";
import {TextField, TextFieldProps} from "@mui/material";
import { FC, useEffect, useState } from 'react';

import { DatePickerCustomContainer } from "./Date.styled";
import { dateStringify } from '../../../../../utils/dateStringify';
import { IDateProps } from './Date.types';
import { ActionBar } from './actionBar/ActionBar';
import { DATE_IS_STILL } from './Date.helper';

export const DateInput: FC<IDateProps> = ({
                                        required,
                                        disabled,
                                        views,
                                        openTo,
                                        value,
                                        name,
                                        inputFormat,
                                        onChange,
                                        error,
                                        label,
                                        textError,
                                        setIsActive,
                                        isActive = false,
                                        isStill = false,
                                        placeholder,
                                        onBlur,
                                        maxWidth,
                                        disabledPast,
                                        disableFuture,
                                        minDate
                                      }) => {
  const [isDisabled, setIsDisabled] = useState<boolean | null>(null);
  const [init, setInit] = useState<boolean>(true);

  useEffect(()=> {
    if(init || isActive !== isDisabled){
      setIsDisabled(isActive);
      setInit(true)
    }
  }, [isActive, init, isDisabled])

  const handleIsDisabled = () => {
    setIsDisabled(prevState => {
      setIsActive && setIsActive()
      const newValue = !prevState;
      onChange(newValue ? DATE_IS_STILL : null);
      return newValue;
    });
  }

  const isDay = views.find(view => view === 'day');

  const onChangeHandler = (value: any) => {
    const dateConvert = new Date(String(value));
    dateConvert.setDate(dateConvert.getDate() + 1);
    onChange(dateConvert as any);
    onBlur && onBlur();
  }

  const content = <LocalizationProvider width={'100%'} dateAdapter={AdapterDateFns} adapterLocale={pl}>
    <DatePicker
      inputFormat={inputFormat}
      disableFuture={disableFuture ?? true}
      disablePast={disabledPast ?? false}
      minDate={minDate}
      openTo={openTo}
      views={views}
      closeOnSelect={false}
      value={value}
      onChange={onChangeHandler}
      label={label}
      disabled={disabled}
      components={{
        ActionBar: () => isStill ? <ActionBar isDisabled={isDisabled} handleIsDisabled={handleIsDisabled}/> : null,
      }}
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
        if (params.inputProps) {
          if(isDisabled){
            params.inputProps.value = DATE_IS_STILL;
          }
          else{
            params.inputProps.value = value ? dateStringify(value, !!isDay): "";
          }

          return <TextField size='small' {...params} placeholder={placeholder} required={required} error={error} name={name} ref={params.inputRef} />;
        }

        return <TextField size='small' {...params} placeholder={placeholder} required={required} error={error}  name={name}  />;
      }}
    />
  </LocalizationProvider>;

  return (<DatePickerCustomContainer isError={false} maxWidth={maxWidth}>
    {content}
    {error && <p>{textError}</p>}
  </DatePickerCustomContainer>);
}

