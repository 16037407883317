import { ReactElement } from 'react';
import { IPermissionElement } from './PermissionContainer.types';
import { checkRoles } from '../../../utils/checkRoles';
import { RoleFunction } from '../../../constants/projectUsers';


export const generatePermissionElements =  (permissionsElement :IPermissionElement[], role: RoleFunction) => {

  let renderElement : ReactElement | null = null;
  permissionsElement.forEach(element => {
    if(checkRoles(role, element.permissions) && element.element){
      renderElement = element.element;
    }
  })

  return renderElement;
}