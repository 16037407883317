import { AuthController } from '../../shared/appLayer/authController/AuthController';
import React from 'react';
import libraryBcg from '../../../assets/img/libraryBackground.png';
import { Layer } from '../../shared/layer/Layer';
import { checkRoles } from '../../../utils/checkRoles';
import { RoleFunction } from '../../../constants/projectUsers';
import { CustomTable } from '../../shared/customTable/CustomTable';
import { useUsers } from './useUsers.vm';
import { Grid, Tab, Tabs } from '@mui/material';
import { UserModal } from './modal/UserModal';
import { observer } from 'mobx-react-lite';
import { TabsContainer } from '../../shared/user/layout/caseLayout/CaseLayout.styled';

interface IProps {
  tabsActive?: boolean;
}

export const Users: React.FC<IProps> = observer(({ tabsActive = true }) => {
  const {
    open,
    headCells,
    roleFunction,
    handleOpen,
    handleClose,
    typeModal,
    handleDelete,
    id,
    users,
    checkboxes,
    filter,
    handleChange,
    activateEmailHandler
  } = useUsers();

  return (
    <AuthController>
      <Layer image={libraryBcg} isSearch={false}>
        <TabsContainer>
          <Tabs
            value={filter}
            onChange={handleChange}
            className={`tabs-${filter}`}>
            {(tabsActive || filter === 'users') && <Tab value='users' label='UŻYTKOWNICY' />}
            {(tabsActive || filter === 'admins') && <Tab value='admins' label='ADMINISTRATORZY' />}
          </Tabs>
        </TabsContainer>
        <Grid container width={'100%'} pt={1}>
          {users && users.length > 0 && <CustomTable headCells={headCells} handleDelete={handleDelete}
                                                     isVisible={checkRoles(roleFunction, RoleFunction.ROLE_ADMIN)}
                                                     handleOpen={handleOpen}
                                                     tableConfigOrder={'name'}
                                                     width={'35%'}
                                                     data={users}
                                                     additionalCell={true}
                                                     activateEmailHandler={activateEmailHandler}
                                                     userType={filter === 'users' ? 'Publikujący' : undefined}
                                                     checkboxes={filter === 'users' ? checkboxes : undefined} />}
          {open && <UserModal filter={filter} id={id} typeModal={typeModal} open={open} handleClose={handleClose} />}
        </Grid>
      </Layer>
    </AuthController>
  );
});
