import styled from 'styled-components';
import { themeComponentStyled, themeMUI } from '../../../../theme/theme';

export const CardContainer = styled.div`
  background-color: ${themeComponentStyled.colors.gray};

  & .MuiCardHeader-root {
    background-color: ${themeComponentStyled.colors.gray};
  }

  & .MuiCardActions-root {
    padding: 15px;
    gap: 8px;
    display: flex;
    justify-content: flex-end;
    background-color: ${themeComponentStyled.colors.gray};
    cursor: pointer;

    & svg {
      cursor: pointer;
    }
  }

  & .MuiCardMedia-root {
    opacity: 0.6;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }

  & .MuiCardContent-root:last-child {
    background-color: ${themeComponentStyled.colors.gray};
    padding-bottom: 16px;
    height: 198px;

    & h6:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      line-height: 32px;
      max-height: 128px
    }
  }

  & .MuiCardContent-root:first-child {
    height: 80px;
    padding-bottom: 16px;
    background-color: ${themeComponentStyled.colors.gray};
  }

  & hr {
    border-color: rgba(218, 218, 218, 0.12);
  }

  & a {
    text-decoration: none;
    color: ${themeMUI.palette.text.primary};
  }

  & div {
    cursor: pointer;
  }

`;