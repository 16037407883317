import React, { useEffect } from 'react';
import { useLibraryStore } from '../../../store/hooks';
import { useLibraryApi } from '../../../apiHooks';

export const useSwitchActive = () => {
  const libraryStore = useLibraryStore();
  const { selectedCategoryObject } = libraryStore;
  const { switchCategoryActive } = useLibraryApi();
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    if (selectedCategoryObject) {
      setChecked(selectedCategoryObject.isActive);
    }
  }, [selectedCategoryObject]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (selectedCategoryObject) {
      void switchCategoryActive(selectedCategoryObject.id, event.target.checked);
    }
  };

  return {
    checked,
    handleChange
  };
};