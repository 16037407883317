import React, { useEffect, useState } from 'react';
import { useLibraryApi } from '../../../apiHooks';
import { useLibraryStore } from '../../../store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { GeneralLink } from '../../../utils';
import { LibraryType } from './Library.types';
import { isOfTypeLibraryType } from '../../shared/user/layout/caseLayout/Caselayout.helper';

export const useLibrary = () => {
  const { getCategories, getMedicamentCategories } = useLibraryApi();
  const libraryStore = useLibraryStore();
  const { categories } = libraryStore;
  const params = useParams();
  const navigate = useNavigate();
  const { libType } = params;
  const [value, setValue] = useState<LibraryType>(libType as LibraryType);

  useEffect(()=> {
    if(!isOfTypeLibraryType(String(value))){
      navigate(GeneralLink.PAGE_404);
    }
  }, [navigate, libType,value])


  const handleChange = (event: React.SyntheticEvent, newValue: LibraryType) => {
    setValue(newValue);
    libraryStore.setSelectedCategory(newValue);
    navigate(`/library/${newValue}`);
  };

  useEffect(() => {
    void getCategories();
    void getMedicamentCategories();
  }, [getCategories, getMedicamentCategories]);

  useEffect(() => {
    if (libType) {
      setValue(libType as LibraryType);
      libraryStore.setSelectedCategory(libType);
    }
  }, [libType, libraryStore]);

  return {
    value,
    handleChange,
    categories
  };
};