import { Layer } from '../../shared/layer/Layer';
import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { AuthController } from '../../shared/appLayer/authController/AuthController';
import { TabsContainer } from './Library.styled';
import { libraryData } from './libraryData';
import { useLibrary } from './useLibrary.vm';
import { observer } from 'mobx-react-lite';
import libraryBcg from './../../../assets/img/libraryBackground.png';

export const Library = observer(() => {
  const { value, handleChange, categories } = useLibrary();
  return (
    <AuthController>
      <Layer image={libraryBcg} isSearch={false}>
        <TabsContainer>
          <Tabs
            value={value}
            onChange={handleChange}
            className={`tabs-${value}`}>
            {categories.map((el, index) => !el.isDeleted &&
              <Tab key={el.id} value={el.label} id={el.id} label={el.name} />)}
          </Tabs>
          {libraryData.map((el) => (
            value === el.title ? <React.Fragment key={el.id}>{el.component}</React.Fragment> : null
          ))}
        </TabsContainer>
      </Layer>
    </AuthController>
  );
});