import * as yup from 'yup';

import {
  IInputControllerThirdStage,
  IThirdStageValues
} from './ThirdStage.types';
import { IOption } from '../../../../../../shared/ui/form/select/Select.types';
import { ICheckboxConfig } from '../../../../../../shared/ui/form/checkboxes/Checkboxes.types';

export const preparatoryTreatmentSomatostatinAnalogueTypeOptions: IOption[] = [
  {
    label: 'Oktreotyd LAR',
    value: 'Oktreotyd LAR'
  },
  {
    label: 'Lanreotyd Autogel',
    value: 'Lanreotyd Autogel'
  }
];

export const oktreotydDoseOptions: IOption[] = [
  {
    label: '10',
    value: '10'
  },
  {
    label: '20',
    value: '20'
  },
  {
    label: '30',
    value: '30'
  },
  {
    label: '40',
    value: '40'
  }
];

export const lanreotydDoseOptions: IOption[] = [
  {
    label: '60',
    value: '60'
  },
  {
    label: '90',
    value: '90'
  },
  {
    label: '120',
    value: '120'
  }
];

export const cabergolineDoseOptions: IOption[] = [
  {
    label: '20',
    value: '20'
  },
  {
    label: '40',
    value: '40'
  },
  {
    label: '60',
    value: '60'
  }
];

export const pegvisomantDoseOptions: IOption[] = [
  {
    label: '10',
    value: '10'
  },
  {
    label: '15',
    value: '15'
  },
  {
    label: '20',
    value: '20'
  },
  {
    label: '25',
    value: '25'
  },
  {
    label: '30',
    value: '30'
  }
];


export const qualificationForTreatmentNeurosurgicalOptions: IOption[] = [
  {
    label: 'przezklinowego',
    value: 'przezklinowego'
  },
  {
    label: 'przezczaszkowego',
    value: 'przezczaszkowego'
  }
];

export const startOfTreatmentPasireotideLARNormalizationLevelOptions: IOption[] = [
  {
    label: '< 0,4 ug/dl',
    value: '< 0,4 ug/dl'
  },
  {
    label: '< 1,0 ug/dl',
    value: '< 1,0 ug/dl'
  }
];

export const carbohydrateMetabolismDisordersTypeOptions: IOption[] = [
  {
    label: 'nieprawidłowa glikemia na czczo',
    value: 'nieprawidłowa glikemia na czczo'
  },
  {
    label: 'nieprawidłowa tolerancja glukozy',
    value: 'nieprawidłowa tolerancja glukozy'
  },
  {
    label: 'cukrzyca',
    value: 'cukrzyca'
  }
];

export const complicationsOfNeurosurgicalSurgariesCheckboxesConfig: ICheckboxConfig[] = [
  {
    label: 'Gonadotropowa niedoczynność przedniego płata przysadki',
    name: 'Gonadotropowa niedoczynność przedniego płata przysadki'
  },
  {
    label: 'Kortykotropowa niedoczynność przedniego płata przysadki',
    name: 'Kortykotropowa niedoczynność przedniego płata przysadki'
  },
  {
    label: 'Tyreotropowa niedoczynność przedniego płata przysadki',
    name: 'Tyreotropowa niedoczynność przedniego płata przysadki'
  }
];

export const toleranceTreatmentCheckboxesConfig: ICheckboxConfig[] = [
  {
    label: 'wzrost aktywności ALT/AST',
    name: 'wzrost aktywności ALT/AST',
    size: 4
  },
  {
    label: 'wzrost aktywności GGTP',
    name: 'wzrost aktywności GGTP',
    size: 4
  },
  {
    label: 'wydłużenie odstępu QT',
    name: 'wydłużenie odstępu QT',
    size: 4
  },
  {
    label: 'kamica pęcherzyka żółciowego lub kamica przewodowa nieobecna wcześniej',
    name: 'kamica pęcherzyka żółciowego lub kamica przewodowa nieobecna wcześniej',
    size: 12
  }
];

export const supportiveTreatmentOfCoexistingDiseasesCheckboxesConfig: ICheckboxConfig[] = [
  {
    label: 'uzyskanie kontroli glikemii',
    name: 'uzyskanie kontroli glikemii'
  },
  {
    label: 'uzyskanie kontroli ciśnienia tętniczego',
    name: 'uzyskanie kontroli ciśnienia tętniczego'
  },
  {
    label: 'uzyskanie kontroli niewydolności serca',
    name: 'uzyskanie kontroli niewydolności serca'
  },
  {
    label: 'konieczność leczenia operacyjnego z powodu choroby zwyrodnieniowej stawów kręgosłupa lub biodra',
    name: 'konieczność leczenia operacyjnego z powodu choroby zwyrodnieniowej stawów kręgosłupa lub biodra'
  },
  {
    label: 'konieczność leczenia operacyjnego z powodu nowotworów złośliwych',
    name: 'konieczność leczenia operacyjnego z powodu nowotworów złośliwych'
  }
];
export const startOfTreatmentPasireotideLARReasonsCheckboxesConfig: ICheckboxConfig[] = [

  {
    label: 'brak kontroli biochemicznej',
    name: 'brak kontroli biochemicznej'
  },
  {
    label: 'bóle głowy',
    name: 'bóle głowy'
  },
  {
    label: 'utrzymujące się objawy kliniczne',
    name: 'utrzymujące się objawy kliniczne'
  }
];

export const startOfTreatmentPegvisomantReasonsCheckboxesConfig: ICheckboxConfig[] = [
  {
    label: 'brak kontroli biochemicznej',
    name: 'brak kontroli biochemicznej'
  },
  {
    label: 'zaburzenia gospodarki węglowodanowej',
    name: 'zaburzenia gospodarki węglowodanowej'
  },
  {
    label: 'utrzymujące się objawy kliniczne',
    name: 'utrzymujące się objawy kliniczne'
  }
];

export const validationThirdStageSchema = yup.object().shape({
  achievingHormonalNormalizationGh: yup
    .string()
    .required(),
  achievingHormonalNormalizationIgf1: yup
    .string()
    .required()

});

export const initialThirdStage: IThirdStageValues = {
  numberOfMonthsFromDiagnosisToTreatment: '',
  reasonForNotTreating: '',
  somastatinAnalogs: [],
  cabergolines: [],
  withoutPharmacologicalPreparation: '',
  surgeries: [],
  complicationsDiabetesInsipidus: '',
  complicationsDiabetesInsipidusHow: [],
  treatmentOfChronicAcromegallyAnalogues: [],
  treatmentOfChronicAcromegallyPasireotides: [],
  treatmentOfChronicAcromegallyPegvisomants: [],
  treatmentOfChronicAcromegallyCabergolines: [],
  qualificationForSurgicalTreatmentHowAccess: '',
  qualificationForRadiotherapyTreatmentType: '',
  achievingHormonalNormalizationGh: '',
  achievingHormonalNormalizationIgf1: '',
  achievingHormonalNormalizationIgf1Rate: '',
  serumPrlGrade: '',
  toleranceOfTheTreatmentUsed: [],
  toleranceOfTheTreatmentUsedType: '',
  treatmentOfComorbidities: [],
  treatmentOfComorbiditiesCheck: [],
  initiationOfDrugTreatmentWithPasireotideLarReason: [],
  initiationOfDrugTreatmentWithPasireotideLarConcentration: '',
  initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1: '',
  startTreatmentWithPegvisomantNormalizationUg: [],
  startTreatmentWithPegvisomantNormalizationIGF1: '',
  qualificationForSurgicalTreatmentIsActive: false,
  qualificationForRadiotherapyTreatmentIsActive: false,
  initiationOfDrugTreatmentWithPasireotideLarIsActive: false,
  startTreatmentWithPegvisomantNormalizationIsActive: false
};

export const checkboxInputControllerConfig: IInputControllerThirdStage = {
  numberOfMonthsFromDiagnosisToTreatment: false,
  reasonForNotTreating: false,
  withoutPharmacologicalPreparation: false,
  qualificationForSurgicalTreatmentHowAccess: false,
  qualificationForRadiotherapyTreatmentType: false,
  toleranceOfTheTreatmentUsedType: false,
  initiationOfDrugTreatmentWithPasireotideLarConcentration: false,
  initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1: false
};


export const convertInputControllerThirdStage = (thirdStage: IThirdStageValues) => {
  return {
    numberOfMonthsFromDiagnosisToTreatment: thirdStage.numberOfMonthsFromDiagnosisToTreatment !== '',
    reasonForNotTreating: thirdStage.reasonForNotTreating !== '',
    somastatinAnalogs: thirdStage.somastatinAnalogs.length > 0,
    cabergolines: thirdStage.cabergolines.length > 0,
    withoutPharmacologicalPreparation: thirdStage.withoutPharmacologicalPreparation !== '',
    treatmentOfChronicAcromegalyAnalogues: thirdStage.treatmentOfChronicAcromegallyAnalogues.length > 0,
    treatmentOfChronicAcromegallyPasireotides: thirdStage.treatmentOfChronicAcromegallyPasireotides.length > 0,
    treatmentOfChronicAcromegalyPegvisomants: thirdStage.treatmentOfChronicAcromegallyPegvisomants.length > 0,
    treatmentOfChronicAcromegallyCabergolines: thirdStage.treatmentOfChronicAcromegallyCabergolines.length > 0,
    qualificationForSurgicalTreatmentHowAccess: thirdStage.qualificationForSurgicalTreatmentHowAccess !== '',
    qualificationForRadiotherapyTreatmentType: thirdStage.qualificationForRadiotherapyTreatmentType !== '',
    toleranceOfTheTreatmentUsedType: thirdStage.toleranceOfTheTreatmentUsedType !== '',
    initiationOfDrugTreatmentWithPasireotideLarReason: thirdStage.initiationOfDrugTreatmentWithPasireotideLarReason.length > 0,
    initiationOfDrugTreatmentWithPasireotideLarConcentration: thirdStage.initiationOfDrugTreatmentWithPasireotideLarConcentration !== '',
    initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1: thirdStage.initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1 !== '',
    startTreatmentWithPegvisomantNormalizationUg: thirdStage.startTreatmentWithPegvisomantNormalizationUg.length > 0
  };
};