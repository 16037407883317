import React, { FC } from 'react';
import { Button, Grid } from '@mui/material';
import { Input } from '../../../../../../../shared/ui/form/input/Input';
import { DateInput } from '../../../../../../../shared/ui/form/date/Date';
import { GenericIcon } from '../../../../../../../shared/genericIcon/GenericIcon';
import { themeMUI } from '../../../../../../../../theme/theme';
import { useArraySection } from '../../../../useArraySection';
import { useErrorEndDate } from '../../../acromegaly/useErrorEndDate';
import {
  initialSteroidogenesisInhibitorOther
} from './SteroidogenesisInhibitorOther.helper';

import {
  ISteroidogenesisInhibitorOther,
  ISteroidogenesisInhibitorOtherProps
} from './SteroidogenesisInhibitorOther.types';

export const SteroidogenesisInhibitorOther: FC<ISteroidogenesisInhibitorOtherProps> = ({id, initial, handleAddSteroidogenesisInhibitorOther, editable, handleDeleteSteroidogenesisInhibitorOther}) => {
  const {formik, handleOnClick, disabledAdd} = useArraySection<ISteroidogenesisInhibitorOther>(initial ?? initialSteroidogenesisInhibitorOther, handleAddSteroidogenesisInhibitorOther)

  const errorDate = useErrorEndDate(formik, formik.values.dateStart, formik.values.dateEnd)

  return <>
    {!editable &&  <Grid item xs={0.5} mt={1} mb={editable ? 0: 1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                         justifyContent={'center'}>
      {Number(id) + 1}.
    </Grid>}
    <Grid item xs={2.75} mt={1} mb={editable ? 0: 1}>
      <Input
        type={'text'}
        disabled={!editable}
        name={'title'}
        placeholder={'nazwa'}
        label={'Nazwa'}
        onChange={formik.handleChange}
        value={formik.values.title} />
    </Grid>
    <Grid item xs={2.75} pl={1} mt={1} mb={editable ? 0: 1}>
      <Input
        type={'text'}
        disabled={!editable}
        name={'dose'}
        placeholder={'dawka (mg/tydzień)'}
        label={'Dawka (mg/tydzień)'}
        onChange={formik.handleChange}
        value={formik.values.dose} />
    </Grid>

    <Grid item xs={2.75} pl={1} mt={1} mb={editable ? 0: 1}>
      <DateInput
        disabled={!editable}
        label={'okres - OD'}
        required={true}
        views={['year', 'month']}
        openTo={'year'}
        name={'dateStart'}
        inputFormat={'mm.yyyy'}
        value={formik.values.dateStart}
        onBlur={() => formik.setFieldTouched('dateStart', true)}
        onChange={(e) => formik.setFieldValue('dateStart', e)} />
    </Grid>
    <Grid item xs={2.75} pl={1} mt={1} mb={editable ? 0: 1}>
      <DateInput
        disabled={!editable}
        label={'okres - DO'}
        isStill={true}
        required={true}
        views={['year', 'month']}
        openTo={'year'}
        name={'dateEnd'}
        error={errorDate}
        inputFormat={'mm.yyyy'}
        value={formik.values.dateEnd}
        isActive={formik.values.dateEndIsActive}
        setIsActive={() => formik.setFieldValue('dateEndIsActive', !formik.values.dateEndIsActive)}
        onBlur={() => formik.setFieldTouched('dateEnd', true)}
        onChange={(e) => formik.setFieldValue('dateEnd', e)} />
    </Grid>
    {handleDeleteSteroidogenesisInhibitorOther &&
      <Grid item xs={0.5} pl={1} display={'flex'} justifyContent={'center'}>
        <div style={{ margin: 'auto' }} onClick={() => {
          if (typeof id === 'number') {
            handleDeleteSteroidogenesisInhibitorOther(id);
          }
        }}>
          <GenericIcon
            iconName='Close'
            style={{
              color: themeMUI.palette.primary.main,
              cursor: 'pointer',
              verticalAlign: 'middle'
            }} />
        </div>
      </Grid>
    }
    {handleAddSteroidogenesisInhibitorOther &&
      <Grid item xs={1} pl={1} pt={1} display={'flex'} justifyContent={'center'}>
        <Button disabled={disabledAdd || errorDate} variant='contained' color={'primary'}
                onClick={handleOnClick}>ZAPISZ</Button>
      </Grid>
    }
  </>;
}