import * as yup from 'yup';

import { ICheckboxConfig } from '../../../../../../shared/ui/form/checkboxes/Checkboxes.types';
import { RegexRules } from '../../../../../../../utils';
import { IFirstStageValues, IInputControllerFirstStage } from './FirstStage.types';
import { IOption } from '../../../../../../shared/ui/form/select/Select.types';

export const cardiovascularDiseasesCheckboxesConfig: ICheckboxConfig[] = [
  {
    label: 'nadciśnienie tętnicze',
    name: 'nadciśnienie tętnicze'
  },
  {
    label: 'zespół zakrzepowo-zatorowy',
    name: 'zespół zakrzepowo-zatorowy'
  },
  {
    label: 'udar mózgu',
    name: 'udar mózgu'
  },
  {
    label: 'niewydolność serca',
    name: 'niewydolność serca'
  },
  {
    label: 'wady zastawkowe',
    name: 'wady zastawkowe'
  },
  {
    label: 'choroba wieńcowa',
    name: 'choroba wieńcowa'
  }
];

export const metabolicDiseasesDiabetesMedicinesSwitchesConfig: ICheckboxConfig[] = [
  {
    label: 'metformina',
    name: 'metformina'
  },
  {
    label: 'pochodna sulfonylomocznika',
    name: 'pochodna sulfonylomocznika'
  },
  {
    label: 'analog GLP-1',
    name: 'analog GLP-1'
  },
  {
    label: 'insulina',
    name: 'insulina'
  }
];

export const cancersDiseasesCheckboxesConfig: ICheckboxConfig[] = [
  {
    label: 'rak jelita grubego',
    name: 'rak jelita grubego'
  },
  {
    label: 'rak sutka',
    name: 'rak sutka'
  },
  {
    label: 'rak tarczycy',
    name: 'rak tarczycy'
  }
];


export const endoctrineDisordersCheckboxesAcromegalyConfig: ICheckboxConfig[] = [
  {
    label: 'zespół PCO',
    name: 'zespół PCO'
  },
  {
    label: 'wole guzkowe',
    name: 'wole guzkowe'
  }
];

export const endoctrineDisordersCheckboxesCushingConfig: ICheckboxConfig[] = [
  {
    label: 'zespół PCO',
    name: 'zespół PCO'
  },
  {
    label: 'wole guzkowe',
    name: 'wole guzkowe'
  },
  {
    label: 'osteopenia/osteoporoza',
    name: 'osteopenia/osteoporoza'
  }
];

export const movementOrganDiseasesCheckboxesConfig: ICheckboxConfig[] = [
  {
    label: 'zmiany zwyrodnieniowe kręgosłupa',
    name: 'zmiany zwyrodnieniowe kręgosłupa'
  },
  {
    label: 'zmiany zwyrodnieniowe stawu biodrowego',
    name: 'zmiany zwyrodnieniowe stawu biodrowego'
  },
  {
    label: 'zespół cieśni nadgarstka',
    name: 'zespół cieśni nadgarstka'
  }
];

export const metabolicDiseasesDiabetesOptions: IOption[] = [{
  label: 'typ 1',
  value: 'typ 1'
},
  {
    label: 'typ 2',
    value: 'typ 2'
  },
  {
    label: 'inne',
    value: 'inne'
  }
];


export const gallbladderStoneOptions: IOption[] = [{
  label: 'Tak',
  value: 'Tak'
},
  {
    label: 'Nie',
    value: 'Nie'
  },
  {
    label: 'Stan po cholecystektomii',
    value: 'Stan po cholecystektomii'
  }
];

export const checkboxInputControllerConfig: IInputControllerFirstStage = {
  cardiovascularDiseases: false,
  metabolicDiseasesDiabetes: false,
  metabolicDiseasesAnotherMedicines: false,
  metabolicDiseasesAnotherDiseases: false,
  metabolicDiseasesDiabetesMedicinesAnother: false,
  cancersAnother: false,
  endoctrineDisordersAnother: false,
  movementOrganDiseasesAnother: false,
  movementOrganDiseasesGallstones: false,
  gastrointestinalDiseases: false
};

export const convertInputControllerFirstStage = (firstStage: IFirstStageValues) => {
  return {
    cardiovascularDiseases: firstStage.cardiovascularDiseases.length > 0,
    metabolicDiseasesDiabetes: firstStage.metabolicDiseasesDiabetes !== '',
    metabolicDiseasesAnotherMedicines: firstStage.metabolicDiseasesAnotherMedicines !== '',
    metabolicDiseasesAnotherDiseases: firstStage.metabolicDiseasesAnotherDiseases !== '',
    metabolicDiseasesDiabetesMedicinesAnother: firstStage.metabolicDiseasesDiabetesMedicinesAnother !== '',
    cancersAnother: firstStage.cancersAnother !== '',
    endoctrineDisordersAnother: firstStage.endoctrineDisordersAnother !== '',
    movementOrganDiseasesAnother: firstStage.movementOrganDiseasesAnother !== '',
    movementOrganDiseasesGallstones: firstStage.movementOrganDiseasesGallstones !== '',
    gastrointestinalDiseases: firstStage.gastrointestinalDiseases !== ''
  };
};
export const validationFirstStageSchema = yup.object().shape({
  name: yup
    .string()
    .matches(RegexRules.INITIALS)
    .required(),
  gender: yup
    .string()
    .required(),
  years: yup
    .string()
    .matches(RegexRules.NUMBER)
    .required(),
  weight: yup
    .string()
    .matches(RegexRules.NUMBER)
    .required(),
  height: yup
    .string()
    .matches(RegexRules.NUMBER)
    .required()
});

export const initialFirstStage: IFirstStageValues = {
  name: '',
  years: -1,
  gender: '',
  weight: -1,
  height: -1,
  bmi: -1,
  cardiovascularDiseases: [],
  metabolicDiseasesDiabetes: '',
  metabolicDiseasesDiabetesMedicines: [],
  metabolicDiseasesAnotherMedicines: '',
  metabolicDiseasesAnotherDiseases: '',
  metabolicDiseasesDiabetesMedicinesAnother: '',
  cancers: [],
  cancersAnother: '',
  endoctrineDisorders: [],
  endoctrineDisordersAnother: '',
  movementOrganDiseases: [],
  movementOrganDiseasesGallstones: '',
  movementOrganDiseasesAnother: '',
  gastrointestinalDiseases: ''
};
