
import React from 'react';
import { Grid } from '@mui/material';
import { DiseaseType, ICaseStatus } from '../../../../interfaces/common/common';
import { AccordionCase } from '../../../shared/accordion/AccordionCase';
import { observer } from 'mobx-react-lite';
import { IAccordionElement } from '../../../../interfaces/quPage/quPage';

import { useComments } from './useComments';
import { checkRoles } from '../../../../utils/checkRoles';
import { RoleFunction } from '../../../../constants/projectUsers';
import { FixedButton } from '../../../shared/user/layout/fixedButton/FixedButton';
import { ReactComponent as Pen } from '../../../../assets/icon/pen.svg';
import {EmptyList} from "../../../shared/customTable/emptyList/EmptyList";

export const Comments = observer(() => {
  const {idComment, items, type, status, roleFunction, handleEdit, emptyMessage} = useComments();

  return (
    <Grid container display={'flex'} gap={2} flexDirection={'column'}>
      {(status === ICaseStatus.DRAFT && checkRoles(roleFunction, [RoleFunction.ROLE_PUBLISHING])) && <FixedButton icon={<Pen/>} title={'EDYTUJ'} handleOnClick={handleEdit} />}
      {
          emptyMessage ? <EmptyList>{emptyMessage}</EmptyList> :  items.map(element =>
          <AccordionCase
            badgeVisibility={(element: IAccordionElement)=> {
              return element.id === idComment
            }}
            key={element.id}
            element={element}
            expandedDefault={true}
            type={type as DiseaseType}
            id={element.id}/>)
      }
    </Grid>
  )
});