import styled from 'styled-components';
import { DialogTitle } from '@mui/material';

export const ModalRootContent = styled.div`

  width: 100%;
`;

export const StyledDialogTitle = styled(DialogTitle)<{ textAlign: string }>`
  text-align: ${props => props.textAlign};
  display: flex;
  justify-content: space-between;
  align-items: center;

`;

export const ImgButton = styled.img`
  cursor: pointer;
`;