export interface INotification {
  id: number,
  title: string,
  status: string,
  dateWithHour: string,
  typeTitleSort: string;
  idCaseCushing: number | null,
  idCaseAcromegaly: number | null;
  idCaseCushingTitle: string | null
  idCaseAcromegalyTitle: string | null
  type: string
  typeTitleSortQuestionType: string | null
  typeTitleSortQuestionTypeQA: string | null
}

export enum NotificationStatus {
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export enum NotificationSelect {
  PUBLISH = 'PUBLISH',
  HIDDEN = 'HIDDEN',
  CORRECTION = 'CORRECTION',
  QUESTION = 'QUESTION'
}
