import { useRootStore } from '../RootStateContext';

export const useNotificationsStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.notificationsStore) {
    throw new Error('notificationsStore store should be defined');
  }

  return rootStore.notificationsStore;
};
