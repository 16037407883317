import { ICaseStatus } from '../../../interfaces/common/common';
import { RoleFunction } from '../../../constants/projectUsers';
import { AcceptAdminLink, ExpertAdminLink, GuestLink, PublishUserLink, ViewUserLink } from '../../../utils';

export const generateChipData = (status: ICaseStatus, roleFunction: RoleFunction, countNotification?: number) => {
  switch (status) {

    case ICaseStatus.SENT:
      switch (roleFunction) {
        case RoleFunction.ROLE_PUBLISHING :
          return {
            color: '#03A9F4',
            label: 'w akceptacji'
          };
        case RoleFunction.ROLE_ACCEPTING :
          return { color: '#E65100', label: 'do akceptacji' };
      }
      break;
    case ICaseStatus.TO_ACCEPTING:
      switch (roleFunction) {
        case RoleFunction.ROLE_PUBLISHING :
          return { color: '#03A9F4', label: 'w akceptacji' };
        case RoleFunction.ROLE_ACCEPTING :
          return { color: '#03A9F4', label: 'w akceptacji' };
        case RoleFunction.ROLE_EXPERT :
          return { color: '#E65100', label: 'do akceptacji' };
      }
      break;
    case ICaseStatus.PUBLISHED :
      return { color: '#1B5E20', label: 'opublikowany' };
    case ICaseStatus.DRAFT:
      return { color: countNotification ? '#E65100' : '#9E9E9E', label: countNotification ? 'uwagi' : 'szkic' };
    case ICaseStatus.HIDDEN:
      return { color: '#BDBDBD', label: 'ukryty' };
    default:
      return { color: '#1B5E20', label: '' };
  }
};

export const switchRole = (role: RoleFunction) => {
  switch (role) {
    case RoleFunction.ROLE_PUBLISHING:
      return PublishUserLink.CASE_VIEW;
    case RoleFunction.ROLE_VIEWING:
      return ViewUserLink.CASE_VIEW;
    case RoleFunction.ROLE_GUEST:
      return GuestLink.CASE_VIEW;
    case  RoleFunction.ROLE_ACCEPTING:
      return AcceptAdminLink.CASE_VIEW;
    case RoleFunction.ROLE_EXPERT:
      return ExpertAdminLink.CASE_VIEW;
  }
};