import { useAuthStore, useLibraryStore } from '../../../../../store/hooks';
import { useLibraryApi } from '../../../../../apiHooks';
import { useEffect } from 'react';
import { useSwitchActive } from '../../useSwitchActive.vm';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GeneralLink, generateLink } from '../../../../../utils';
import { useCookies } from 'react-cookie';
import { usePagination } from '../../../../../hooks/usePagination';

export const usePublications = (handleOpen: (type: string, id?: number) => void) => {
  const libraryStore = useLibraryStore();
  const {
    categories,
    pasyreotydArticles,
    osilodrostadArticles
  } = libraryStore;
  const { getArticles } = useLibraryApi();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { libType } = params;
  const { roleFunction } = useAuthStore();
  const { checked, handleChange } = useSwitchActive();
  const id = categories && categories.find(el => el.label === libType)?.id;
  const [, setCookie] = useCookies(['redirectLink']);

  useEffect(() => {
    if (libType && id) {
      void getArticles(id);
    }

  }, [getArticles, libType, libraryStore, id]);

  useEffect(() => {
    return () => libraryStore.clearArticles();
  }, [libraryStore]);

  const {
    handleSearch, search,
    currentPage: pasyreotydCurrentPage,
    totalPage: pasyreotydPageCount,
    handlePageChange: pasyreotydHandlePageChange,
    items: pasyreotydArticlesDrawArray
  } = usePagination(pasyreotydArticles, 9, ['title', 'author', 'description', 'publisher']);

  const {
    handleSearch: osilodrostadHandleSearch, search: osilodrostadSearch,
    currentPage: osilodrostadCurrentPage,
    totalPage: osilodrostadPageCount,
    handlePageChange: osilodrostadHandlePageChange,
    items: osilodrostadArticlesDrawArray
  } = usePagination(osilodrostadArticles, 9, ['title', 'author', 'description', 'publisher']);

  const handleDelete = (id: number) => {
    handleOpen('delete', id);
  };

  const handleEdit = (id: number) => {
    handleOpen('edit', id);
  };

  const handleClickByGuest = () => {
    const link = generateLink(roleFunction, GeneralLink.LOGIN_USER);
    setCookie('redirectLink', location.pathname);
    navigate(link);
  };

  return {
    pasyreotydPageCount,
    pasyreotydCurrentPage,
    osilodrostadPageCount,
    osilodrostadCurrentPage,
    handleSearch,
    search,
    osilodrostadHandleSearch,
    osilodrostadHandlePageChange,
    pasyreotydHandlePageChange,
    pasyreotydArticlesDrawArray,
    osilodrostadArticlesDrawArray,
    osilodrostadSearch,
    roleFunction,
    handleDelete,
    handleChange,
    checked,
    handleEdit,
    handleClickByGuest
  };
};