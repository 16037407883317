import { useAuthApi } from '../../../apiHooks';
import { useAuthStore } from '../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { RoleFunction } from '../../../constants/projectUsers';
import { GeneralLink, generateLink } from '../../../utils';
import { useModal } from '../modal/useModal';
import { useCookies } from 'react-cookie';

export const useHeader = () => {
  const { serverLogoutUser } = useAuthApi();
  const { roleFunction } = useAuthStore();
  const navigate = useNavigate();
  const { open, handleOpen, handleClose } = useModal();
  const [cookies, , removeCookie] = useCookies(['redirectLink']);
  const handleLogout = async () => {
    const roleFunctionState = roleFunction;
    if (cookies?.redirectLink) {
      removeCookie('redirectLink');
    }
    await serverLogoutUser();
    navigate(generateLink(roleFunctionState === RoleFunction.ROLE_VIEWING ? RoleFunction.ROLE_VIEWING : RoleFunction.ROLE_GUEST, roleFunctionState === RoleFunction.ROLE_VIEWING ? GeneralLink.LOGIN_USER : GeneralLink.LOGIN_PUBLISHING));
  };

  const handleLogin = async () => {
    navigate(generateLink(RoleFunction.ROLE_GUEST, GeneralLink.LOGIN_PUBLISHING));
  };

  return {
    handleLogout,
    handleLogin,
    roleFunction,
    open, handleOpen, handleClose
  };
};