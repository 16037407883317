import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { IAccordionElement } from '../interfaces/quPage/quPage';
import { IZeroStageValues } from '../components/pages/user/caseDraft/stages/common/zeroStage/ZeroStages.types';
import { IFirstStageValues } from '../components/pages/user/caseDraft/stages/common/firstStage/FirstStage.types';
import {
  ISecondStageValues as ISecondStageValuesCushing
} from '../components/pages/user/caseDraft/stages/cushing/secondStage/SecondStage.types';
import {
  ISecondStageValues as ISecondStageValuesAcromegaly
} from '../components/pages/user/caseDraft/stages/acromegaly/secoundStage/SecondStage.types';
import {
  IThirdStageValues as IThirdStageValuesAcromegaly
} from '../components/pages/user/caseDraft/stages/acromegaly/thirdStage/ThirdStage.types';
import {
  IThirdStageValues as IThirdStageValuesCushing
} from '../components/pages/user/caseDraft/stages/cushing/thirdStage/ThirdStage.types';
import { IFourthStageValues } from '../components/pages/user/caseDraft/stages/common/fourthStage/FourthStage.types';
import { ICaseStatus } from '../interfaces/common/common';

export class CaseStore {

  forum: IAccordionElement[] | null = null;
  discussion: IAccordionElement[] | null = null;
  comments: IAccordionElement[] | null = null;

  stage: number = 0;
  status: ICaseStatus = ICaseStatus.HIDDEN;
  zeroStage: IZeroStageValues | null = null;
  firstStage: IFirstStageValues | null = null;
  secondStage: ISecondStageValuesCushing | ISecondStageValuesAcromegaly | null = null;
  thirdStage: IThirdStageValuesAcromegaly | IThirdStageValuesCushing | null = null;
  fourthStage: IFourthStageValues | null = null;

  comment: string = '';

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setForum(forum: IAccordionElement[]) {
    this.forum = forum;
  }

  addStages(
    stage: number,
    status: ICaseStatus,
    zeroStage: IZeroStageValues,
    firstStage: IFirstStageValues | null,
    secondStage : ISecondStageValuesCushing | ISecondStageValuesAcromegaly | null,
    thirdStage: IThirdStageValuesAcromegaly | IThirdStageValuesCushing | null,
    fourthStage: IFourthStageValues | null
  ) {

    this.stage = stage;
    this.status = status;
    this.zeroStage = zeroStage;
    this.firstStage = firstStage;
    this.secondStage = secondStage;
    this.thirdStage = thirdStage;
    this.fourthStage = fourthStage;
  }

  setDiscussion(discussion: IAccordionElement[]) {
    this.discussion = discussion;
  }

  setComments(comments: IAccordionElement[]) {
    this.comments = comments;
  }

  saveComment(comment: string) {
    this.comment = comment;
  }

  clearStore() {
    this.forum = null;
    this.discussion = null;
    this.comments = null;
    this.stage = 0;
    this.status = ICaseStatus.DRAFT;
    this.zeroStage = null;
    this.firstStage = null;
    this.secondStage= null;
    this.thirdStage = null;
    this.fourthStage = null;
    this.comment= '';
  }
}