import Page404 from '../../../../pages/page404/Page404';
import UnderConstruction from '../../../../pages/underConstruction/UnderConstruction';
import Register from '../../../../pages/auth/register/Register';
import ChangePassword from '../../../../pages/auth/changePassword/ChangePassword';
import LoginUser from '../../../../pages/auth/login/loginUser/LoginUser';
import LoginPublishing from '../../../../pages/auth/login/loginPublishing/LoginPublishing';
import { GeneralLink } from '../../../../../utils';
import { Link } from './link.types';
import { HomeRedirect } from '../../../../pages/homeRedirect/HomeRedirect';

export const generalLinks: Link[] = [
  {
    path: GeneralLink.REDIRECT_HOME,
    element: <HomeRedirect />
  },
  {
    path: GeneralLink.LOGIN_USER,
    element: <LoginUser />
  },
  {
    path: GeneralLink.LOGIN_PUBLISHING,
    element: <LoginPublishing />
  },
  {
    path: GeneralLink.CHANGE_PASSWORD,
    element: <ChangePassword />
  },
  {
    path: GeneralLink.REGISTER,
    element: <Register />
  },
  {
    path: GeneralLink.UNDER_CONSTRUCTION,
    element: <UnderConstruction />
  },
  {
    path: GeneralLink.PAGE_404,
    element: <Page404 />
  },
  {
    path: GeneralLink.REST,
    element: <Page404 />
  }
];