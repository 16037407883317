import { StyledTableCell } from './CustomTable.styled';
import { TableCell } from '@mui/material';
import React from 'react';
import { RoleFunction } from '../../../constants/projectUsers';
import { checkRoles, convertRole } from '../../../utils/checkRoles';
import { useAuthStore } from '../../../store/hooks';
import { ReactComponent as Mail } from '../../../assets/icon/mail.svg';
import { convertLinkDate } from '../../../utils/dateStringify';
import { DiseaseType } from '../../pages/home/Home.types';

interface IProps {
  keyName: string;
  value: string | number | boolean;
  isActive: string | number;
  link: string;
  width: string;
  typeCase: DiseaseType;
  activateEmailHandler?: () => void;
  handleClickByGuest?: () => void;
}

export const CellSwitcher: React.FC<IProps> = ({
                                                 keyName,
                                                 value,
                                                 isActive,
                                                 link,
                                                 width,
                                                 typeCase,
                                                 activateEmailHandler,
                                                 handleClickByGuest
                                               }) => {
  const { roleFunction } = useAuthStore();

  switch (keyName) {
    case 'id':
    case 'link':
    case 'roles':
      return <TableCell width={width}>{convertRole(value as RoleFunction, typeCase)}</TableCell>;;
    case 'linkDate':
      return <TableCell width={width}>{convertLinkDate(value as string) ?? ''}</TableCell>;
    case 'title':
      return (
        <StyledTableCell align='left' width={width}>
          {checkRoles(roleFunction, RoleFunction.ROLE_GUEST) && isActive ?
            <div onClick={handleClickByGuest}>{value}</div> :
            <a href={link as string || '#'} target='_blank' rel='noreferrer'>{value}</a>}
        </StyledTableCell>
      );
    case 'email':
      return (
        <StyledTableCell width={width}><a href={`mailto:${value}`}>{value}</a></StyledTableCell>
      );
    case 'fullName':
      return (
        <StyledTableCell width={width}>{value}</StyledTableCell>
      );
    case 'active':
      return <StyledTableCell width={'50px'}>{!value && <Mail onClick={activateEmailHandler} />}</StyledTableCell>;
    default:
      return (<TableCell width={width}></TableCell>);
  }
};