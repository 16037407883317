import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const DialogTitleInside = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.15px;
  text-align: center;
  max-width: 693px;
  padding-top:16px;
  width: 95vw;
  & svg {
    margin-bottom: 8px;
  }
`


export const LinkEmail = styled(Link)`
  color: var(--primary-dark, #1E88E5);
  text-align: center;
  font-size: 24px;
  width:100%;
  font-style: normal;
  font-weight: 700;
  line-height: 133.4%;
  text-decoration: none;
  padding: 8px 0;
  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12))
`

export const LinkWebsite = styled(Link)`
  color: var(--primary-dark, #1E88E5);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; 
  letter-spacing: 0.15px;
  text-decoration: none;
  display: block;
`

export const FooterModal = styled.div`
`
