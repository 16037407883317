import { makeAutoObservable } from 'mobx';

import { RoleFunction } from '../constants/projectUsers';
import { RootStore } from './rootStore';
import { IAuthResponse } from '../interfaces/api/auth';

export class AuthStore {
  isUserLoad = false;
  isLoggedIn = false;
  isSessionExpired: boolean | null = null;
  authInfo: IAuthResponse | null = null;
  roleFunction: RoleFunction = RoleFunction.ROLE_GUEST;
  navigationLink: string | null = null;
  expertType: string | null = '';
  tokenEmail: string = '';

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  appLogin(authInfo: IAuthResponse) {
    this.isLoggedIn = true;
    this.authInfo = authInfo;
    this.roleFunction = authInfo.role;
    this.expertType = (this.roleFunction === RoleFunction.ROLE_EXPERT && authInfo.type) || '';
    this.isSessionExpired = false;
  }

  setRole(role: RoleFunction) {
    this.roleFunction = role;
  }

  get actualRoleFunction() {
    return this.roleFunction;
  }

  appLogout() {
    this.isLoggedIn = false;
    this.clearNavigationLink();
    this.isSessionExpired = true;
    this.roleFunction = RoleFunction.ROLE_GUEST;
  }

  userLoad() {
    this.setIsUserLoad(true);
  }

  clearNavigationLink() {
    this.navigationLink = null;
  }

  private setIsUserLoad(isUserLoad: boolean) {
    this.isUserLoad = isUserLoad;
  }

  setIsSessionExpired(isSessionExpired: boolean | null) {
    this.isSessionExpired = isSessionExpired;
  }

  setUserEmailBytoken(tokenEmail: string) {
    this.tokenEmail = tokenEmail;
  }
}
