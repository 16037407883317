import styled, { keyframes } from 'styled-components';
import { IHeaderProps } from '../header/Header.types';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const Section = styled.main`
  padding-top: 100px;
  color: black;
  padding-bottom: 32px;
  max-width: 1280px;
  margin: auto;
  min-height: calc(100vh - 86px);
`;

export const ImageHomeLayout = styled.div<{ image: string }>`
  background: url('${props => props.image}') no-repeat;
  width: 100%;
  height: calc(100% - 100px);
  position: absolute;
  left: 0;
  animation: 1s ${fadeIn} ease-out;
`;

export const BackgroundStyled = styled.div<IHeaderProps>`
  background: rgb(238, 238, 238);
  position: absolute;
  width: calc(100% + 32px);
  height: 48px;
  top: 100px;
  margin-top: ${props => props.isSearch ? '45px' : '0px'};
  z-index: 0;
  left: -32px;
`;
