import { CardContainer, LabelDiv } from './CardOther.styled';
import { Card, CardActions, CardContent, CardMedia, Divider, Paper, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as Pen } from '../../../../assets/icon/pen.svg';
import { ReactComponent as Bin } from '../../../../assets/icon/bin.svg';
import { observer } from 'mobx-react-lite';
import landscape from '../../../../assets/img/landscape.png';
import isturisa from '../../../../assets/img/isturisa.png';
import signifor from '../../../../assets/img/signifor.png';
import { ILibraryTypes } from '../../../../interfaces/library/library';
import { checkRoles } from '../../../../utils/checkRoles';
import { RoleFunction } from '../../../../constants/projectUsers';
import { useAuthStore } from '../../../../store/hooks';

interface IProps {
  title: string;
  link: string;
  image: string | null;
  type: string;
  date: string;
  id: number;
  imgHeight: string;
  handleDelete: (id: number) => void;
  handleEdit: (id: number) => void;
  handleClickByGuest?: () => void;
  isActive: boolean;
}

const imageSwitcher: ILibraryTypes = {
  akromegalia: landscape,
  cushing: landscape,
  isturisa: isturisa,
  signifor: signifor
};

export const CardComponent: React.FC<IProps> = observer(({
                                                           isActive,
                                                           id,
                                                           type,
                                                           date,
                                                           image,
                                                           link,
                                                           title,
                                                           imgHeight,
                                                           handleDelete,
                                                           handleEdit,
                                                           handleClickByGuest
                                                         }) => {
  const { roleFunction } = useAuthStore();

  const content = <>
    <div style={{ position: 'relative' }}>
      <CardMedia
        component='img'
        height={imgHeight}
        image={image || imageSwitcher[type as keyof ILibraryTypes]}
        alt={title}
      />
      {(type === 'akromegalia' || type === 'cushing') &&
        <LabelDiv type={type}>{type.toUpperCase()}</LabelDiv>}
    </div>
    <Divider />
    <CardContent>
      <Typography variant='h6'>
        {title}
      </Typography>
    </CardContent>
  </>;

  return (
    <Card>
      <CardContainer>
        <Paper elevation={1}>
          {checkRoles(roleFunction, RoleFunction.ROLE_GUEST) && isActive ?
            <div onClick={handleClickByGuest}>{content}</div> :
            <a href={link} target='_blank' rel='noreferrer'>
              {content}
            </a>}
          {checkRoles(roleFunction, RoleFunction.ROLE_ACCEPTING) && <>
            <Divider />
            <CardActions>
              <Pen onClick={() => handleEdit(id)} />
              <Bin onClick={() => handleDelete(id)} />
            </CardActions>
          </>}
        </Paper>
      </CardContainer>
    </Card>
  );
});