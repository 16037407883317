import { useAuthStore } from '../../../store/hooks';
import { useCasesListApi } from '../../../apiHooks';
import { checkRoles } from '../../../utils/checkRoles';
import { RoleFunction } from '../../../constants/projectUsers';
import { Param } from '../appLayer/appRouting/links/link.types';
import { GeneralLink, generateLink } from '../../../utils';
import { DiseaseType, ICaseStatus } from '../../../interfaces/common/common';
import { switchRole } from './CardItem.helper';
import { useNavigate } from 'react-router-dom';
import { ICardItem } from './CardItem.types';
import { useCookies } from 'react-cookie';

export const useCaseItem = (type: DiseaseType, item: ICardItem, handleClose?: () => void) => {
  const navigate = useNavigate();
  const { roleFunction } = useAuthStore();
  const [, setCookie] = useCookies(['redirectLink']);
  const { changeStatus, deleteDraft } = useCasesListApi(handleClose);

  const handleOnClick = () => {
    if (!checkRoles(roleFunction, [RoleFunction.ROLE_ADMIN])) {
      const params: Param[] = [
        [':type', type],
        [':id', item.id],
        [':caseTab', 'description']
      ];
      
      const currentLink = generateLink(roleFunction, switchRole(roleFunction), params);
      if (checkRoles(roleFunction, [RoleFunction.ROLE_GUEST])) {
        setCookie('redirectLink', currentLink);
        navigate(generateLink(roleFunction, GeneralLink.LOGIN_USER));
      } else {
        navigate(currentLink);
      }
    }
  };

  const status = item.status as string;

  const handleAction = () => {
    if (status === ICaseStatus.PUBLISHED) {
      void changeStatus(type, item.id, 'HIDDEN');

    } else if (status === ICaseStatus.HIDDEN) {
      void changeStatus(type, item.id, 'PUBLISHED');
    } else if (status === ICaseStatus.DRAFT) {
      void deleteDraft(type, item.id);
    }
  };

  const generateModal = (isActionAccept?: boolean, isActionPublish?: boolean) => {
    let title = '';
    let content = '';
    let button = '';
    if ((isActionAccept && status === ICaseStatus.HIDDEN)) {
      title = 'Przywracanie przypadku';
      content = 'Czy jesteś pewien/pewna że chcesz przywrócić widoczność przypadku?';
      button = 'Przywróć';
    } else if ((isActionAccept && status === ICaseStatus.PUBLISHED)) {
      title = 'Ukrywanie przypadku';
      content = 'Czy jesteś pewien/pewna że chcesz ukryć przypadek?';
      button = 'Ukryj';
    } else if (((isActionPublish && status === ICaseStatus.DRAFT) || isActionAccept)) {
      title = 'Usuwanie przypadku';
      content = 'Czy jesteś pewien/pewna że chcesz usunąć przypadek?';
      button = 'Usuń';
    }

    return {
      title,
      content,
      button
    };
  };

  return {
    generateModal,
    roleFunction,
    handleAction,
    handleOnClick,
    status
  };
};