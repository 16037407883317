import { FC } from 'react';
import { IButtonsActionsProps } from './ButtonsActions.types';
import { Button } from '@mui/material';
import { ButtonsActionsContainer } from './ButtonsActions.styled';

export const ButtonsActions: FC<IButtonsActionsProps> = ({
                                                           handleDraftSave,
                                                           handleSave,
                                                           isPreviousVisible = true,
                                                           mt = 4,
                                                           nextStep = 'Następny krok'
                                                         }) => {

  const handlePrevState = async () => {
    await handleDraftSave('previous', isPreviousVisible)
  };

  return (
    <ButtonsActionsContainer item display={'flex'} gap={1} mt={mt} xs={12}>
      {isPreviousVisible &&
        <Button fullWidth={true} onClick={handlePrevState} size={'large'} color={'primary'} variant={'outlined'}>Poprzedni
          krok</Button>}
      <Button fullWidth={true} onClick={() => handleDraftSave('current', isPreviousVisible)} size={'large'} color={'primary'}
              variant={'outlined'}>Zapisz
        szkic</Button>
      <Button fullWidth={true} onClick={handleSave} size={'large'} color={'primary'}
              variant={'contained'}>{nextStep}</Button>
    </ButtonsActionsContainer>
  );
};