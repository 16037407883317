import {useParams} from 'react-router-dom';
import {useModal} from '../../../shared/modal/useModal';
import {QUPageModal} from '../../qaPage/QUPage.types';
import {ModalType} from '../../../shared/modal/ModalRoot.types';
import {ModalAddQuestion} from '../../../shared/modal/common/modalAddQuestion/ModalAddQuestion';
import {
  ModalAddQuestionConfirmation
} from '../../../shared/modal/common/modalAddQuestionConfirmation/ModalAddQuestionConfirmation';
import {getModalSelectedByList} from '../../../shared/modal/ModalRoot.helper';
import React, {useEffect} from 'react';
import {useScroll} from '../useScroll';
import {useCaseApi} from '../../../../apiHooks';
import {useAuthStore, useCaseStore} from '../../../../store/hooks';
import {optionsAddQuestionForum} from './Forum.helper';
import {useSort} from '../../../../hooks/useSort';
import {useAnswer} from '../../../../hooks/useAnswer';
import {checkRoles} from "../../../../utils/checkRoles";
import {RoleFunction} from "../../../../constants/projectUsers";

export const useForumVm = () => {
  const {id, type} = useParams();
  const {getForum, postForumAnswer, putForumAnswerVisibility} = useCaseApi();
  const caseStore = useCaseStore();
  const {roleFunction} = useAuthStore();

  useScroll(caseStore.forum !== null);

  const {
    items,
    sortDirection,
    handleChangeSortDirection,
    resetSort
  } = useSort(caseStore.forum, ['answer', 'dateWithHour']);

  const {
    open,
    handleClose,
    handleOpen,
    typeModal
  } = useModal<QUPageModal>();

  const {
    getAnswer,
    handleAddAnswer,
    handleChangeAnswer,
    resetAnswers
  } = useAnswer(caseStore.forum,
    async (idQuestion: number, answer: string) => {
      await postForumAnswer(Number(id), String(type), idQuestion, answer);
      resetSort();
      resetAnswers();
    }
  )

  useEffect( () => {
    void getForum(Number(id), String(type),caseStore.forum === null);
  }, [getForum, id, type, caseStore]);

  const handleOpenModal = () => {
    handleOpen('add-question');
  };

  const handleConfirmation = () => {
    handleOpen('add-question-confirmation');
  };

  const handleSort = () => {
    handleChangeSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
  }

  const handleHiddenQuestion = async (idQuestion: number, visibility: boolean) => {
    await putForumAnswerVisibility(Number(id), idQuestion,  String(type), visibility);
    resetSort();
    resetAnswers();
  }

  const types: ModalType<QUPageModal>[] = [
    {
      key: 'add-question',
      element: <ModalAddQuestion type={'forum'}
                                 values={optionsAddQuestionForum}
                                 handleClose={handleClose}
                                 handleConfirmation={handleConfirmation}/>,
      title: 'Zadaj pytanie:'
    },
    {
      key: 'add-question-confirmation',
      element: <ModalAddQuestionConfirmation handleClose={handleClose} />,
      title: 'Pytanie wysłane',
      titleJustify: 'center'
    }
  ];

  const modalSelected = getModalSelectedByList<QUPageModal>(typeModal, types);

  const emptyMessage = (caseStore.forum  !== null && items.length === 0) ?
      (checkRoles(roleFunction, [RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_ACCEPTING]) ? 'Brak wypowiedzi na forum w wybranym opisie przypadku medycznego.':
      'Nie zadano żadnego pytania do autora opisu przypadku medycznego lub do eksperta merytorycznego. Chcesz być pierwszy? Użyj przycisku “Zadaj pytanie?” znajdującego się w lewej dolnej części ekranu.')
      : null;

  return {
    roleFunction,
    items,
    type,
    open,
    modalSelected,
    handleOpenModal,
    handleConfirmation,
    handleClose,
    handleOpen,
    handleSort,
    handleHiddenQuestion,
    getAnswer,
    handleAddAnswer,
    handleChangeAnswer,
    typeModal,
    emptyMessage
  };
};