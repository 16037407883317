import { Card, CardContent, CardMedia, Divider, Paper, Typography } from '@mui/material';
import { CardContainer } from './Card.styled';
import React from 'react';
import { checkRoles } from '../../../../utils/checkRoles';
import { RoleFunction } from '../../../../constants/projectUsers';
import { useAuthStore } from '../../../../store/hooks';
import { CardActionsButtons } from '../../caseItem/actions/cardActionsButtons';

interface IProps {
  title: string;
  id: number;
  image: string;
  link: string;
  imgHeight: string;
  style: string;
  handleDelete: (id: number) => void;
  handleEdit: (id: number) => void;
  isActive: boolean;
  handleClickByGuest?: () => void;
}

export const CardComponent: React.FC<IProps> = ({
                                                  style,
                                                  isActive,
                                                  handleDelete,
                                                  handleEdit,
                                                  id,
                                                  link,
                                                  title,
                                                  image,
                                                  imgHeight,
                                                  handleClickByGuest
                                                }) => {
  const { roleFunction } = useAuthStore();
  const content = <><CardMedia
    component='img'
    height={imgHeight}
    src={image}
    alt={title}
  />
    <Divider />
    <CardContent>
      <Typography variant='h6'>
        {title}
      </Typography>
    </CardContent>
  </>;
  return (
    <Card>
      <CardContainer position={style}>
        <Paper elevation={1}>
          {checkRoles(roleFunction, RoleFunction.ROLE_GUEST) && isActive ?
            <div onClick={handleClickByGuest}>{content}</div> :
            <a href={link} target='_blank' rel='noreferrer'>
              {content}
            </a>}
          {checkRoles(roleFunction, RoleFunction.ROLE_ACCEPTING) && <>
            <Divider />
            <CardActionsButtons
              handleEdit={() => handleEdit(id)}
              handleRemove={() => handleDelete(id)}
            />
          </>}
        </Paper>
      </CardContainer>
    </Card>
  );
};