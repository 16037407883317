import styled from 'styled-components';
import { IButtonContent } from './SortButton.types';
import { Grid } from '@mui/material';

export const ButtonContent = styled.span<IButtonContent>`
  color: ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : props.theme.colors.cushing1};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px; 
  letter-spacing: 0.4px;
  text-transform: uppercase;
`

export const GridCustom = styled(Grid)<IButtonContent>`
  path{
    fill: ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : props.theme.colors.cushing1} !important;
  }

`