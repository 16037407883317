import { Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .Mui-selected': {
    borderRadius: '50%',
    fontWeight: '400',
    height: '26px',
    width: '26px',
    padding: 0
  }
}));