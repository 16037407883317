import { generalLinks } from './generalLinks';
import { AdminLink } from '../../../../../utils';
import { Link } from './link.types';
import { Users } from '../../../../pages/users/Users';
import { Notifications } from '../../../../pages/notifications/Notifications';
import { HomeRedirect } from '../../../../pages/homeRedirect/HomeRedirect';
import { QUPages } from '../../../../pages/qaPage/QAPage';


export const onlyAdminLinks: Link[] = [
  {
    path: AdminLink.REDIRECT_HOME,
    element: <HomeRedirect />
  },
  {
    path: AdminLink.HOME,
    element: <Users />
  },
  {
    path: AdminLink.QA,
    element: <QUPages />
  },
  {
    path: AdminLink.NOTIFICATIONS,
    element: <Notifications />
  }
];

export const adminLinks = generalLinks.concat(onlyAdminLinks);