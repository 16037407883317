import { makeAutoObservable } from 'mobx';

import { RootStore } from './rootStore';

export class PoiStore {
  isGlobalLoader = false;
  isGlobalError = false;
  errorMessage: string | null = null;
  errorCode: number | null = null;
  error: string | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setGlobalLoader = () => (this.isGlobalLoader = true);
  unsetGlobalLoader = () => (this.isGlobalLoader = false);

  setGlobalError = (status: number, message: string, error?: string) => {
    this.isGlobalError = true;
    this.errorCode = status;
    this.errorMessage = message || 'skontaktuj się z administratorem';
    this.error = error ?? null;
  };

  clearError() {
    this.isGlobalError = false;
    this.errorCode = null;
    this.errorMessage = null;
    this.error = null;
  }


}
