import { useParams } from 'react-router-dom';
import { useCaseApi, useQAElementsApi } from '../../../../../../apiHooks';
import { IAddTypeQuestion, IModalAddQuestionProps } from '../ModalAddQuestion.types';
import { useForm } from '../../../../../../hooks/useForm';
import { initialAddTypeQuestion, validationAddTypeQuestionSchema } from '../ModalAddQuestion.helper';
import { Button, DialogActions, FormControlLabel, Grid, Radio } from '@mui/material';
import { SubHeader } from '../ModalAddQuestion.styled';
import { RadioGroupStyled } from '../../../../../pages/user/caseDraft/stages/common/fourthStage/FourthStage.styled';
import { Input } from '../../../../ui/form/input/Input';
import React, { FC } from 'react';

export const ModalAddQuestionType: FC<IModalAddQuestionProps> = ({ handleClose, handleConfirmation, values, type }) => {
  const params = useParams();
  const { postQAElement } = useQAElementsApi();
  const { postForumElement } = useCaseApi();

  const onSubmit = async (values: IAddTypeQuestion) => {
    let isCompleted = type === 'qa' ? await postQAElement(values) : await postForumElement(Number(params.id), String(params.type), values);

    if (isCompleted) {
      handleConfirmation();
    }
  };

  const {
    formik
  } = useForm<IAddTypeQuestion>(initialAddTypeQuestion, validationAddTypeQuestionSchema, onSubmit);

  return (
    <Grid container pb={2} pl={3} pr={3}>
      <Grid container maxWidth={'693px'} width={'95vw'} justifyContent={'flex-end'} mt={2}>
        <Grid item xs={12}>
          <SubHeader>Po udzieleniu odpowiedzi pytanie zostanie opublikowane i będzie widoczne na tej stronie dla
            wszystkich użytkowników.</SubHeader>
        </Grid>
        <Grid item xs={12} mt={2}>
          <RadioGroupStyled row={true} name={'type'} value={formik.values.type}>
            {values && values.map(element =>
              <FormControlLabel key={element.value} onChange={formik.handleChange} value={element.value} control={<Radio
                color={Boolean(formik.errors.type) ? 'error' : 'primary'} />} label={element.label} />
            )}
          </RadioGroupStyled>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Input error={Boolean(formik.errors.author)} name={'author'} type={'text'} onChange={formik.handleChange}
                 value={formik.values.author}
                 placeholder={'Imię i nazwisko (lub pseudonim)'} />
        </Grid>
        <Grid item xs={12} mt={2} mb={2}>
          <Input error={Boolean(formik.errors.questionName)} name={'questionName'} type={'text'} multiline={true}
                 onChange={formik.handleChange}
                 value={formik.values.questionName} rows={3} placeholder={'Treść pytania'}
                 errorMessage={formik.errors.questionName}
          />
        </Grid>
        <DialogActions>
          <Button disabled={false} variant='outlined' color={'primary'}
                  onClick={handleClose}>Anuluj</Button>
          <Button disabled={false} variant='contained' color={'primary'}
                  onClick={() => formik.handleSubmit()}>Wyślij</Button>
        </DialogActions>

      </Grid>
    </Grid>);

};