import styled from 'styled-components';
import { IDivider, ILabelRadioGroup } from './CaseDraft.types';
import { themeMUI } from '../../../../theme/theme';

export const ButtonNextContainer = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 396px;
  }
`;

export const HeaderCaseLayout = styled.h1`
  margin-top: 18px;
  color: #000;
  font-size: 34px;
  line-height: 42px;
  letter-spacing: 0.25px;
  font-weight: 400;
`;

export const RequiredFieldsInfo = styled.p`
  color: var(--text-secondary, rgba(0, 0, 0, 0.60));
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12))
`;

export const Divider = styled.div<IDivider>`
  margin: ${props => props.margin ?? 16}px 0;
  height: 1px;
  background: #E0E0E0;
  width: 100%;
`;

export const TextareaContainer = styled.div`
  margin-top: 8px;
`;

export const LabelRadioGroup = styled.div<ILabelRadioGroup>`
  color: ${props => props.error ? themeMUI.palette.error.dark : 'black'};
  margin-right: 10px;
  display: flex;
  align-items: center;
`;
