import { usePublications } from './usePublications.vm';
import { FormControl, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import React from 'react';
import { CardComponent } from '../../../../shared/card/cardPublications/Card';
import { observer } from 'mobx-react-lite';
import { StyledPagination } from '../../../../shared/pagination/StyledPagination';
import { SearchBar } from '../../../../shared/searchBar/SearchBar';
import { PublicationsModal } from './modal/PublicationsModal';
import { useModal } from '../../../../shared/modal/useModal';
import { checkRoles } from '../../../../../utils/checkRoles';
import { RoleFunction } from '../../../../../constants/projectUsers';

export const Publications = observer(() => {
  const { handleClose, open, handleOpen, typeModal, id } = useModal();
  const {
    pasyreotydPageCount,
    pasyreotydCurrentPage,
    osilodrostadPageCount,
    osilodrostadCurrentPage,
    handleSearch,
    search,
    osilodrostadHandleSearch,
    osilodrostadHandlePageChange,
    pasyreotydHandlePageChange,
    pasyreotydArticlesDrawArray,
    osilodrostadArticlesDrawArray,
    osilodrostadSearch,
    roleFunction,
    handleDelete,
    handleChange,
    checked,
    handleEdit,
    handleClickByGuest
  } = usePublications(handleOpen);

  return (
    <Grid container pt={1}>
      <SearchBar
        isVisible={checkRoles(roleFunction, RoleFunction.ROLE_ACCEPTING)}
        handleOpen={handleOpen}
        filterChangeHandler={(e) => {
          handleSearch(e);
          osilodrostadHandleSearch(e);
        }}
        filterValue={search || osilodrostadSearch}
      ><FormControl>
        <FormControlLabel
          control={
            <Switch
              sx={{ marginLeft: '16px' }}
              checked={checked}
              onChange={handleChange}
            />
          }
          label='Dostęp tylko dla PWZ'
        />
      </FormControl></SearchBar>
      {pasyreotydArticlesDrawArray && pasyreotydArticlesDrawArray.length > 0 && <>
        <Typography variant='h4' component={'div'}>Pasyreotyd</Typography>
        <Grid container>
          {pasyreotydArticlesDrawArray?.map(el => (
            <Grid item xs={12} md={4} p={1} key={el.id} minHeight={'272px'}>
              <CardComponent
                handleClickByGuest={handleClickByGuest}
                handleEdit={handleEdit}
                isActive={el.categoryIsActive}
                handleDelete={handleDelete}
                id={el.id}
                date={el.datePublishedArticle || ''}
                link={process.env.REACT_APP_IMAGE_URL + `${el.pathFile}${el.namePDF}`}
                title={el.title}
                author={el.author}
                publisher={el.publisher}
              />
            </Grid>
          ))}
          <Grid item xs={12} display={'flex'} justifyContent={'right'} mt={2} mb={2}>
            <StyledPagination
              showFirstButton showLastButton
              size='small'
              count={pasyreotydPageCount}
              color='primary'
              page={pasyreotydCurrentPage}
              onChange={(e, value: number) => {
                pasyreotydHandlePageChange(value);
              }}
            />
          </Grid>
        </Grid>
      </>
      }
      {osilodrostadArticlesDrawArray && osilodrostadArticlesDrawArray.length > 0 && <>
        <Typography variant='h4' component={'div'}>Osilodrostat</Typography>
        <Grid container>
          {osilodrostadArticlesDrawArray?.map(el => (
            <Grid item xs={12} md={4} p={1} key={el.id}>
              <CardComponent
                handleClickByGuest={handleClickByGuest}
                handleEdit={handleEdit}
                isActive={el.categoryIsActive}
                handleDelete={handleDelete}
                id={el.id}
                date={el.datePublishedArticle || ''}
                link={process.env.REACT_APP_IMAGE_URL + `${el.pathFile}${el.namePDF}`}
                title={el.title}
                author={el.author}
                publisher={el.publisher}
              />
            </Grid>
          ))}
          <Grid item xs={12} display={'flex'} justifyContent={'right'} mt={2} mb={2}>
            <StyledPagination
              showFirstButton showLastButton
              size='small'
              count={osilodrostadPageCount}
              color='primary'
              page={osilodrostadCurrentPage}
              onChange={(e, value: number) => {
                osilodrostadHandlePageChange(value);
              }}
            />
          </Grid>
        </Grid>
      </>
      }
      {open && <PublicationsModal id={id} typeModal={typeModal} open={open} handleClose={handleClose} />}
    </Grid>
  );
});