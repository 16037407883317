import { RoleFunction } from '../constants/projectUsers';
import { DiseaseType } from '../interfaces/common/common';

export const checkRoles = (role: RoleFunction | null, permissions?: RoleFunction | RoleFunction[]) => {

  if (!permissions) {
    return true;
  }
  if (Array.isArray(permissions)) {
    return !!permissions.find(permission => permission === role);
  }
  return role === permissions;
};

export const convertRole = (role: RoleFunction, typeCase: DiseaseType) => {
  switch(role){
    case RoleFunction.ROLE_ADMIN: return 'główny'; 
    case RoleFunction.ROLE_EXPERT:  return `merytoryczny ${typeCase === 'cushing' ? 'cushing' : 'akromegalia'}`; 
    case RoleFunction.ROLE_ACCEPTING: return 'akceptujący'; 
    default: return '';
  }
}