import * as yup from 'yup';

import {
  ICustomErrorsSecondStage, IDateValidationSecondStage,
  IInputControllerSecondStage,
  ISecondStageValues
} from './SecondStage.types';
import { IOption } from '../../../../../../shared/ui/form/select/Select.types';

export const currentAcromegalyCourseSpecializationOptions: IOption[] = [{
  label: 'Endokrynolog',
  value: 'Endokrynolog'
},
  {
    label: 'Lekarz rodzinny / Internista',
    value: 'lekarz rodzinny / Internista'
  },
  {
    label: 'Stomatolog',
    value: 'Stomatolog'
  },
  {
    label: 'Okulista',
    value: 'Okulista'
  },
  {
    label: 'Neurolog',
    value: 'Neurolog'
  },
  {
    label: 'Kardiolog',
    value: 'Kardiolog'
  },
  {
    label: 'Ortopeda',
    value: 'Ortopeda'
  },
  {
    label: 'Reumatolog',
    value: 'Reumatolog'
  },
  {
    label: 'Inne',
    value: 'Inne'
  }
];

export const macroAdenomaPressureSinus: IOption[] = [
  {
    label: 'prawa',
    value: 'prawa'
  },
  {
    label: 'lewa',
    value: 'lewa'
  },
  {
    label: 'obie',
    value: 'obie'
  }
];

export const macroAdenomaKnospScale: IOption[] = [
  {
    label: '0',
    value: '0'
  },
  {
    label: 'I',
    value: 'I'
  },
  {
    label: 'II',
    value: 'II'
  },
  {
    label: 'III',
    value: 'III'
  },
  {
    label: 'IV',
    value: 'IV'
  }
];

export const clinicalSymptomsCarbohydrateMetabolismDisordersTypeOptions: IOption [] = [
  {
    label: 'nieprawidłowa glikemia na czczo',
    value: 'nieprawidłowa glikemia na czczo'
  },
  {
    label: 'nieprawidłowa tolerancja glukozy',
    value: 'nieprawidłowa tolerancja glukozy'
  },
  {
    label: 'cukrzyca',
    value: 'cukrzyca'
  }
];

export const datesValidation : IDateValidationSecondStage[] = [
  {
    start: 'cardiovascularComplicationsDateStart',
    end: 'cardiovascularComplicationsDateEnd',
    check: 'cardiovascularComplicationsIsActive'
  },
  {
    start: 'carbohydrateMetabolismDisordersDateStart',
    end: 'carbohydrateMetabolismDisordersDateEnd',
    check: 'carbohydrateMetabolismDisordersIsActive'
  }
]


export const validationSecondStageSchema = yup.object().shape({
  courseDateStart: yup
    .string()
    .required(),
  courseDateStartOfTreatment: yup
    .string()
    .required(),
  courseWhoRecognized: yup
    .string()
    .required(),
  growthHormoneConcentration: yup
    .string()
    .required(),
  serumIGF1Concentration: yup
    .string()
    .required(),
  uln: yup
    .string()
    .required()
});

export const initialSecondStage: ISecondStageValues = {
  currentDiagnosis:'',
  courseDateStart:null,
  courseDateStartOfTreatment: null,
  courseWhoRecognized: '',
  cardiovascularComplicationsDateStart: null,
  cardiovascularComplicationsDateEnd: null,
  cardiovascularComplicationsIsActive: false,
  carbohydrateMetabolismDisordersType: '',
  carbohydrateMetabolismDisordersDateStart: null,
  carbohydrateMetabolismDisordersDateEnd: null,
  carbohydrateMetabolismDisordersIsActive: false,
  anotherCardiovascularComplications: '',
  growthHormoneConcentration: '',
  glucoseToleranceTestGH0:'',
  glucoseToleranceTestGH60: '',
  glucoseToleranceTestGH120: '',
  profileGHHowMuch: '',
  profileGHConcentration: '',
  serumIGF1Concentration: '',
  uln: '',
  serumPrlConcentration: '',
  serumTshConcentration: '',
  serumFt4Concentration: '',
  imagingResultMrTk: '',
  oppresionOfTheOpticChiasm: '',
  sinusInfiltraionSinus: '',
  degreeOnTheKnospScale: '',
  confirmationOfSomatotropicTumor: '',
  confirmationOfMixedTumor:'',
  proliferationMarkerKi67: '',
  geneticTest: '',
  profileIsActive: false,
  glucoseToleranceTestIsActive: false,
  anotherCardiovascularComplicationsIsActive: false,
  geneticTestIsActive: false
};

export const checkboxInputControllerConfig: IInputControllerSecondStage = {
  cardiovascularComplicationsDateStart:false,
  cardiovascularComplicationsDateEnd: false,
  cardiovascularComplicationsIsActive: false,
  carbohydrateMetabolismDisordersType: false,
  carbohydrateMetabolismDisordersDateStart: false,
  carbohydrateMetabolismDisordersDateEnd: false,
  carbohydrateMetabolismDisordersIsActive: false,
  anotherCardiovascularComplications: false,
  glucoseToleranceTestGH0: false,
  glucoseToleranceTestGH60:false,
  glucoseToleranceTestGH120: false,
  profileGHHowMuch: false,
  profileGHConcentration: false,
  sinusInfiltraionSinus: false,
  degreeOnTheKnospScale: false,
  geneticTest: false,
};

export const initialErrorSecondStage: ICustomErrorsSecondStage = {
  cardiovascularComplicationsDateStart: false,
  cardiovascularComplicationsDateEnd: false,
  carbohydrateMetabolismDisordersDateStart: false,
  carbohydrateMetabolismDisordersDateEnd: false,
};

export const convertInputControllerSecondStage = (secondStage: ISecondStageValues) => {
  return {
    cardiovascularComplicationsDateStart: secondStage.cardiovascularComplicationsDateStart !== null,
    cardiovascularComplicationsDateEnd: secondStage.cardiovascularComplicationsDateEnd !== null,
    cardiovascularComplicationsIsActive: secondStage.cardiovascularComplicationsIsActive,
    carbohydrateMetabolismDisordersType: secondStage.carbohydrateMetabolismDisordersType !== "",
    carbohydrateMetabolismDisordersDateStart: secondStage.carbohydrateMetabolismDisordersDateStart !== null,
    carbohydrateMetabolismDisordersDateEnd: secondStage.carbohydrateMetabolismDisordersDateEnd !== null,
    carbohydrateMetabolismDisordersIsActive: secondStage.carbohydrateMetabolismDisordersIsActive,
    anotherCardiovascularComplications: secondStage.anotherCardiovascularComplications !== "",
    glucoseToleranceTestGH0: secondStage.glucoseToleranceTestGH0 !== "",
    glucoseToleranceTestGH60: secondStage.glucoseToleranceTestGH60 !== "",
    glucoseToleranceTestGH120: secondStage.glucoseToleranceTestGH120 !== "",
    profileGHHowMuch:secondStage.profileGHHowMuch !== "",
    profileGHConcentration:secondStage.profileGHConcentration !== "",
    sinusInfiltraionSinus: secondStage.sinusInfiltraionSinus !== "",
    degreeOnTheKnospScale: secondStage.degreeOnTheKnospScale !== "",
    geneticTest: secondStage.geneticTest !== "",
  }
}
