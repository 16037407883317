import { QUPageTab } from '../qaPage/QUPage.types';
import { isOfType } from '../../../utils/isOfType';
import { CaseViewTab } from './CaseView.types';
import { DiseaseType } from '../../../interfaces/common/common';

export const isOfTypeCaseViewTab = (value: string): value is CaseViewTab => {
  return isOfType<QUPageTab>(value, ['description', 'discussion', 'forum', 'comments']);
}

export const isOfTypeDiseaseType = (value: string): value is DiseaseType => {
  return isOfType<DiseaseType>(value, ['acromegaly', 'cushing']);
}
