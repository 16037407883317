import { Grid, Tab, Tabs } from '@mui/material';
import React, { FC } from 'react';
import { ImageHomeLayout, Section, TabsContainer } from './CaseLayout.styled';
import { ICaseLayoutProps } from './CaseLayout.types';
import { Header } from '../../../header/Header';
import { BackgroundStyled } from '../../../background/Background.styled';
import { Footer } from '../../../appLayer/footer/Footer';

export const CaseLayout: FC<ICaseLayoutProps> = ({ children, filter, handleChange, tabsActive = true }) => {

  return (
    <>
      <Header />
      <Section>
        <BackgroundStyled />
        <Grid container pr={4} pl={4}>
          <Grid item xs={4}>
            <ImageHomeLayout type={filter} />
          </Grid>
          <Grid item xs={8} position={'relative'}>
            <TabsContainer>
              <Tabs
                value={filter}
                onChange={handleChange}
                className={`tabs-${filter}`}>
                {(tabsActive || filter === 'acromegaly') && <Tab value='acromegaly' label='AKROMEGALIA' />}
                {(tabsActive || filter === 'cushing') && <Tab value='cushing' label='ZESPÓŁ CUSHINGA' />}
              </Tabs>
            </TabsContainer>
            {children}
          </Grid>
        </Grid>
      </Section>
      <Footer />
    </>
  );
};