import { Button } from '@mui/material';
import {ReactComponent as SortIcon} from '../../../../assets/icon/sort.svg';
import { FC, useState } from 'react';
import { ISortButtonProps } from './SortButton.types';
import { ButtonContent, GridCustom } from './SortButton.styled';
import { DiseaseType } from '../../../../interfaces/common/common';

export const SortButton: FC<ISortButtonProps>= ({handleOnClick, type}) =>{
  const [ascSort, setAscSort]  = useState(false);

  const handleOnClickSort = () => {
    setAscSort(prevState => !prevState);
    handleOnClick()
  }
  return (
    <GridCustom type={type as DiseaseType} mr={1} item xs={12} display={'flex'} justifyContent={'flex-end'} >
      <Button onClick={handleOnClickSort} startIcon={<SortIcon/>}><ButtonContent type={type}>{ascSort ? "OD NAJSTARSZYCH" : "OD NAJNOWSZYCH"}</ButtonContent></Button>
    </GridCustom>
  )
}