import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { useCustomTable } from './useCustomTable.vm';
import { visuallyHidden } from '@mui/utils';
import React, { ReactNode } from 'react';
import { SearchBar } from '../searchBar/SearchBar';
import { checkRoles } from '../../../utils/checkRoles';
import { RoleFunction } from '../../../constants/projectUsers';
import { useAuthStore } from '../../../store/hooks';
import { StyledPagination } from '../pagination/StyledPagination';
import { ITableData } from '../../../interfaces/common/customTable';
import { HeadCell } from '../../../interfaces/library/library';
import { CellSwitcher } from './CellSwitcher';
import { StyledTableCell } from './CustomTable.styled';
import { ReactComponent as Bin } from '../../../assets/icon/bin.svg';
import { RadioGroupStyled } from '../ui/form/radioGroupStyled/RadioGroupStyled.styled';
import { UserCheckbox } from '../../../interfaces/users/usersInfo';
import { DiseaseType } from '../../pages/home/Home.types';

interface ICustomTable {
  data: ITableData[];
  handleOpen?: (type: string) => void;
  isVisible: boolean;
  handleDelete: (id: number) => void;
  children?: ReactNode;
  width: string;
  tableConfigOrder: string;
  headCells: HeadCell[];
  checkboxes?: UserCheckbox[];
  userType?: string;
  additionalCell?: boolean;
  activateEmailHandler?: (email: string) => void;
  handleClickByGuest?: () => void;
}

export const CustomTable: React.FC<ICustomTable> = ({
                                                      headCells,
                                                      children,
                                                      handleDelete,
                                                      isVisible,
                                                      data,
                                                      handleOpen,
                                                      tableConfigOrder,
                                                      width,
                                                      checkboxes, userType,
                                                      additionalCell,
                                                      activateEmailHandler,
                                                      handleClickByGuest
                                                    }) => {
    const {
      createSortHandler, orderBy, order, visibleRows, handleSearch, search, currentPage,
      totalPage,
      handlePageChange, radioValue, handleChange
    } = useCustomTable(data, tableConfigOrder, headCells, userType);
    const { roleFunction } = useAuthStore();

    return (
      <Grid container width={'100%'}>
        <SearchBar
          isVisible={isVisible}
          filterChangeHandler={handleSearch}
          filterValue={search}
          handleOpen={handleOpen}
        >
          {checkboxes && <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <FormControl>
              <RadioGroupStyled
                row
                name='userType'>
                {checkboxes.map(el => (
                  <FormControlLabel
                    checked={radioValue === el.value}
                    onChange={(e) => handleChange(e, el.value)}
                    value={el.id}
                    key={el.id}
                    control={<Radio />}
                    label={el.value} />
                ))}
              </RadioGroupStyled>
            </FormControl>
          </Grid>}
          {children}</SearchBar>
        <TableContainer>
          <Table
            sx={{
              minWidth: '650px',
              backgroundColor: 'white',
              maxWidth: '100%'
            }}
          >
            {checkRoles(roleFunction, [RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_ADMIN]) && <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => (
                  <TableCell
                    key={headCell.id}
                    align={'left'}
                    sx={{ fontWeight: '600' }}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    width={index === 0 ? width : '100%'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell width={'50px'}></TableCell>
                {additionalCell && <TableCell width={'50px'}></TableCell>}
              </TableRow>
            </TableHead>}
            <TableBody>
              {visibleRows && visibleRows.map((el, index) => (
                  <TableRow key={index}>
                    {Object.keys(el).map((key, index) => (
                      <>
                      <CellSwitcher
                        handleClickByGuest={handleClickByGuest}
                        activateEmailHandler={() => activateEmailHandler && activateEmailHandler(el['email'] as string)}
                        width={index === 0 ? width : '100%'}
                        key={key} keyName={key} value={el[key]}
                        isActive={el['isActive']}
                        typeCase={el['typeCase'] as DiseaseType}
                        link={el['link'] as string || '#'} /> </>
                    ))}
                    <StyledTableCell
                      align='right'>{checkRoles(roleFunction, [RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_ADMIN]) &&
                      <Bin onClick={() => handleDelete(el['id'] as number)} />}</StyledTableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!totalPage && <Grid
          container
          maxHeight='52px'
          justifyContent='flex-end'
          pt={2}
        >
          <StyledPagination
            showFirstButton
            showLastButton
            size='small'
            count={totalPage}
            color='primary'
            page={currentPage}
            onChange={(e, value: number) => {
              handlePageChange(value);
            }}
          />
        </Grid>}
      </Grid>
    );
  }
;