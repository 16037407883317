
import {createRoot} from 'react-dom/client';
import {RootStateProvider} from "./store/RootStateContext";
import {App} from "./components/App";

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <RootStateProvider><App/></RootStateProvider>
);
