import { Checkbox, FormControlLabel, FormGroup, Grid, Radio, Switch } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { Fieldset } from '../../../../../../shared/ui/form/fieldset/FieldsetContainer';
import { useSecondStageVm } from './useSecondStage.vm';
import { SelectCustom } from '../../../../../../shared/ui/form/select/Select';
import {
  clinicalSymptomsCarbohydrateMetabolismDisordersTypeOptions,
  currentAcromegalyCourseSpecializationOptions,
  macroAdenomaKnospScale,
  macroAdenomaPressureSinus
} from './SecondStage.helper';
import { InputController } from '../../../../../../shared/ui/form/inputController/InputController';
import { IInputControllerSecondStage } from './SecondStage.types';
import { Input } from '../../../../../../shared/ui/form/input/Input';
import { MarkersLabel } from './SecondStage.styled';
import { Divider } from '../../../CaseDraft.styled';
import { DateInput } from '../../../../../../shared/ui/form/date/Date';
import { RadioGroupStyled } from '../../../../../../shared/ui/form/radioGroupStyled/RadioGroupStyled.styled';
import { ButtonsActions } from '../../common/buttonsActions/ButtonsActions';
import { radioValueOptional } from '../../../CaseDraft.helper';

export const SecondStage = observer(() => {
  const {
    activeFieldset,
    adenomaFieldsActive,
    inputControllerElements,
    customErrors,
    setActive,
    formik,
    handleSave,
    handleDraftSave,
    handleChangeSwitchAndCheckbox,
    handleResetFieldset,
    cardiovascularComplicationsCheckbox,
    carbohydrateMetabolismDisordersCheckbox,
    glucoseToleranceTestCheckbox,
    profileGHCheckbox
  } = useSecondStageVm();

  return (<Grid container justifyContent={'space-between'}>
    <Fieldset legend={'Aktualne rozpoznanie'}>
      <Grid container>
        <Grid item xs={12} display={'flex'} flexDirection={'row'}>
          <RadioGroupStyled row={true} name={'currentDiagnosis'} value={formik.values.currentDiagnosis}>
            <FormControlLabel
              value={'akromegalia w przebiegu guza przysadki wydzielającego GH'}
              control={<Radio onClick={() => formik.setFieldValue('currentDiagnosis', radioValueOptional(formik.values.currentDiagnosis, 'akromegalia w przebiegu guza przysadki wydzielającego GH'))}/>}
              label='akromegalia w przebiegu guza przysadki wydzielającego GH' />

            <FormControlLabel
              value={'ektopowa produkcja GH-RH przez nowotwór neuroendokrynny (NET)'}
              control={<Radio onClick={() => formik.setFieldValue('currentDiagnosis', radioValueOptional(formik.values.currentDiagnosis, 'ektopowa produkcja GH-RH przez nowotwór neuroendokrynny (NET)'))}/>}
              label='ektopowa produkcja GH-RH przez nowotwór neuroendokrynny (NET)' />
          </RadioGroupStyled>
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset
      activeKey={activeFieldset}
      keyFieldset={'valid-fieldset-1'}
      legend={'Dotychczasowy przebieg akromegalii'}
      handleReset={handleResetFieldset}>
      <Grid container mt={2}>
        <Grid item xs={4} pr={1}>
          <DateInput
            label={'Data rozpoznania'}
            required={true}
            views={['year', 'month']}
            openTo={'year'}
            name={'courseDateStart'}
            inputFormat={'mm.yyyy'}
            value={formik.values.courseDateStart}
            error={Boolean(formik.errors.courseDateStart)}
            onBlur={() => formik.setFieldTouched('courseDateStart', true)}
            onChange={(e) => formik.setFieldValue('courseDateStart', e)} />
        </Grid>
        <Grid item xs={4} pr={1}>
          <SelectCustom
            required={true}
            error={Boolean(formik.errors.courseWhoRecognized)}
            name={'courseWhoRecognized'}
            placeholder={'Kto rozpoznał / specjalizacja'}
            label={'Kto rozpoznał / specjalizacja'}
            onChange={formik.handleChange}
            value={formik.values.courseWhoRecognized}
            options={currentAcromegalyCourseSpecializationOptions} />
        </Grid>
        <Grid item xs={4} pr={1}>
          <DateInput
            label={'Data rozpoczęcia leczenia'}
            required={true}
            views={['year', 'month']}
            openTo={'year'}
            name={'courseDateStartOfTreatment'}
            inputFormat={'mm.yyyy'}
            value={formik.values.courseDateStartOfTreatment}
            error={Boolean(formik.errors.courseDateStartOfTreatment)}
            onBlur={() => formik.setFieldTouched('courseDateStartOfTreatment', true)}
            onChange={(e) => formik.setFieldValue('courseDateStartOfTreatment', e)} />
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset
      activeKey={activeFieldset}
      keyFieldset={'valid-fieldset-2'}
      legend={'Objawy kliniczne i powikłania w okresie ustalania rozpoznania'}
      handleReset={handleResetFieldset}>
      <Grid container>
        <Grid item xs={12} pt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
              justifyContent={'center'}>
          <InputController<IInputControllerSecondStage>
            nextLine={true}
            setActive={setActive}
            active={cardiovascularComplicationsCheckbox}
            setField={formik.setFieldValue}
            inputName={['cardiovascularComplicationsDateStart', 'cardiovascularComplicationsDateEnd', 'cardiovascularComplicationsIsActive']}
            checkbox={{
              name: 'cardiovascularComplicationsCheckbox',
              label: 'powikłania sercowo-naczyniowe'
            }}>
            <Grid container>
              <Grid item xs={6} pr={1}>
                <DateInput
                  disabled={!cardiovascularComplicationsCheckbox}
                  label={'Okres trwania - OD'}
                  required={true}
                  views={['year', 'month']}
                  openTo={'year'}
                  name={'cardiovascularComplicationsDateStart'}
                  inputFormat={'mm.yyyy'}
                  value={formik.values.cardiovascularComplicationsDateStart}
                  error={Boolean(customErrors.cardiovascularComplicationsDateStart)}
                  onBlur={() => formik.setFieldTouched('cardiovascularComplicationsDateStart', true)}
                  onChange={(e) => formik.setFieldValue('cardiovascularComplicationsDateStart', e)} />
              </Grid>
              <Grid item xs={6}>
                <DateInput
                  disabled={!cardiovascularComplicationsCheckbox}
                  label={'Okres trwania - DO'}
                  required={true}
                  isStill={true}
                  isActive={formik.values.cardiovascularComplicationsIsActive}
                  setIsActive={() => formik.setFieldValue('cardiovascularComplicationsIsActive', !formik.values.cardiovascularComplicationsIsActive)}
                  views={['year', 'month']}
                  openTo={'year'}
                  name={'cardiovascularComplicationsDateEnd'}
                  inputFormat={'mm.yyyy'}
                  value={formik.values.cardiovascularComplicationsDateEnd}
                  error={Boolean(customErrors.cardiovascularComplicationsDateEnd)}
                  onBlur={() => formik.setFieldTouched('cardiovascularComplicationsDateEnd', true)}
                  onChange={(e) => formik.setFieldValue('cardiovascularComplicationsDateEnd', e)} />
              </Grid>
            </Grid>
          </InputController>
        </Grid>
        <Grid item xs={12} pt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
              justifyContent={'center'}>
          <InputController<IInputControllerSecondStage>
            nextLine={true}
            setActive={setActive}
            active={carbohydrateMetabolismDisordersCheckbox}
            setField={formik.setFieldValue}
            inputName={['carbohydrateMetabolismDisordersType', 'carbohydrateMetabolismDisordersDateStart', 'carbohydrateMetabolismDisordersDateEnd', 'carbohydrateMetabolismDisordersIsActive']}
            checkbox={{
              name: 'carbohydrateMetabolismDisordersCheckbox',
              label: 'zaburzenia gospodarki węglowodanowej'
            }}>
            <Grid container>
              <Grid item xs={4} pr={1}>
                <SelectCustom
                  disabled={!carbohydrateMetabolismDisordersCheckbox}
                  name={'carbohydrateMetabolismDisordersType'}
                  placeholder={'Typ/rodzaj'}
                  label={'Typ/rodzaj'}
                  onChange={formik.handleChange}
                  value={formik.values.carbohydrateMetabolismDisordersType}
                  options={clinicalSymptomsCarbohydrateMetabolismDisordersTypeOptions} />
              </Grid>
              <Grid item xs={4} pr={1}>
                <DateInput
                  disabled={!carbohydrateMetabolismDisordersCheckbox}
                  label={'Okres trwania - OD'}
                  required={true}
                  views={['year', 'month']}
                  openTo={'year'}
                  name={'carbohydrateMetabolismDisordersDateStart'}
                  inputFormat={'mm.yyyy'}
                  value={formik.values.carbohydrateMetabolismDisordersDateStart}
                  error={Boolean(customErrors.carbohydrateMetabolismDisordersDateStart)}
                  onBlur={() => formik.setFieldTouched('carbohydrateMetabolismDisordersDateStart', true)}
                  onChange={(e) => formik.setFieldValue('carbohydrateMetabolismDisordersDateStart', e)} />
              </Grid>
              <Grid item xs={4}>
                <DateInput
                  disabled={!carbohydrateMetabolismDisordersCheckbox}
                  label={'Okres trwania - DO'}
                  isStill={true}
                  isActive={formik.values.carbohydrateMetabolismDisordersIsActive}
                  setIsActive={() => formik.setFieldValue('carbohydrateMetabolismDisordersIsActive', !formik.values.carbohydrateMetabolismDisordersIsActive)}
                  required={true}
                  views={['year', 'month']}
                  openTo={'year'}
                  name={'carbohydrateMetabolismDisordersDateEnd'}
                  inputFormat={'mm.yyyy'}
                  value={formik.values.carbohydrateMetabolismDisordersDateEnd}
                  error={Boolean(customErrors.carbohydrateMetabolismDisordersDateEnd)}
                  onBlur={() => formik.setFieldTouched('carbohydrateMetabolismDisordersDateEnd', true)}
                  onChange={(e) => formik.setFieldValue('carbohydrateMetabolismDisordersDateEnd', e)} />
              </Grid>
            </Grid>
          </InputController>
        </Grid>
        <Grid item xs={12} mt={1}>
          <InputController<IInputControllerSecondStage>
            setActive={setActive}
            active={inputControllerElements.anotherCardiovascularComplications}
            setField={formik.setFieldValue}
            checkbox={{
              name: 'anotherCardiovascularComplicationsCheckbox',
              label: 'inne (jakie?)'
            }}
            inputName={'anotherCardiovascularComplications'}>
            <Input
              disabled={!inputControllerElements.anotherCardiovascularComplications}
              name={'anotherCardiovascularComplications'}
              type={'text'}
              onChange={formik.handleChange}
              value={formik.values.anotherCardiovascularComplications}
              placeholder={''} />
          </InputController>
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset
      activeKey={activeFieldset}
      keyFieldset={'valid-fieldset-3'}
      legend={'Badania hormonalne w okresie ustalania rozpoznania'}
      handleReset={handleResetFieldset}>
      <Grid container>
        <Grid item xs={12} pt={2} pb={1}>
          <Input
            required={true}
            name={'growthHormoneConcentration'}
            type={'text'}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.growthHormoneConcentration)}
            value={formik.values.growthHormoneConcentration}
            placeholder={'Stężenie hormonu wzrostu (GH) w surowicy na czczo (μg/l)'}
            label={'Stężenie hormonu wzrostu (GH) w surowicy na czczo (μg/l)'} />
        </Grid>
        <InputController<IInputControllerSecondStage>
          setActive={setActive}
          nextLine={true}
          active={glucoseToleranceTestCheckbox}
          setField={formik.setFieldValue}
          checkbox={{
            name: 'glucoseToleranceTestCheckbox',
            label: 'Wykonano ocenę GH w OGTT'
          }}
          inputName={['glucoseToleranceTestGH0', 'glucoseToleranceTestGH60', 'glucoseToleranceTestGH120']}>
          <Grid container display={'flex'} flexDirection={'row'}>
            <Grid item xs={4} pr={1} mb={1}>
              <Input name={'glucoseToleranceTestGH0'}
                     disabled={!glucoseToleranceTestCheckbox}
                     type={'text'}
                     onChange={formik.handleChange}
                     value={formik.values.glucoseToleranceTestGH0}
                     label={'GH 0\''}
                     placeholder={'GH 0\''} />
            </Grid>
            <Grid item xs={4} pr={1} mb={1}>
              <Input name={'glucoseToleranceTestGH60'}
                     disabled={!glucoseToleranceTestCheckbox}
                     type={'text'}
                     onChange={formik.handleChange}
                     value={formik.values.glucoseToleranceTestGH60}
                     label={'GH 60\''}
                     placeholder={'GH 60\''} />
            </Grid>
            <Grid item xs={4} pr={1} mb={1}>
              <Input name={'glucoseToleranceTestGH120'}
                     disabled={!glucoseToleranceTestCheckbox}
                     type={'text'}
                     onChange={formik.handleChange}
                     value={formik.values.glucoseToleranceTestGH120}
                     label={'GH 120\''}
                     placeholder={'GH 120\''} />
            </Grid>
          </Grid>
        </InputController>
        <InputController<IInputControllerSecondStage>
          setActive={setActive}
          nextLine={true}
          active={profileGHCheckbox}
          setField={formik.setFieldValue}
          checkbox={{
            name: 'profileGHCheckbox',
            label: 'Wykonano profil GH'
          }}
          inputName={['profileGHConcentration', 'profileGHHowMuch']}>
          <Grid container display={'flex'} flexDirection={'row'}>
            <Grid item xs={6} pr={1} mb={1}>
              <Input name={'profileGHHowMuch'}
                     disabled={!profileGHCheckbox}
                     type={'text'}
                     onChange={formik.handleChange}
                     value={formik.values.profileGHHowMuch}
                     label={'Liczba oznaczeń GH w profilu'}
                     placeholder={'Liczba oznaczeń GH w profilu'} />
            </Grid>
            <Grid item xs={6} pr={1} mb={1}>
              <Input name={'profileGHConcentration'}
                     disabled={!profileGHCheckbox}
                     type={'text'}
                     onChange={formik.handleChange}
                     value={formik.values.profileGHConcentration}
                     label={'Średnie stężenie GH w μg/l'}
                     placeholder={'Średnie stężenie GH w μg/l'} />
            </Grid>
          </Grid>
        </InputController>

        <Grid container mt={1} display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
          <Grid item xs={12}>
            Oznaczono stężenie IGF-1
          </Grid>
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} pr={1}>
              <Input name={'serumIGF1Concentration'} type={'text'} required={true}
                     onChange={formik.handleChange} value={formik.values.serumIGF1Concentration}
                     error={Boolean(formik.errors.serumIGF1Concentration)}
                     label={'Stężenie IGF-1 w surowicy w wartościach bezwzględnych (μg/l)'}
                     placeholder={'Stężenie IGF-1 w surowicy w wartościach bezwzględnych (μg/l)'} />
            </Grid>
            <Grid item xs={6}>
              <Input name={'uln'} type={'text'} required={true} onChange={formik.handleChange}
                     value={formik.values.uln} error={Boolean(formik.errors.uln)}
                     label={'ULN'} placeholder={'ULN'}
                     addedInfo={'w stosunku do górnej granicy przedziału referencyjnego dla płci i wieku'} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={3}>
          <Grid container>
            <Grid item xs={4} pr={1}>
              <Input name={'serumPrlConcentration'} type={'text'} onChange={formik.handleChange}
                     value={formik.values.serumPrlConcentration} label={'Stężenie PRL surowicy (μg/l)'}
                     placeholder={'Stężenie PRL surowicy (μg/l)'} />
            </Grid>
            <Grid item xs={4} pr={1}>
              <Input name={'serumTshConcentration'} type={'text'} onChange={formik.handleChange}
                     value={formik.values.serumTshConcentration} label={'Stężenie TSH w surowicy (mIU/l)'}
                     placeholder={'Stężenie TSH w surowicy (mIU/l)'} />
            </Grid>
            <Grid item xs={4}>
              <Input name={'serumFt4Concentration'} type={'text'} onChange={formik.handleChange}
                     value={formik.values.serumFt4Concentration} label={'Stężenie fT4 w surowicy (pmol/l)'}
                     placeholder={'Stężenie fT4 w surowicy (pmol/l)'} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Wynik obrazowania układu podwzgórzowo-przysadkowego w MR/TK'}>
      <Grid container>
        <Grid item xs={12} pt={4} display={'flex'}>
          <RadioGroupStyled row={true} name={'imagingResultMrTk'} gap={'8px'} value={formik.values.imagingResultMrTk}>
            <FormControlLabel onChange={formik.handleChange} value={'mikrogruczolak'} control={<Radio onClick={() => formik.setFieldValue('imagingResultMrTk', radioValueOptional(formik.values.imagingResultMrTk, 'mikrogruczolak'))}/>}
                              label='mikrogruczolak' />
            <FormControlLabel onChange={formik.handleChange} value={'makrogruczolak'} control={<Radio onClick={() => formik.setFieldValue('imagingResultMrTk', radioValueOptional(formik.values.imagingResultMrTk, 'makrogruczolak'))}/>}
                              label='makrogruczolak' />
          </RadioGroupStyled>
        </Grid>
        {adenomaFieldsActive &&
          <Grid item xs={12} pt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                justifyContent={'center'}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!adenomaFieldsActive}
                  checked={Boolean(formik.values.oppresionOfTheOpticChiasm)}
                  onChange={(event) => handleChangeSwitchAndCheckbox('oppresionOfTheOpticChiasm', event.target.checked ? 'ucisk skrzyżowania wzrokowego' : '')}
                  name={'oppresionOfTheOpticChiasm'} />
              }
              label={'ucisk skrzyżowania wzrokowego'}
            />
          </Grid>}
        {adenomaFieldsActive && <Grid item xs={12} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                                      justifyContent={'center'}>
          <InputController<IInputControllerSecondStage>
            setActive={setActive}
            active={inputControllerElements.sinusInfiltraionSinus && inputControllerElements.degreeOnTheKnospScale}
            setField={formik.setFieldValue}
            inputName={['sinusInfiltraionSinus', 'degreeOnTheKnospScale']}
            checkbox={{
              name: 'macroAdenomaCheckbox',
              label: 'naciekanie zatok jamistych',
              disabled: !adenomaFieldsActive
            }}>
            <Grid container>
              <Grid item xs={6} pr={1}>
                <SelectCustom
                  disabled={!inputControllerElements.sinusInfiltraionSinus}
                  name={'sinusInfiltraionSinus'}
                  placeholder={'Zatoka'}
                  label={'Zatoka'}
                  onChange={formik.handleChange}
                  value={formik.values.sinusInfiltraionSinus}
                  options={macroAdenomaPressureSinus} />
              </Grid>
              <Grid item xs={6}>
                <SelectCustom
                  disabled={!inputControllerElements.degreeOnTheKnospScale}
                  name={'degreeOnTheKnospScale'}
                  placeholder={'Stopień w skali Knospa'}
                  label={'Stopień w skali Knospa'}
                  onChange={formik.handleChange}
                  value={formik.values.degreeOnTheKnospScale}
                  options={macroAdenomaKnospScale} />
              </Grid>
            </Grid>
          </InputController>
        </Grid>}
      </Grid>
    </Fieldset>
    <Fieldset legend={'Badanie histopatologiczne'}>
      <Grid container>
        <Grid item xs={12} pt={4} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
              justifyContent={'center'}>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={Boolean(formik.values.confirmationOfSomatotropicTumor)}
                               name={'confirmationOfSomatotropicTumor'}
                               onChange={(event) => handleChangeSwitchAndCheckbox('confirmationOfSomatotropicTumor', event.target.checked ? 'potwierdzenie immunohistochemiczne guza kortykotropowego w materiale tkankowym' : '')} />}
              label='potwierdzenie immunohistochemiczne guza kortykotropowego w materiale tkankowym'
            />
            <FormControlLabel
              value={'potwierdzenie guza mieszanego (somato- i laktotropowy)'}
              name={'confirmationOfMixedTumor'}
              control={<Switch checked={Boolean(formik.values.confirmationOfMixedTumor)}
                               onChange={(event) => handleChangeSwitchAndCheckbox('confirmationOfMixedTumor', event.target.checked ? 'potwierdzenie guza mieszanego (somato- i laktotropowy)' : '')} />}
              label='potwierdzenie guza mieszanego (somato- i laktotropowy)' />
          </FormGroup>
        </Grid>
        <Grid item xs={12} pt={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
              justifyContent={'center'}>
          <RadioGroupStyled row={true} name={'proliferationMarkerKi67'} value={formik.values.proliferationMarkerKi67}>
            <MarkersLabel>Marker proliferacji Ki-67</MarkersLabel>
            <FormControlLabel
              onChange={formik.handleChange}
              value={'< 3%'}
              control={<Radio onClick={() => formik.setFieldValue('proliferationMarkerKi67', radioValueOptional(formik.values.proliferationMarkerKi67, '< 3%'))}/>}
              label='< 3%' />
            <FormControlLabel
              onChange={formik.handleChange}
              value={'≥ 3%'}
              control={<Radio onClick={() => formik.setFieldValue('proliferationMarkerKi67', radioValueOptional(formik.values.proliferationMarkerKi67, '≥ 3%'))}/>}
              label='≥ 3%' />

          </RadioGroupStyled>
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Badanie genetyczne'}>
      <Grid container>
        <InputController<IInputControllerSecondStage>
          setActive={setActive}
          active={inputControllerElements.geneticTest}
          setField={formik.setFieldValue}
          inputName={'geneticTest'}
          checkbox={{
            name: 'geneticTestingCheckbox',
            label: 'Badanie genetyczne'
          }}>
          <Input
            disabled={!inputControllerElements.geneticTest}
            name={'geneticTest'}
            type={'text'}
            placeholder={'Wynik'}
            label={'Wynik'}
            value={formik.values.geneticTest}
            onChange={formik.handleChange}
          />
        </InputController>
      </Grid>
    </Fieldset>
    <ButtonsActions
      handleDraftSave={handleDraftSave}
      handleSave={handleSave} />
  </Grid>);
});



