import { useFormik } from 'formik';
import { useEffect, useState } from 'react';


import { initialSurgery } from './Surgary.helper';
import { AddSurgeryFunction, ISurgery } from './Surgary.types';

export const useSurgaryVm = (initial?: ISurgery, handleAddSurgery?: AddSurgeryFunction) => {
  const [disabledAdd, setDisabledAdd] = useState(true);

  const formik = useFormik<ISurgery>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: initial ?? initialSurgery,
    onSubmit: async (values) => {
    }
  });

  useEffect(() => {
    setDisabledAdd(!(formik.values.type !== '' && formik.values.date !== ''));
  }, [formik.values]);

  const handleOnClick = () => {
    if (handleAddSurgery) {
      handleAddSurgery(formik.values);
      formik.resetForm();
    }
  };

  return { formik, handleOnClick, disabledAdd };
};