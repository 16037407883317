import { CardActionsButtonsProps } from './cardActionsButtons.types';
import React, { FC } from 'react';
import { CardActions } from '@mui/material';
import { ReactComponent as Pen } from '../../../../assets/icon/pen.svg';
import { ReactComponent as Bin } from '../../../../assets/icon/bin.svg';
import { ActionButton } from './cardActionsButtons.styled';
import { ReactComponent as ClosedEye } from '../../../../assets/icon/closedEye.svg';
import { ReactComponent as OpenEye } from '../../../../assets/icon/openEye.svg';

export const CardActionsButtons: FC<CardActionsButtonsProps> = ({
                                                                  handleEdit,
                                                                  handleRemove,
                                                                  handleVisible,
                                                                  handleHidden
                                                                }) => {

  return (
    <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', height: '41px' }}>
      {handleEdit && <ActionButton onClick={handleEdit}>
        <Pen />
      </ActionButton>}
      {handleRemove && <ActionButton onClick={handleRemove}>
        <Bin />
      </ActionButton>}
      {handleVisible && <ActionButton onClick={handleVisible}>
        <ClosedEye />
      </ActionButton>}
      {handleHidden && <ActionButton onClick={handleHidden}>
        <OpenEye />
      </ActionButton>}
    </CardActions>
  );
};