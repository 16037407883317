import { Button, Grid, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { Input } from '../ui/form/input/Input';
import { Add } from '@mui/icons-material';

interface IProps {
  filterValue: string;
  filterChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpen?: (type: string) => void;
  isVisible: boolean;
  children?: ReactNode;
}

export const SearchBar: React.FC<IProps> = ({
                                              children,
                                              isVisible,
                                              handleOpen,
                                              filterValue,
                                              filterChangeHandler

                                            }) => {

  return (
    isVisible ? <Grid
      container
      p={'0px'}
      mb={2}
      mt={2}
      justifyContent='space-between'
      alignItems='center'
      flexWrap='nowrap'
      sx={{
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        maxHeight: '40px'
      }}
    >
      <Grid item xs={children ? 6 : 12}>
        <Input
          type='text'
          name='search'
          label='Wyszukaj'
          placeholder='Wpisz szukane słowa...'
          value={filterValue}
          onChange={filterChangeHandler}
        />
      </Grid>
      {children}
      <Grid
        item
        ml={2}
      >
        <Grid
          container
          gap={2}
        >
          <Button
            variant='contained'
            onClick={() => handleOpen && handleOpen('add')}
            startIcon={<Add />}
          >
            <Typography variant='body1' sx={{ ml: '12px', fontWeight: '500' }}>
              DODAJ
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid> : <></>
  );
};