import { useLibraryStore, usePoiStore } from '../../../../../../store/hooks';
import { useLibraryApi } from '../../../../../../apiHooks/useLibraryApi';
import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(),
  programsMedicament: yup
    .string()
    .required()
});

export const useProgramsModal = (handleClose: () => void, id: number | null) => {
  const libraryStore = useLibraryStore();
  const poiStore = usePoiStore();
  const { errorMessage, errorCode } = poiStore;
  const { cushingCheckbox, acromegalyCheckbox, articles } = libraryStore;
  const { addArticlePrograms, deleteArticle, editArticlePrograms } = useLibraryApi(handleClose);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [pdfLink, setPdfLink] = useState<string | null>(null);
  const [pdfFileError, setPdfFileError] = useState<boolean>(false);

  const programInfo = articles.find(el => el.id === id);

  const setFileHandler = useCallback((pdfFile: File | null) => {
    setPdfFile(pdfFile);
  }, []);

  useEffect(() => {
    if (pdfFile) setPdfLink(URL.createObjectURL(pdfFile));
  }, [pdfFile]);

  const formik = useFormik({
    initialValues: {
      title: '',
      programsMedicament: ''
    },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = new FormData();
      data.append('title', values.title);
      data.append('categoryMed', values.programsMedicament);

      if (id) {
        await editArticlePrograms({ title: values.title, categoryMed: +values.programsMedicament }, id);
      } else if (pdfFile) {
        data.append('file', pdfFile);
        await addArticlePrograms(data);
      }
    }
  });

  useEffect(() => {
    return () => poiStore.clearError();
  }, [poiStore]);

  const titleErrorMessage = (errorCode === 404 || errorCode === 400) && errorMessage;

  const { setFieldValue } = formik;

  useEffect(() => {
    if (programInfo) {
      setFieldValue('title', programInfo.title);
      setFieldValue('programsMedicament', programInfo.categoryMedicamentsId);
      setPdfLink(process.env.REACT_APP_IMAGE_URL + `${programInfo.pathFile}${programInfo.namePDF}`);
    }
  }, [setFieldValue, programInfo]);

  const handleOnClick = () => {
    if (!pdfFile && !id) {
      setPdfFileError(true);
    }
    formik.handleSubmit();
  };

  useEffect(() => {
    if (pdfFile) {
      setPdfFileError(false);
    }
  }, [pdfFile]);

  const checkboxes = [{ ...acromegalyCheckbox }, { ...cushingCheckbox }];

  const handleDeleteItem = () => {
    if (id) {
      void deleteArticle(id);
    }
  };
  return {
    checkboxes,
    formik,
    handleOnClick,
    setFileHandler,
    pdfFile,
    pdfLink,
    pdfFileError,
    handleDeleteItem,
    titleErrorMessage
  };
};