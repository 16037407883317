import { RoleFunction } from '../../../constants/projectUsers';

export enum BadgeType {
  CASES, NOTIFICATION, QA
}

export const generateMenu = (type: string, isGuest: boolean, params: string, libType: string, qaTab: string) => {
  return [
    {
      id: 1,
      title: 'Użytkownicy',
      link: '/users',
      permissions: [RoleFunction.ROLE_ADMIN]
    },
    {
      id: 2,
      title: 'Strona główna',
      link: isGuest ? `/cases/${type}` : `/${type}`,
      permissions: [RoleFunction.ROLE_GUEST, RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_VIEWING]
    },
    {
      id: 3,
      title: 'Strona główna',
      link: `/${params}`,
      permissions: [RoleFunction.ROLE_EXPERT]
    },
    {
      id: 4,
      title: 'Moje przypadki',
      link: `/cases/${type}`,
      permissions: [RoleFunction.ROLE_PUBLISHING],
      badge: BadgeType.CASES
    },
    {
      id: 5,
      title: 'Przypadki',
      link: `/cases/${type}`,
      permissions: [RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_ACCEPTING],
      badge: BadgeType.CASES
    },
    {
      id: 6,
      title: 'Powiadomienia',
      link: '/notifications',
      permissions: [RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_ADMIN, RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_ACCEPTING],
      badge: BadgeType.NOTIFICATION
    },
    // {
    //   id: 7,
    //   title: 'Q&A',
    //   link: `/qa/${qaTab}`,
    //   badge: BadgeType.QA
    // },
    {
      id: 8,
      title: 'Biblioteka',
      link: `/library/${libType}`,
      permissions: [RoleFunction.ROLE_GUEST, RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_VIEWING]
    }
  ];
};

export const menuHelper = [
  {
    id: 1,
    title: 'Strona główna',
    link: '/:type',
    permissions: [RoleFunction.ROLE_GUEST, RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_VIEWING]
  },
  {
    id: 2,
    title: 'Użytkownicy',
    link: '/users',
    permissions: [RoleFunction.ROLE_ADMIN]
  },
  {
    id: 3,
    title: 'Moje przypadki',
    link: '/cases/:type',
    permissions: [RoleFunction.ROLE_PUBLISHING],
    badge: BadgeType.CASES
  },
  {
    id: 4,
    title: 'Przypadki',
    link: '/cases/:type',
    permissions: [RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_ACCEPTING],
    badge: BadgeType.CASES
  },
  {
    id: 5,
    title: 'Powiadomienia',
    link: '/notifications',
    permissions: [RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_ADMIN, RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_ACCEPTING],
    badge: BadgeType.NOTIFICATION
  },
  // {
  //   id: 6,
  //   title: 'Q&A',
  //   link: '/qa/:qaTab',
  //   badge: BadgeType.QA
  // },
  {
    id: 7,
    title: 'Biblioteka',
    link: '/library/:libType',
    permissions: [RoleFunction.ROLE_GUEST, RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_VIEWING]
  }
];