import { AccordionActions, AccordionDetails, AccordionSummary, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC } from 'react';
import { IAccordionCaseProps } from './AccordionCase.types';
import {
  AccordionActionsContainer,
  AccordionCustom,
  AccordionHeader,
  Answear,
  DividerContainer
} from './AccordionCase.styled';
import { ReactComponent as Badge } from '../../../assets/icon/badge.svg';
import { ReactComponent as HideQuestion } from '../../../assets/icon/hideQuestion.svg';
import { convertLinkDate } from '../../../utils/dateStringify';

export const AccordionCase: FC<IAccordionCaseProps> = ({
                                                         type,
                                                         id,
                                                         isActive,
                                                         handleOnChange,
                                                         element,
                                                         actions,
                                                         expandedDefault = false,
                                                         badgeVisibility
                                                       }) => {
  
  return (
    <AccordionCustom disableGutters={true} defaultExpanded={expandedDefault} type={type}
                     expanded={expandedDefault || isActive} onChange={() => handleOnChange && handleOnChange(id)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeader>
          <h2>{!element.visibility && element.answer &&
            <HideQuestion />}{(badgeVisibility ? badgeVisibility(element) : !element.answer) &&
            <Badge />}{element.author ? `${element.author} • ` : ''}{convertLinkDate(element.dateWithHour)}</h2>
          <p>{element.name}</p>
        </AccordionHeader>
      </AccordionSummary>
      <DividerContainer>
        <Divider />
      </DividerContainer>
      {element.answer && <AccordionDetails>
        <Answear>
          <h2>Ekspert • {convertLinkDate(element.answer.dateWithHour)}</h2>
          <p>{element.answer.name}</p>
        </Answear>
      </AccordionDetails>}
      {Boolean(actions) && <AccordionActions>
        <AccordionActionsContainer>
          {actions}
        </AccordionActionsContainer>
      </AccordionActions>}
    </AccordionCustom>);

};