import {Divider, Grid, Typography} from "@mui/material";
import {useCaseStore} from "../../../../../../../store/hooks";
import React from "react";
import {IThirdStageValues} from "../../../../../user/caseDraft/stages/cushing/thirdStage/ThirdStage.types";
import { Text } from '../../../Description.styled';
import { Input } from '../../../../../../shared/ui/form/input/Input';
import { getDate } from '../../../../../../../utils/dateSort';
import { CheckedIconContainer } from '../../../checkedIcon/CheckedIcon';
import { observer } from 'mobx-react-lite';

export const ThirdStage = observer(() => {

    const caseStore = useCaseStore();
    const {
        thirdStage,
    }= caseStore;

    const thirdStageStageCushing = thirdStage as IThirdStageValues;

    if(thirdStageStageCushing) {

      const treatmentBeforeOperationSteroidogenesisInhibitorSection = thirdStageStageCushing.treatmentBeforeOperationInhibitors.length > 0;

      const treatmentBeforeOperationPasireotidesSection = thirdStageStageCushing.treatmentBeforeOperationPasireotides.length > 0;

      const treatmentBeforeOperationCabergolinesSection = thirdStageStageCushing.treatmentBeforeOperationCabergolines.length > 0;

      const preparationSection = treatmentBeforeOperationSteroidogenesisInhibitorSection || treatmentBeforeOperationPasireotidesSection || treatmentBeforeOperationCabergolinesSection;

      const hypercortisolaemiaPasireotidesSection = thirdStageStageCushing.hypercortisolaemiaPasireotides.length > 0;

      const hypercortisolaemiaCabergolinesSection = thirdStageStageCushing.hypercortisolaemiaCabergolines.length > 0;

      const pharmacologicalTreatmentSection = hypercortisolaemiaPasireotidesSection || hypercortisolaemiaCabergolinesSection || Boolean(thirdStageStageCushing.steroidogenesisInhibitorHow);

      const qualificationsSection = Boolean(thirdStageStageCushing.qualificationsDiagnosisAccess) || Boolean(thirdStageStageCushing.qualificationsDiagnosisAccess) || thirdStageStageCushing.qualificationsDiagnosis.length> 0;

      const biochemicalNormalizationYesSection = thirdStageStageCushing.biochemicalNormalization === 'Tak';

      const anotherComplicationsSection = thirdStageStageCushing.anotherComplications?.length > 0 || Boolean(thirdStageStageCushing.anotherComplicationsAnother);


      const titleSection = treatmentBeforeOperationSteroidogenesisInhibitorSection || treatmentBeforeOperationPasireotidesSection || treatmentBeforeOperationCabergolinesSection ||
        preparationSection || hypercortisolaemiaPasireotidesSection || hypercortisolaemiaCabergolinesSection || pharmacologicalTreatmentSection || qualificationsSection ||
        biochemicalNormalizationYesSection || anotherComplicationsSection || thirdStageStageCushing.treatmentOfChoivePatient.length > 0 ||  thirdStageStageCushing.operations.length > 0  ||
        thirdStageStageCushing.subnormalCortisolLevels ||  thirdStageStageCushing.hydrocortisolTreatment  || thirdStageStageCushing.complicationsOfSurgicalProcedures ||
        thirdStageStageCushing.biochemicalNormalization || thirdStageStageCushing.toleranceOfPharmacologicalTreatment.length > 0 || thirdStageStageCushing.supprotiveTreatment.length > 0  ||
        thirdStageStageCushing.improvementControl.length > 0 || thirdStageStageCushing.complicationsOfSurgicalProceduresCheck.length > 0;


      if(!titleSection){
        return null;
      }

      return (
            <Grid container justifyContent={'space-between'} width={'100%'} mt={6} >
                <Typography variant={'h4'} pb={2}>Leczenie zespołu cushinga</Typography>
                <Divider sx={{ width: '100%' }} />

                {thirdStageStageCushing.treatmentOfChoivePatient.length > 0  && <Grid container display={'flex'} alignItems={'center'} mt={2}>
                  <Grid item xs={12} mt={2} mb={4} >
                    <Typography variant={'h6'} >Leczenie z wyboru, które zastosowano u pacjenta:</Typography>
                  </Grid>
                  {thirdStageStageCushing.treatmentOfChoivePatient.map((treatment, index)=>
                    <Grid container key={treatment.treatment + index} display={'flex'} alignItems={'flex-start'} mb={2} >
                      <Grid item xs={0.5} pl={2}>
                        <Text>{index+1}.</Text>
                      </Grid>
                      <Grid item xs={5.5} pl={2} alignSelf={'stretch'}>
                        <Input disabled variant={'standard'} fullWidth={true} name={'element.group'} label={'data'}
                               type={'text'} value={getDate(treatment.date)} />
                      </Grid>
                      <Grid item xs={6} pl={2} alignSelf={'stretch'}>
                        <Input multiline disabled variant={'standard'} fullWidth={true} name={'element.treatment'} label={'leczenie'}
                               type={'text'} value={treatment.treatment} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>}


              {preparationSection &&
                <Grid container>
                  <Typography mt={4} variant={'h6'}>Leczenie przygotowawcze przed leczeniem operacyjnym (jeśli dotyczy):</Typography>
                  {thirdStageStageCushing.treatmentBeforeOperationInhibitors.length > 0 && <Grid container display={'flex'} alignItems={'flex-start'} justifyItems={'stretch'}   >
                    <Grid item xs={12} display={'flex'} alignItems={'center'} mt={2}>
                      <Text>{'inhibitor steroidogenezy'}</Text>
                    </Grid>
                    <>{thirdStageStageCushing.treatmentBeforeOperationInhibitors.map(treatment => <>
                          <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'treatment.type'} label={'jaki?'}
                                   type={'text'} maxLength={2} value={treatment.type} />
                          </Grid>
                          <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                            <Input multiline disabled variant={'standard'} fullWidth={true} name={'treatment.dose'} label={'dawka'}
                                   type={'text'} maxLength={2} value={treatment.dose} />
                          </Grid>
                          <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'treatment.dateStart'} label={'stosowanie - OD'}
                                   type={'text'} maxLength={2} value={getDate(treatment.dateStart)} />
                          </Grid>
                          <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'treatment.dateEnd'} label={'stosowanie - DO'}
                                   type={'text'} maxLength={2} value={getDate(treatment.dateEnd)} />
                          </Grid>
                        </>)
                      }
                    </>
                  </Grid>}
                  {treatmentBeforeOperationPasireotidesSection && <Grid container display={'flex'} alignItems={'center'} >
                    <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                      <Text>{'Pasyreotyd LAR co 4 tygodnie'}</Text>
                    </Grid>
                    {
                      thirdStageStageCushing.treatmentBeforeOperationPasireotides.map((treatmentBeforeOperationPasireotide, index) => (
                        <React.Fragment key={treatmentBeforeOperationPasireotide.dose + index}>
                          <Grid item xs={4} pl={2} alignSelf={'stretch'} mt={2}>
                            <Input multiline disabled variant={'standard'} fullWidth={true} name={'dose'} label={'dawka (mg)'}
                                   type={'text'} maxLength={2} value={treatmentBeforeOperationPasireotide.dose} />
                          </Grid>
                          <Grid item xs={4} pl={2} alignSelf={'stretch'} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'dateStart'} label={'stosowanie - OD'}
                                   type={'text'} maxLength={2} value={getDate(treatmentBeforeOperationPasireotide.dateStart)} />
                          </Grid>
                          <Grid item xs={4} pl={2} alignSelf={'stretch'} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'dateEnd'} label={'stosowanie - DO'}
                                   type={'text'} maxLength={2} value={getDate(treatmentBeforeOperationPasireotide.dateEnd)} />
                          </Grid>
                        </React.Fragment>
                      ))
                    }

                  </Grid>}
                  {treatmentBeforeOperationCabergolinesSection && <Grid container display={'flex'} alignItems={'center'} >
                    <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                      <Text>{'Kabergolina'}</Text>
                    </Grid>
                    {
                      thirdStageStageCushing.treatmentBeforeOperationCabergolines.map((treatmentBeforeOperationCabergoline, index) => (
                        <React.Fragment key={treatmentBeforeOperationCabergoline.dose + index}>
                          <Grid item xs={4} pl={2} alignSelf={'stretch'} mt={2}>
                            <Input multiline disabled variant={'standard'} fullWidth={true} name={'dose'} label={'dawka (mg)'}
                                   type={'text'} maxLength={2} value={treatmentBeforeOperationCabergoline.dose} />
                          </Grid>
                          <Grid item xs={4} pl={2} alignSelf={'stretch'} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'dateStart'} label={'stosowanie - OD'}
                                   type={'text'} maxLength={2} value={getDate(treatmentBeforeOperationCabergoline.dateStart)} />
                          </Grid>
                          <Grid item xs={4} pl={2} alignSelf={'stretch'} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'dateEnd'} label={'stosowanie - DO'}
                                   type={'text'} maxLength={2} value={getDate(treatmentBeforeOperationCabergoline.dateEnd)} />
                          </Grid>
                        </React.Fragment>
                      ))
                    }
                  </Grid>}
                </Grid>}


              {thirdStageStageCushing.operations.length > 0  && <Grid container display={'flex'} alignItems={'center'} mt={2}>
                <Grid item xs={12} mt={2} mb={4} >
                  <Typography variant={'h6'} >Zabiegi operacyjne:</Typography>
                </Grid>
                {thirdStageStageCushing.operations.map((operation, index)=>
                  <Grid container key={operation.type + index} display={'flex'} alignItems={'flex-start'}>
                    <Grid item xs={6} pl={2} alignSelf={'stretch'}mt={2} >
                      <Input disabled variant={'standard'} fullWidth={true} name={'operation.date'} label={'data'}
                             type={'text'} value={getDate(operation.date)} />
                    </Grid>
                    <Grid item xs={6} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} name={'operation.type'} label={'rodzaj zabiegu'}
                             type={'text'} value={operation.type} />
                    </Grid>
                    <Grid item xs={6} pl={2} alignSelf={'stretch'} mt={2}>
                      <Text>W przypadku guza przysadki:</Text>
                    </Grid>
                    <Grid item xs={6} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'} alignSelf={'stretch'} mt={2}>
                      <CheckedIconContainer/><Text>{operation.pituaryTumor}</Text>
                    </Grid>
                  </Grid>
                )}
              </Grid>}


              {thirdStageStageCushing.subnormalCortisolLevels  && <Grid container display={'flex'} alignItems={'center'} mt={2}>
                <Grid item xs={12}>
                  <Typography variant={'h6'} >Subnormalne stężenie kortyzolu w surowicy w okresie bezpośrednio po operacji (&lt; 2,0 μg/dl):</Typography>
                </Grid>
                <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                  <CheckedIconContainer/><Text>{thirdStageStageCushing.subnormalCortisolLevels}</Text>
                </Grid>
              </Grid>}


              {thirdStageStageCushing.hydrocortisolTreatment  && <Grid container display={'flex'} alignItems={'center'} mt={2}>
                <Grid item xs={12}>
                  <Typography variant={'h6'} >Konieczność leczenia substytucyjnego hydrokortyzonem:</Typography>
                </Grid>
                <Grid item xs={2} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                  <CheckedIconContainer/><Text>{thirdStageStageCushing.hydrocortisolTreatment}</Text>
                </Grid>
                {thirdStageStageCushing.hydrocortisolTreatment === 'Tak' && <><Grid item xs={5} pl={2}>
                  <Input multiline disabled variant={'standard'} fullWidth={true} name={'hydrocortisolTreatmentDateStart'} label={'od'}
                         type={'text'} value={getDate(thirdStageStageCushing.hydrocortisolTreatmentDateStart)} />
                </Grid>
                <Grid item xs={5} pl={2}>
                  <Input multiline disabled variant={'standard'} fullWidth={true} name={'hydrocortisolTreatmentDateEnd'} label={'do'}
                         type={'text'} value={getDate(thirdStageStageCushing.hydrocortisolTreatmentDateEnd)} />
                </Grid></>}
              </Grid>}


               <Grid container display={'flex'} alignItems={'center'} mt={2}>
                 {thirdStageStageCushing.complicationsOfSurgicalProcedures  &&<><Grid item xs={12}>
                  <Typography variant={'h6'} >Powikłania zabiegów operacyjnych (neurochirurgicznych w przypadku ch. Cushinga):</Typography>
                </Grid>
                <Grid item xs={3} pl={2} mt={2}>
                  <Text>Moczówka prosta</Text>
                </Grid>
                 <Grid item xs={4} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                   <CheckedIconContainer/><Text>{thirdStageStageCushing.complicationsOfSurgicalProcedures}</Text>
                 </Grid>
                 </>}


                {thirdStageStageCushing.complicationsOfSurgicalProceduresCheck.map(procedure=>
                  <Grid item xs={12} key={procedure} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                    <CheckedIconContainer/><Text>{procedure}</Text>
                  </Grid>

                )}
              </Grid>


              {pharmacologicalTreatmentSection &&
                <Grid container mb={2}>
                  <Typography mt={4} variant={'h6'}>Leczenie farmakologiczne stosowane w przypadku przetrwałej hiperkortyzolemii:</Typography>
                  {hypercortisolaemiaPasireotidesSection && <Grid container display={'flex'} alignItems={'center'} >
                    <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                      <Text>{'Pasyreotyd LAR co 4 tygodnie'}</Text>
                    </Grid>
                    {
                      thirdStageStageCushing.hypercortisolaemiaPasireotides.map((treatmentBeforeOperationPasireotide, index) => (
                        <React.Fragment key={treatmentBeforeOperationPasireotide.dose + index}>
                          <Grid item xs={4} pl={2} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'dose'} label={'dawka (mg)'}
                                   type={'text'} maxLength={2} value={treatmentBeforeOperationPasireotide.dose} />
                          </Grid>
                          <Grid item xs={4} pl={2} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'dateStart'} label={'stosowanie - OD'}
                                   type={'text'} maxLength={2} value={getDate(treatmentBeforeOperationPasireotide.dateStart)} />
                          </Grid>
                          <Grid item xs={4} pl={2} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'dateEnd'} label={'stosowanie - DO'}
                                   type={'text'} maxLength={2} value={getDate(treatmentBeforeOperationPasireotide.dateEnd)} />
                          </Grid>
                        </React.Fragment>
                      ))
                    }
                  </Grid>}
                  {hypercortisolaemiaCabergolinesSection && <Grid container display={'flex'} alignItems={'flex-start'} >
                    <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                      <Text>{'Kabergolina'}</Text>
                    </Grid>
                    {
                      thirdStageStageCushing.hypercortisolaemiaCabergolines.map((treatmentBeforeOperationCabergoline, index) => (
                        <React.Fragment key={treatmentBeforeOperationCabergoline.dose + index}>
                          <Grid item xs={4} pl={2} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'dose'} label={'dawka (mg)'}
                                   type={'text'} maxLength={2} value={treatmentBeforeOperationCabergoline.dose} />
                          </Grid>
                          <Grid item xs={4} pl={2} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'dateStart'} label={'stosowanie - OD'}
                                   type={'text'} maxLength={2} value={getDate(treatmentBeforeOperationCabergoline.dateStart)} />
                          </Grid>
                          <Grid item xs={4} pl={2} mt={2}>
                            <Input disabled variant={'standard'} fullWidth={true} name={'dateEnd'} label={'stosowanie - DO'}
                                   type={'text'} maxLength={2} value={getDate(treatmentBeforeOperationCabergoline.dateEnd)} />
                          </Grid>
                        </React.Fragment>
                      ))
                    }
                  </Grid>}

                  {Boolean(thirdStageStageCushing.steroidogenesisInhibitorHow) && <Grid container display={'flex'} alignItems={'flex-start'} >
                    <Grid item xs={4} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                      <Text>{'inhibitor steroidogenezy'}</Text>
                    </Grid>
                    <Grid item xs={8} pl={0.5} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                      <CheckedIconContainer/><Text>{thirdStageStageCushing.steroidogenesisInhibitorHow}</Text>
                    </Grid>
                    <>
                      {
                        thirdStageStageCushing.steroidogenesisInhibitorHow === 'Osilodrostat' || thirdStageStageCushing.steroidogenesisInhibitorHow === 'Metyrapon' ?
                         <>
                          <Grid item xs={6} pl={2} mt={2} alignSelf={'stretch'}>
                            <Input multiline disabled variant={'standard'} fullWidth={true} name={'steroidogenesisInhibitorDoseOf'} label={'dawka'}
                                   type={'text'} value={thirdStageStageCushing.steroidogenesisInhibitorDoseOf} />
                          </Grid>
                          <Grid item xs={6} pl={2} mt={2} alignSelf={'stretch'}>
                            <Input multiline disabled variant={'standard'} fullWidth={true} name={'steroidogenesisInhibitorHowMuch24h'} label={'ile razy na dobę'}
                            type={'text'} value={thirdStageStageCushing.steroidogenesisInhibitorHowMuch24h} />
                          </Grid>
                           <Grid item xs={12} pl={2} mt={2} alignSelf={'stretch'}>
                             <Input multiline disabled variant={'standard'} fullWidth={true} name={'steroidogenesisInhibitorHowEscalatedDose'} label={'w jaki sposób eskalowano dawkę'}
                                    type={'text'} value={thirdStageStageCushing.steroidogenesisInhibitorHowEscalatedDose} />
                           </Grid>
                           <Grid item xs={3} pl={2} mt={2} alignSelf={'stretch'}>
                             <Input multiline disabled variant={'standard'} fullWidth={true} name={'steroidogenesisInhibitorDoseOfMax'} label={'dawka maksymalna'}
                                    type={'text'} value={thirdStageStageCushing.steroidogenesisInhibitorDoseOfMax} />
                           </Grid>
                           <Grid item xs={3} pl={2} mt={2} alignSelf={'stretch'}>
                             <Input multiline disabled variant={'standard'} fullWidth={true} name={'steroidogenesisInhibitorDoseOfMaintenance'} label={'dawka podtrzymująca'}
                                    type={'text'} value={getDate(thirdStageStageCushing.steroidogenesisInhibitorDoseOfMaintenance)} />
                           </Grid>
                           <Grid item xs={3} pl={2} mt={2} alignSelf={'stretch'}>
                             <Input disabled variant={'standard'} fullWidth={true} name={'steroidogenesisInhibitorDoseOfDateStart'} label={'okres - OD'}
                                    type={'text'} value={getDate(thirdStageStageCushing.steroidogenesisInhibitorDoseOfDateStart)} />
                           </Grid>
                           <Grid item xs={3} pl={2} mt={2} alignSelf={'stretch'}>
                             <Input disabled variant={'standard'} fullWidth={true} name={'steroidogenesisInhibitorDoseOfDateEndd'} label={'okres - DO'}
                                    type={'text'} value={getDate(thirdStageStageCushing.steroidogenesisInhibitorDoseOfDateEndd)} />
                           </Grid>
                         </>
                          :
                          <>
                            {
                              thirdStageStageCushing.steroidogenesisInhibitorDoses.map((steroidogenesisInhibitorDose, index) => (
                                <React.Fragment key={steroidogenesisInhibitorDose.dose + index}>
                                  <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                                    <Input multiline disabled variant={'standard'} fullWidth={true} name={'nazwa'} label={'nazwa'}
                                           type={'text'} maxLength={2} value={steroidogenesisInhibitorDose.title} />
                                  </Grid>
                                  <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                                    <Input multiline disabled variant={'standard'} fullWidth={true} name={'dose'} label={'dawka (mg/tydzień)'}
                                           type={'text'} maxLength={2} value={steroidogenesisInhibitorDose.dose} />
                                  </Grid>
                                  <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                                    <Input disabled variant={'standard'} fullWidth={true} name={'dateStart'} label={'stosowanie - OD'}
                                           type={'text'} maxLength={2} value={getDate(steroidogenesisInhibitorDose.dateStart)} />
                                  </Grid>
                                  <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                                    <Input disabled variant={'standard'} fullWidth={true} name={'dateEnd'} label={'stosowanie - DO'}
                                           type={'text'} maxLength={2} value={getDate(steroidogenesisInhibitorDose.dateEnd)} />
                                  </Grid>
                                </React.Fragment>
                              ))
                            }
                          </>
                      }
                    </>
                  </Grid>}
                </Grid>}


              {qualificationsSection && <Grid container>
                <Grid item xs={12} mt={4} mb={2} >
                  <Typography variant={'h6'}>Kwalifikacja do:</Typography>
                </Grid>
                {thirdStageStageCushing.qualificationsDiagnosisAccess &&
                  <>
                    <Grid item xs={6} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                      <CheckedIconContainer/><Text>ponownego leczenia neurochirurgicznego</Text>
                    </Grid>
                    <Grid item xs={6} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                      <Input disabled variant={'standard'} fullWidth={true} name={'qualificationsDiagnosisAccess'} label={'z jakiego dostępu'}
                             type={'text'} value={thirdStageStageCushing.qualificationsDiagnosisAccess} />
                    </Grid>
                  </>
                }
                {thirdStageStageCushing.qualificationsAnotherTreatment &&
                  <>
                    <Grid item xs={6} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                      <CheckedIconContainer/><Text>innego leczenia operacyjnego</Text>
                    </Grid>
                    <Grid item xs={6} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} name={'qualificationsAnotherTreatment'} label={'jakie'}
                             type={'text'} value={thirdStageStageCushing.qualificationsAnotherTreatment} />
                    </Grid>
                  </>
                }
                {
                  thirdStageStageCushing.qualificationsDiagnosis.map(qualification=>
                    <Grid key={qualification} item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                      <CheckedIconContainer/><Text>{qualification}</Text>
                    </Grid>
                  )
                }

              </Grid>}

                {thirdStageStageCushing.biochemicalNormalization &&
                  <Grid container mb={2}>
                    <Typography mt={4} mb={2} variant={'h6'}>Czy uzyskano normalizację stężenia kortyzolu?:</Typography>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                      <CheckedIconContainer/><Text>{thirdStageStageCushing.biochemicalNormalization}</Text>
                    </Grid>
                      {biochemicalNormalizationYesSection && <>
                       {thirdStageStageCushing.biochemicalNormalizationSerumCortisolDate && <><Grid item xs={6} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                          <CheckedIconContainer/><Text>wolnego kortyzolu w ślinie</Text>
                        </Grid>
                        <Grid item xs={6} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                          <Input disabled variant={'standard'} fullWidth={true} name={'biochemicalNormalizationSerumCortisolDate'} label={'OD'}
                                 type={'text'} value={getDate(thirdStageStageCushing.biochemicalNormalizationSerumCortisolDate)} />
                        </Grid></>}
                          {thirdStageStageCushing.biochemicalNormalizationCortisolDate && <><Grid item xs={6} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                            <CheckedIconContainer/><Text>kortyzolu w surowicy</Text>
                          </Grid>
                            <Grid item xs={6} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                              <Input disabled variant={'standard'} fullWidth={true} name={'biochemicalNormalizationCortisolDate'} label={'OD'}
                                     type={'text'} value={getDate(thirdStageStageCushing.biochemicalNormalizationCortisolDate)} />
                            </Grid></>}
                          {thirdStageStageCushing.biochemicalNormalizationDZMDate && <><Grid item xs={6} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                            <CheckedIconContainer/><Text>wolnego kortyzolu w DZM</Text>
                          </Grid>
                            <Grid item xs={6} pl={2} display={'flex'} justifyItems={'center'}>
                              <Input disabled variant={'standard'} fullWidth={true} name={'biochemicalNormalizationDZMDate'} label={'OD'}
                                     type={'text'} value={getDate(thirdStageStageCushing.biochemicalNormalizationDZMDate)} />
                            </Grid></>}
                      </>}
                  </Grid>}


                {thirdStageStageCushing.toleranceOfPharmacologicalTreatment.length >0  && <Grid container display={'flex'} alignItems={'center'} mt={2}>
                  <Grid item xs={12}  mb={2}>
                    <Typography variant={'h6'} >Tolerancja stosowanego leczenia:</Typography>
                  </Grid>
                    {
                        thirdStageStageCushing.toleranceOfPharmacologicalTreatment.map(treatment=> <>

                            <Grid item key={treatment} xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                                <CheckedIconContainer/><Text>{treatment}</Text>
                            </Grid>
                        </>)
                    }
                </Grid>}


                {anotherComplicationsSection  && <Grid container display={'flex'} alignItems={'center'} mt={2} mb={2}>
                  <Grid item xs={12}  mb={2}>
                    <Typography variant={'h6'} >Inne powikłania stosowanego leczenia:</Typography>
                  </Grid>
                    {
                        thirdStageStageCushing.anotherComplications.map(treatment=> <>

                            <Grid item key={treatment} xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                                <CheckedIconContainer/><Text>{treatment}</Text>
                            </Grid>
                        </>)
                    }
                    {
                        thirdStageStageCushing.anotherComplicationsAnother && <>
                        <Grid item xs={3} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                          <CheckedIconContainer/><Text>inne (jakie?)</Text>
                        </Grid>
                        <Grid item xs={9} >
                          <Input multiline disabled variant={'standard'} fullWidth={true} name={'anotherComplicationsAnother'} label={'inne (jakie?)'}
                                 type={'text'} value={thirdStageStageCushing.anotherComplicationsAnother} />
                        </Grid>
                      </>
                    }
                </Grid>}



                {thirdStageStageCushing.supprotiveTreatment.length > 0  && <Grid container display={'flex'} alignItems={'center'} mt={2}>
                  <Grid item xs={12} mt={2} mb={4} >
                    <Typography variant={'h6'} >Leczenie farmakologiczne chorób współistniejących i powikłań hiperkortyzolemii:</Typography>
                  </Grid>
                    {thirdStageStageCushing.supprotiveTreatment.map((treatment, index)=>
                        <Grid container key={treatment.drug + index} display={'flex'} alignItems={'center'} mb={2} >
                            <Grid item xs={0.5} pl={2}>
                                <Text>{index+1}</Text>
                            </Grid>
                            <Grid item xs={5.5} pl={2} alignSelf={'stretch'}>
                                <Input multiline disabled variant={'standard'} fullWidth={true} name={'treatment.drug'} label={'lek'}
                                       type={'text'} value={treatment.drug} />
                            </Grid>
                            <Grid item xs={6} pl={2} alignSelf={'stretch'}>
                                <Input multiline disabled variant={'standard'} fullWidth={true} name={'treatment.dose'} label={'dawka'}
                                       type={'text'} value={treatment.dose} />
                            </Grid>
                        </Grid>
                    )}
                </Grid>}


                {thirdStageStageCushing.improvementControl.length > 0  && <Grid container display={'flex'} alignItems={'center'} mt={2}>
                  <Grid item xs={12}  mb={2}>
                    <Typography variant={'h6'} >Uzyskano poprawę w zakresie:</Typography>
                  </Grid>
                    {
                        thirdStageStageCushing.improvementControl.map(improvementControlElement=> <>

                            <Grid item key={improvementControlElement} xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                                <CheckedIconContainer/><Text>{improvementControlElement}</Text>
                            </Grid>
                        </>)
                    }
                </Grid>}

              </Grid>
        )
    }
    return <></>
});