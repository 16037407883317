import { HeadCell } from '../../../../interfaces/library/library';

export const searchByFilter = <T>(searchValue: string, targetKeys: HeadCell[]) => (element: T) => {
  if (!searchValue) return true;

  const searchArray = searchValue.trim().toLowerCase().split(' ').filter(el => el.length > 0);

  const targetString = targetKeys.map(key => element[key.id as keyof T]).join(' ').toLowerCase();

  return searchArray.every(searchWord => targetString.includes(searchWord));
};

export const searchByFilterValue =
  <T extends object>(searchValue: string, targetKeys: (keyof T)[]) =>
    (element: T) => {
      //prepare search value from string to array of words and remove empty strings or one-letter words
      const searchArray = searchValue
        .trim()
        .toLowerCase()
        .split(' ');
      // .filter(el => el.length > 0);

      //prepare searchString from given properties from targetKeys in element
      const targetString = targetKeys.map((el: keyof T) => element[el as keyof T]).join(' ');
      return searchArray.every(searchWord => targetString.toLowerCase().includes(searchWord));
    };

export const filterByUserType = <T extends object>(key: keyof T, userType: string) =>
  (element: T) => {
    if (userType === 'Publikujący' && element[key] === true) {
      return true;
    } else if (userType === 'Niezarejestrowani' && element[key] === false) {
      return true;
    }
    return false;
  };