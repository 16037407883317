import {useCaseStore} from "../../../../../../../store/hooks";
import {Grid, Typography} from "@mui/material";
import React from "react";
import { observer } from 'mobx-react-lite';

export const FourthStage = observer(() => {
    const caseStore = useCaseStore();
    const {
        fourthStage,
    }= caseStore;

    if(fourthStage && fourthStage.summary){
        return(
            <Grid container justifyContent={'space-between'} width={'100%'} mt={6} mb={6} >
                <Grid item xs={12}>
                    <Typography variant={'h4'} pb={5}>Podsumowania/wnioski:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <p>{fourthStage.summary}</p>
                </Grid>
            </Grid>
        )
    }
    return <></>
});