import { Dialog, Modal } from '@mui/material';
import React, { FC } from 'react';
import { ModalRootContent, StyledDialogTitle } from './ModalRoot.styled';
import { IModalRootProps } from './ModalRoot.types';
import { GlobalLoader } from '../appLayer/globalLoader/GlobalLoader';

export const ModalRoot: FC<IModalRootProps> = ({ icon, children, handleClose, open, title, titleJustify = 'left' }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}>
      <ModalRootContent>
        <GlobalLoader />
        <Dialog
          open={open}
          onClose={handleClose}>
          {title && <StyledDialogTitle textAlign={titleJustify}>
            {title}{icon}
          </StyledDialogTitle>}
          {children}
        </Dialog>
      </ModalRootContent>
    </Modal>
  );
};