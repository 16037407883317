import styled from 'styled-components';
import { themeComponentStyled } from '../../../theme/theme';
import { IHeaderProps } from './Header.types';

export const HeaderStyled = styled.header<IHeaderProps>`
  z-index: 999;
  position: fixed;
  display: flex;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: ${props => props.isSearch ? '0 32px' : '0'};
  background-color: ${themeComponentStyled.colors.gray};

  & img {
    height: 38px;
  }

  & svg {
    cursor: pointer
  }
`;