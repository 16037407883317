import { useEffect, useState } from 'react';
import { IDiscussionElement } from '../components/pages/caseView/CaseView.types';


export function useAnswer<T extends {id: number}>(elements: T[] | null, postAnswer: ( idAnswer: number, answer: string)=> void){
  const [reset, setReset] = useState(true);
  const [answers, setAnswers] = useState<IDiscussionElement[] | null>(null);

  useEffect( () => {
    if(reset && elements){
      const answersElementInit = elements.map(element=> {
        return {id: element.id, answer: ""} as IDiscussionElement;
      });

      setAnswers(answersElementInit);
      setReset(false);
    }
  }, [elements, answers, reset]);

  const handleAddAnswer = async (idAnswer: number) => {
    const index = answers?.findIndex(element => element.id === idAnswer);
    if(answers && index !== undefined){
      await postAnswer(idAnswer, answers[index].answer);
      const answersElementNew = answers ? [...answers] : [];
      answersElementNew[index].answer = "";
      setAnswers(answersElementNew);
    }
  }

  const handleChangeAnswer = (idAnswer: number, answer: string) => {

    const index = answers?.findIndex(element => element.id === idAnswer);

    if(answers && index !== undefined){
      const answersElementNew = answers ? [...answers] : [];
      answersElementNew[index].answer = answer;
      setAnswers(answersElementNew);
    }
  }

  const getAnswer  = (idAnswer: number) => {
    const index = answers?.findIndex(element => element.id === idAnswer);
    if(answers && index !== undefined){
      return answers[index].answer;
    }
    return "";
  }

  const resetAnswers = () => {
    setReset(true);
  }

  return {
    getAnswer,
    handleAddAnswer,
    handleChangeAnswer,
    resetAnswers,
    answers
  }
}