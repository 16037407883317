import styled from "styled-components";

interface IDatePickerCustom {
  isError: boolean;
  maxWidth?: string;
  textAlign?: string;
}

export const DatePickerCustomContainer = styled.div<IDatePickerCustom>`
  width: 100%;
  text-align: ${props => props.textAlign};
  
  input {
    width: 100%;
  }
  
  div{
    width: 100%;
    display: flex;
    -moz-box-pack: justify;
    justify-content: flex-end;
  }
`
