import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRedirect } from '../../../hooks/useRedirect';

export const HomeRedirect = observer(() => {
  const { redirect } = useRedirect();

  useEffect(() => {
    redirect();
  }, [redirect]);

  return null;
});
