import { useEffect, useState } from 'react';
import { DateType } from '../../CaseDraft.types';

export const useErrorEndDate = (formik: any, dateStart: DateType, dateEnd: DateType) => {
  const [errorDate, setErrorDate] = useState(false);

  useEffect(() => {
    if(dateStart !== null && dateEnd !== null){
      const dateStartText = new Date(dateStart);
      const dateEndText = new Date(dateEnd);

      if(dateStartText > dateEndText){
        setErrorDate(true)
      }
      else {
        setErrorDate(false)
      }
    }

  }, [formik, dateEnd, dateStart])

  return errorDate;
}