import { IFormField } from '../../../../interfaces/common/form';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';

import { IRegisterForm } from './Register.interfaces';
import { RegexRules, ValidationError } from '../../../../utils';


// login form configuration
export const registerFormConfig: IFormField[] = [
  {
    name: 'academicTitle',
    label: 'Tytuł naukowy',
    required: false,
    type: 'text'
  },
  {
    name: 'fullName',
    label: 'Imię i nazwisko',
    required: true,
    type: 'text'
  },
  {
    name: 'specialization',
    label: 'Specjalizacja',
    required: false,
    type: 'text'
  },
  {
    name: 'workPlace',
    label: 'Miejsce pracy',
    required: false,
    type: 'text'
  },
  {
    name: 'email',
    label: 'E-mail',
    required: false,
    disabled: true,
    readOnly: true,
    type: 'text'
  },
  {
    name: 'password',
    label: 'Hasło',
    required: true,
    type: 'password'
  },
  {
    name: 'passwordRepeat',
    label: 'Potwierdź hasło',
    required: true,
    type: 'password'
  }
];


// validation schema for login form
export const validationRegisterSchema: ObjectSchema<IRegisterForm> = yup.object().shape({
  academicTitle: yup.string(),
  fullName: yup
    .string()
    .trim()
    .required(ValidationError.FIELD_REQUIRED),
  specialization: yup.string(),
  workPlace: yup.string(),
  email: yup.string().matches(RegexRules.EMAIL, ValidationError.EMAIL_NOT_CORRECT).required(ValidationError.FIELD_REQUIRED),
  password: yup
    .string()
    .required(ValidationError.PASSWORD_NOT_CORRECT)
    .matches(RegexRules.PASSWORD, ValidationError.PASSWORD_NOT_CORRECT),
  passwordRepeat: yup
    .string()
    .required(ValidationError.PASSWORD_NOT_CORRECT)
    .matches(
      RegexRules.PASSWORD,
      ValidationError.PASSWORD_NOT_CORRECT
    )
    .oneOf([yup.ref('password')], ValidationError.PASSWORDS_NOT_MATCH)
});

// initial data for login form
export const initialRegisterForm: IRegisterForm = {
  academicTitle: '',
  fullName: '',
  specialization: '',
  workPlace: '',
  email: '',
  password: '',
  passwordRepeat: ''
};