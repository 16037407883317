import { FormControlLabel, FormGroup, Grid, Radio, Switch } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Fieldset } from '../../../../../../shared/ui/form/fieldset/FieldsetContainer';
import { useSecondStageVm } from './useSecondStage.vm';
import { DateInput } from '../../../../../../shared/ui/form/date/Date';
import { SelectCustom } from '../../../../../../shared/ui/form/select/Select';
import {
  currentCushingSpecializationOptions,
  currentRecognitionAdrenalCushingSyndromeOptions,
  currentRecognitionEtopicACTHProductionSyndromeOptions,
  macroAdenomaKnospScaleOptions,
  macroAdenomaPressureSinusOptions
} from './SecondStage.helper';
import { InputController } from '../../../../../../shared/ui/form/inputController/InputController';
import { IInputControllerSecondStage } from './SecondStage.types';
import { MarkersLabel, RadioExtraContainer } from './SecondStage.styled';
import { RadioGroupStyled } from '../../common/fourthStage/FourthStage.styled';
import { Input } from '../../../../../../shared/ui/form/input/Input';
import { ButtonsActions } from '../../common/buttonsActions/ButtonsActions';
import { radioValueOptional } from '../../../CaseDraft.helper';

export const SecondStage = observer(() => {
  const {
    inputControllerElements,
    setActive,
    setActiveBroadcast,
    formik,
    activeFieldset,
    handleSave,
    handleResetFieldset,
    handleDraftSave,
    handleChangeSwitchAndCheckbox,
    adenomaFieldsActive
  } = useSecondStageVm();

  return (<Grid container justifyContent={'space-between'}>
    <Fieldset legend={'Aktualne rozpoznanie'}>
      <Grid container>
        <Grid item xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2}>
          <RadioGroupStyled row={true} name={'currentDiagnosisCushing'} value={formik.values.currentDiagnosisCushing}>

            <FormControlLabel
              onChange={formik.handleChange}
              value={'choroba Cushinga'}
              style={{ marginBottom: 8 }}
              control={<Radio checked={formik.values.currentDiagnosisCushing === 'choroba Cushinga'}
                onClick={() => formik.setFieldValue('currentDiagnosisCushing', radioValueOptional(formik.values.currentDiagnosisCushing, 'choroba Cushinga'))} />}
              label='choroba Cushinga' />
            <InputController<IInputControllerSecondStage>
              setBroadcast={setActiveBroadcast}
              broadcastFields={['currentDiagnosisProductionACTHCheck', 'currentDiagnosisAdrenalCushingCheck']}
              active={formik.values.currentDiagnosisCushing === 'zespół ektopowej produkcji ACTH'}
              setField={formik.setFieldValue}
              radioProvider={true}
              isHalf={true}
              radio={{
                name: 'currentDiagnosisCushing',
                label: 'zespół ektopowej produkcji ACTH'
              }}
              inputName={'currentDiagnosisCushing'}>
              <SelectCustom
                disabled={formik.values.currentDiagnosisCushing !== 'zespół ektopowej produkcji ACTH'}
                error={Boolean(formik.errors.currentDiagnosisProductionACTHCheck)}
                name={'currentDiagnosisProductionACTHCheck'}
                placeholder={'Wybierz'}
                label={'Wybierz'}
                onChange={formik.handleChange}
                value={formik.values.currentDiagnosisProductionACTHCheck}
                options={currentRecognitionEtopicACTHProductionSyndromeOptions} />

            </InputController>
            <InputController<IInputControllerSecondStage>
              setBroadcast={setActiveBroadcast}
              broadcastFields={['currentDiagnosisProductionACTHCheck', 'currentDiagnosisAdrenalCushingCheck']}
              active={formik.values.currentDiagnosisCushing === 'zespół Cushinga pochodzenia nadnerczowego'}
              setField={formik.setFieldValue}
              radioProvider={true}
              isHalf={true}
              radio={{
                name: 'currentDiagnosisCushing',
                label: 'zespół Cushinga pochodzenia nadnerczowego'
              }}
              inputName={'currentDiagnosisCushing'}>
              <SelectCustom
                disabled={formik.values.currentDiagnosisCushing !== 'zespół Cushinga pochodzenia nadnerczowego'}
                error={Boolean(formik.errors.currentDiagnosisAdrenalCushingCheck)}
                name={'currentDiagnosisAdrenalCushingCheck'}
                placeholder={'Wybierz'}
                label={'Wybierz'}
                onChange={formik.handleChange}
                value={formik.values.currentDiagnosisAdrenalCushingCheck}
                options={currentRecognitionAdrenalCushingSyndromeOptions} />
            </InputController>
            <FormControlLabel
              value={'ACTH-zależny zespół Cushinga o nieustalonej etiologii'}
              control={<Radio
                checked={formik.values.currentDiagnosisCushing === 'ACTH-zależny zespół Cushinga o nieustalonej etiologii'}
                onClick={() => formik.setFieldValue('currentDiagnosisCushing', radioValueOptional(formik.values.currentDiagnosisCushing, 'ACTH-zależny zespół Cushinga o nieustalonej etiologii'))} />}
              label='ACTH-zależny zespół Cushinga o nieustalonej etiologii' />
          </RadioGroupStyled>

        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset activeKey={activeFieldset}
      keyFieldset={'valid-fieldset-1'}
      legend={'Rozpoznanie zespołu Cushinga'}
      handleReset={handleResetFieldset}>
      <Grid container mt={2}>
        <Grid item xs={4} pr={2}>
          <DateInput
            label={'Data rozpoznania'}
            required={true}
            views={['year', 'month']}
            openTo={'year'}
            name={'courseDateStart'}
            inputFormat={'mm.yyyy'}
            value={formik.values.courseDateStart}
            error={Boolean(formik.errors.courseDateStart)}
            onBlur={() => formik.setFieldTouched('courseDateStart', true)}
            onChange={(e) => formik.setFieldValue('courseDateStart', e)} />
        </Grid>
        <Grid item xs={4} pr={2}>
          <SelectCustom
            required={true}
            error={Boolean(formik.errors.courseWhoRecognized)}
            name={'courseWhoRecognized'}
            placeholder={'Kto rozpoznał - specjalizacja'}
            label={'Kto rozpoznał - specjalizacja'}
            onChange={formik.handleChange}
            value={formik.values.courseWhoRecognized}
            options={currentCushingSpecializationOptions} />
        </Grid>
        <Grid item xs={4}>
          <DateInput
            label={'Data rozpoczęcia leczenia'}
            required={true}
            views={['year', 'month']}
            openTo={'year'}
            name={'courseDateStartOfTreatment'}
            inputFormat={'mm.yyyy'}
            value={formik.values.courseDateStartOfTreatment}
            error={Boolean(formik.errors.courseDateStartOfTreatment)}
            onBlur={() => formik.setFieldTouched('courseDateStartOfTreatment', true)}
            onChange={(e) => formik.setFieldValue('courseDateStartOfTreatment', e)} />
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Objawy kliniczne i powikłania w okresie ustalania rozpoznania'}
      subtitle={'(Zwłaszcza istotne odnotowanie powikłań sercowo-naczyniowego i okresu ich trwania w latach i miesiącach)'}>
      <Grid container>
        <Grid item xs={12} pt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
          <Input
            fullWidth={true}
            multiline={true}
            rows={3}
            name={'clinicalSymptoms'}
            type={'text'}
            onChange={formik.handleChange}
            value={formik.values.clinicalSymptoms}
            placeholder={''}
            label={'Wpisz'}
          />
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Badania hormonalne w okresie ustalania rozpoznania'}>
      <Grid container>
        <Grid item xs={12} pt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
          <InputController<IInputControllerSecondStage>
            setActive={setActive}
            active={inputControllerElements.hormonalTestDuringTheDiagnosisCortisol}
            setField={formik.setFieldValue}
            isHalf={true}
            checkbox={{
              name: 'hormonalTestDuringTheDiagnosisCortisolCheckbox',
              label: 'kortyzol w surowicy w godzinach porannych'
            }}
            inputName={'hormonalTestDuringTheDiagnosisCortisol'}>
            <Input
              disabled={!inputControllerElements.hormonalTestDuringTheDiagnosisCortisol}
              name={'hormonalTestDuringTheDiagnosisCortisol'} type={'text'}
              onChange={formik.handleChange} value={formik.values.hormonalTestDuringTheDiagnosisCortisol}
              placeholder={'(μg/dl)'}
              label={'(μg/dl)'} />
          </InputController>
        </Grid>
        <Grid item xs={12} pt={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
          <InputController<IInputControllerSecondStage>
            setActive={setActive}
            active={inputControllerElements.hormonalTestDuringTheDiagnosisACTH}
            setField={formik.setFieldValue}
            isHalf={true}
            checkbox={{
              name: 'hormonalTestDuringTheDiagnosisACTHCheckbox',
              label: 'ACTH w osoczu w godzinach porannych'
            }}
            inputName={'hormonalTestDuringTheDiagnosisACTH'}>
            <Input disabled={!inputControllerElements.hormonalTestDuringTheDiagnosisACTH}
              name={'hormonalTestDuringTheDiagnosisACTH'} type={'text'}
              onChange={formik.handleChange} value={formik.values.hormonalTestDuringTheDiagnosisACTH}
              placeholder={'(pg/ml)'}
              label={'(pg/ml)'} />
          </InputController>
        </Grid>
        <Grid item xs={12} pt={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
          <InputController<IInputControllerSecondStage>
            setActive={setActive}
            active={inputControllerElements.hormonalTestDuringTheDiagnosisFreeCortisol}
            setField={formik.setFieldValue}
            isHalf={true}
            checkbox={{
              name: 'hormonalTestDuringTheDiagnosisFreeCortisolCheckbox',
              label: 'wolny kortyzol w moczu (optymalnie średnia z dwóch lub trzech DZM)'
            }}
            inputName={'hormonalTestDuringTheDiagnosisFreeCortisol'}>
            <Input disabled={!inputControllerElements.hormonalTestDuringTheDiagnosisFreeCortisol}
              name={'hormonalTestDuringTheDiagnosisFreeCortisol'} type={'text'}
              onChange={formik.handleChange} value={formik.values.hormonalTestDuringTheDiagnosisFreeCortisol}
              placeholder={'(μg/dobę)'}
              label={'(μg/dobę)'} />
          </InputController>
        </Grid>
        <Grid item xs={12} pt={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
          <InputController<IInputControllerSecondStage>
            setActive={setActive}
            active={inputControllerElements.hormonalTestDuringTheDiagnosisCRH}
            setField={formik.setFieldValue}
            isHalf={true}
            checkbox={{
              name: 'hormonalTestDuringTheDiagnosisCRHCheckbox',
              label: 'test z CRH'
            }}
            inputName={'hormonalTestDuringTheDiagnosisCRH'}>
            <RadioGroupStyled row={true} name={'hormonalTestDuringTheDiagnosisCRH'}
              value={formik.values.hormonalTestDuringTheDiagnosisCRH}>
              <FormControlLabel disabled={!inputControllerElements.hormonalTestDuringTheDiagnosisCRH}
                onChange={formik.handleChange}
                value={'pozytywny'} control={<Radio />} label='pozytywny' />
              <FormControlLabel disabled={!inputControllerElements.hormonalTestDuringTheDiagnosisCRH}
                onChange={formik.handleChange}
                value={'negatywny'} control={<Radio />} label='negatywny' />
            </RadioGroupStyled>
          </InputController>
        </Grid>
        <Grid item xs={12} pt={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
          <InputController<IInputControllerSecondStage>
            setActive={setActive}
            active={inputControllerElements.hormonalTestDuringTheDiagnosisDesmopressin}
            setField={formik.setFieldValue}
            isHalf={true}
            checkbox={{
              name: 'hormonalTestDuringTheDiagnosisDesmopressinCheckbox',
              label: 'test z desmopresyną'
            }}
            inputName={'hormonalTestDuringTheDiagnosisDesmopressin'}>
            <RadioGroupStyled row={true} name={'hormonalTestDuringTheDiagnosisDesmopressin'}
              value={formik.values.hormonalTestDuringTheDiagnosisDesmopressin}>
              <FormControlLabel disabled={!inputControllerElements.hormonalTestDuringTheDiagnosisDesmopressin}
                onChange={formik.handleChange}
                value={'pozytywny'} control={<Radio />} label='pozytywny' />
              <FormControlLabel disabled={!inputControllerElements.hormonalTestDuringTheDiagnosisDesmopressin}
                onChange={formik.handleChange}
                value={'negatywny'} control={<Radio />} label='negatywny' />
            </RadioGroupStyled>
          </InputController>
        </Grid>

        <Grid item xs={12} pt={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
          <InputController<IInputControllerSecondStage>
            setActive={setActive}
            active={inputControllerElements.hormonalTestDuringTheDiagnosisDexamethasone}
            setField={formik.setFieldValue}
            isHalf={true}
            checkbox={{
              name: 'hormonalTestDuringTheDiagnosisDexamethasoneCheckbox',
              label: 'test z „wysoką” dawką deksametazonu'
            }}
            inputName={'hormonalTestDuringTheDiagnosisDexamethasone'}>
            <RadioGroupStyled row={true} name={'hormonalTestDuringTheDiagnosisDexamethasone'}
              value={formik.values.hormonalTestDuringTheDiagnosisDexamethasone}>
              <FormControlLabel disabled={!inputControllerElements.hormonalTestDuringTheDiagnosisDexamethasone}
                onChange={formik.handleChange} value={'pozytywny'} control={<Radio />}
                label='pozytywny' />
              <FormControlLabel disabled={!inputControllerElements.hormonalTestDuringTheDiagnosisDexamethasone}
                onChange={formik.handleChange} value={'negatywny'} control={<Radio />}
                label='negatywny' />
            </RadioGroupStyled>
          </InputController>
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Wynik obrazowania układu podwzgórzowo-przysadkowego w MR/TK'}>
      <Grid container>
        <Grid item xs={12} pt={4} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'}>
          <RadioGroupStyled row={true} name={'imagingResultMrTkMacroMicroadenoma'}
            value={formik.values.imagingResultMrTkMacroMicroadenoma}>
            <FormControlLabel onChange={formik.handleChange} value={'mikrogruczolak'} control={<Radio
              onClick={() => formik.setFieldValue('imagingResultMrTkMacroMicroadenoma', radioValueOptional(formik.values.imagingResultMrTkMacroMicroadenoma, 'mikrogruczolak'))} />}
              label='mikrogruczolak' />
            <RadioExtraContainer>
              <FormControlLabel onChange={formik.handleChange} value={'makrogruczolak'} control={<Radio
                onClick={() => formik.setFieldValue('imagingResultMrTkMacroMicroadenoma', radioValueOptional(formik.values.imagingResultMrTkMacroMicroadenoma, 'makrogruczolak'))} />}
                label='makrogruczolak' />
              {adenomaFieldsActive &&
                <RadioGroupStyled row={true} name={'imagingResultMrTkMacroadenomaInfiltration'}
                  value={formik.values.imagingResultMrTkMacroadenomaInfiltration}>
                  <FormControlLabel onChange={formik.handleChange} value={'ucisk skrzyżowania wzrokowego'}
                    control={<Radio />} label='ucisk skrzyżowania wzrokowego' />

                  <Grid width={'100%'} container display={'flex'} alignItems={'center'}>
                    <FormControlLabel onChange={formik.handleChange} value={'naciekanie zatok jamistych'} control={
                      <Radio />} label='naciekanie zatok jamistych' />
                    <Grid item xs={6} display={'flex'} justifyContent={'flex-start'} gap={1}>
                      <SelectCustom
                        disabled={formik.values.imagingResultMrTkMacroadenomaInfiltration !== 'naciekanie zatok jamistych'}
                        required={true}
                        error={Boolean(formik.errors.imagingResultMrTkMacroadenomaInfiltrationRockyBay)}
                        name={'imagingResultMrTkMacroadenomaInfiltrationRockyBay'}
                        placeholder={'Zatoka'}
                        label={'Zatoka'}
                        onChange={formik.handleChange}
                        value={formik.values.imagingResultMrTkMacroadenomaInfiltrationRockyBay}
                        options={macroAdenomaPressureSinusOptions} />
                      <SelectCustom
                        disabled={formik.values.imagingResultMrTkMacroadenomaInfiltration !== 'naciekanie zatok jamistych'}
                        required={true}
                        error={Boolean(formik.errors.imagingResultMrTkMacroadenomaInfiltrationRockyScaleKnosp)}
                        name={'imagingResultMrTkMacroadenomaInfiltrationRockyScaleKnosp'}
                        placeholder={'Stopień w skali Knospa'}
                        label={'Stopień w skali Knospa'}
                        onChange={formik.handleChange}
                        value={formik.values.imagingResultMrTkMacroadenomaInfiltrationRockyScaleKnosp}
                        options={macroAdenomaKnospScaleOptions} />
                    </Grid>
                  </Grid>
                </RadioGroupStyled>}
            </RadioExtraContainer>
          </RadioGroupStyled>
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Inne badania obrazowe w okresie ustalania rozpoznania'}>
      <Grid container>
        <Grid item xs={12} pt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
          <InputController<IInputControllerSecondStage>
            setActive={setActive}
            active={inputControllerElements.hormonalTestsSomatostatinReceptorResult}
            setField={formik.setFieldValue}
            isHalf={true}
            checkbox={{
              name: 'hormonalTestsSomatostatinReceptorResultCheckbox',
              label: 'scyntygrafia receptorów somatostatynowych'
            }}
            inputName={'hormonalTestsSomatostatinReceptorResult'}>
            <Input disabled={!inputControllerElements.hormonalTestsSomatostatinReceptorResult}
              name={'hormonalTestsSomatostatinReceptorResult'} type={'text'}
              onChange={formik.handleChange}
              value={formik.values.hormonalTestsSomatostatinReceptorResult} placeholder={'Wynik'} />
          </InputController>
        </Grid>
        <Grid item xs={12} pt={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
          <InputController<IInputControllerSecondStage>
            setActive={setActive}
            active={inputControllerElements.hormonalTestsPositronTomographyResult}
            setField={formik.setFieldValue}
            isHalf={true}
            checkbox={{
              name: 'hormonalTestsPositronTomographyResultCheckbox',
              label: 'badanie pozytonowej tomografii emisyjnej z izotopem 68Ga'
            }}
            inputName={'hormonalTestsPositronTomographyResult'}>
            <Input disabled={!inputControllerElements.hormonalTestsPositronTomographyResult}
              name={'hormonalTestsPositronTomographyResult'} type={'text'} onChange={formik.handleChange}
              value={formik.values.hormonalTestsPositronTomographyResult} placeholder={'Wynik'} />
          </InputController>
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Badanie histopatologiczne'}>
      <Grid container>
        <Grid item xs={12} pt={4} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
          <FormGroup>
            <FormControlLabel
              onChange={formik.handleChange}
              value={'potwierdzenie immunohistochemiczne guza kortykotropowego w materiale tkankowym'}
              name={'confirmationOfSomatotropicTissueMaterial'}
              control={<Switch checked={Boolean(formik.values.confirmationOfSomatotropicTissueMaterial)}
                onChange={(event) => handleChangeSwitchAndCheckbox('confirmationOfSomatotropicTissueMaterial', event.target.checked)} />}
              label='potwierdzenie immunohistochemiczne guza kortykotropowego w materiale tkankowym'
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} pt={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
          <RadioGroupStyled row={true} name={'proliferationMarkerKi67'} value={formik.values.proliferationMarkerKi67}>
            <MarkersLabel>Marker proliferacji Ki-67</MarkersLabel>
            <FormControlLabel
              onChange={formik.handleChange}
              value={'< 3%'}
              control={<Radio
                onClick={() => formik.setFieldValue('proliferationMarkerKi67', radioValueOptional(formik.values.proliferationMarkerKi67, '< 3%'))} />}
              label='< 3%' />
            <FormControlLabel
              onChange={formik.handleChange}
              value={'≥ 3%'}
              control={<Radio
                onClick={() => formik.setFieldValue('proliferationMarkerKi67', radioValueOptional(formik.values.proliferationMarkerKi67, '≥ 3%'))} />}
              label='≥ 3%' />
          </RadioGroupStyled>
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Badanie w mikroskopie elektronowym'}>
      <Grid container pt={3}>
        <RadioGroupStyled row={true} name={'electronMicroscopicExamination'}
          value={formik.values.electronMicroscopicExamination}>
          <FormControlLabel
            onChange={formik.handleChange}
            value={'SG-ACTH'}
            control={<Radio
              onClick={() => formik.setFieldValue('electronMicroscopicExamination', radioValueOptional(formik.values.electronMicroscopicExamination, 'SG-ACTH'))} />}
            label='SG-ACTH' />
          <FormControlLabel
            onChange={formik.handleChange}
            value={'DG-ACTH'}
            control={<Radio
              onClick={() => formik.setFieldValue('electronMicroscopicExamination', radioValueOptional(formik.values.electronMicroscopicExamination, 'DG-ACTH'))} />}
            label='DG-ACTH' />
          <FormControlLabel
            onChange={formik.handleChange}
            value={'Crook Cell adenoma'}
            control={<Radio
              onClick={() => formik.setFieldValue('electronMicroscopicExamination', radioValueOptional(formik.values.electronMicroscopicExamination, 'Crook Cell adenoma'))} />}
            label='Crook Cell adenoma' />
        </RadioGroupStyled>
      </Grid>
    </Fieldset>
    <ButtonsActions
      handleDraftSave={handleDraftSave}
      handleSave={handleSave} />
  </Grid>);
});



