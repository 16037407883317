import { Button, Typography } from '@mui/material';
import { initialRegisterForm, registerFormConfig, validationRegisterSchema } from './Register.helper';
import { AuthLayout } from '../../../shared/auth/layout/AuthLayout';
import { SubHeader } from './Register.styled';
import { generateInputs } from '../../../../utils';
import { useRegister } from './useRegister.vm';
import { observer } from 'mobx-react-lite';

const Register = observer(() => {
  const { formik } = useRegister(initialRegisterForm, validationRegisterSchema);

  return (
    <AuthLayout
      contentAfterLogo={<Typography variant={'h5'} mt={2} pb={2}>Rejestracja użytkownika</Typography>}
    >
      <>
        <SubHeader>Pola oznaczone gwiazdką (*) są wymagane.</SubHeader>
        {
          generateInputs(registerFormConfig, formik)
        }
        <Button onClick={() => formik.handleSubmit()} variant='contained'>Wyślij</Button>
      </>
    </AuthLayout>
  );
});

export default Register;