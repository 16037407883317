import * as yup from 'yup';

import {
  IInputControllerSecondStage,
  ISecondStageValues
} from './SecondStage.types';
import { IOption } from '../../../../../../shared/ui/form/select/Select.types';

export const currentRecognitionEtopicACTHProductionSyndromeOptions : IOption[] = [
  {
    label: "rak drobnokomórkowy",
    value: 'rak drobnokomórkowy'
  },
  {
    label: "nowotwór neuroendokrynny (NET)",
    value: 'nowotwór neuroendokrynny (NET)'
  },
]

export const currentRecognitionAdrenalCushingSyndromeOptions : IOption[] = [
  {
    label: "gruczolak nadnercza",
    value: 'gruczolak nadnercza'
  },
  {
    label: "rak nadnercza",
    value: 'rak nadnercza'
  },
]

export const currentCushingSpecializationOptions : IOption[] = [{
  label: "Endokrynolog",
  value: 'Endokrynolog'
},
  {
    label: "Lekarz rodzinny",
    value: 'Lekarz rodzinny'
  },
  {
    label: "Internista",
    value: 'Internista'
  },
  {
    label: "Okulista",
    value: 'Okulista'
  },
  {
    label: "Neurolog",
    value: 'Neurolog'
  },
  {
    label: "Kardiolog",
    value: 'Kardiolog'
  },
  {
    label: "Ortopeda",
    value: 'Ortopeda'
  },
  {
    label: "Reumatolog",
    value: 'Reumatolog'
  },
  {
    label: "PACJENT / rodzina",
    value: 'Pacjent / rodzina'
  },
  {
    label: "Inne",
    value: 'Inne'
  }
]

export const macroAdenomaPressureSinusOptions : IOption[] = [{
  label: "prawa",
  value: 'prawa'
  },
  {
    label: "lewa",
    value: 'lewa'
  },
]
export const macroAdenomaKnospScaleOptions : IOption[] = [
  {
    label: "0",
    value: '0'
  },
  {
    label: "I",
    value: 'I'
  },
  {
    label: "II",
    value: 'II'
  },
  {
    label: "III",
    value: 'III'
  },
  {
    label: "IV",
    value: 'IV'
  }
]

export const validationSecondStageSchema = yup.object().shape({
  courseDateStart: yup
    .string()
    .required(),
  courseDateStartOfTreatment: yup
    .string()
    .required(),
  courseWhoRecognized: yup
    .string()
    .required()
});

export const initialSecondStage : ISecondStageValues = {
  currentDiagnosisCushing: "",
  currentDiagnosisProductionACTHCheck:  "",
  currentDiagnosisAdrenalCushingCheck:  "",
  courseDateStart: null,
  courseDateStartOfTreatment:  null,
  courseWhoRecognized:  "",
  clinicalSymptoms:  "",
  hormonalTestDuringTheDiagnosisCortisolIsActive: false,
  hormonalTestDuringTheDiagnosisCortisol:  "",
  hormonalTestDuringTheDiagnosisACTH:  "",
  hormonalTestDuringTheDiagnosisACTHIsActive: false,
  hormonalTestDuringTheDiagnosisFreeCortisol:  "",
  hormonalTestDuringTheDiagnosisFreeCortisolIsActive: false,
  hormonalTestDuringTheDiagnosisCRH:  "",
  hormonalTestDuringTheDiagnosisCRHIsActive: false,
  hormonalTestDuringTheDiagnosisDesmopressin:  "",
  hormonalTestDuringTheDiagnosisDesmopressinIsActive: false,
  hormonalTestDuringTheDiagnosisDexamethasone:  "",
  hormonalTestDuringTheDiagnosisDexamethasoneIsActive: false,
  imagingResultMrTkMacroMicroadenoma: "",
  imagingResultMrTkMacroadenomaInfiltration: "",
  imagingResultMrTkMacroadenomaInfiltrationRockyScaleKnosp: "",
  imagingResultMrTkMacroadenomaInfiltrationRockyBay: "",
  confirmationOfSomatotropicTissueMaterial : false,
  proliferationMarkerKi67:  "",
  hormonalTestsSomatostatinReceptorResult: "",
  hormonalTestsSomatostatinReceptorResultIsActive: false,
  hormonalTestsPositronTomographyResult : "",
  hormonalTestsSomatostatinReceptorIsActive: false,
  electronMicroscopicExamination : "",
  hormonalTestsSomatostatinReceptor: "",
  hormonalTestsPositronTomography: "",
}

export const checkboxInputControllerConfig : IInputControllerSecondStage = {
  currentDiagnosisCushing: false,
  currentDiagnosisProductionACTHCheck: false,
  currentDiagnosisAdrenalCushingCheck: false,
  hormonalTestDuringTheDiagnosisCortisol: false,
  hormonalTestDuringTheDiagnosisACTH:false,
  hormonalTestDuringTheDiagnosisFreeCortisol: false,
  hormonalTestDuringTheDiagnosisCRH: false,
  hormonalTestDuringTheDiagnosisDesmopressin: false,
  hormonalTestDuringTheDiagnosisDexamethasone:false,
  imagingResultMrTkMacroadenomaInfiltration: false,
  hormonalTestsSomatostatinReceptorResult: false,
  hormonalTestsPositronTomographyResult: false
}

export const convertInputControllerSecondStage = (secondStage: ISecondStageValues) => {
  return {
    currentDiagnosisCushing: secondStage.currentDiagnosisCushing !== '',
    currentDiagnosisProductionACTHCheck: secondStage.currentDiagnosisProductionACTHCheck !== '',
    currentDiagnosisAdrenalCushingCheck: secondStage.currentDiagnosisAdrenalCushingCheck !== '',
    hormonalTestDuringTheDiagnosisCortisol: secondStage.hormonalTestDuringTheDiagnosisCortisol !== '',
    hormonalTestDuringTheDiagnosisACTH: secondStage.hormonalTestDuringTheDiagnosisACTH !== '',
    hormonalTestDuringTheDiagnosisFreeCortisol: secondStage.hormonalTestDuringTheDiagnosisFreeCortisol !== '',
    hormonalTestDuringTheDiagnosisCRH: secondStage.hormonalTestDuringTheDiagnosisCRH !== '',
    hormonalTestDuringTheDiagnosisDesmopressin: secondStage.hormonalTestDuringTheDiagnosisDesmopressin !== '',
    hormonalTestDuringTheDiagnosisDexamethasone: secondStage.hormonalTestDuringTheDiagnosisDexamethasone !== '',
    imagingResultMrTkMacroadenomaInfiltration: secondStage.imagingResultMrTkMacroadenomaInfiltration !== '',
    hormonalTestsSomatostatinReceptorResult: secondStage.hormonalTestsSomatostatinReceptorResult !== '',
    hormonalTestsPositronTomographyResult: secondStage.hormonalTestsPositronTomographyResult !== '',
  }
}
