import { ModalRoot } from '../../shared/modal/ModalRoot';
import { Tab, Tabs } from '@mui/material';
import { AuthController } from '../../shared/appLayer/authController/AuthController';
import { Layer } from '../../shared/layer/Layer';
import libraryBcg from '../../../assets/img/libraryBackground.png';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { FixedButton } from '../../shared/user/layout/fixedButton/FixedButton';
import { useQAPageVm } from './useQAPage.vm';
import { checkRoles } from '../../../utils/checkRoles';
import { RoleFunction } from '../../../constants/projectUsers';
import { TabsContainer } from '../library/Library.styled';
import { ContentContainer } from '../caseView/CaseView.styled';
import { QAElements } from './qaElements/QAElements';
import { SortButton } from '../../shared/sortButton/SortButton';
import { ReactComponent as Question } from '../../../assets/icon/question.svg';

export const QUPages = observer(() => {

  const {
    technicalItems,
    medicalItems,
    tab,
    roleFunction,
    open,
    handleClose,
    handleOpenModal,
    handleTabChange,
    modalSelected,
    handleSortClick,
    technicalResetSort,
    medicalResetSort
  } = useQAPageVm();

  return (
    <AuthController>
      <Layer image={libraryBcg} isSearch={false}>
        {checkRoles(roleFunction, [RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_VIEWING]) &&
          <FixedButton icon={<Question />} title={'ZADAJ PYTANIE'} handleOnClick={handleOpenModal} />}
        {open && modalSelected && <ModalRoot handleClose={handleClose} open={open} title={modalSelected.title}
                                             titleJustify={modalSelected.titleJustify}>
          {modalSelected.element}
        </ModalRoot>}
        <TabsContainer>
          <Tabs
            value={tab}
            onChange={handleTabChange}>
            <Tab value='medical' label='MERYTORYCZNE' />
            <Tab value='technical' label='TECHNICZNE' />
          </Tabs>
        </TabsContainer>
        <ContentContainer>
          <SortButton type={'general'} handleOnClick={handleSortClick}></SortButton>
          {tab === 'technical' &&
            <QAElements reset={technicalResetSort} elements={technicalItems} type={'technical'} />}
          {tab === 'medical' && <QAElements reset={medicalResetSort} elements={medicalItems} type={'medical'} />}
        </ContentContainer>
      </Layer>
    </AuthController>);
});