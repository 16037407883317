import { IInputControllerSteroidogenesisInhibitor, ISteroidogenesisInhibitor } from './SteroidogenesisInhibitor.types';
import { IOption } from '../../../../../../../shared/ui/form/select/Select.types';

export const initialTreatmentBeforeOperationSTeroidogenesisInhibitor : ISteroidogenesisInhibitor = {
  type: "",
  dose: "",
  dateStart: null,
  dateEnd: null,
  dateEndIsActive: false
}

export const checkboxInputControllerConfig: IInputControllerSteroidogenesisInhibitor = {
  type: false,
  dose: false,
  dateStart: false,
  dateEnd: false,
}

export const typeOptions: IOption[] = [
  {
    value: "Metyrapon",
    label: "Metyrapon"
  },
  {
    value: "Osilodrostat",
    label: "Osilodrostat"
  },
  {
    value: "Mitotan",
    label: "Mitotan"
  },
  {
    value: "Ketokonazol",
    label: "Ketokonazol"
  },
  {
    value: "Levoketokonazol",
    label: "Levoketokonazol"
  },
  {
    value: "Etomidat",
    label: "Etomidat"
  },
]
