import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { IProfile } from '../interfaces/users/usersInfo';

export class UsersStore {
  roles: any[] | null = null;
  users: any[] | null = null;
  profile: IProfile | null = null;
  passwordErrorMessage: string = '';

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setRoles(roles: any[]) {
    this.roles = roles;
  }

  setUsers(users: any[]) {
    this.users = users;
  };

  clearUsers() {
    this.users = null;
  }

  setProfile(profile: IProfile) {
    this.profile = profile;
  }

  setPasswordErrorMessage(passwordErrorMessage: string) {
    this.passwordErrorMessage = passwordErrorMessage;
  }

}