import { useCallback, useEffect } from 'react';
import { INotification, NotificationSelect } from '../../../interfaces/notifications/notifications';
import { usePagination } from '../../../hooks/usePagination';
import { useNotificationsStore } from '../../../store/hooks';
import { useNotificationsApi } from '../../../apiHooks';

export const useNotifications = () => {
  const { getNotifications } = useNotificationsApi();
  const notificationsStore = useNotificationsStore();
  const { notifications } = notificationsStore;

  useEffect(() => {
    void getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    return () => {
      notificationsStore.clearNotification();
    };
  }, [notificationsStore]);

  const filterData = useCallback((list: INotification[], status?: NotificationSelect | 'wszystkie') => {
    if (list) {
      if (status === 'wszystkie') {
        return list;
      } else {
        return list.filter(el => el.typeTitleSort === status);
      }
    }
    return [];
  }, []);

  const options = [{
    value: 'wszystkie',
    label: 'wszystkie'
  }, {
    label: 'publikacja opisu',
    value: NotificationSelect.PUBLISH
  }, {
    label: 'ukrycie opisu',
    value: NotificationSelect.HIDDEN
  }, {
    label: 'korekta',
    value: NotificationSelect.CORRECTION
  }, {
    label: 'pytanie',
    value: NotificationSelect.QUESTION
  }];

  const generateLink = (
    typeTitleSort: string,
    caseType: string,
    caseId: number | null,
    typeTitleSortQuestionType: string | null,
    typeTitleSortQuestionTypeQA: string | null
  ) => {
    const baseLink = `/cases/${caseType}/${caseId}`;

    switch (typeTitleSort) {
      case 'PUBLISH':
        return `${baseLink}/description`;
      case 'CORRECTION':
        return `${baseLink}/comments`;
      case 'QUESTION':
        switch (typeTitleSortQuestionType) {
          case 'Forum':
            return `${baseLink}/forum`;
          case 'Discussion':
            return `${baseLink}/discussion`;
          case 'QA':
            switch (typeTitleSortQuestionTypeQA) {
              case 'Tech':
                return '/qa/technical';
              case 'Med':
                return '/qa/medical';
              default:
                return '/qa/medical';
            }
          default:
            return;
        }
      default:
        return `${baseLink}/description`;
    }

  };


  const {
    currentPage,
    totalPage,
    handlePageChange,
    items,
    status,
    handleSelect
  } = usePagination(notifications ?? null, 15, undefined, filterData);

  return {
    currentPage,
    totalPage,
    handlePageChange,
    items,
    status,
    handleSelect,
    options,
    isLoaded: notifications !== null,
    generateLink
  };
};