import { HeaderStyled } from './Header.styled';
import logoNav from '../../../assets/img/logo-nav.png';
import { Grid } from '@mui/material';
import { Menu } from '../menu/Menu';
import { ReactComponent as Profile } from './../../../assets/icon/profile.svg';
import { ReactComponent as Logout } from './../../../assets/icon/logout.svg';
import { ReactComponent as Login } from './../../../assets/icon/login.svg';
import { RoleFunction } from '../../../constants/projectUsers';
import { FC } from 'react';
import { IHeaderProps } from './Header.types';
import { checkRoles } from '../../../utils/checkRoles';
import { useHeader } from './useHeader.vm';
import { ProfileModal } from './modal/ProfileModal';

export const Header: FC<IHeaderProps> = ({ isSearch = true }) => {
  const { roleFunction, open, handleOpen, handleClose, handleLogout, handleLogin } = useHeader();

  return (
    <HeaderStyled isSearch={isSearch}>
      <Grid container justifyContent={'center'} maxWidth={'1280px'} margin={'0 auto'}>
        <Grid item xs={4}>
          <img src={logoNav} alt={'logo-nav'} />
        </Grid>
        <Grid item xs={8} justifyContent={'space-between'} display={'flex'}>
          <Menu />
          <Grid item display={'flex'} alignItems={'center'} gap={'16px'}>
            {checkRoles(roleFunction, [RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_EXPERT]) &&
              <Profile onClick={() => handleOpen('add')} />}
            {checkRoles(roleFunction, [RoleFunction.ROLE_ADMIN, RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_ACCEPTING]) ?
              <Logout onClick={handleLogout} /> : <Login onClick={handleLogin} />}
          </Grid>
        </Grid>
      </Grid>
      {open && <ProfileModal open={open} handleClose={handleClose} />}
    </HeaderStyled>
  );
};