import { createGlobalStyle } from 'styled-components';

const ResetStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
    font-size: 16.5px;
    font-family: 'Roboto', sans-serif;
  }

  body {
    overflow-y: scroll
  }

  html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-family: 'Roboto', sans-serif;
  }

  *::selection {
    background: #c31931;
    color: white;
  }

  ol, ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  legend {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  /* Add more styles as needed... */
`;

const OtherStyle = createGlobalStyle`
  #root {
    position: relative;
    font-family: 'Roboto', sans-serif !important;
  }

  p, span, div {
    font-family: 'Roboto', sans-serif !important;
  }

  * {
    scrollbar-color: #63666a #f2f2f2;
  }

  *::-webkit-scrollbar {
    width: 11px;
  }

  *::-webkit-scrollbar-track {
    background: #f2f2f2;
    border: 1px solid #BBBCBC;
    border-radius: 5px;
    margin-left: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background: #63666a;
    border: 1px solid #BBBCBC;
    border-radius: 5px;
  }

  .MuiCalendarPicker-root {
    & button.Mui-selected {
      border-radius: 28px;
      color: white;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
    }
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

`;

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
`

export const GlobalStyle = () => <><ResetStyle /><OtherStyle /><Fonts/></>;