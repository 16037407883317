import { Label } from '../../../../../../shared/ui/form/label/Label';
import { DescriptionElement, Title } from '../../../Description.styled';
import React from 'react';
import { useCaseStore } from '../../../../../../../store/hooks';
import {observer} from "mobx-react-lite";

export const ZeroStage = observer(() => {
  const caseStore = useCaseStore();
  const {
    zeroStage,
  }= caseStore;

  return (
    <>
      {zeroStage && <>
        <Label>Tytuł i opis przypadku:</Label>
        <Title>{zeroStage.title}</Title>
        <DescriptionElement>{zeroStage.description}</DescriptionElement>
      </>}
    </>
  )
});