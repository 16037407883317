import React from 'react';

import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { ICheckboxesGroupProps } from './CheckboxesGroup.types';


export function CheckboxesGroup<T extends object>({
                                                    elements,
                                                    name,
                                                    onChange,
                                                    values,
                                                    mt,
                                                    full = false,
                                                    singleField = false
                                                  }: ICheckboxesGroupProps<T>) {
  return (
    <FormGroup>
      <Grid container>
        {
          elements.map(checkboxElement =>
            <Grid item key={checkboxElement.name} mt={mt} xs={full ? 12 : (checkboxElement.size ?? 4)}>
              <FormControlLabel
                control={
                  <Checkbox checked={Boolean(values.find(value => checkboxElement.name === value))}
                            onChange={() => onChange(name, checkboxElement.name)} name={checkboxElement.name} />
                }
                label={checkboxElement.label}
              />
            </Grid>
          )
        }
      </Grid>
    </FormGroup>
  );
}