import styled from 'styled-components';
import { Grid } from '@mui/material';
import { themeMUI } from '../../../theme/theme';
import { IColorType } from '../../../interfaces/common/common';

export const ButtonContent = styled.span<IColorType>`
  color:  ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : (props.type === 'cushing' ? props.theme.colors.cushing1 : themeMUI.palette.primary.main)};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px; 
  letter-spacing: 0.4px;
  text-transform: uppercase;
`

export const GridCustom = styled(Grid)<IColorType>`
  path{
    fill:  ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : (props.type === 'cushing' ? props.theme.colors.cushing1 : themeMUI.palette.primary.main)};
  }

`