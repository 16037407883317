import { InputLabel, MenuItem, Select } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { ISelectProps } from './Select.types';
import { SelectStyled } from './Select.styled';

export const SelectCustom: FC<ISelectProps> = ({ style, errorMessage, error, fullWidth, options, ...props }) => {
  const [errorInput, setErrorInput] = useState(false);

  useEffect(() => {
    if (error) {
      setErrorInput(error);
    } else {
      setErrorInput(false);
    }
  }, [error]);


  return (<SelectStyled size='small'>
    <InputLabel required={props.required} error={errorInput}>{props.label}</InputLabel>
    <Select
      style={style}
      id={props.name}
      error={errorInput}
      color={errorInput ? 'error' : 'primary'}
      {...props}
    >
      {options.map(({ value, label, id, role }) => <MenuItem key={value || id}
                                                             value={value || id}>{label || role}</MenuItem>)}
    </Select>
    {error && errorMessage && <p>{errorMessage}</p>}
  </SelectStyled>);

};