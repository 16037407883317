import { useCasesListApi, useNotificationsApi } from '../../../../apiHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthStore, useCaseStore } from '../../../../store/hooks';
import { RoleFunction } from '../../../../constants/projectUsers';
import { checkRoles } from '../../../../utils/checkRoles';
import { DiseaseType, ICaseStatus } from '../../../../interfaces/common/common';
import { generateLink, PublishUserLink } from '../../../../utils';
import { useModal } from '../../../shared/modal/useModal';
import { useEffect } from 'react';

export const useDescription = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { type, id } = params;
  const { changeStatus } = useCasesListApi();
  const { clearCaseNotification } = useNotificationsApi();
  const caseStore = useCaseStore();
  const { comment } = caseStore;
  const { roleFunction } = useAuthStore();

  const sendToAcceptHandler = async () => {
    let status = '';
    if (checkRoles(roleFunction, RoleFunction.ROLE_ACCEPTING)) {
      status = 'TO_ACCEPTING';
    } else if (checkRoles(roleFunction, RoleFunction.ROLE_EXPERT)) {
      status = 'PUBLISHED';
    }
    if (status && type && id) {
      await changeStatus(type as DiseaseType, +id, status, true);
    }
  };
  const isSentStatus = caseStore?.status === ICaseStatus.SENT;
  const isToAcceptStatus = caseStore?.status === ICaseStatus.TO_ACCEPTING;

  const isRoleAccepting = checkRoles(roleFunction, [RoleFunction.ROLE_ACCEPTING]);
  const isRoleExpert = checkRoles(roleFunction, [RoleFunction.ROLE_EXPERT]);

  const showFabButton = (isSentStatus && isRoleAccepting) || (isToAcceptStatus && isRoleExpert);

  const handleEdit = () => {
    navigate(generateLink(RoleFunction.ROLE_PUBLISHING, PublishUserLink.CASE_EDIT, [[':type', type as DiseaseType], [':id', Number(id)]]));
  };

  useEffect(() => {
    if (checkRoles(roleFunction, [RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_PUBLISHING])) {
      if (type && id) {
        void clearCaseNotification(type, +id);
      }
    }
  }, [clearCaseNotification, id, roleFunction, type]);

  const {
    status
  } = caseStore;
  const { open, handleOpen, handleClose } = useModal();

  return {
    caseStore,
    roleFunction,
    comment,
    type,
    sendToAcceptHandler,
    isSentStatus,
    isToAcceptStatus,
    isRoleAccepting,
    isRoleExpert,
    showFabButton,
    handleEdit,
    status,
    open,
    handleOpen,
    handleClose
  };
};