import { IZeroStageValues } from './ZeroStages.types';
import { ObjectSchema } from 'yup';
import * as yup from 'yup';

export const initialZeroStage : IZeroStageValues = {
  title:  "",
  description:  "",
}


export const validationSchema: ObjectSchema<IZeroStageValues> = yup.object().shape({
  title: yup.string().required(),
  description:  yup.string().required(),
})