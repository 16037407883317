import { useLibraryStore, usePoiStore } from '../../../../../../store/hooks';
import { useLibraryApi } from '../../../../../../apiHooks/useLibraryApi';
import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(),
  isturisaSignifor: yup
    .string()
    .required()
});

export const useChplModal = (handleClose: () => void, id: number | null) => {
  const libraryStore = useLibraryStore();
  const poiStore = usePoiStore();
  const { errorMessage, errorCode } = poiStore;
  const { isturisaCheckbox, signiforCheckbox, isturisaArticles, signiforArticles } = libraryStore;
  const { addArticleChpl, deleteArticle, editArticleChpl } = useLibraryApi(handleClose);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [pdfLink, setPdfLink] = useState<string | null>(null);
  const [pdfFileError, setPdfFileError] = useState<boolean>(false);
  const chplInfo = [...isturisaArticles, ...signiforArticles].find(el => el.id === id);

  const setFileHandler = useCallback((pdfFile: File | null) => {
    setPdfFile(pdfFile);
  }, []);

  useEffect(() => {
    if (pdfFile) setPdfLink(URL.createObjectURL(pdfFile));
  }, [pdfFile]);

  useEffect(() => {
    return () => poiStore.clearError();
  }, [poiStore]);

  const formik = useFormik({
    initialValues: {
      title: '',
      isturisaSignifor: ''
    },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = new FormData();
      data.append('title', values.title);
      data.append('categoryMed', values.isturisaSignifor);

      if (id) {
        await editArticleChpl({ title: values.title, categoryMed: +values.isturisaSignifor }, id);
      } else if (pdfFile) {
        data.append('file', pdfFile);
        await addArticleChpl(data);
      }
    }

  });

  const titleErrorMessage = (errorCode === 404 || errorCode === 400) && errorMessage;

  const handleOnClick = () => {
    if (!pdfFile && !id) {
      setPdfFileError(true);
    }
    formik.handleSubmit();
  };

  useEffect(() => {
    if (pdfFile) {
      setPdfFileError(false);
    }
  }, [pdfFile]);

  const { setFieldValue } = formik;

  useEffect(() => {
    if (chplInfo) {
      setFieldValue('title', chplInfo.title);
      setFieldValue('isturisaSignifor', chplInfo.categoryMedicamentsId);
      setPdfLink(process.env.REACT_APP_IMAGE_URL + `${chplInfo.pathFile}${chplInfo.namePDF}`);
    }
  }, [setFieldValue, chplInfo]);

  const checkboxes = [{ ...isturisaCheckbox }, { ...signiforCheckbox }];

  const handleDeleteItem = () => {
    if (id) {
      void deleteArticle(id);
    }
  };

  return {
    checkboxes,
    formik,
    handleOnClick,
    setFileHandler,
    pdfFile,
    pdfLink,
    pdfFileError,
    handleDeleteItem,
    titleErrorMessage
  };
};