import {
  ITreatmentOfChronicAcromegallyCabergoline
} from './TreatmentOfChronicAcromegallyCabergoline.types';

export const initialTreatmentOfChronicAcromegallyCabergoline : ITreatmentOfChronicAcromegallyCabergoline= {
  frequency: "",
  dose: "",
  dateStart: null,
  dateEnd: null,
  dateEndIsActive: false
}