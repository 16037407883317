import React, { FC } from 'react';
import { Button, Grid } from '@mui/material';
import { ISupportTreatmentProps } from './SupportTreatment.types';
import { useSupportTreatmentVm } from './useSupportTreatment.vm';
import { Input } from '../../../../../../../shared/ui/form/input/Input';
import { GenericIcon } from '../../../../../../../shared/genericIcon/GenericIcon';
import { themeMUI } from '../../../../../../../../theme/theme';

export const SupportTreatment: FC<ISupportTreatmentProps> = ({
                                                               id,
                                                               number,
                                                               initial,
                                                               handleAddSupportTreatment,
                                                               editable,
                                                               handleDeleteSupportTreatment
                                                             }) => {

  const { formik, handleOnClick, disabledAdd } = useSupportTreatmentVm(initial, handleAddSupportTreatment);

  return <>
    {!editable && <Grid item xs={0.5} mt={1} mb={editable ? 0: 1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                        justifyContent={'center'}>
      {number}.
    </Grid>}
    <Grid item xs={5.5} pl={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'} justifyItems={'center'} mt={1} mb={editable ? 0: 1} >
      <Input
        disabled={!editable}
        fullWidth={true}
        name={'drug'}
        label={'Lek'}
        placeholder={'lek'}
        type={'text'}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.drug}
        error={Boolean(formik.errors.drug)} />
    </Grid>
    <Grid item xs={5.5} mt={1} mb={editable ? 0: 1} pl={2} display={'flex'} flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent={'center'} justifyItems={'center'}>
      <Input
        disabled={!editable}
        fullWidth={true}
        name={'dose'}
        label={'Dawka'}
        placeholder={'dawka'}
        type={'text'}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.dose}
        error={Boolean(formik.errors.dose)} />
    </Grid>
    {handleDeleteSupportTreatment &&
      <Grid item xs={0.5} display={'flex'} flexDirection={'column'} alignItems={'flex-end'} mt={1} mb={1}
      >
        <div style={{ margin: 'auto' }} onClick={() => {
          if (typeof id === 'number') {
            handleDeleteSupportTreatment(id);
          }
        }}>
          <GenericIcon
            iconName='Close'
            style={{
              color: themeMUI.palette.primary.main,
              cursor: 'pointer',
              verticalAlign: 'middle'
            }} />
        </div>
      </Grid>
    }
    {
      handleAddSupportTreatment &&
      <Grid item xs={1} display={'flex'} justifyContent={'flex-start'} mt={1} mb={0} pl={1}>
        <Button disabled={disabledAdd} variant='contained' color={'primary'}
                onClick={handleOnClick}>ZAPISZ</Button>
      </Grid>
    }
  </>;
};