import React from 'react';
import { observer } from 'mobx-react-lite';
import { Router } from './appRouting/RoutesGeneral';
import { ThemeProvider as ThemeProviderStyledComponent } from 'styled-components';

import { useAuthStore } from '../../../store/hooks';
import { GlobalLoader } from './globalLoader/GlobalLoader';
import { GlobalStyle } from './globalStyle/GlobalStyle';
import { Main } from './main/Main';
import { CheckUser } from './checkUser/CheckUser';
import { ThemeProvider } from '@mui/material';
import { themeComponentStyled, themeMUI } from '../../../theme/theme';

export const AppLayer = observer(() => {
  const { isUserLoad } = useAuthStore();

  return (
    <ThemeProviderStyledComponent theme={themeComponentStyled}>
      <ThemeProvider theme={themeMUI}>
        <Main>
          <GlobalStyle />
          <CheckUser />
          {isUserLoad ?
            <Router /> :
            <GlobalLoader />}
          <GlobalLoader />
        </Main>
      </ThemeProvider>
    </ThemeProviderStyledComponent>
  );
});


