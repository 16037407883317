import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { themeMUI } from '../../../../../theme/theme';

export const Header = styled.h1`
  font-size: 24px;
  font-weight: 400;
  line-height: 133.4%;
`

export const ExtraLink = styled(Link)`
  color: ${themeMUI.palette.primary.main};
  font-size: 16px;
  line-height: 150%; 
  letter-spacing: 0.15px;
  text-decoration: none;
`

export const HeaderBox = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;