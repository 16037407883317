import { useProgressBarStore } from '../../../../../../../store/hooks/useProgressBarStore';
import { useFormik } from 'formik';

import { IZeroStageValues } from './ZeroStages.types';
import { initialZeroStage, validationSchema } from './ZeroStage.helper';
import { useDraftCaseApi } from '../../../../../../../apiHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { generateLink, PublishUserLink } from '../../../../../../../utils';
import { RoleFunction } from '../../../../../../../constants/projectUsers';
import { DiseaseType } from '../../../../../../../interfaces/common/common';

export const useZeroStageVm = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const progressBarStore = useProgressBarStore();
  const { draftCaseZeroStage, draftCaseZeroStageSaved } = useDraftCaseApi();

  const handleSave = async () => {
    const errors = await formik.validateForm();

    if (Object.keys(errors).length === 0) {
      formik.handleSubmit();
    }
  };

  const formik = useFormik<IZeroStageValues>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    initialValues: progressBarStore.zeroStage ?? initialZeroStage,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let idNavigate = Number(id);
      if (id) {
        await draftCaseZeroStageSaved(formik.values, Number(id), type as DiseaseType);
      } else {
        idNavigate = await draftCaseZeroStage(formik.values, type as DiseaseType);
      }
      progressBarStore.setZeroStage(values);
      navigate(generateLink(RoleFunction.ROLE_PUBLISHING, PublishUserLink.CASE_EDIT, [[':type', type as DiseaseType], [':id', idNavigate]]));
    }
  });


  return {
    handleSave,
    formik
  };
};