import { useApiCall } from './useApiCall';
import { useQAPageStore } from '../store/hooks';
import { useCallback } from 'react';
import { IAccordionElement } from '../interfaces/quPage/quPage';
import { IResponse } from '../interfaces/api/common';
import { QUPageTab } from '../components/pages/qaPage/QUPage.types';
import { IAddTypeQuestion } from '../components/shared/modal/common/modalAddQuestion/ModalAddQuestion.types';

export const useQAElementsApi = () => {
  const { api } = useApiCall();
  const qaPageStore = useQAPageStore();

  const getQAMedicalElements = useCallback(
    async (loader: boolean) => {
      const res: IAccordionElement[] = await api.apiInstance.get('list/medical', loader);
      if (res) {
        qaPageStore.setQUMedicalElements(res);
      }
    },
    [api.apiInstance, qaPageStore]
  );

  const getQATechnicalElements = useCallback(
    async (loader: boolean) => {
      const res: IAccordionElement[] = await api.apiInstance.get('list/technical', loader);
      if (res) {
        qaPageStore.setQUTechnicalElements(res);
      }
    },
    [api.apiInstance, qaPageStore]
  );

  const postQAElement = useCallback(
    async (question: IAddTypeQuestion) => {
      const res: IResponse<IAccordionElement> = await api.apiInstance.post('medical-question', question);

      return res?.code === 200;
    },
    [api.apiInstance]
  );

  const postQAAnswer = useCallback(
    async (medicalquestion: number, answerName: string, type: QUPageTab) => {
      const res: IResponse<IAccordionElement> = await api.apiInstance.post('medical-answer', {
        medicalquestion,
        answerName
      });

      if (res.code === 200) {
        if (type === 'medical') {
          await getQAMedicalElements(false);
        } else {
          await getQATechnicalElements(false);
        }
      }

      return res.code === 200;
    },
    [api.apiInstance, getQATechnicalElements, getQAMedicalElements]
  );

  const putQAAnswerVisibility = useCallback(
    async (id: number, visibility: boolean, type: QUPageTab) => {
      const res: IResponse<IAccordionElement> = await api.apiInstance.put(`medical-question/visibility/${id}`, { visibility: visibility ? 1 : 0 });

      if (res.code === 200) {
        if (type === 'medical') {
          await getQAMedicalElements(false);
        } else {
          await getQATechnicalElements(false);
        }
      }
      return res.code === 200;
    },
    [api.apiInstance, getQATechnicalElements, getQAMedicalElements]
  );

  const putQAAnswerCategory = useCallback(
    async (id: number, type: QUPageTab) => {
      const res: IResponse<IAccordionElement> = await api.apiInstance.put(`medical-question/type/${id}`, {});

      if (res?.code === 200) {
        if (type === 'medical') {
          await getQAMedicalElements(false);
        } else {
          await getQATechnicalElements(false);
        }
      }

      return res.code === 200;
    },
    [api.apiInstance, getQATechnicalElements, getQAMedicalElements]
  );

  return {
    getQAMedicalElements,
    getQATechnicalElements,
    postQAElement,
    postQAAnswer,
    putQAAnswerVisibility,
    putQAAnswerCategory
  };
};

