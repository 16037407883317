import React from 'react';

import { IStage } from '../../../../interfaces/common/progressBar';

import { SecondStage as SecondStageCushing } from './stages/cushing/secondStage/SecondStage';
import { SecondStage } from './stages/acromegaly/secoundStage/SecondStage';
import { ThirdStage } from './stages/acromegaly/thirdStage/ThirdStage';
import { ThirdStage as ThirdStageCushing } from './stages/cushing/thirdStage/ThirdStage';
import { FourthStage } from './stages/common/fourthStage/FourthStage';
import { ZeroStage } from './stages/common/zeroStage/ZeroStage';
import { ZeroStage as ZeroStageAcromegaly } from '../caseDraft/stages/common/zeroStage/ZeroStage';
import { FirstStage } from './stages/common/firstStage/FirstStage';
import { IDraftCaseAcromegaly, IDraftCaseCushing } from './CaseDraft.types';
import { IZeroStageValues } from './stages/common/zeroStage/ZeroStages.types';
import { IFirstStageValues } from './stages/common/firstStage/FirstStage.types';
import { ImagingResultMrTKType, ISecondStageValues } from './stages/acromegaly/secoundStage/SecondStage.types';
import { ISecondStageValues as ISecondStageValuesCushing } from './stages/cushing/secondStage/SecondStage.types';
import { IThirdStageValues } from './stages/acromegaly/thirdStage/ThirdStage.types';
import { IThirdStageValues as IThirdStageValuesCushing } from './stages/cushing/thirdStage/ThirdStage.types';
import { IFourthStageValues } from './stages/common/fourthStage/FourthStage.types';
import { DATE_IS_STILL } from '../../../shared/ui/form/date/Date.helper';

export enum Stages {
  ZERO, FIRST = 1, SECOND, THIRD, FOURTH
}

export const stagesCushing: IStage[] = [
  {
    stage: Stages.ZERO,
    component: <ZeroStage />,
    isTitle: false
  },
  {
    stage: Stages.FIRST,
    component: <FirstStage />,
    name: 'Dane demograficzne Pacjenta i podstawowe dane z wywiadów:',
    isTitle: true
  },
  {
    stage: Stages.SECOND,
    component: <SecondStageCushing />,
    name: 'Dane dotyczące choroby zasadniczej i etiologii hiperkortyzolemii',
    isTitle: true
  },
  {
    stage: Stages.THIRD,
    component: <ThirdStageCushing />,
    name: 'Leczenie zespołu Cushinga',
    isTitle: true
  },
  {
    stage: Stages.FOURTH,
    component: <FourthStage />,
    isTitle: false
  }
];

export const stagesAcromegaly: IStage[] = [
  {
    stage: Stages.ZERO,
    component: <ZeroStageAcromegaly />,
    isTitle: false
  },
  {
    stage: Stages.FIRST,
    component: <FirstStage />,
    name: 'Dane demograficzne Pacjenta i podstawowe dane z wywiadów:',
    isTitle: true
  },
  {
    stage: Stages.SECOND,
    component: <SecondStage />,
    name: 'Dane dotyczące akromegalii',
    isTitle: true
  },
  {
    stage: Stages.THIRD,
    component: <ThirdStage />,
    name: 'Leczenie akromegalii',
    isTitle: true
  },
  {
    stage: Stages.FOURTH,
    component: <FourthStage />,
    isTitle: false
  }
];


export const radioValueOptional = (valueInput: string | boolean, value: string | boolean) => {
  return value === valueInput ? '' : value;
}
export const checkEmptyNumber = (value: number) => {
  return value < 0  ? '' : value;
};

export const convertProgressBarCushing = (data: IDraftCaseCushing) => {
  const step = data.step;
  const status = data.status;

  const zeroStage: IZeroStageValues = {
    title: data.title,
    description: data.description
  };

  const firstStage: IFirstStageValues | null = step >= 1 ? {
    isAuthor: data.isAuthor,
    name: checkStringIsNull(data.name),
    years: checkNumberIsNull(data.years),
    gender: checkStringIsNull(data.gender),
    weight: checkNumberIsNull(data.weight),
    height: checkNumberIsNull(data.height),
    bmi: checkNumberIsNull(data.bmi),
    cardiovascularDiseases: data.cardiovascularDiseases,
    metabolicDiseasesDiabetes: checkStringIsNull(data.metabolicDiseasesDiabetes),
    metabolicDiseasesDiabetesMedicines: data.metabolicDiseasesDiabetesMedicines,
    metabolicDiseasesAnotherMedicines: checkStringIsNull(data.metabolicDiseasesAnotherMedicines),
    metabolicDiseasesDiabetesMedicinesAnother:  checkStringIsNull(data.metabolicDiseasesDiabetesMedicinesAnother),
    metabolicDiseasesAnotherDiseases: checkStringIsNull(data.metabolicDiseasesAnotherDiseases),
    cancers: data.cancers ?? [],
    cancersAnother: checkStringIsNull(data.cancersAnother),
    endoctrineDisorders: data.endoctrineDisorders ?? [],
    endoctrineDisordersAnother: checkStringIsNull(data.endoctrineDisordersAnother),
    movementOrganDiseases: data.movementOrganDiseases ?? [],
    movementOrganDiseasesGallstones: checkStringIsNull(data.movementOrganDiseasesGallstones),
    movementOrganDiseasesAnother: checkStringIsNull(data.movementOrganDiseasesAnother),
    gastrointestinalDiseases: checkStringIsNull(data.gastrointestinalDiseases)
  } : null;

  const secondStage: ISecondStageValuesCushing | null = step >= 2 ? {
    currentDiagnosisCushing: checkStringIsNull(data.currentDiagnosisCushing),
    currentDiagnosisProductionACTHCheck: checkStringIsNull(data.currentDiagnosisProductionACTHCheck),
    currentDiagnosisAdrenalCushingCheck: checkStringIsNull(data.currentDiagnosisAdrenalCushingCheck),
    courseDateStart: checkDate(false, data.courseDateStart),
    courseDateStartOfTreatment: checkDate(false, data.courseDateStartOfTreatment),
    courseWhoRecognized: checkStringIsNull(data.courseWhoRecognized),
    clinicalSymptoms: checkStringIsNull(data.clinicalSymptoms),
    hormonalTestDuringTheDiagnosisCortisol: checkStringIsNull(data.hormonalTestDuringTheDiagnosisCortisol),
    hormonalTestDuringTheDiagnosisCortisolIsActive: checkBooleanIsNull(data.hormonalTestDuringTheDiagnosisCortisolIsActive),
    hormonalTestDuringTheDiagnosisACTH: checkStringIsNull(data.hormonalTestDuringTheDiagnosisACTH),
    hormonalTestDuringTheDiagnosisACTHIsActive: checkBooleanIsNull(data.hormonalTestDuringTheDiagnosisACTHIsActive),
    hormonalTestDuringTheDiagnosisFreeCortisol: checkStringIsNull(data.hormonalTestDuringTheDiagnosisFreeCortisol),
    hormonalTestDuringTheDiagnosisFreeCortisolIsActive: checkBooleanIsNull(data.hormonalTestDuringTheDiagnosisFreeCortisolIsActive),
    hormonalTestDuringTheDiagnosisCRH: checkStringIsNull(data.hormonalTestDuringTheDiagnosisCRH),
    hormonalTestDuringTheDiagnosisCRHIsActive: checkBooleanIsNull(data.hormonalTestDuringTheDiagnosisCRHIsActive),
    hormonalTestDuringTheDiagnosisDesmopressin: checkStringIsNull(data.hormonalTestDuringTheDiagnosisDesmopressin),
    hormonalTestDuringTheDiagnosisDesmopressinIsActive: checkBooleanIsNull(data.hormonalTestDuringTheDiagnosisDesmopressinIsActive),
    hormonalTestDuringTheDiagnosisDexamethasone: checkStringIsNull(data.hormonalTestDuringTheDiagnosisDexamethasone),
    hormonalTestDuringTheDiagnosisDexamethasoneIsActive: checkBooleanIsNull(data.hormonalTestDuringTheDiagnosisDexamethasoneIsActive),
    imagingResultMrTkMacroMicroadenoma: data.imagingResultMrTkMacroMicroadenoma as ImagingResultMrTKType,
    imagingResultMrTkMacroadenomaInfiltration: checkStringIsNull(data.imagingResultMrTkMacroadenomaInfiltration),
    imagingResultMrTkMacroadenomaInfiltrationRockyBay: checkStringIsNull(data.imagingResultMrTkMacroadenomaInfiltrationRockyBay),
    imagingResultMrTkMacroadenomaInfiltrationRockyScaleKnosp: checkStringIsNull(data.imagingResultMrTkMacroadenomaInfiltrationRockyScaleKnosp),
    confirmationOfSomatotropicTissueMaterial: data.confirmationOfSomatotropicTissueMaterial === null ? false : data.confirmationOfSomatotropicTissueMaterial ,
    proliferationMarkerKi67: checkStringIsNull(data.proliferationMarkerKi67),
    hormonalTestsSomatostatinReceptorResult: checkStringIsNull(data.hormonalTestsSomatostatinReceptorResult),
    hormonalTestsSomatostatinReceptorIsActive: checkBooleanIsNull(data.hormonalTestsSomatostatinReceptorIsActive),
    hormonalTestsPositronTomographyResult: checkStringIsNull(data.hormonalTestsPositronTomographyResult),
    hormonalTestsSomatostatinReceptorResultIsActive: checkBooleanIsNull(data.hormonalTestsSomatostatinReceptorResultIsActive),
    electronMicroscopicExamination: checkStringIsNull(data.electronMicroscopicExamination),
    hormonalTestsSomatostatinReceptor: checkStringIsNull(data.hormonalTestsSomatostatinReceptor),
    hormonalTestsPositronTomography: checkStringIsNull(data.hormonalTestsPositronTomography)
  } : null;

  const thirdStage: IThirdStageValuesCushing | null = step >= 3 ? {
    treatmentOfChoivePatient: data.treatmentOfChoivePatient ?? [],
    treatmentBeforeOperationInhibitors: data.treatmentBeforeOperationInhibitors ?? [],
    treatmentBeforeOperationPasireotides: data.treatmentBeforeOperationPasireotides ?? [],
    treatmentBeforeOperationCabergolines: data.treatmentBeforeOperationCabergolines ?? [],
    operations: data.operations ?? [],
    subnormalCortisolLevels: checkStringIsNull(data.subnormalCortisolLevels),
    hydrocortisolTreatment: checkStringIsNull(data.hydrocortisolTreatment),
    hydrocortisolTreatmentDateStart: checkDate(false, data.hydrocortisolTreatmentDateStart),
    hydrocortisolTreatmentDateEnd: checkDate(false,data.hydrocortisolTreatmentDateEnd),
    complicationsOfSurgicalProcedures: checkStringIsNull(data.complicationsOfSurgicalProcedures),
    complicationsOfSurgicalProceduresCheck: data.complicationsOfSurgicalProceduresCheck.sort() ?? [],
    hypercortisolaemiaPasireotides: data.hypercortisolaemiaPasireotides ?? [],
    hypercortisolaemiaCabergolines: data.hypercortisolaemiaCabergolines ?? [],
    steroidogenesisInhibitorHow: checkStringIsNull(data.steroidogenesisInhibitorHow),
    steroidogenesisInhibitorDoseOf: checkStringIsNull(data.steroidogenesisInhibitorDoseOf),
    steroidogenesisInhibitorHowMuch24h: checkStringIsNull(data.steroidogenesisInhibitorHowMuch24h),
    steroidogenesisInhibitorHowEscalatedDose: checkStringIsNull(data.steroidogenesisInhibitorHowEscalatedDose),
    steroidogenesisInhibitorDoseOfMax: checkStringIsNull(data.steroidogenesisInhibitorDoseOfMax),
    steroidogenesisInhibitorDoseOfMaintenance: checkStringIsNull(data.steroidogenesisInhibitorDoseOfMaintenance),
    steroidogenesisInhibitorDoseOfDateStart: checkDate(false, data.steroidogenesisInhibitorDoseOfDateStart),
    steroidogenesisInhibitorDoseOfDateEndd: checkDate(data.steroidogenesisInhibitorDoseOfDateEnddIsActive, data.steroidogenesisInhibitorDoseOfDateEndd),
    steroidogenesisInhibitorDoseOfDateEnddIsActive: checkBooleanIsNull(data.steroidogenesisInhibitorDoseOfDateEnddIsActive),
    steroidogenesisInhibitorIsActive:  checkBooleanIsNull(data.steroidogenesisInhibitorIsActive),
    steroidogenesisInhibitorDoses: data.steroidogenesisInhibitorDoses ?? [],
    qualificationsDiagnosis: data.qualificationsDiagnosis ?? [],
    qualificationsDiagnosisAccess: checkStringIsNull(data.qualificationsDiagnosisAccess),
    qualificationsAnotherTreatment: checkStringIsNull(data.qualificationsAnotherTreatment),
    biochemicalNormalization: checkStringIsNull(data.biochemicalNormalization),
    toleranceOfPharmacologicalTreatment: data.toleranceOfPharmacologicalTreatment ?? [],
    anotherComplications: data.anotherComplications ?? [],
    anotherComplicationsAnother: checkStringIsNull(data.anotherComplicationsAnother),
    supprotiveTreatment: data.supprotiveTreatment ?? [],
    improvementControl: data.improvementControl ?? [],
    biochemicalNormalizationDZMIsActive: checkBooleanIsNull(data.biochemicalNormalizationDZMIsActive),
    biochemicalNormalizationDZMDate: checkDate(false,data.biochemicalNormalizationDZMDate),
    biochemicalNormalizationCortisolIsActive: checkBooleanIsNull(data.biochemicalNormalizationCortisolIsActive),
    biochemicalNormalizationCortisolDate: checkDate(false,data.biochemicalNormalizationCortisolDate),
    biochemicalNormalizationSerumCortisolIsActive: checkBooleanIsNull(data.biochemicalNormalizationSerumCortisolIsActive),
    biochemicalNormalizationSerumCortisolDate: checkDate(false,data.biochemicalNormalizationSerumCortisolDate),

  } : null;

  const fourthStage: IFourthStageValues | null = {
    summary: checkStringIsNull(data.summary)
  };

  return {
    step: data.step,
    status,
    zeroStage,
    firstStage,
    secondStage,
    thirdStage,
    fourthStage
  };
};
export const convertProgressBarAcromegaly = (data: IDraftCaseAcromegaly) => {

  const step = data.step;
  const status = data.status;
  const zeroStage: IZeroStageValues = {
    title: data.title,
    description: data.description
  };

  const firstStage: IFirstStageValues | null = step >= 0 ? {
    name: checkStringIsNull(data.name),
    isAuthor: data.isAuthor,
    years: checkNumberIsNull(data.years),
    gender: checkStringIsNull(data.gender),
    weight: checkNumberIsNull(data.weight),
    height: checkNumberIsNull(data.height),
    bmi: checkNumberIsNull(data.bmi),
    cardiovascularDiseases: data.cardiovascularDiseases,
    metabolicDiseasesDiabetes: checkStringIsNull(data.metabolicDiseasesDiabetes),
    metabolicDiseasesDiabetesMedicines: data.metabolicDiseasesDiabetesMedicines,
    metabolicDiseasesAnotherMedicines: checkStringIsNull(data.metabolicDiseasesAnotherMedicines),
    metabolicDiseasesDiabetesMedicinesAnother:  checkStringIsNull(data.metabolicDiseasesDiabetesMedicinesAnother),
    metabolicDiseasesAnotherDiseases: checkStringIsNull(data.metabolicDiseasesAnotherDiseases),
    cancers: data.cancers ?? [],
    cancersAnother: checkStringIsNull(data.cancersAnother),
    endoctrineDisorders: data.endoctrineDisorders ?? [],
    endoctrineDisordersAnother: checkStringIsNull(data.endoctrineDisordersAnother),
    movementOrganDiseases: data.movementOrganDiseases ?? [],
    movementOrganDiseasesGallstones: checkStringIsNull(data.movementOrganDiseasesGallstones),
    movementOrganDiseasesAnother: checkStringIsNull(data.movementOrganDiseasesAnother),
    gastrointestinalDiseases: checkStringIsNull(data.gastrointestinalDiseases)
  } : null;

  const secondStage: ISecondStageValues | null = step >= 2 ? {
    currentDiagnosis: checkStringIsNull(data.currentDiagnosis),
    courseDateStart: data.courseDateStart,
    courseDateStartOfTreatment:data.courseDateStartOfTreatment,
    courseWhoRecognized: checkStringIsNull(data.courseWhoRecognized),
    cardiovascularComplicationsIsActive: checkBooleanIsNull(data.cardiovascularComplicationsIsActive),
    carbohydrateMetabolismDisordersIsActive: checkBooleanIsNull(data.carbohydrateMetabolismDisordersIsActive),
    cardiovascularComplicationsDateStart:  data.cardiovascularComplicationsDateStart,
    cardiovascularComplicationsDateEnd: checkDate(data.cardiovascularComplicationsIsActive, data.cardiovascularComplicationsDateEnd),
    carbohydrateMetabolismDisordersType: checkStringIsNull(data.carbohydrateMetabolismDisordersType),
    carbohydrateMetabolismDisordersDateStart: data.carbohydrateMetabolismDisordersDateStart,
    carbohydrateMetabolismDisordersDateEnd: checkDate(data.carbohydrateMetabolismDisordersIsActive, data.carbohydrateMetabolismDisordersDateEnd),
    anotherCardiovascularComplications: checkStringIsNull(data.anotherCardiovascularComplications),
    growthHormoneConcentration: checkStringIsNull(data.growthHormoneConcentration),
    glucoseToleranceTestGH0: checkStringIsNull(data.glucoseToleranceTestGH0),
    glucoseToleranceTestGH60: checkStringIsNull(data.glucoseToleranceTestGH60),
    glucoseToleranceTestGH120: checkStringIsNull(data.glucoseToleranceTestGH120),
    profileGHHowMuch: checkStringIsNull(data.profileGHHowMuch),
    profileGHConcentration: checkStringIsNull(data.profileGHConcentration),
    serumIGF1Concentration: checkStringIsNull(data.serumIGF1Concentration),
    uln: checkStringIsNull(data.uln),
    serumPrlConcentration: checkStringIsNull(data.serumPrlConcentration),
    serumTshConcentration: checkStringIsNull(data.serumTshConcentration),
    serumFt4Concentration: checkStringIsNull(data.serumFt4Concentration),
    imagingResultMrTk: checkStringIsNull(data.imagingResultMrTk) as ImagingResultMrTKType,
    oppresionOfTheOpticChiasm: checkStringIsNull(data.oppresionOfTheOpticChiasm),
    sinusInfiltraionSinus: checkStringIsNull(data.sinusInfiltraionSinus),
    degreeOnTheKnospScale: checkStringIsNull(data.degreeOnTheKnospScale),
    confirmationOfSomatotropicTumor: checkStringIsNull(data.confirmationOfSomatotropicTumor),
    confirmationOfMixedTumor: checkStringIsNull(data.confirmationOfMixedTumor),
    proliferationMarkerKi67: checkStringIsNull(data.proliferationMarkerKi67),
    geneticTest: checkStringIsNull(data.geneticTest),
    profileIsActive: false,
    glucoseToleranceTestIsActive:false,
    anotherCardiovascularComplicationsIsActive:false,
    geneticTestIsActive: false,
  } : null;

  const thirdStage: IThirdStageValues | null = step >= 2 ? {
    numberOfMonthsFromDiagnosisToTreatment: checkStringIsNull(data.numberOfMonthsFromDiagnosisToTreatment),
    reasonForNotTreating: checkStringIsNull(data.reasonForNotTreating),
    somastatinAnalogs: data.somastatinAnalogs ?? [],
    cabergolines: data.cabergolines ?? [],
    withoutPharmacologicalPreparation: checkStringIsNull(data.withoutPharmacologicalPreparation),
    surgeries: data.surgeries ?? [],
    complicationsDiabetesInsipidus: checkStringIsNull(data.complicationsDiabetesInsipidus),
    complicationsDiabetesInsipidusHow: data.complicationsDiabetesInsipidusHow ?? [],
    treatmentOfChronicAcromegallyAnalogues: data.treatmentOfChronicAcromegallyAnalogues ?? [],
    treatmentOfChronicAcromegallyPasireotides: data.treatmentOfChronicAcromegallyPasireotides ?? [],
    treatmentOfChronicAcromegallyPegvisomants: data.treatmentOfChronicAcromegallyPegvisomants ?? [],
    treatmentOfChronicAcromegallyCabergolines: data.treatmentOfChronicAcromegallyCabergolines ?? [],
    qualificationForSurgicalTreatmentHowAccess: checkStringIsNull(data.qualificationForSurgicalTreatmentHowAccess),
    qualificationForRadiotherapyTreatmentType: checkStringIsNull(data.qualificationForRadiotherapyTreatmentType),
    achievingHormonalNormalizationGh: checkStringIsNull(data.achievingHormonalNormalizationGh),
    achievingHormonalNormalizationIgf1: checkStringIsNull(data.achievingHormonalNormalizationIgf1),
    achievingHormonalNormalizationIgf1Rate: checkStringIsNull(data.achievingHormonalNormalizationIgf1Rate),
    serumPrlGrade: checkStringIsNull(data.serumPrlGrade) ?? [],
    toleranceOfTheTreatmentUsedType: checkStringIsNull(data.toleranceOfTheTreatmentUsedType),
    toleranceOfTheTreatmentUsed: data.toleranceOfTheTreatmentUsed ?? [],
    treatmentOfComorbidities: data.treatmentOfComorbidities ?? [],
    treatmentOfComorbiditiesCheck: data.treatmentOfComorbiditiesCheck ?? [],
    initiationOfDrugTreatmentWithPasireotideLarReason: data.initiationOfDrugTreatmentWithPasireotideLarReason.sort() ?? [],
    initiationOfDrugTreatmentWithPasireotideLarConcentration: checkStringIsNull(data.initiationOfDrugTreatmentWithPasireotideLarConcentration),
    initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1: checkStringIsNull(data.initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1),
    startTreatmentWithPegvisomantNormalizationUg: data.startTreatmentWithPegvisomantNormalizationUg ?? [],
    startTreatmentWithPegvisomantNormalizationIGF1: checkStringIsNull(data.startTreatmentWithPegvisomantNormalizationIGF1),
    qualificationForSurgicalTreatmentIsActive: false,
    qualificationForRadiotherapyTreatmentIsActive: false,
    initiationOfDrugTreatmentWithPasireotideLarIsActive: false,
    startTreatmentWithPegvisomantNormalizationIsActive: false,
  } : null;

  const fourthStage: IFourthStageValues | null = {
    summary: checkStringIsNull(data.summary)
  };

  return {
    step: data.step > Stages.FOURTH ? Stages.FOURTH : data.step,
    status,
    zeroStage,
    firstStage,
    secondStage,
    thirdStage,
    fourthStage
  };

};

const checkDate = (check: boolean, element: string | null) => {
  return check ? ((element === null) ? DATE_IS_STILL : null) : (element === '' ? null : element);
};


const checkStringIsNull = (element: string | null) => {
  return (element === null || element === undefined) ? '' : String(element);
};

const checkNumberIsNull = (element: number | null) => {
  return (element === null || element === undefined) ? 0 : Number(element);
};

const checkBooleanIsNull = (element: boolean | null) => {
  return (element === null || element === undefined) ? false : element;
};

