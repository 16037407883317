import React, { useState } from 'react';
import { getComparator, stableSort } from './utils/tableUtils';
import { HeadCell, IData } from '../../../interfaces/library/library';
import { searchByFilter } from './utils/searchByFilter';
import { usePagination } from '../../../hooks/usePagination';
import { ITableData } from '../../../interfaces/common/customTable';

type Order = 'asc' | 'desc';


export const useCustomTable = (data: ITableData[], tableConfigOrder: string, headCells: HeadCell[], userType?: string) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>(tableConfigOrder);

  const {
    currentPage,
    totalPage,
    handlePageChange,
    search,
    handleSearch,
    items, radioValue, handleChange
  } = usePagination(data, 10, ['title', 'email', 'roles', 'phone', 'fullName'], undefined, userType);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = items && stableSort(items, getComparator(order, orderBy)).filter(searchByFilter(search, headCells))

  const createSortHandler =
    (property: keyof IData) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  return {
    handleRequestSort,
    createSortHandler,
    currentPage,
    totalPage,
    handlePageChange,
    search,
    orderBy,
    visibleRows,
    order,
    handleSearch,
    radioValue, handleChange
  };
};