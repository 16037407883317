import { Button } from '@mui/material';
import {ReactComponent as SortIcon} from '../../../assets/icon/sort.svg';
import { FC, useState } from 'react';
import { ISortButtonProps } from './SortButton.types';
import { ButtonContent, GridCustom } from './SortButton.styled';
import { ColorType } from '../../../interfaces/common/common';
import { SortDirection } from '../../../interfaces/common/sort';

export const SortButton: FC<ISortButtonProps>= ({handleOnClick, type}) =>{
  const [ascSort, setAscSort]  = useState<SortDirection>('ASC');

  const handleOnClickSort = () => {

    setAscSort(prevState => {
      const newValue = prevState === 'ASC' ? 'DESC' : 'ASC';

      handleOnClick(newValue)
      return newValue
    });

  }
  return (
    <GridCustom type={type as ColorType} mr={1} item xs={12} display={'flex'} justifyContent={'flex-end'} >
      <Button onClick={handleOnClickSort} startIcon={<SortIcon/>}><ButtonContent type={type}>{ascSort === 'DESC' ? "OD NAJSTARSZYCH" : "OD NAJNOWSZYCH"}</ButtonContent></Button>
    </GridCustom>
  )
}