import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { INotification } from '../interfaces/notifications/notifications';

export class NotificationsStore {
  notifications: INotification[] | null = null;
  notificationsCount: any | null = null;


  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setNotifications(notifications: INotification[]) {
    this.notifications = notifications;
  }

  setNotificationsCount(notificationsCount: any) {
    this.notificationsCount = notificationsCount;
  }

  clearNotification() {
    this.notifications = null;
  }

}