import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DiseaseType } from '../../../../../interfaces/common/common';
import { findNewLocation } from './Caselayout.helper';
import { isOfTypeDiseaseType } from '../../../../pages/library/Library.helper';
import { GeneralLink } from '../../../../../utils';

export const useCaseLayout = (handlePageChange?: (page: number) => void) => {
  const param = useParams();
  const navigate = useNavigate();
  const { type } = param;
  const location = useLocation();
  const [filter, setFilter] = useState<DiseaseType>(type as DiseaseType ?? 'acromegaly');

  useEffect(()=> {
    if(!isOfTypeDiseaseType(String(filter))){
      navigate(GeneralLink.PAGE_404);
    }
  }, [navigate, filter])

  const handleChange = (event: React.SyntheticEvent, newValue: DiseaseType) => {
    setFilter(newValue);
    handlePageChange && handlePageChange(1);
    const newLocation = findNewLocation(location.pathname, newValue);
    navigate(newLocation);
  };

  return {
    filter,
    handleChange
  };
};