import { useLibraryStore, usePoiStore } from '../../../../../../store/hooks';
import { useLibraryApi } from '../../../../../../apiHooks/useLibraryApi';
import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(),
  materials: yup
    .string()
    .required()
});

export const useMaterialsModal = (handleClose: () => void, id: number | null) => {
  const libraryStore = useLibraryStore();
  const { isturisaCheckbox, signiforCheckbox, isturisaArticles, signiforArticles } = libraryStore;
  const { addArticleMaterials, deleteArticle, editArticleMaterial } = useLibraryApi(handleClose);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [pdfLink, setPdfLink] = useState<string | null>(null);
  const [pdfFileError, setPdfFileError] = useState<boolean>(false);
  const [imageLink, setImageLink] = useState<string | null>(null);
  const materialInfo = [...isturisaArticles, ...signiforArticles].find(el => el.id === id);
  const poiStore = usePoiStore();
  const { errorMessage, errorCode } = poiStore;
  const setFileHandler = useCallback((pdfFile: File | null) => {
    setPdfFile(pdfFile);
  }, []);

  useEffect(() => {
    if (imgFile) setImageLink(URL.createObjectURL(imgFile));
  }, [imgFile]);

  const setImageHandler = useCallback((imgFile: File | null) => {
    setImgFile(imgFile);
  }, []);

  useEffect(() => {
    if (pdfFile) setPdfLink(URL.createObjectURL(pdfFile));
  }, [pdfFile]);

  const formik = useFormik({
    initialValues: {
      title: '',
      materials: ''
    },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = new FormData();
      data.append('title', values.title);
      data.append('categoryMedicaments', values.materials);
      if (imgFile) {
        data.append('fileImg', imgFile);
      }

      if (id) {
        await editArticleMaterial(data, id);
      } else if (pdfFile) {
        data.append('file', pdfFile);
        await addArticleMaterials(data);
      }
    }
  });
  const titleErrorMessage = (errorCode === 404 || errorCode === 400) && errorMessage;
  const { setFieldValue } = formik;

  useEffect(() => {
    if (materialInfo) {
      setFieldValue('title', materialInfo.title);
      setFieldValue('materials', materialInfo.categoryMedicamentsId);
      setPdfLink(process.env.REACT_APP_IMAGE_URL + `${materialInfo.pathFile}${materialInfo.namePDF}`);
      if (materialInfo.nameImg) {
        setImageLink(process.env.REACT_APP_IMAGE_URL + `${materialInfo.pathFile}${materialInfo.nameImg}`);
      }

    }
  }, [setFieldValue, materialInfo]);

  useEffect(() => {
    return () => poiStore.clearError();
  }, [poiStore]);

  const handleOnClick = () => {
    if (!pdfFile && !id) {
      setPdfFileError(true);
    }
    formik.handleSubmit();
  };

  useEffect(() => {
    if (pdfFile) {
      setPdfFileError(false);
    }
  }, [pdfFile]);

  const checkboxes = [{ ...isturisaCheckbox }, { ...signiforCheckbox }];

  const handleDeleteItem = () => {
    if (id) {
      void deleteArticle(id);
    }
  };

  return {
    checkboxes,
    formik,
    handleOnClick,
    titleErrorMessage,
    setFileHandler,
    pdfFile,
    pdfLink,
    pdfFileError,
    setImageHandler,
    imageLink,
    handleDeleteItem
  };
};