import { Button, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { ModalRoot } from '../../modal/ModalRoot';
import { SelectCustom } from '../../ui/form/select/Select';
import { Input } from '../../ui/form/input/Input';
import { useProfileModal } from './useProfileModal.vm';
import { specializationOptions } from '../../auth/specialization.helper';
import { observer } from 'mobx-react-lite';

interface IProps {
  open: boolean,
  handleClose: () => void
}

export const ProfileModal: React.FC<IProps> = observer(({ handleClose, open }) => {
  const { formik, passwordErrorMessage } = useProfileModal(handleClose);

  return (
    <ModalRoot open={open} handleClose={handleClose} title={'Edytuj dane'}>
      <Grid container pt={2} pb={2} pl={3} pr={3} flexDirection={'column'} width={'700px'}>
        <Grid container>
          <Grid container>
            <Grid item xs={6} pr={1}>
              <Input
                onBlur={formik.handleBlur}
                autoComplete='off'
                name={'academicTitle'}
                type={'text'}
                onChange={formik.handleChange}
                value={formik.values.academicTitle}
                error={formik.touched.academicTitle && Boolean(formik.errors.academicTitle)}
                placeholder={'Tytuł naukowy'}
                label={'Tytuł naukowy'}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectCustom
                name={'specialization'}
                placeholder={'Specjalizacja'}
                label={'Specjalizacja'}
                onChange={formik.handleChange}
                value={formik.values.specialization}
                options={specializationOptions} />
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Input
              onBlur={formik.handleBlur}
              autoComplete='off'
              type={'text'}
              name={'fullName'}
              placeholder={'Imię i nazwisko'}
              label={'Imię i nazwisko'}
              onChange={formik.handleChange}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              value={formik.values.fullName}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Input
              onBlur={formik.handleBlur}
              autoComplete='off'
              type={'text'}
              name={'workPlace'}
              placeholder={'Miejsce pracy'}
              label={'Miejsce pracy'}
              onChange={formik.handleChange}
              error={formik.touched.workPlace && Boolean(formik.errors.workPlace)}
              value={formik.values.workPlace}
            />
          </Grid>
          <Grid item xs={12} pt={2} pb={2}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'caption'}>Aby zmienić hasło, wpisz obecne hasło, nowe hasło i powtórz nowe
              hasło.</Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Input
              onBlur={formik.handleBlur}
              autoComplete='off'
              type={'password'}
              name={'currentPassword'}
              placeholder={'Obecne hasło'}
              label={'Obecne hasło'}
              onChange={formik.handleChange}
              error={(formik.touched.currentPassword && Boolean(formik.errors.currentPassword)) || Boolean(passwordErrorMessage)}
              value={formik.values.currentPassword}
              errorMessage={passwordErrorMessage}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Input
              onBlur={formik.handleBlur}
              autoComplete='off'
              type={'password'}
              name={'password'}
              placeholder={'Nowe hasło'}
              label={'Nowe hasło'}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              value={formik.values.password}
              errorMessage={formik.errors.password}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Input
              onBlur={formik.handleBlur}
              autoComplete='off'
              type={'password'}
              name={'repeatNewPassword'}
              placeholder={'Powtórz nowe hasło'}
              label={'Powtórz nowe hasło'}
              onChange={formik.handleChange}
              error={formik.touched.repeatNewPassword && Boolean(formik.errors.repeatNewPassword)}
              value={formik.values.repeatNewPassword}
              errorMessage={formik.errors.repeatNewPassword}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={'flex-end'} mt={2}>
          <Grid item>
            <Button disabled={false} variant='outlined' color={'primary'}
                    onClick={handleClose}>Anuluj</Button>
          </Grid>
          <Grid item ml={2}>
            <Button disabled={false} variant='contained' color={'primary'}
                    onClick={() => formik.handleSubmit()}>Zapisz</Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalRoot>
  );
});