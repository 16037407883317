import { useApiCall } from './useApiCall';
import { useCallback } from 'react';
import { useLibraryStore } from '../store/hooks';
import { ILibraryLink } from '../interfaces/library/library';

export const useLibraryApi = (handleClose?: () => void) => {
  const { api } = useApiCall();
  const libraryStore = useLibraryStore();

  const getCategories = useCallback(
    async () => {
      const res = await api.apiInstance.get('medical-categories/all');

      if (res) {
        libraryStore.setCategories(res);
      }
    },
    [api.apiInstance, libraryStore]
  );

  const getMedicamentCategories = useCallback(
    async () => {
      const res = await api.apiInstance.get('medical-library/medicaments/categories/all');
      if (res) {
        libraryStore.setMedicamentCategories(res);
      }
    },
    [api.apiInstance, libraryStore]
  );

  const getArticles = useCallback(async (id: number) => {
    const res = await api.apiInstance.get(`medical-library/${id}`, true);
    if (res) {
      libraryStore.setArticles(res);
    }
  }, [api.apiInstance, libraryStore]);

  const addArticleChpl = useCallback(async (data: FormData) => {

    const res = await api.apiInstance.post('medical-library', data, true);
    if (res) {
      libraryStore.setArticles([...libraryStore.articles, res.data]);
      handleClose && handleClose();
    }
  }, [api.apiInstance, handleClose, libraryStore]);

  const editArticleChpl = useCallback(async (data: { title: string; categoryMed: number }, id: number) => {
    const res = await api.apiInstance.put(`medical-library/chpl/${id}`, data, true);
    if (res) {
      const currentArticles = libraryStore.articles.map(el => el.id === id ? res.data : el);
      libraryStore.setArticles(currentArticles);
      handleClose && handleClose();
    }
  }, [api.apiInstance, handleClose, libraryStore]);

  const addArticlePrograms = useCallback(async (data: FormData) => {
    const res = await api.apiInstance.post('medical-library/programs-medicaments', data, true);
    if (res) {
      libraryStore.setArticles([...libraryStore.articles, res.data]);
      handleClose && handleClose();
    }
  }, [api.apiInstance, handleClose, libraryStore]);

  const addArticleMaterials = useCallback(async (data: FormData) => {
    const res = await api.apiInstance.post('medical-library/promotional-materials', data, true);
    if (res) {
      libraryStore.setArticles([...libraryStore.articles, res.data]);
      handleClose && handleClose();
    }
  }, [api.apiInstance, handleClose, libraryStore]);

  const addArticlePublications = useCallback(async (data: FormData) => {
    const res = await api.apiInstance.post('medical-library/publications', data, true);
    if (res) {
      libraryStore.setArticles([...libraryStore.articles, res.data]);
      handleClose && handleClose();
    }
  }, [api.apiInstance, handleClose, libraryStore]);

  const addArticleLinks = useCallback(async (data: ILibraryLink) => {
    const res = await api.apiInstance.post('medical-library/link', data, true);
    if (res) {
      libraryStore.setArticles([...libraryStore.articles, res.data]);
      handleClose && handleClose();
    }
  }, [api.apiInstance, handleClose, libraryStore]);

  const editArticlePrograms = useCallback(async (data: { title: string; categoryMed: number }, id: number) => {
    const res = await api.apiInstance.put(`medical-library/programs-medicaments/${id}`, data, true);
    if (res) {
      const currentArticles = libraryStore.articles.map(el => el.id === id ? res.data : el);
      libraryStore.setArticles(currentArticles);
      handleClose && handleClose();
    }
  }, [api.apiInstance, handleClose, libraryStore]);

  const editArticlePublication = useCallback(async (data: { title: string; author: string; datePublication: string; publisher: string; categoryMedicaments: number; }, id: number) => {
    const res = await api.apiInstance.put(`medical-library/publications/${id}`, data, true);
    if (res) {
      const currentArticles = libraryStore.articles.map(el => el.id === id ? res.data : el);
      libraryStore.setArticles(currentArticles);
      handleClose && handleClose();
    }
  }, [api.apiInstance, handleClose, libraryStore]);

  const editArticleMaterial = useCallback(async (data: FormData, id: number) => {
    const res = await api.apiInstance.post(`medical-library/promotional-materials/${id}`, data, true);
    if (res) {
      const currentArticles = libraryStore.articles.map(el => el.id === id ? res.data : el);
      libraryStore.setArticles(currentArticles);
      handleClose && handleClose();
    }
  }, [api.apiInstance, handleClose, libraryStore]);

  const deleteArticle = useCallback(async (id: number) => {
    const res = await api.apiInstance.delete(`medical-library/${id}`, true);
    if (res) {
      const currentArticles = libraryStore.articles!.filter(el => el.id !== id);
      libraryStore.setArticles(currentArticles);
      handleClose && handleClose();
    }
  }, [api.apiInstance, handleClose, libraryStore]);

  const switchCategoryActive = useCallback(async (id: number, isActive: boolean) => {
    const res = await api.apiInstance.put(`medical-library/category/${id}`, {
      'isActive': isActive ? 1 : 0
    }, true);
    if (res) {
      const currentCategories = libraryStore.categories.map(el => el.id === id ? res.data : el);
      libraryStore.setCategories(currentCategories);
      return !!res;
    }
  }, [api.apiInstance, libraryStore]);

  return {
    getCategories,
    getMedicamentCategories,
    getArticles,
    addArticleChpl,
    addArticlePrograms,
    addArticleMaterials,
    addArticlePublications,
    addArticleLinks,
    deleteArticle,
    switchCategoryActive,
    editArticleChpl,
    editArticlePrograms,
    editArticlePublication,
    editArticleMaterial
  };
};
