import {observer} from 'mobx-react-lite';
import {useEffect} from "react";
import {useAuthApi} from "../../../../apiHooks";

export const CheckUser = observer(() => {

  const {checkAuth} = useAuthApi();

  useEffect(() => {
    void checkAuth();
  }, [checkAuth]);

  return null;
});
