import styled from "styled-components";
import { IFieldsetContainer, IFieldsetLegend } from './FieldsetContainer.types';

export const FieldsetContainer = styled.fieldset<IFieldsetContainer>`
  display: flex;
  flex-direction: column;
  border: 0;
  width: 100%;
  margin-top: ${props=> props.margin ?? '32px'};
  scroll-margin-block: 100px;
 
`

export const FieldsetLegend = styled.legend<IFieldsetLegend>`
  font-size: ${props => props.size === 'l' ? '34px' : '20px'};
  font-weight: ${props => props.size === 'l' ? '400' : '500'};
  line-height: ${props => props.size === 'l' ? '42px' : '160%'};
  letter-spacing: ${props => props.size === 'l' ? '0.25px' : '0.15px'};
  color: ${props=> props.color ?? 'black'};
`

export const FieldsetParagraph = styled.p`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
`