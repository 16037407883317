import { useLibraryStore, usePoiStore } from '../../../../../../store/hooks';
import { useLibraryApi } from '../../../../../../apiHooks/useLibraryApi';
import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(),
  publications: yup
    .string()
    .required(),
  author: yup
    .string()
    .required(),
  datePublication: yup
    .string()
    .matches(/[0-9]+\.(0?[1-9]|[1][0-2])/)
    .required(),
  publisher: yup
    .string()
    .required()
});

export const usePublicationsModal = (handleClose: () => void, id: number | null) => {
  const libraryStore = useLibraryStore();
  const poiStore = usePoiStore();
  const { errorMessage, errorCode } = poiStore;
  const { pasyreotydCheckbox, osilodrostadCheckbox, pasyreotydArticles, osilodrostadArticles } = libraryStore;
  const { addArticlePublications, deleteArticle, editArticlePublication } = useLibraryApi(handleClose);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [pdfLink, setPdfLink] = useState<string | null>(null);
  const [pdfFileError, setPdfFileError] = useState<boolean>(false);
  const publicationInfo = [...pasyreotydArticles, ...osilodrostadArticles].find(el => el.id === id);

  const setFileHandler = useCallback((pdfFile: File | null) => {
    setPdfFile(pdfFile);
  }, []);

  useEffect(() => {
    if (pdfFile) setPdfLink(URL.createObjectURL(pdfFile));
  }, [pdfFile]);

  const formik = useFormik({
    initialValues: {
      title: '',
      publications: '',
      author: '',
      datePublication: '',
      publisher: ''
    },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (id) {
        const data = {
          title: values.title,
          author: values.author,
          datePublication: values.datePublication,
          publisher: values.publisher,
          categoryMedicaments: +values.publications
        };
        await editArticlePublication(data, id);

      } else if (pdfFile) {
        const data = new FormData();
        data.append('title', values.title);
        data.append('file', pdfFile);
        data.append('author', values.author);
        data.append('datePublication', values.datePublication);
        data.append('publisher', values.publisher);
        data.append('categoryMedicaments', values.publications);
        await addArticlePublications(data);
      }
    }
  });
  useEffect(() => {
    return () => poiStore.clearError();
  }, [poiStore]);

  const titleErrorMessage = (errorCode === 404 || errorCode === 400) && errorMessage;
  const handleOnClick = () => {
    if (!pdfFile && !id) {
      setPdfFileError(true);
    }
    formik.handleSubmit();
  };

  useEffect(() => {
    if (pdfFile) {
      setPdfFileError(false);
    }
  }, [pdfFile]);

  const { setFieldValue } = formik;

  useEffect(() => {
    if (publicationInfo) {
      setFieldValue('title', publicationInfo.title);
      setFieldValue('author', publicationInfo.author);
      setFieldValue('publications', publicationInfo.categoryMedicamentsId);
      setFieldValue('datePublication', publicationInfo.datePublishedArticle);
      setFieldValue('publisher', publicationInfo.publisher);
      setPdfLink(process.env.REACT_APP_IMAGE_URL + `${publicationInfo.pathFile}${publicationInfo.namePDF}`);
    }
  }, [publicationInfo, setFieldValue]);

  const checkboxes = [{ ...pasyreotydCheckbox }, { ...osilodrostadCheckbox }];

  const handleDeleteItem = () => {
    if (id) {
      void deleteArticle(id);
    }
  };

  return {
    handleDeleteItem,
    checkboxes,
    formik,
    handleOnClick,
    setFileHandler,
    pdfFile,
    pdfLink,
    pdfFileError,
    titleErrorMessage
  };
};