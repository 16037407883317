import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/hooks';
import { RoleFunction } from '../constants/projectUsers';
import { useCallback } from 'react';

export const useRedirect = () => {
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const { roleFunction } = authStore;

  const redirect = useCallback(() => {
    switch (roleFunction) {
      case RoleFunction.ROLE_ADMIN:
        navigate('/users');
        break;
      case RoleFunction.ROLE_GUEST:
        navigate('/login');
        break;
      case RoleFunction.ROLE_PUBLISHING:
      case RoleFunction.ROLE_VIEWING:
      case RoleFunction.ROLE_ACCEPTING:
      case RoleFunction.ROLE_EXPERT:
        navigate('/acromegaly');
        break;
    }
  }, [navigate, roleFunction]);

  return {
    redirect
  };
};