import React, { FC } from 'react';
import { Button, FormControlLabel, Grid, Radio } from '@mui/material';
import { DateInput } from '../../../../../../../shared/ui/form/date/Date';
import { IInterventionProps } from './Intervention.types';
import { useInterventionvm } from './useInterventionvm';
import { Input } from '../../../../../../../shared/ui/form/input/Input';
import { InterventionLabel } from '../ThirdStage.styled';
import { RadioGroupStyled } from '../../../common/fourthStage/FourthStage.styled';
import { GenericIcon } from '../../../../../../../shared/genericIcon/GenericIcon';
import { themeMUI } from '../../../../../../../../theme/theme';

export const Intervention: FC<IInterventionProps> = ({
                                                       id,
                                                       number,
                                                       initial,
                                                       handleAddIntervention,
                                                       editable,
                                                       handleDeleteIntervention
                                                     }) => {

  const { formik, handleOnClick, disabledAdd } = useInterventionvm(initial, handleAddIntervention);

  return <>
    {!editable && <Grid item xs={0.5} mt={1} mb={editable ? 0: 1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                        justifyContent={'center'}>
      {number}.
    </Grid>}
    <Grid item xs={handleDeleteIntervention ? 5.5 : 6} mt={1} mb={editable ? 0: 1} pr={1} display={'flex'} flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent={'center'} justifyItems={'center'}>

      <DateInput
        label={'Data'}
        required={false}
        disabled={!editable}
        views={['year', 'month']}
        openTo={'year'}
        name={'date'}
        inputFormat={'mm.yyyy'}
        value={formik.values.date}
        error={formik.touched.date && Boolean(formik.errors.date)}
        onBlur={() => formik.setFieldTouched('date', true)}
        onChange={(e) => formik.setFieldValue('date', e)} />
    </Grid>
    <Grid item xs={6} pl={1} mt={1} mb={editable ? 0: 1} display={'flex'} flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent={'center'}>
      <Input
        fullWidth={true}
        required={false}
        disabled={!editable}
        name={'type'}
        label={'Rodzaj zabiegu'}
        type={'text'}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.type}
        error={Boolean(formik.errors.type)} />
    </Grid>

    <Grid item xs={11} mt={1} display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyItems={'center'}
          justifyContent={'flex-start'}>
      <InterventionLabel>W przypadku guza przysadki:</InterventionLabel>
      <RadioGroupStyled row={true} name={'pituaryTumor'} value={formik.values.pituaryTumor}>
        <FormControlLabel onChange={formik.handleChange} value={'dostęp przezklinowy'}
                          control={<Radio disabled={!editable} />} label='dostęp przezklinowy' />
        <FormControlLabel onChange={formik.handleChange} value={'dostęp przezczaszkowy (kraniotomia)'}
                          control={<Radio disabled={!editable} />} label='dostęp przezczaszkowy (kraniotomia)' />
      </RadioGroupStyled>
    </Grid>
    {handleDeleteIntervention &&
      <Grid item xs={1} display={'flex'} flexDirection={'column'} alignItems={'flex-end'} mt={1}
      >
        <div style={{ margin: 'auto' }} onClick={() => {
          if (typeof id === 'number') {
            handleDeleteIntervention(id);
          }
        }}>
          <GenericIcon
            iconName='Close'
            style={{
              color: themeMUI.palette.primary.main,
              cursor: 'pointer',
              verticalAlign: 'middle'
            }} />
        </div>
      </Grid>
    }
    {
      handleAddIntervention &&
      <Grid item xs={1} display={'flex'} justifyContent={'center'} alignItems={'center'}
            mt={1}>
        <Button disabled={disabledAdd} variant='contained' color={'primary'} sx={{height: 40}}
                onClick={handleOnClick}>ZAPISZ</Button>
      </Grid>
    }
  </>;
};