import { useLinks } from './useLinks.vm';
import { observer } from 'mobx-react-lite';
import { FormControl, FormControlLabel, Grid, Switch } from '@mui/material';
import { CustomTable } from '../../../../shared/customTable/CustomTable';
import { LinksModal } from './modal/LinksModal';
import { useModal } from '../../../../shared/modal/useModal';
import { checkRoles } from '../../../../../utils/checkRoles';
import { RoleFunction } from '../../../../../constants/projectUsers';
import { HeadCell } from '../../../../../interfaces/library/library';

export const Links = observer(() => {
  const { handleClose, open, handleOpen, typeModal, id } = useModal();
  const { linkArticles, roleFunction, handleDelete, handleChange, checked, handleClickByGuest } = useLinks(handleOpen);
  const headCells: HeadCell[] = [
    {
      id: 'linkDate',
      numeric: true,
      disablePadding: false,
      label: 'DATA'
    }, {
      id: 'title',
      numeric: true,
      disablePadding: false,
      label: 'TYTUŁ'
    }];

  return (
    <Grid container width={'100%'} pt={1}>
      {linkArticles &&
        <CustomTable headCells={headCells} handleDelete={handleDelete}
                     isVisible={checkRoles(roleFunction, RoleFunction.ROLE_ACCEPTING)}
                     handleOpen={handleOpen}
                     tableConfigOrder={'dateWithHour'}
                     data={linkArticles}
                     width={'15%'}
                     handleClickByGuest={handleClickByGuest}
        >
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleChange}
                />
              }
              label='Dostęp tylko dla PWZ'
            />
          </FormControl>
        </CustomTable>
      }
      {open && <LinksModal id={id} typeModal={typeModal} open={open} handleClose={handleClose} />}
    </Grid>
  );
});