import { ModalRoot } from '../../../modal/ModalRoot';
import { Button, Grid } from '@mui/material';
import React, { FC } from 'react';
import { IModalContentProps } from '../../../modal/ModalRoot.types';
import {ReactComponent as InfoIcon} from '../../../../../assets/icon/info.svg';
import { DialogTitleInside, LinkEmail, LinkWebsite } from './ModalSideEffects.styled';

export const ModalSideEffects : FC<IModalContentProps> = ({open, handleClose }) => {

  return (
    <ModalRoot open={open} handleClose={handleClose}>

      <DialogTitleInside>
        <InfoIcon/>
        <h1>Uprzejmie informujemy, że poniższy Serwis nie służy do zgłaszania informacji dotyczących bezpieczeństwa farmakoterapii.</h1>
      </DialogTitleInside>


      <Grid gap={1} pl={2} pr={2} container  display={'flex'} alignItems={'center'} justifyContent={'center'} textAlign={'center'} maxWidth={'693px'} margin={'auto'} width={'95vw'} mt={2}>
        <p>Prosimy o zgłaszanie działań niepożądanych produktu leczniczego firmy Recordati Rare Diseases bezpośrednio na adres e-mail</p>
        <LinkEmail relative={'path'} to={'mailto:rrdpharmacovigilance@recordati.com'}>RRDpharmacovigilance@recordati.com</LinkEmail>
        <p>W przypadku zidentyfikowania w przedstawionym opisie przypadku informacji dotyczących bezpieczeństwa farmakoterapii produktu leczniczego firmy Recordati Rare Diseases, w tym działań/zdarzeń niepożądanych, sytuacji szczególnych, wad jakościowych, Recordati Rare Diseases jako podmiot odpowiedzialny może kontaktować się z osobą, która przygotowała opis przypadku, w celu pozyskania dodatkowych informacji dotyczących zgłoszenia.</p>
        <p>Informacje dotyczące działań niepożądanych innych produktów leczniczych należy przekazać do właściwego podmiotu odpowiedzialnego za dany produkt leczniczy i do Urzędu Rejestracji Produktów Leczniczych, Wyrobów Medycznych i Produktów Biobójczych.</p>
        <LinkWebsite target={'_blank'} relative={'path'} to={'https://smz.ezdrowie.gov.pl'}>(https://smz.ezdrowie.gov.pl)</LinkWebsite>
      </Grid>
      <Grid container mb={3} mt={3} display={'flex'} justifyContent={'center'} >
        <Button variant={'contained'} onClick={handleClose}>Zamknij</Button>
      </Grid>
    </ModalRoot>
  )
}