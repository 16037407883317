import { Button, Grid } from '@mui/material';
import { AccordionCase } from '../../../shared/accordion/AccordionCase';
import { DiseaseType } from '../../../../interfaces/common/common';
import { ModalRoot } from '../../../shared/modal/ModalRoot';
import { useForumVm } from './useForum.vm';
import { SortButton } from '../sortButton/SortButton';
import { Input } from '../../../shared/ui/form/input/Input';
import { GridActions, GridOutlineActions } from '../CaseView.styled';
import { checkRoles } from '../../../../utils/checkRoles';
import { RoleFunction } from '../../../../constants/projectUsers';
import { generatePermissionElements } from '../../../shared/permissionContainer/PermissionContainer';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { FixedButton } from '../../../shared/user/layout/fixedButton/FixedButton';
import { ReactComponent as Question } from '../../../../assets/icon/question.svg';
import {EmptyList} from "../../../shared/customTable/emptyList/EmptyList";

export const Forum = observer(() => {

  const {
    roleFunction,
    items,
    type,
    open,
    handleClose,
    modalSelected,
    handleSort,
    handleOpenModal,
    handleHiddenQuestion,
    getAnswer,
    handleAddAnswer,
    handleChangeAnswer,
    emptyMessage
  } = useForumVm();

  return (
    <Grid container display={'flex'} gap={2} flexDirection={'column'}>
      {checkRoles(roleFunction, [RoleFunction.ROLE_VIEWING]) &&  <FixedButton icon={<Question/>} title={'ZADAJ PYTANIE'} handleOnClick={handleOpenModal} />}
      <SortButton type={type as DiseaseType} handleOnClick={handleSort}/>
      {open && modalSelected && <ModalRoot handleClose={handleClose} open={open} title={modalSelected.title}
                                           titleJustify={modalSelected.titleJustify} >
        {modalSelected.element}
      </ModalRoot>}
      { emptyMessage ? <EmptyList>{emptyMessage}</EmptyList> :
        items.map(element => <AccordionCase key={element.id} element={element} expandedDefault={true} type={type as DiseaseType} id={element.id}
                                            actions={checkRoles(roleFunction, [RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_ADMIN, RoleFunction.ROLE_PUBLISHING]) ?
                                              generatePermissionElements([
                                                {
                                                  element: <GridOutlineActions type={type as DiseaseType} item display={'flex'} justifyContent={'right'}><Button
                                                    onClick={() => handleHiddenQuestion(element.id, !element.visibility)}
                                                    variant={'outlined'}>{element.visibility ? "Ukryj wątek" : "Odkryj wątek"}</Button></GridOutlineActions>,
                                                  permissions: [RoleFunction.ROLE_ACCEPTING]
                                                },
                                                {
                                                  element: !element.answer ? <GridActions type={type as DiseaseType} item xs={12} display={'flex'} flexDirection={'column'} alignItems={'flex-end'} gap={1}>
                                                    <Input value={getAnswer(element.id)} onChange={(event)=> handleChangeAnswer(element.id, event.target.value)} variant={'outlined'} name={"answerName"} type={"text"} multiline rows={3} />
                                                    <Grid container display={'flex'} justifyContent={'flex-end'} gap={1}>
                                                      <Button
                                                        onClick={() => handleAddAnswer(element.id)}
                                                        variant={'contained'}>Odpowiedz</Button>
                                                    </Grid>
                                                  </GridActions> : null,
                                                  permissions: [RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_PUBLISHING]
                                                }
                                              ], roleFunction) : null}/>)
      }
    </Grid>
  );
});