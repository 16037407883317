import { useAuthStore, useLibraryStore } from '../../../../../store/hooks';
import { useLibraryApi } from '../../../../../apiHooks';
import { useEffect } from 'react';
import { useSwitchActive } from '../../useSwitchActive.vm';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GeneralLink, generateLink } from '../../../../../utils';
import { useCookies } from 'react-cookie';

export const usePublications = (handleOpen: (type: string, id?: number) => void) => {
  const libraryStore = useLibraryStore();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { libType } = params;
  const { articles, categories } = libraryStore;
  const { getArticles } = useLibraryApi();
  const { roleFunction } = useAuthStore();
  const { checked, handleChange } = useSwitchActive();
  const [, setCookie] = useCookies(['redirectLink']);
  const id = categories && categories.find(el => el.label === libType)?.id;

  useEffect(() => {
    if (libType && id) {
      void getArticles(id);
    }

  }, [getArticles, libType, libraryStore, id]);
  useEffect(() => {
    return () => libraryStore.clearArticles();
  }, [libraryStore]);

  const handleDelete = (id: number) => {
    handleOpen('delete', id);
  };
  const handleEdit = (id: number) => {
    handleOpen('edit', id);
  };

  const handleClickByGuest = () => {
    const link = generateLink(roleFunction, GeneralLink.LOGIN_USER);
    setCookie('redirectLink', location.pathname);
    navigate(link);
  };

  return {
    articles,
    roleFunction,
    handleDelete,
    handleChange,
    checked,
    handleEdit,
    handleClickByGuest
  };
};