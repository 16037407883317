import { useAuthStore, useUsersStore } from '../../../store/hooks';
import { useModal } from '../../shared/modal/useModal';
import { HeadCell } from '../../../interfaces/library/library';
import React, { useEffect, useState } from 'react';
import { useUsersApi } from '../../../apiHooks';
import { createTableRows } from '../../shared/customTable/utils/createTableRows';
import { UserType } from '../../../interfaces/users/usersInfo';

const headCellsModel: HeadCell[] = [
  {
    id: 'roles',
    numeric: false,
    disablePadding: false,
    label: 'Rola'
  },
  {
    id: 'fullName',
    numeric: true,
    disablePadding: false,
    label: 'Imię i nazwisko'
  }, {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'E-mail'
  }];

export const useUsers = () => {
  const { roleFunction } = useAuthStore();
  const { getUsers, activateEmail } = useUsersApi();
  const usersStore = useUsersStore();
  const { users } = usersStore;
  const { handleClose, open, handleOpen, typeModal, id } = useModal();
  const [filter, setFilter] = useState<UserType>('users');

  const checkboxes = [
    {
      id: 1,
      value: 'Publikujący',
      active: true
    },
    {
      id: 2,
      value: 'Niezarejestrowani',
      active: false
    }
  ];

  const headCells: HeadCell[] = filter === 'users' ? headCellsModel.slice(1, 3) : headCellsModel.slice(0, 3)

  const handleDelete = (id: number) => {
    handleOpen('delete', id);
  };

  useEffect(() => {
    void getUsers(filter);
  }, [getUsers, filter]);

  const tableData = users ? createTableRows(users, headCells) : [];

  const handleChange = (event: React.SyntheticEvent, newValue: UserType) => {
    setFilter(newValue);
    usersStore.clearUsers();
  };


  const activateEmailHandler = (email: string) => {
    void activateEmail(email);
  };


  return {
    open,
    headCells,
    roleFunction,
    handleOpen,
    handleClose,
    typeModal,
    handleDelete,
    id,
    users: tableData,
    checkboxes,
    handleChange,
    filter,
    activateEmailHandler
  };
};