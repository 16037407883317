import { NavLink, useParams } from 'react-router-dom';
import { Circle, MenuContainer, NavContainer } from './Menu.styled';
import { generateMenu } from './Menu.helper';
import { observer } from 'mobx-react-lite';
import { useAuthStore, useNotificationsStore } from '../../../store/hooks';
import { checkRoles } from '../../../utils/checkRoles';
import { Fragment } from 'react';
import { RoleFunction } from '../../../constants/projectUsers';

export const Menu = observer(() => {
  const { roleFunction, expertType } = useAuthStore();
  const { notificationsCount } = useNotificationsStore();
  const params = useParams();
  const elements = [notificationsCount?.cases, notificationsCount?.all, notificationsCount?.qa];

  return (
    <MenuContainer>
      {generateMenu(expertType || params.type || 'acromegaly', checkRoles(roleFunction, RoleFunction.ROLE_GUEST), params.type ?? 'acromegaly', params.libType ?? 'chpl', params.qaTab ?? 'medical').map(link => (
        <Fragment key={link.id}>{checkRoles(roleFunction, link.permissions) && <NavContainer key={link.id}>
          <NavLink to={link.link}>{link.title}</NavLink>
          <>
            {
              !!(link.badge !== undefined && elements[link.badge] && Boolean(elements[link.badge])) &&
              <Circle>{elements[link.badge]}</Circle>
            }
          </>
        </NavContainer>}</Fragment>
      ))}
    </MenuContainer>
  );
});
