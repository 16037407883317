import { useForm } from '../../../../hooks/useForm';
import { initialChangePasswordForm, validationChangePasswordSchema } from './ChangePassword.helper';
import { useAuthApi } from '../../../../apiHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { GeneralLink } from '../../../../utils';

export interface IChangePassword {
  password: string;
  repeatPassword: string;
}

export const useChangePassword = () => {
  const { changePassword } = useAuthApi();
  const params = useParams();
  const navigate = useNavigate();
  const { token } = params;

  const onSubmit = async (values: IChangePassword) => {
    if (token) {
      const res = await changePassword(values.password, token);
      if (res) {
        navigate(GeneralLink.LOGIN_PUBLISHING);
      }
    }
  };

  const {
    formik
  } = useForm(initialChangePasswordForm, validationChangePasswordSchema, onSubmit);

  return {
    formik
  };
};
