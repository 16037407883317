import React, { FC } from 'react';
import { Input } from '../../../ui/form/input/Input';
import { Button } from '@mui/material';
import { ReactComponent as PlusIcon } from '../../../../../assets/icon/plus.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/icon/download.svg';
import { generateLink, PublishUserLink } from '../../../../../utils';
import { RoleFunction } from '../../../../../constants/projectUsers';
import { ICaseFilterProps } from './CaseFilter.types';
import { useNavigate } from 'react-router-dom';
import { ButtonAddContainer, FilterContainer, LeftColumnContainer } from './CaseFilter.styled';
import { SelectCustom } from '../../../ui/form/select/Select';
import { generateOptions } from './CaseFilter.helper';
import { checkRoles } from '../../../../../utils/checkRoles';
import { useAuthStore } from '../../../../../store/hooks';
import { useCaseApi } from '../../../../../apiHooks';

export const CaseFilter: FC<ICaseFilterProps> = ({ filter, isStatus, handleSearch, search, status, handleSelect }) => {
  const navigate = useNavigate();
  const { postReportExcel } =useCaseApi();
  const { roleFunction } = useAuthStore();

  const handleAdd = () => {
    navigate(generateLink(RoleFunction.ROLE_PUBLISHING, PublishUserLink.CASE_NEW, [[':type', filter]]));
  };

  const handleDownloadExcel = () => {
    void postReportExcel();
  };

  return (<FilterContainer>
    <LeftColumnContainer>
      <Input
        required={false}
        name={'search'}
        placeholder={'wpisz szukane słowa'}
        label={'Wyszukaj'}
        type={'text'}
        value={search}
        onChange={handleSearch}
      />
      {isStatus && <SelectCustom name={'status'} value={status!} options={generateOptions(roleFunction)}
                                 onChange={handleSelect} />}
    </LeftColumnContainer>
    {checkRoles(roleFunction, RoleFunction.ROLE_PUBLISHING) ? <ButtonAddContainer type={filter}>
      <Button size={'large'} onClick={handleAdd} startIcon={<PlusIcon />}>Dodaj</Button></ButtonAddContainer> : null}
    {checkRoles(roleFunction, RoleFunction.ROLE_ACCEPTING) ? <ButtonAddContainer type={filter}>
      <Button size={'large'} onClick={handleDownloadExcel} startIcon={<DownloadIcon />}>Zestawienie</Button></ButtonAddContainer> : null}
  </FilterContainer>);
};