import { IOption } from '../../shared/ui/form/select/Select.types';
import { QUPageTab } from './QUPage.types';
import { isOfType } from '../../../utils/isOfType';

export const optionsAddQuestionQA : IOption[] = [
  {
    value: 'MEDICAL',
    label: 'Pytanie merytoryczne'
  },
  {
    value: 'TECHNICAL',
    label: 'Pytanie techniczne',
  }
]

export const isOfTypeQUPageTab = (value: string): value is QUPageTab => {
  return isOfType<QUPageTab>(value, ['medical' , 'technical']);
}