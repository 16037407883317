import React, { FC } from 'react';
import { IDrugProps } from './Drug.types';
import { Button, Grid } from '@mui/material';

import { useDrugVm } from './useDrug.vm';
import { Input } from '../../../../../../../shared/ui/form/input/Input';
import { GenericIcon } from '../../../../../../../shared/genericIcon/GenericIcon';
import { themeMUI } from '../../../../../../../../theme/theme';

export const Drug: FC<IDrugProps> = ({ id, number, initial, handleAddDrug, editable, handleDeleteDrug }) => {
  const { formik, handleOnClick, disabledAdd } = useDrugVm(initial, handleAddDrug);

  return <>
    {!editable && <Grid item xs={0.5} mt={1} mb={editable ? 0: 1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
      {number}.
    </Grid>}
    <Grid item xs={3.5} pr={3} mt={1} mb={editable ? 0: 1} display={'flex'} flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent={'center'}>
      <Input
        disabled={!editable}
        name={'group'}
        type={'text'}
        onChange={formik.handleChange}
        value={formik.values.group}
        placeholder={'grupa leków'}
        label={'Grupa leków'}
      />
    </Grid>
    <Grid item xs={4} pr={3} mt={1} mb={editable ? 0: 1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
      <Input
        disabled={!editable}
        name={'drug'}
        type={'text'}
        onChange={formik.handleChange}
        value={formik.values.drug}
        placeholder={'lek'}
        label={'Lek'}
      />
    </Grid>
    <Grid item xs={3.5} mt={1} mb={editable ? 0: 1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
      <Input
        disabled={!editable}
        name={'quantity'}
        type={'text'}
        onChange={formik.handleChange}
        value={formik.values.quantity}
        placeholder={'dawka'}
        label={'Dawka'}
      />
    </Grid>
    {handleDeleteDrug &&
      <Grid item xs={0.5} display={'flex'} flexDirection={'column'} alignItems={'flex-end'} mt={1} mb={1}
      >
        <div style={{ margin: 'auto' }} onClick={() => {
          if (typeof id === 'number') {
            handleDeleteDrug(id);
          }
        }}>
          <GenericIcon
            iconName='Close'
            style={{
              color: themeMUI.palette.primary.main,
              cursor: 'pointer',
              verticalAlign: 'middle'
            }} />
        </div>
      </Grid>
    }
    {handleAddDrug &&
      <Grid item xs={1} mt={1} mb={0} pl={1}>
        <Button disabled={disabledAdd} variant='contained' color={'primary'}
                onClick={handleOnClick}>ZAPISZ</Button>
      </Grid>
    }
  </>;
};