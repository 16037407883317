import { FormikErrors, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { ObjectSchema } from 'yup';

export const useForm = <T extends object>(
  initialValues: T,
  validationSchema: ObjectSchema<T>,
  onSubmit: (values: T) => void,
  messagesQueue?: (keyof T)[]
) => {

  const formik = useFormik<T>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await onSubmit(values);
    }
  });

  useEffect(() => {
    const errors: FormikErrors<T> = { ...formik.errors };

    let errorMessage: string | null = null;

    if (messagesQueue) {
      for (const key of messagesQueue) {
        const error = errors[key];
        if (error) {
          errorMessage = String(error);
          break;
        }
      }
    } else {
      if (Object.keys(errors).length > 0) {
        const firstErrorKey = Object.keys(errors)[0];
        errorMessage = String(errors[firstErrorKey as keyof T]);
      }
    }

    setErrorMessage(errorMessage);
  }, [formik, messagesQueue]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);


  return {
    formik,
    errorMessage
  };
};