import styled from 'styled-components';
import { IDiseaseType } from '../../../../interfaces/common/common';

export const Title = styled.h1`
  margin-top: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
`;

export const DescriptionElement = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin: 16px 0;
  letter-spacing: 0.15px;
`;

export const Text = styled.span`
  line-height: 28px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  margin: 12px 0;
`;

export const DescriptionContainer = styled.div`

  & .MuiFormControl-root, .MuiFormControl-root div{
    height: 100%;
    align-items: flex-start;
  }
  
  & label.MuiFormLabel-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.6);
    transform: translate(0, -1.5px) scale(0.75)
  }

  & div.MuiInputBase-root.MuiInput-root.Mui-disabled:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.6)
  }

  & input.MuiInputBase-input.MuiInput-input.Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.87)
  }

  & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.Mui-disabled {
    color: rgba(0, 0, 0, 0.87)
  }

  & span.MuiButtonBase-root.MuiCheckbox-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.6);
  }

  & span.MuiButtonBase-root.MuiRadio-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.6)
  }

  & button.MuiButtonBase-root.MuiFab-root {
    position: fixed;
    bottom: 48px;
    left: 48px;

    & svg {
      margin-top: -1px;
      margin-left: 8px;
    }
  }

`;

export const ButtonAddContainer = styled.div<IDiseaseType>`
  button {
    display: flex;
    justify-content: flex-end;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    background: ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : props.theme.colors.cushing1};
    margin-right: 0;
    margin-left: auto;
    
    :hover, :active {
      background: ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : props.theme.colors.cushing1};
      opacity: 0.8
    }
  }
`;

