import styled from 'styled-components';
import { themeComponentStyled, themeMUI } from '../../../../theme/theme';

export const CardContainer = styled.div`
  background-color: ${themeComponentStyled.colors.gray};

  & .MuiCardActions-root {
    padding: 15px;
    gap: 8px;
    display: flex;
    justify-content: flex-end;
    background-color: ${themeComponentStyled.colors.gray};
    cursor: pointer;

    & svg {
      cursor: pointer
    }
  }

  & div {
    cursor: pointer
  }

  & .MuiCardMedia-root {
    opacity: 0.6;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }

  & .MuiCardContent-root {
    background-color: ${themeComponentStyled.colors.gray};
    padding-bottom: 16px !important;

    & h6 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 32px;
      min-height: 64px
    }
  }

  & hr {
    border-color: rgba(218, 218, 218, 0.12);
  }

  & a {
    text-decoration: none;
    color: ${themeMUI.palette.text.primary};
  }

`;

export const LabelDiv = styled.div<{ type: string }>`
  background-color: ${props => props.type === 'akromegalia' ? '#D60D47' : '#30B700'};
  height: 55px;
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  line-height: 55px;
  padding-left: 16px;
  font-weight: 500;
  font-size: 20px;
  color: white
`;