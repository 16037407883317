import styled from 'styled-components';
import {
  InputControllerContainerProps
} from '../../../../pages/user/caseDraft/stages/cushing/secondStage/SecondStage.types';

export const InputControllerContainer = styled.div<InputControllerContainerProps>`
  display: flex;
  flex-direction: ${props => props.nextLine ? 'column' : 'row'};
  width: 100%;
  gap: 8px;

  input {
    width: 100%;
  }

  span {
    white-space: ${props => props.$wrap ? 'break-spaces' : 'nowrap'};
  }
`;

export const ElementContainer = styled.div`
  width: 100%;
  text-wrap: normal;
`;