import { IconButton, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { IInputProps } from './Input.types';
import { InputAdornmentStyled, InputStyled } from './Input.styled';

export const Input: FC<IInputProps> = ({
                                         onBlur,
                                         type,
                                         readOnly,
                                         errorMessage,
                                         autoComplete,
                                         addedInfo,
                                         error,
                                         fullWidth,
                                         maxLength,
                                          sx,
                                         ...props
                                       }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorInput, setErrorInput] = useState(false);

  useEffect(() => {
    if (error) {
      setErrorInput(error);
    } else {
      setErrorInput(false);
    }
  }, [error]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const inputType = type !== 'password' ? type : (showPassword ? 'text' : 'password');

  return (<InputStyled><TextField
    id={props.name}
    type={inputType}
    error={errorInput}
    size='small'
    autoComplete={autoComplete}
    onBlur={onBlur}
    inputProps={{
      maxLength,
      autoComplete,
      readOnly
    }}
    {...props}
  />
    {type === 'password' && <InputAdornmentStyled position='end'>
      <IconButton
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornmentStyled>}
    {error && errorMessage && <p>{errorMessage}</p>}
    {addedInfo && <p>{addedInfo}</p>}
  </InputStyled>);
};