import { DATE_IS_STILL } from '../components/shared/ui/form/date/Date.helper';
import {DateType }from '../../src/components/pages/user/caseDraft/CaseDraft.types';

interface IErrorValid {
  global : boolean | null;
  dateStart : boolean;
  dateEnd: boolean;
}

export const dateIsStill = (date: DateType) : DateType => {
    return (date === '' || String(date) === DATE_IS_STILL) ? null : date;
}

export const validateFull = (inputControllerStart: boolean, inputControllerEnd: boolean, valueStart: DateType, valueEnd: DateType) => {

    let errors : IErrorValid = {
        global : null,
        dateStart : false,
        dateEnd: false
    }

    const preparatoryTreatmentSomatostatinAnalogueValidate = validateDates(inputControllerStart, inputControllerEnd, valueStart, valueEnd);

    if (preparatoryTreatmentSomatostatinAnalogueValidate) {
        errors.global = preparatoryTreatmentSomatostatinAnalogueValidate[0];
        errors.dateStart = preparatoryTreatmentSomatostatinAnalogueValidate[1];
        errors.dateEnd = preparatoryTreatmentSomatostatinAnalogueValidate[2];
    }

    const preparatoryTreatmentSomatostatinAnalogueRange = validateDateRange(inputControllerStart, inputControllerEnd, valueStart, valueEnd);

    if (preparatoryTreatmentSomatostatinAnalogueRange) {
        errors.dateStart = errors.dateStart ? true :  preparatoryTreatmentSomatostatinAnalogueRange[0];
        errors.dateEnd = errors.dateEnd ? true :  preparatoryTreatmentSomatostatinAnalogueRange[1];
        errors.global = preparatoryTreatmentSomatostatinAnalogueRange[2];
    }
    return errors;
};

export const validateDateRange = (inputControllerStart: boolean, inputControllerEnd: boolean, valueStart: DateType, valueEnd: DateType) => {

    if(inputControllerStart && inputControllerEnd){

        if(valueEnd === null || valueStart === null){
            return [false, false, false]
        }

        const startDate= new Date(valueStart);
        const endDate= new Date(valueEnd);

        if (startDate.toString() === 'Invalid Date') {
            return [true, false, true]
        }
        if (endDate.toString() === 'Invalid Date' && valueEnd !== 'nadal') {
            return [false, true, true]
        }
        else if (startDate >= endDate) {
            return [false, true, true]
        }
    }
    return null;
};

export const validateDates = (inputControllerStart: boolean, inputControllerEnd: boolean, valueStart: DateType, valueEnd: DateType) => {

    if(inputControllerStart && inputControllerEnd){
        const errors = [false, false, false];

        errors[1] = valueStart === null;
        if(errors[1]){
            errors[0]=true;
        }

        errors[2] = valueEnd === null;
        if(errors[2]){
            errors[0]=true;
        }

        return errors;
    }
    return false;

}


