import { FC } from 'react';
import { IModalPublishedCaseProps } from './ModalPublishedCase.types';
import { ModalRoot } from '../../../../../../../shared/modal/ModalRoot';
import { Grid } from '@mui/material';
import { ButtonsActions } from '../../buttonsActions/ButtonsActions';
import { ImgButton } from '../../../../../../../shared/modal/ModalRoot.styled';
import checkImg from '../../../../../../../../assets/img/check.png';
import { Paragraph } from '../FourthStage.styled';

export const ModalPublishedCase: FC<IModalPublishedCaseProps> = ({
                                                                   handleClose,
                                                                   handleDraftSave,
                                                                   handleSave,
                                                                   open,
                                                                   finishMessage
                                                                 }) => {

  return (
    <ModalRoot open={open} handleClose={handleClose}>
      <Grid container pb={2} pl={3} pr={3} minHeight={'170px'}>
        <Grid container maxWidth={'693px'} width={'95vw'} mt={2} textAlign={'center'} justifyContent={'center'}
              alignItems={'center'} gap={3.5}>
          <ImgButton onClick={handleClose} src={checkImg} alt={'check'} />
          {finishMessage ? <Paragraph>{finishMessage}</Paragraph> :
            <><Paragraph>
              Kliknij WYŚLIJ DO AKCEPTACJI aby wysłać opis do akceptacji lub ZAPISZ JAKO SZKIC, jeśli przed wysłaniem
              chciałbyś go jeszcze przejrzeć.
            </Paragraph>
              <Paragraph>
                Opis przypadku po zaakceptowaniu będzie widoczny dla innych użytkowników.
              </Paragraph>
              <ButtonsActions
                isPreviousVisible={false}
                mt={0}
                handleDraftSave={handleDraftSave}
                handleSave={handleSave}
                nextStep={'Wyślij do Akceptacji'}
              />
            </>}
        </Grid>
      </Grid>
    </ModalRoot>
  );
};