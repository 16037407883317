import { ModalRoot } from '../../../shared/modal/ModalRoot';
import React from 'react';
import { IModalType } from '../../../../interfaces/library/library';
import { SelectCustom } from '../../../shared/ui/form/select/Select';
import { Button, FormControl, FormControlLabel, Grid, Radio, Typography } from '@mui/material';
import { Input } from '../../../shared/ui/form/input/Input';
import { observer } from 'mobx-react-lite';
import { useUserModal } from './useUserModal.vm';
import { RadioGroupStyled } from '../../../shared/ui/form/radioGroupStyled/RadioGroupStyled.styled';
import { UserType } from '../../../../interfaces/users/usersInfo';

interface IProps {
  open: boolean,
  handleClose: () => void;
  typeModal: string | null
  id: number | null
  filter: UserType
}

export const UserModal: React.FC<IProps> = observer(({filter, id, handleClose, open, typeModal }) => {
  const {
    modalTitle,
    formik,
    roles,
    deleteUserHandler,
    checkboxes,
    isExpert
  } = useUserModal(handleClose, typeModal, id, filter);

  return (
    <ModalRoot
      open={open}
      handleClose={handleClose}
      title={modalTitle[typeModal as keyof IModalType]}
    >
      <Grid container pt={2} pb={2} pl={3} pr={3} flexDirection={'column'} width={'700'}>
        {typeModal === 'add' ? <><Grid item xs={12}>
          {roles &&
            <SelectCustom error={Boolean(formik.errors.role)} label={'Wybierz rolę'} fullWidth name={'role'}
                          value={formik.values.role} options={roles}
                          onChange={formik.handleChange} />}

          {isExpert && <Grid item xs={12} mt={2}>
            <FormControl>
              <RadioGroupStyled
                row
                name='diseaseType'>
                {checkboxes.map(el => (
                  <FormControlLabel
                    checked={el.id === formik.values.diseaseType}
                    onChange={formik.handleChange}
                    value={el.id}
                    key={el.id}
                    control={<Radio
                      color={formik.touched.diseaseType && Boolean(formik.errors.diseaseType) ? 'error' : 'primary'} />}
                    label={el.title} />
                ))}
              </RadioGroupStyled>
            </FormControl>
          </Grid>}
        </Grid>
          <Grid item xs={12} mt={2}>
            <Input fullWidth name={'email'} label={'E-mail'} type={'text'}
                   onChange={formik.handleChange}
                   value={formik.values.email}
                   error={formik.touched.email && Boolean(formik.errors.email)} />
          </Grid>
        </> : <Typography variant={'body1'}>Czy jesteś pewien/pewna że chcesz usunąć użytkownika?</Typography>}
        <Grid container width={'652px'} justifyContent={'flex-end'} mt={3}>
          <Grid item>
            <Button disabled={false} variant='outlined' color={'primary'}
                    onClick={handleClose}>Anuluj</Button>
          </Grid>
          <Grid item ml={2}>
            <Button disabled={false} variant='contained' color={'primary'}
                    onClick={typeModal === 'delete' ? deleteUserHandler : () => formik.handleSubmit()}>{typeModal === 'delete' ? 'Usuń' : 'Wyślij'}</Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalRoot>
  );

});
