import { useEffect } from 'react';

export const useScroll = (loading: boolean) => {
  useEffect(() => {
    if(loading){
      const timeoutId = setTimeout(() => {
        window.scrollTo({
          top: 20000,
          behavior: 'smooth'
        });
      }, 10);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [loading]);

}