import { useState } from 'react';

export const useAccordion = (isSingle: boolean = false) => {
  const [expandedIds, setExpandedIds] = useState<number[]>([]);

  const handleChange = (id: number) => {
    if (expandedIds.find(expandedId => id === expandedId)) {
      const newIds = expandedIds.filter(expandedId => expandedId !== id);
      setExpandedIds(newIds);
    } else {
      if (isSingle) {
        setExpandedIds([id]);
      } else {
        const newIds = [...expandedIds];
        newIds.push(id);
        setExpandedIds(newIds);
      }
    }
  };

  return {
    expandedIds,
    handleChange
  };
};