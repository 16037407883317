import { ModalRoot } from '../../../../shared/modal/ModalRoot';
import { Button, Grid } from '@mui/material';
import { Input } from '../../../../shared/ui/form/input/Input';
import React from 'react';
import { useCommentModal } from './useCommentModal.vm';
import { ArrowBackIosNew } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';

interface IProps {
  open: boolean,
  handleClose: () => void;
}

export const CommentModal: React.FC<IProps> = observer(({ open, handleClose }) => {
  const { formik, handleHide } = useCommentModal(handleClose);

  return <ModalRoot
    icon={<ArrowBackIosNew sx={{ cursor: 'pointer' }} onClick={() => handleHide(formik.values.comment)} />}
    open={open}
    handleClose={handleClose}
    title={'Uwagi do autora przypadku:'}>
    <Grid container pt={2} pb={2} pl={3} pr={3} flexDirection={'column'} width={'693px'}>
      <Grid item xs={12}>

        <Input error={formik.touched.comment && Boolean(formik.errors.comment)} name={'comment'}
               type={'text'} multiline={true}
               onChange={formik.handleChange}
               value={formik.values.comment} rows={3} placeholder={'*Treść komentarza'} />

        <Grid container justifyContent={'flex-end'} mt={3}>
          <Grid item mr={2}>
            <Button disabled={false} variant='outlined' color={'primary'}
                    onClick={handleClose}>Anuluj</Button>
          </Grid>
          <Grid item>
            <Button disabled={false} variant='contained' color={'primary'}
                    onClick={() => formik.handleSubmit()}>Wyślij do autora tego przypadku</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </ModalRoot>;
});