import { RootStore } from './rootStore';
import { makeAutoObservable} from 'mobx';
import { IAccordionElement } from '../interfaces/quPage/quPage';

export class QUPageStore {
   quMedicalElements: IAccordionElement[] | null = null;
   quTechnicalElements: IAccordionElement[] | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setQUMedicalElements(quElements: IAccordionElement[]) {
    this.quMedicalElements = quElements;
  }

  setQUTechnicalElements(quElements: IAccordionElement[]) {
    this.quTechnicalElements = quElements;
  }

  clearStore(){
    this.quMedicalElements = null;
    this.quTechnicalElements = null;
  }
}