import React from 'react';
import { ICheckboxInputProps } from './InputController.types';
import { Checkbox, FormControlLabel, Radio, Switch } from '@mui/material';

import { ElementContainer, InputControllerContainer } from './InputController.styled';

export function InputController<T extends object>({
                                                    checkbox,
                                                    radio,
                                                    switchComponent,
                                                    setField,
                                                    inputName,
                                                    children,
                                                    setActive,
                                                    setBroadcast,
                                                    active,
                                                    broadcastFields,
                                                    radioProvider = false,
                                                    isHalf = false,
                                                    nextLine = false
                                                  }: ICheckboxInputProps<T>) {
  

  const handleChange = (value: string) => {
    if (radioProvider) {
      setField(String(inputName), active ? '' : value);
    } else {
      let isActive = setBroadcast ? (broadcastFields && setBroadcast(inputName, broadcastFields, active)) : (setActive && setActive(inputName, !active));

      if (!isActive) {
        if (Array.isArray(inputName)) {
          inputName.forEach(elementName => {
            setField(String(elementName), String(elementName).includes('Active') ? false : '');
          });
        } else {
          setField(String(inputName), '');
        }
      }
    }
  };

  let control = <></>;
  let labelComponent = '';

  if (checkbox) {
    const { name, label, disabled } = checkbox;
    control = <Checkbox disabled={disabled} checked={active} onChange={(e) => handleChange(label)} name={name} />;
    labelComponent = label;
  }
  if (radio) {
    const { name, label, disabled } = radio;
    control = <Radio disabled={disabled} checked={active} onClick={(e) => handleChange(label)} name={name} />;
    labelComponent = label;
  }
  if (switchComponent) {
    const { name, label, disabled } = switchComponent;
    control = <Switch disabled={disabled} checked={active} onChange={(e) => handleChange(label)} name={name} />;
    labelComponent = label;
  }

  if (isHalf) {
    return (
      <InputControllerContainer $wrap nextLine={nextLine}>
        <ElementContainer>
          <FormControlLabel
            control={
              control
            }
            label={labelComponent}
          />
        </ElementContainer>
        <ElementContainer>
          {children}
        </ElementContainer>
      </InputControllerContainer>
    );
  } else {
    return (
      <InputControllerContainer nextLine={nextLine}>
        <FormControlLabel
          control={
            control
          }
          label={labelComponent}
        />
        {children}
      </InputControllerContainer>
    );
  }
}