import { generalLinks } from './generalLinks';
import CaseDraft from '../../../../pages/user/caseDraft/CaseDraft';
import { PublishUserLink } from '../../../../../utils';
import { Link } from './link.types';
import { CaseView } from '../../../../pages/caseView/CaseView';
import { MyCases } from '../../../../pages/myCases/MyCases';
import Home from '../../../../pages/home/Home';
import { Notifications } from '../../../../pages/notifications/Notifications';
import { Library } from '../../../../pages/library/Library';
import { HomeRedirect } from '../../../../pages/homeRedirect/HomeRedirect';
//import { QUPages } from '../../../../pages/qaPage/QAPage';

export const onlyPublishUserLinks: Link[] = [
  {
    path: PublishUserLink.REDIRECT_HOME,
    element: <HomeRedirect />
  },
  {
    path: PublishUserLink.HOME,
    element: <Home />
  },
  {
    path: PublishUserLink.CASE_NEW,
    element: <CaseDraft />
  },
  {
    path: PublishUserLink.CASE_EDIT,
    element: <CaseDraft />
  },
  {
    path: PublishUserLink.CASE_VIEW,
    element: <CaseView />
  },
  {
    path: PublishUserLink.CASES,
    element: <MyCases />
  },
  // {
  //   path: PublishUserLink.QA,
  //   element: <QUPages />
  // },
  {
    path: PublishUserLink.CASE_VIEW,
    element: <CaseView />
  },
  {
    path: PublishUserLink.NOTIFICATIONS,
    element: <Notifications />
  },
  {
    path: PublishUserLink.LIBRARY,
    element: <Library />
  }
];

export const publishLinks = generalLinks.concat(onlyPublishUserLinks);