// login publishing form configuration
import { IFormField } from '../../../../interfaces/common/form';
import * as yup from 'yup';
import { RegexRules, ValidationError } from '../../../../utils';

export const changePasswordFormConfig: IFormField[] = [

  {
    name: 'password',
    label: 'Nowe hasło',
    required: true,
    type: 'password'
  },
  {
    name: 'repeatPassword',
    label: 'Powtórz hasło',
    required: true,
    type: 'password'
  }
];

// validation schema for change password
export const validationChangePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required(ValidationError.PASSWORD_NOT_CORRECT)
    .matches(RegexRules.PASSWORD, ValidationError.PASSWORD_NOT_CORRECT),
  repeatPassword: yup
    .string()
    .required(ValidationError.PASSWORD_NOT_CORRECT)
    .matches(
      RegexRules.PASSWORD,
      ValidationError.PASSWORD_NOT_CORRECT
    )
    .oneOf([yup.ref('password')], ValidationError.PASSWORDS_NOT_MATCH)
});


// initial data for change password
export const initialChangePasswordForm = {
  password: '',
  repeatPassword: ''
};