import { Divider, Grid, Typography } from '@mui/material';
import {observer} from 'mobx-react-lite';
import React from 'react';
import { useCaseStore } from '../../../../../../../store/hooks';
import { ISecondStageValues } from '../../../../../user/caseDraft/stages/cushing/secondStage/SecondStage.types';
import { CheckedIconContainer } from '../../../checkedIcon/CheckedIcon';
import { Text } from '../../../Description.styled';
import { Input } from '../../../../../../shared/ui/form/input/Input';
import { getDate } from '../../../../../../../utils/dateSort';

export const SecondStage = observer(() => {
  const caseStore = useCaseStore();
  const {
    secondStage,
  }= caseStore;

  const secondStageCushing = secondStage as ISecondStageValues;

  if(secondStageCushing){
    const currentDiagnosisSection = Boolean(secondStageCushing.currentDiagnosisCushing);

    const currentDiagnosisColumn = Boolean(secondStageCushing.currentDiagnosisCushing) ? (secondStageCushing.currentDiagnosisCushing === 'zespół ektopowej produkcji ACTH' ? secondStageCushing.currentDiagnosisProductionACTHCheck : secondStageCushing.currentDiagnosisAdrenalCushingCheck) : null;


    const hormonalTestsSection = Boolean(secondStageCushing.hormonalTestDuringTheDiagnosisCortisol) || Boolean(secondStageCushing.hormonalTestDuringTheDiagnosisACTH) ||
                                 Boolean(secondStageCushing.hormonalTestDuringTheDiagnosisFreeCortisol) || Boolean(secondStageCushing.hormonalTestDuringTheDiagnosisCRH);
                                 Boolean(secondStageCushing.hormonalTestDuringTheDiagnosisCRH) || Boolean(secondStageCushing.hormonalTestDuringTheDiagnosisDesmopressin);
                                 Boolean(secondStageCushing.hormonalTestDuringTheDiagnosisDexamethasone);


    const imagingResultMrTkSection = Boolean(secondStageCushing.imagingResultMrTkMacroMicroadenoma) || Boolean(secondStageCushing.imagingResultMrTkMacroadenomaInfiltration) ||
      Boolean(secondStageCushing.imagingResultMrTkMacroadenomaInfiltrationRockyBay) || Boolean(secondStageCushing.imagingResultMrTkMacroadenomaInfiltrationRockyScaleKnosp);

    const hormonalTestsResultSection = Boolean(secondStageCushing.hormonalTestsPositronTomographyResult) || Boolean(secondStageCushing.hormonalTestsSomatostatinReceptorResult)

    const histopathologySection = Boolean(secondStageCushing.confirmationOfSomatotropicTissueMaterial) || Boolean(secondStageCushing.proliferationMarkerKi67);


    return (
      <Grid container justifyContent={'space-between'} width={'100%'} mt={6} >
        <Typography variant={'h4'} pb={2}>Dane dotyczące choroby zasadniczej i etiologii hiperkortyzolemii</Typography>
        <Divider sx={{ width: '100%' }} />

        {currentDiagnosisSection && currentDiagnosisColumn && <Grid container display={'flex'} alignItems={'center'} mt={4}>
          {currentDiagnosisSection &&<><Typography width={'100%'} variant={'h6'}>Aktualne rozpoznanie:</Typography>
            <Grid item xs={6} display={'flex'} alignItems={'center'} justifyItems={'center'} >
              <CheckedIconContainer/><Text>{secondStageCushing.currentDiagnosisCushing}</Text>
            </Grid>
          <>{currentDiagnosisColumn && <Grid item xs={6}>
            <Input disabled variant={'standard'} fullWidth={true} name={'currentDiagnosisColumn'}
                   type={'text'} value={currentDiagnosisColumn} />
              </Grid>}</>
          </>}
        </Grid>}

        
        <Grid container display={'flex'} alignItems={'center'} mt={4}>
          <Typography variant={'h6'} mb={2} width={'100%'}>Rozpoznanie zespołu Cushinga:</Typography>
          <Grid item xs={4} pr={1} >
            <Input disabled variant={'standard'} fullWidth={true} required={true} name={'courseDateStart'} label={'Data rozpoznania'}
                   type={'text'} maxLength={2} value={getDate(secondStageCushing.courseDateStart)} />
          </Grid>
          <Grid item xs={4} pr={1} pl={1}>
            <Input disabled variant={'standard'} fullWidth={true} required={true} name={'courseWhoRecognized'} label={'Kto rozpoznał / specjalizacja'}
                   type={'text'} value={secondStageCushing.courseWhoRecognized} />
          </Grid>
          <Grid item xs={4} pr={1} pl={1}>
            <Input disabled variant={'standard'} fullWidth={true} required={true} name={'courseDateStartOfTreatment'} label={'Data rozpoczęcia leczenia'}
                   type={'text'} value={getDate(secondStageCushing.courseDateStartOfTreatment)} />
          </Grid>
        </Grid>


        {secondStageCushing.clinicalSymptoms && <Grid container display={'flex'} alignItems={'center'} mt={4}>
          <Typography width={'100%'} variant={'h6'}>Objawy kliniczne i powikłania w okresie ustalania rozpoznania:</Typography>
             <Grid item xs={12}>
              <Input multiline disabled variant={'standard'} fullWidth={true} name={'clinicalSymptoms'}
                     type={'text'} value={secondStageCushing.clinicalSymptoms} />
            </Grid>
        </Grid>}


        {hormonalTestsSection && <Grid container display={'flex'} alignItems={'flex-start'}  mt={2}>
          <Typography mt={2} variant={'h6'}>Badania hormonalne w okresie ustalania rozpoznania:</Typography>
          <Grid container display={'flex'} alignItems={'flex-start'}>
            {secondStageCushing.hormonalTestDuringTheDiagnosisCortisol && <><Grid item xs={6} pl={2} display={'flex'} alignItems={'center'}  justifyItems={'center'} alignSelf={'stretch'} mt={2}>
              <CheckedIconContainer/><Text>{'kortyzol w surowicy w godzinach porannych'}</Text>
            </Grid>
              <Grid item xs={6} pl={2} alignSelf={'stretch'} mt={2}>
                <Input multiline disabled variant={'standard'} fullWidth={true} name={'hormonalTestDuringTheDiagnosisCortisol'} label={'(μg/dl)'}
                       type={'text'} value={secondStageCushing.hormonalTestDuringTheDiagnosisCortisol} />
              </Grid>
            </>}
            {secondStageCushing.hormonalTestDuringTheDiagnosisACTH && <><Grid item xs={6} pl={2}  display={'flex'} alignItems={'center'} justifyItems={'center'} alignSelf={'stretch'} mt={2}>
              <CheckedIconContainer/><Text>{'ACTH w osoczu w godzinach porannych'}</Text>
            </Grid>
              <Grid item xs={6} pl={2} alignSelf={'stretch'} mt={2}>
                <Input multiline disabled variant={'standard'} fullWidth={true} name={'hormonalTestDuringTheDiagnosisACTH'} label={'(μg/ml)'}
                       type={'text'} value={secondStageCushing.hormonalTestDuringTheDiagnosisACTH} />
              </Grid>
            </>}
            {secondStageCushing.hormonalTestDuringTheDiagnosisFreeCortisol && <><Grid item xs={6} pl={2}  display={'flex'} alignItems={'center'} justifyItems={'center'} alignSelf={'stretch'} mt={2}>
              <CheckedIconContainer/><Text>{'wolny kortyzol w moczu (optymalnie średnia z dwóch lub trzech DZM)'}</Text>
            </Grid>
              <Grid item xs={6} pl={2} alignSelf={'stretch'} mt={2}>
                <Input multiline disabled variant={'standard'} fullWidth={true} name={'hormonalTestDuringTheDiagnosisFreeCortisol'} label={'(μg/dobę)'}
                       type={'text'} value={secondStageCushing.hormonalTestDuringTheDiagnosisFreeCortisol} />
              </Grid>
            </>}
            {secondStageCushing.hormonalTestDuringTheDiagnosisCRH && <><Grid item xs={6} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
              <CheckedIconContainer/><Text>{'test z CRH'}</Text>
            </Grid>
              <Grid item xs={6} pl={2} mt={2}>
                <Input disabled variant={'standard'} fullWidth={true} name={'hormonalTestDuringTheDiagnosisCRH'} label={''}
                       type={'text'} value={secondStageCushing.hormonalTestDuringTheDiagnosisCRH} />
              </Grid>
            </>}
            {secondStageCushing.hormonalTestDuringTheDiagnosisDesmopressin && <><Grid item xs={6} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'}>
              <CheckedIconContainer/><Text>{'test z desmopresyną'}</Text>
            </Grid>
              <Grid item xs={6} pl={2}>
                <Input disabled variant={'standard'} fullWidth={true} name={'hormonalTestDuringTheDiagnosisDesmopressin'} label={''}
                       type={'text'} value={secondStageCushing.hormonalTestDuringTheDiagnosisDesmopressin} />
              </Grid>
            </>}
            {secondStageCushing.hormonalTestDuringTheDiagnosisDexamethasone && <><Grid item xs={6} pl={2}  display={'flex'} alignItems={'center'} justifyItems={'center'}>
              <CheckedIconContainer/><Text>{'test z „wysoką” dawką deksametazonu'}</Text>
            </Grid>
              <Grid item xs={6} pl={2}>
                <Input disabled variant={'standard'} fullWidth={true} name={'hormonalTestDuringTheDiagnosisDexamethasone'} label={''}
                       type={'text'} value={secondStageCushing.hormonalTestDuringTheDiagnosisDexamethasone} />
              </Grid>
            </>}
          </Grid>
        </Grid>}


        {imagingResultMrTkSection && <><Grid container display={'flex'} alignItems={'center'} justifyItems={'center'}>
          <Typography mt={2} variant={'h6'}>Wynik obrazowania układu podwzgórzowo-przysadkowego w MR/TK:</Typography>
          <Grid container alignItems={'center'}>
            {secondStageCushing.imagingResultMrTkMacroMicroadenoma && <><Grid item xs={3} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'}>
              <CheckedIconContainer/><Text>{secondStageCushing.imagingResultMrTkMacroMicroadenoma}</Text>
            </Grid>
            </>}
            {secondStageCushing.imagingResultMrTkMacroadenomaInfiltration && <><Grid item xs={3}  display={'flex'} alignItems={'center'} justifyItems={'center'}>
              <CheckedIconContainer/><Text>{secondStageCushing.imagingResultMrTkMacroadenomaInfiltration}</Text>
            </Grid>
            </>}
            {secondStageCushing.imagingResultMrTkMacroadenomaInfiltrationRockyBay && <><Grid item xs={3} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'}>
              <Input disabled variant={'standard'} fullWidth={true} name={'imagingResultMrTkMacroadenomaInfiltrationRockyBay'} label={'zatoka'}
                     type={'text'} value={secondStageCushing.imagingResultMrTkMacroadenomaInfiltrationRockyBay} />
              </Grid>
              </>}
            {secondStageCushing.imagingResultMrTkMacroadenomaInfiltrationRockyScaleKnosp && <><Grid item xs={3} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'}>
              <Input disabled variant={'standard'} fullWidth={true} name={'imagingResultMrTkMacroadenomaInfiltrationRockyScaleKnosp'} label={'stopień w skali Knosp'}
                     type={'text'} value={secondStageCushing.imagingResultMrTkMacroadenomaInfiltrationRockyScaleKnosp} />
            </Grid>
            </>}
          </Grid>
        </Grid></>}

        {hormonalTestsResultSection && <><Grid container display={'flex'} justifyItems={'center'} alignItems={'flex-start'}>
          <Typography mt={2} mb={2} variant={'h6'}>Inne badania obrazowe w okresie ustalania rozpoznania:</Typography>
          <Grid container alignItems={'center'} >
            {secondStageCushing.hormonalTestsSomatostatinReceptorResult && <><Grid item xs={6} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'} alignSelf={'stretch'}>
              <CheckedIconContainer/><Text>scyntygrafia receptorów somatostatynowych</Text>
            </Grid>
              <Grid item xs={6} pl={2}>
                <Input multiline disabled variant={'standard'} fullWidth={true} name={'hormonalTestsSomatostatinReceptorResult'} label={'wynik'}
                       type={'text'} value={secondStageCushing.hormonalTestsSomatostatinReceptorResult} />
              </Grid>
            </>}
            {secondStageCushing.hormonalTestsPositronTomographyResult && <><Grid item xs={6} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'} alignSelf={'stretch'} mt={2}>
              <CheckedIconContainer/><Text>badanie pozytonowej tomografii emisyjnej z izotopem 68Ga</Text>
            </Grid>
              <Grid item xs={6} pl={2} mt={2}>
                <Input multiline disabled variant={'standard'} fullWidth={true} name={'hormonalTestsPositronTomographyResult'} label={'wynik'}
                       type={'text'} value={secondStageCushing.hormonalTestsPositronTomographyResult} />
              </Grid>
            </>}
          </Grid>
        </Grid></>}

        {histopathologySection && <><Grid container display={'flex'} alignItems={'center'} justifyItems={'center'}>
          <Typography mt={2} mb={2} variant={'h6'}>Badanie histopatologiczne:</Typography>
          <Grid container alignItems={'center'}>
            {secondStageCushing.confirmationOfSomatotropicTissueMaterial && <Grid item xs={12} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'}>
              <CheckedIconContainer/><Text>potwierdzenie immunohistochemiczne guza kortykotropowego w materiale tkankowym</Text>
            </Grid>}
            {secondStageCushing.proliferationMarkerKi67 && <><Grid item xs={3} >
              <Text>Marker proliferacji Ki-67</Text>
            </Grid>
            <Grid item xs={9} display={'flex'} alignItems={'center'} justifyItems={'center'} >
              <CheckedIconContainer/><Text>{secondStageCushing.proliferationMarkerKi67}</Text>
            </Grid></>}
        </Grid>
        </Grid></>}


        {secondStageCushing.electronMicroscopicExamination && <><Grid container display={'flex'} alignItems={'center'} justifyItems={'center'}>
          <Typography mt={2} mb={2} variant={'h6'}>Badanie w mikroskopie elektronowym:</Typography>
          <Grid container alignItems={'center'}>
            <Grid item xs={12} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'}>
              <CheckedIconContainer/><Text>{secondStageCushing.electronMicroscopicExamination}</Text>
            </Grid>
          </Grid>
        </Grid></>}

      </Grid>
    )
  }
  return <></>;

});