import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { AddInterventionFunction, IIntervention } from './Intervention.types';
import { initialIntervention } from './Intervention.helper';


export const useInterventionvm = (initial?: IIntervention, handleAddIntervention?: AddInterventionFunction) => {
  const [disabledAdd, setDisabledAdd] = useState(true);

  const formik = useFormik<IIntervention>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: initial ?? initialIntervention,
    onSubmit: async (values) => {}
  })

  useEffect(()=> {
    setDisabledAdd(!(formik.values.type !== "" && formik.values.date !== ""))
  }, [formik.values])

  const handleOnClick = () => {
    if(handleAddIntervention){
      handleAddIntervention(formik.values);
      formik.resetForm();
    }
  }


  return {formik, handleOnClick, disabledAdd
  }
}