import React from 'react';

export interface IChildren {
  children: React.ReactNode;
}

export type DiseaseType = 'acromegaly' | 'cushing'

export type ColorType = DiseaseType | 'general'

export interface IColorType {
  type: ColorType;
}

export enum ICaseStatus {
  TO_ACCEPTING = 'TO_ACCEPTING',
  SENT = 'SENT',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  HIDDEN = 'HIDDEN'
}

export enum CaseStatus {
  TO_ACCEPTING = 'w akceptacji',
  SENT = 'w akceptacji',
  DRAFT = 'szkic',
  PUBLISHED = 'opublikowany',
  HIDDEN = 'ukryty'
}

export enum CaseStatusAccept {
  TO_ACCEPTING = 'w akceptacji',
  SENT = 'do akceptacji',
  PUBLISHED = 'opublikowany',
  HIDDEN = 'ukryty'
}

export enum CaseStatusExpert {
  TO_ACCEPTING = 'do akceptacji',
  PUBLISHED = 'opublikowany',
  HIDDEN = 'ukryty'
}

export interface IDiseaseType {
  type: DiseaseType;
}