import React, { FC } from 'react';
import { IAddQuestionProps } from './FixedButton.types';
import { Button } from '@mui/material';
import { ButtonFixedContainer } from './FixedButton.styled';

export const FixedButton: FC<IAddQuestionProps> = ({ handleOnClick, title, icon }) => {

  return (
    <ButtonFixedContainer>
      <Button size='large' variant={'contained'} onClick={() => handleOnClick()}
              endIcon={icon}>{title}</Button>
    </ButtonFixedContainer>
  );
};
