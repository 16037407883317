import { Alert, AlertColor, AlertProps, AlertTitle, IconButton } from '@mui/material';
import React from 'react';
import { ArrowForwardIos } from '@mui/icons-material';
import { StyledRight, AlertContent } from './ListItem.styled';
import { useNavigate } from 'react-router-dom';
import { useNotificationsApi } from '../../../../apiHooks';
import { themeMUI } from '../../../../theme/theme';
import { convertLinkDate } from '../../../../utils/dateStringify';
import styled from 'styled-components';

interface IProps {
  id: number | null;
  link?: string;
  title: string,
  status: string,
  caseTitle: string | null;
  type: AlertColor | undefined;
  data: string;
}

export const ListItem: React.FC<IProps> = ({ id, status, link, title, caseTitle, type, data }) => {
  const navigate = useNavigate();
  const { editStatus } = useNotificationsApi();

  const clickHandler = async () => {
    if (id) {
      const res = await editStatus(id);
      if (res && link) {
        navigate(link);
      }
    }
  };

  const StyledAlert = styled(Alert)<AlertProps>(({ theme }) => ({
    '& .MuiAlert-message': {
      position: 'relative',
      width: '100% !important'
    }
  }));

  return (
    <StyledAlert
      severity={type} variant='filled'
      sx={{
        width: '100%',
        position: 'relative',
        color: status === 'NOT_READ' ? undefined : themeMUI.palette.text.primary,
        backgroundColor: status === 'NOT_READ' ? undefined : '#DADADA'
      }}
    >
      <AlertContent>
        <div>
          <AlertTitle sx={{ fontSize: '16px', fontWeight: '500' }}>{title}</AlertTitle>
          {caseTitle}
        </div>
        <span>{convertLinkDate(data)}</span>
      </AlertContent>
      <StyledRight onClick={clickHandler} status={status}>
        <IconButton
          size={'small'}
          sx={{
            borderRadius: '50%',
            border: status === 'NOT_READ' ? '1px solid #FFFFFF' : '1px solid rgba(0,0,0,0.87)'
          }}>
          <ArrowForwardIos
            type={'filled'} />
        </IconButton>
      </StyledRight>
    </StyledAlert>
  );
};