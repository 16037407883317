import { generalLinks } from './generalLinks';
import { GuestLink } from '../../../../../utils';
import { Link } from './link.types';
import { HomeRedirect } from '../../../../pages/homeRedirect/HomeRedirect';

export const onlyGuestLinks: Link[] = [
  {
    path: GuestLink.REDIRECT_HOME,
    element: <HomeRedirect />
  }
];

export const guestLinks = onlyGuestLinks.concat(generalLinks);