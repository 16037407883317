import styled from 'styled-components';
import { TableCell } from '@mui/material';
import { themeMUI } from '../../../theme/theme';

export const StyledTableCell = styled(TableCell)`

  & svg {
    cursor: pointer
  }

  & a {
    text-decoration: none;
    color: ${themeMUI.palette.text.primary};
  }

  & div {
    cursor: pointer
  }
`;