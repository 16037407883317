import { createTheme } from '@mui/material';

export const themeComponentStyled = {
  colors: {
    acromegaly1: '#D60D47',
    cushing1: '#00843D',
    error: '#D32F2F',
    gray: 'rgba(218, 218, 218)',
    card: '#DADADA'
  },
  breakpoints: {
    extraLarge: 1535,
    large: 1199,
    medium: 899,
    small: 767,
    cell: 599
  }
};

export const themeMUI = createTheme({
  palette: {
    primary: {
      main: '#2196F3',
      dark: '#DADADA'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1489
    }
  }
});
