import styled from 'styled-components';
import { IDiseaseType } from '../../../../../interfaces/common/common';

export const FilterIconContainer = styled.div`
  cursor: pointer;
  z-index: 10;
`;

export const FilterContainer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;


export const LeftColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-left: 20px;

  input {
    width: 300px;
  }
`;


export const ButtonAddContainer = styled.div<IDiseaseType>`
  button {
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    background: ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : props.theme.colors.cushing1};
    margin-right: 20px;

    :hover, :active {
      background: ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : props.theme.colors.cushing1};
      opacity: 0.8
    }
  }
`;