import { useState } from 'react';

export function useModal<T extends string>() {
  const [open, setOpen] = useState(false);
  const [typeModal, setTypeModal] = useState<T | null>(null);
  const [id, setId] = useState<number | null>(null);

  const handleClose = () => {
    setOpen(false);
    setTypeModal(null);
    setId(null);
  };

  const handleOpen = (type: T, id?: number) => {
    setOpen(true);
    setTypeModal(type);
    if (id) {
      setId(id);
    }
  };

  return {
    typeModal,
    open,
    handleClose,
    handleOpen,
    id
  };
};