import { useCallback, useMemo } from 'react';

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { usePoiStore } from '../store/hooks';

export const useApiCall = () => {
  const poiStore = usePoiStore();

  const errorHandler = useCallback(
    (status: number, message: string, error?: string) => {
      poiStore.setGlobalError(status, message, error);
    },
    [poiStore]
  );

  const api = useMemo(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    const responseBody = (response: AxiosResponse) => {
      return response.data;
    };

    const apiInstance = {
      get: async (url: string, isLoader = true, config?: AxiosRequestConfig) => {
        if (isLoader) {
          poiStore.setGlobalLoader();
          poiStore.clearError();
        }

        return axios
          .get(url, config)
          .then(responseBody)
          .catch(error => errorHandler(error.response.status, error.response.data.message))
          .finally(() => isLoader && poiStore.unsetGlobalLoader());
      },
      post: async (url: string, body: string | unknown, isLoader = true, config: AxiosRequestConfig = { responseType: 'json' }) => {
        if (isLoader) {
          poiStore.setGlobalLoader();
          poiStore.clearError();
        }
        return axios
          .post(url, body, config)
          .then(responseBody)
          .catch(error => errorHandler(error.response.status, error.response.data.message, error.response.data.error))
          .finally(() => isLoader && poiStore.unsetGlobalLoader());
      },
      put: async (url: string, body: string | unknown, isLoader = true, config: AxiosRequestConfig = { responseType: 'json' }) => {
        if (isLoader) {
          poiStore.setGlobalLoader();
          poiStore.clearError();
        }
        return axios
          .put(url, body, config)
          .then(responseBody)
          .catch(error => errorHandler(error.response.status, error.response.data.message))
          .finally(() => isLoader && poiStore.unsetGlobalLoader());
      },
      delete: async (url: string, isLoader = true) => {
        if (isLoader) {
          poiStore.setGlobalLoader();
          poiStore.clearError();
        }
        return axios
          .delete(url)
          .then(responseBody)
          .catch(error => errorHandler(error.response.status, error.response.data.message))
          .finally(() => isLoader && poiStore.unsetGlobalLoader());
      }
    };

    return { apiInstance };
  }, [errorHandler, poiStore]);

  return { api };
};
