import { RoleFunction } from '../constants/projectUsers';
import { Param } from '../components/shared/appLayer/appRouting/links/link.types';

export enum GeneralLink {
  REDIRECT_HOME = '/',
  LOGIN_USER = '/login',
  LOGIN_PUBLISHING = '/login-publishing',
  CHANGE_PASSWORD = '/change-password/:token',
  REGISTER = '/register/:token',
  UNDER_CONSTRUCTION = '/under-construction',
  QA = '/qa/:qaTab',
  PAGE_404 = '/404',
  REST = '*',
}

export enum AdminLink {
  REDIRECT_HOME = '/',
  QA = '/qa/:qaTab',
  NOTIFICATIONS = '/notifications',
  HOME = '/users'
}

export enum PublishUserLink {
  REDIRECT_HOME = '/',
  HOME = '/:type',
  CASE_NEW = '/cases/:type/new',
  CASE_EDIT = '/cases/:type/:id/edit',
  CASE_VIEW = '/cases/:type/:id/:caseTab',
  // QA = '/qa/:qaTab',
  CASES = '/cases/:type',
  NOTIFICATIONS = '/notifications',
  LIBRARY = '/library/:libType'
}

export enum ViewUserLink {
  REDIRECT_HOME = '/',
  HOME = '/:type',
  CASE_VIEW = '/cases/:type/:id/:caseTab',
  QA = '/qa/:qaTab',
  LIBRARY = '/library/:libType'
}

export enum GuestLink {
  REDIRECT_HOME = '/',
  HOME = '/cases/:type',
  CASE_VIEW = '/cases/:type/:id/:caseTab',
  LIBRARY = '/library/:libType'
}

export enum ExpertAdminLink {
  REDIRECT_HOME = '/',
  HOME = '/:type',
  CASES = '/cases/:type',
  CASE_VIEW = '/cases/:type/:id/:caseTab',
  QA = '/qa/:qaTab',
  NOTIFICATIONS = '/notifications',
  LIBRARY = '/library/:libType'
}

export enum AcceptAdminLink {
  REDIRECT_HOME = '/',
  HOME = '/:type',
  CASES = '/cases/:type',
  CASE_VIEW = '/cases/:type/:id/:caseTab',
  QA = '/qa/:qaTab',
  NOTIFICATIONS = '/notifications',
  LIBRARY = '/library/:libType'
}


export const generateLink = (roleFunction: RoleFunction, link?: GeneralLink | AdminLink | GuestLink | PublishUserLink | ViewUserLink | ExpertAdminLink | AcceptAdminLink, paramsConfig?: Param[]) => {
  let generatedLink = String(link);

  if (paramsConfig) {
    paramsConfig.forEach((element) => {
      const [template, param] = element;
      generatedLink = generatedLink.replace(template, param.toString());
    });
  }

  switch (roleFunction) {
    case RoleFunction.ROLE_GUEST:
      return generatedLink as GeneralLink;
    case RoleFunction.ROLE_ACCEPTING:
      return generatedLink as AcceptAdminLink;
    case RoleFunction.ROLE_ADMIN:
      return generatedLink as AdminLink;
    case RoleFunction.ROLE_PUBLISHING:
      return generatedLink as PublishUserLink;
    case RoleFunction.ROLE_VIEWING:
      return generatedLink as ViewUserLink;
    case RoleFunction.ROLE_EXPERT:
      return generatedLink as ExpertAdminLink;
    default:
      return '/';
  }
};
