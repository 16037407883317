import { IAddSimpleQuestion, IAddTypeQuestion } from './ModalAddQuestion.types';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';


export const initialAddTypeQuestion: IAddTypeQuestion = {
  author: '',
  questionName: '',
  type: ''
};


export const initialAddQuestion: IAddSimpleQuestion = {
  name: ''
};


export const validationAddTypeQuestionSchema: ObjectSchema<IAddTypeQuestion> = yup.object().shape({
  type: yup.string().required(),
  author: yup.string().required(),
  questionName: yup.string().max(255, 'Pytanie może zawierać maksymalnie 255 znaków').required('')
});

export const validationAddQuestionSchema: ObjectSchema<IAddSimpleQuestion> = yup.object().shape({
  name: yup.string().required()
});