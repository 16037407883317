import { Grid } from '@mui/material';
import { FC } from 'react';
import { IAuthLayoutProps } from './AuthLayout.helper';

import logo from '../../../../assets/img/logo-nav.png';
import { Logo, StyledGrid } from './AuthLayout.styled';

export const AuthLayout: FC<IAuthLayoutProps> = ({ children, contentAfterLogo }) => {

  return (
    <StyledGrid>
      <Grid container height={'100vh'} justifyContent={'flex-end'} alignItems={'center'}>
        <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'} alignContent={'flex-start'} xs={5}
              item>
          <Logo src={logo} alt={'logo'} />
          {contentAfterLogo}
          <Grid width={'400px'} display={'flex'} mb={2} flexDirection={'column'} justifyContent={'center'}
                alignContent={'flex-start'} gap={'16px'}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};