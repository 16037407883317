import { Grid } from '@mui/material';
import React, { FC } from 'react';
import checkImg from '../../../../../assets/img/check.png';
import { IClose } from '../../../../../interfaces/common/modal';
import { ImgButton } from '../../ModalRoot.styled';

export const ModalAddQuestionConfirmation : FC<IClose>= ({handleClose}) => {

  return (
    <Grid container pb={2} pl={3} pr={3}>
      <Grid container maxWidth={'693px'} width={'95vw'} mt={2} textAlign={'center'}>
        <Grid item xs={12} mb={2}>
          Po udzieleniu odpowiedzi pytanie zostanie opublikowane i będzie widoczne na tej stronie dla wszystkich
          użytkowników.
        </Grid>
        <Grid item xs={12} mb={2}>
          <ImgButton onClick={handleClose} src={checkImg} alt={'check'} />
        </Grid>
      </Grid>
    </Grid>
  );
};