import styled from "styled-components";
import {  Grid } from '@mui/material';
import { IButtonContent } from './sortButton/SortButton.types';
import { themeMUI } from '../../../theme/theme';

export const ContentContainer = styled.div`
  margin-top:16px;
`

export const GridActions = styled(Grid)<IButtonContent>`
  button:last-child{
    background:  ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : (props.type === 'cushing' ? props.theme.colors.cushing1 : themeMUI.palette.primary.main)};
  }

`

export const GridOutlineActions = styled(Grid)<IButtonContent>`
  button:last-child{
    color: ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : (props.type === 'cushing' ? props.theme.colors.cushing1 : themeMUI.palette.primary.main)};
    border: 1px solid ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : (props.type === 'cushing' ? props.theme.colors.cushing1 : themeMUI.palette.primary.main)};
  }
  
`