import * as yup from 'yup';

import { ValidationError } from '../../../../../utils';
import { IFormField } from '../../../../../interfaces/common/form';
import { ILoginPublishingForm } from './LoginPublishing.interfaces';

// login publishing form configuration
export const loginPublishingFormConfig: IFormField[] = [
  {
    name: 'email',
    label: 'e-mail',
    required: true,
    type: 'text'
  },
  {
    name: 'password',
    label: 'hasło',
    required: true,
    type: 'password'
  }
];

// validation schema for login publishing form
export const validationLoginPublishingSchema = yup.object().shape({
  email: yup.string().email(ValidationError.EMAIL_NOT_CORRECT).required(ValidationError.EMAIL_NOT_CORRECT),
  password: yup
    .string()
    .required(ValidationError.PASSWORD_NOT_CORRECT)
});

// initial data for login publishing form
export const initialLoginPublishingForm: ILoginPublishingForm = {
  email: 'biuro@softwebo.pl',
  password: 'zaq1@WSX'
};

export const initialLoginPublishingFormProd: ILoginPublishingForm = {
  email: '',
  password: ''
};

// the order of displayed messages configuration
export const messagesLoginPublishingQueue: (keyof ILoginPublishingForm)[] = ['email', 'password'];