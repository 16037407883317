import { ICheckboxesGroupProps } from '../checkboxesGroup/CheckboxesGroup.types';
import { FormControlLabel, FormGroup, Grid, Switch } from '@mui/material';
import React from 'react';

export function SwitchGroup<T extends object>({
                                                elements,
                                                name,
                                                onChange,
                                                values,
                                                mt,
                                                full = false,
                                                singleField = false
                                              }: ICheckboxesGroupProps<T>) {

  return (
    <FormGroup>
      <Grid container>
        {
          elements.map(switchElement =>
            <Grid key={switchElement.name} item mt={mt} xs={full ? 12 : 3}>
              <FormControlLabel
                control={
                  <Switch checked={Boolean(values.find(value => switchElement.name === value))}
                          onChange={() => onChange(name, switchElement.name)} name={switchElement.name} />
                }
                label={switchElement.label}
              />
            </Grid>
          )
        }
      </Grid>
    </FormGroup>
  );
}