import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { filterByUserType, searchByFilterValue } from '../components/shared/customTable/utils/searchByFilter';
import { DiseaseType } from '../interfaces/common/common';
import { SelectChangeEvent } from '@mui/material';

type IFilter<T> = T | 'wszystkie'

function sliceArray<T>(list: T[] | null, currentPage: number, pageSize: number) {
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  return list && list.length > 0 ? list.slice(startIndex, endIndex) : [];
}

function generateAllSettings<T extends object, P>(list: T[], currentPage: number, pageSize: number, keys?: (keyof T)[], filterData?: (list: T[], status?: P | 'wszystkie') => T[], filter?: P | 'wszystkie', search?: string, userType?: string) {
  let newList = [...list];
  if (search && keys) {
    newList = newList.filter(searchByFilterValue(search, keys));
  }
  if (filter && filterData) {
    newList = filterData(newList, filter);
  }

  if (userType) {
    newList = newList.filter(filterByUserType('active' as keyof T, userType));
  }

  const totalPage = newList && newList.length > 0 ? Math.ceil(newList.length / pageSize) : 0;
  const slicedList = sliceArray(newList, currentPage, pageSize);

  return { totalPage, slicedList };
}

export function usePagination<T extends object, P>(list: T[] | null, pageSize: number, keys?: (keyof T)[], filterData?: (list: T[], status?: IFilter<P>) => T[], userType?: string, type?: DiseaseType, filterInit? : IFilter<P>) {
  const [items, setItems] = useState<T[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [totalPage, setTotalPage] = useState<number | undefined>(undefined);
  const [filter, setFilter] = useState<IFilter<P>>(filterInit ?? 'wszystkie');
  const [radioValue, setRadioValue] = useState(userType);
  const [keysValues, setKeysValues] = useState<(keyof T)[]>();

  useEffect(() => {
    if (!keysValues && keys) {
      setKeysValues(keys);
    }
  }, [keys, keysValues]);

  const handleChange = (event: React.SyntheticEvent, value: string) => {
    setRadioValue(value);
    setCurrentPage(1);
    const {
      totalPage,
      slicedList
    } = generateAllSettings(list ?? [], 1, pageSize, keys, filterData, filter, search, value);

    setItems(slicedList);
    setTotalPage(totalPage);
  };

  const handleSelect = (event: SelectChangeEvent<string | number>) => {
    const newFilter = event.target.value as P;
    setFilter(newFilter);
    setCurrentPage(1);
    const {
      totalPage,
      slicedList
    } = generateAllSettings(list ?? [], 1, pageSize, keys, filterData, newFilter, search);

    setItems(slicedList);
    setTotalPage(totalPage);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearch(e.target.value);
    const {
      totalPage,
      slicedList
    } = generateAllSettings(list ?? [], 1, pageSize, keys, filterData, filter, e.target.value, radioValue);

    setItems(slicedList);
    setTotalPage(totalPage);
  };

  const reset = useCallback(() => {
    if (list !== null) {
      setCurrentPage(1);
      const { totalPage, slicedList } = generateAllSettings(
        list,
        1,
        pageSize,
        keysValues,
        filterData,
        filter,
        search,
        radioValue
      );

      setItems(slicedList);
      setTotalPage(totalPage);
    }
  }, [list, pageSize, filterData, filter, search, radioValue, keysValues]);

  useEffect(() => {
    reset();
  }, [reset]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const {
      slicedList,
      totalPage
    } = generateAllSettings(list ?? [], page, pageSize, keys, filterData, filter, search, radioValue);
    setItems(slicedList);
    setTotalPage(totalPage);
  };

  return {
    items: items,
    pageSize,
    currentPage,
    totalPage,
    handlePageChange,
    handleSearch, search, handleSelect, status: filter, handleChange, radioValue
  };
}