import { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import {
  ICustomErrorsSecondStage,
  IInputControllerSecondStage,
  ISecondStageValues as ISecondStageValuesAcromegaly,
  ISecondStageValues
} from './SecondStage.types';
import {
  checkboxInputControllerConfig, convertInputControllerSecondStage, datesValidation,
  initialErrorSecondStage,
  initialSecondStage,
  validationSecondStageSchema
} from './SecondStage.helper';
import { useInputController } from '../../../../../../shared/ui/form/inputController/useInputController';
import { useProgressBarStore } from '../../../../../../../store/hooks';
import { useDraftCaseApi } from '../../../../../../../apiHooks';
import { useParams } from 'react-router-dom';
import { dateIsStill, validateFull } from '../../../../../../../utils/validateDateRange';
import { DiseaseType } from '../../../../../../../interfaces/common/common';
import { Action } from '../../../../../../../store/progressBarStore';
import { DateType } from '../../../CaseDraft.types';

export const useSecondStageVm = () => {
  const { id, type } = useParams();
  const [activeFieldset, setActiveFieldset] = useState<string>('');
  const [adenomaFieldsActive, setAdenomaFieldsActive] = useState(false);
  const [customErrors, setCustomErrors] = useState<ICustomErrorsSecondStage>(initialErrorSecondStage);
  const { draftCaseSecondStageSaved, draftCaseSecondSage } = useDraftCaseApi();
  const progressBarStore = useProgressBarStore();

  const {
    inputControllerElements,
    setActive
  } = useInputController<IInputControllerSecondStage>(progressBarStore.secondStage ? convertInputControllerSecondStage(progressBarStore.secondStage as ISecondStageValuesAcromegaly) : checkboxInputControllerConfig);

  const formik = useFormik<ISecondStageValues>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    initialValues: progressBarStore.secondStage as ISecondStageValuesAcromegaly ?? initialSecondStage,
    validationSchema: validationSecondStageSchema,
    onSubmit: async (values) => {
      const newValues = convertDate();

      await draftCaseSecondSage(newValues, Number(id), type as DiseaseType);
      progressBarStore.setSecondStage(newValues);
    }
  });

  const { setFieldValue, values, errors } = formik;

  useEffect(() => {
    const isMacroOption = values.imagingResultMrTk === 'makrogruczolak';
    setAdenomaFieldsActive(isMacroOption);

    if (values.imagingResultMrTk === 'mikrogruczolak' && values.oppresionOfTheOpticChiasm !== '' && values.degreeOnTheKnospScale !== '' && values.sinusInfiltraionSinus !== '') {
      setFieldValue('oppresionOfTheOpticChiasm', '');
      setFieldValue('degreeOnTheKnospScale', '');
      setFieldValue('sinusInfiltraionSinus', '');
      setActive(['degreeOnTheKnospScale', 'sinusInfiltraionSinus'], false);
    }
  }, [values.imagingResultMrTk, values.oppresionOfTheOpticChiasm, values.degreeOnTheKnospScale, values.sinusInfiltraionSinus, setFieldValue, setActive]);

  useEffect(() => {
    if (errors.courseDateStart || errors.courseWhoRecognized || errors.courseDateStartOfTreatment) {
      setActiveFieldset('valid-fieldset-1');
    } else if (customErrors.carbohydrateMetabolismDisordersDateStart || customErrors.carbohydrateMetabolismDisordersDateEnd || customErrors.cardiovascularComplicationsDateStart || customErrors.cardiovascularComplicationsDateEnd) {
      setActiveFieldset('valid-fieldset-2');
    } else if (errors.growthHormoneConcentration || errors.serumIGF1Concentration || errors.uln) {
      setActiveFieldset('valid-fieldset-3');
    }
  }, [errors, customErrors]);

  const handleSave = async () => {
    const errors = await formik.validateForm();

    const customErrorsNew = { ...customErrors };
    let isCustomErrors = false;

    datesValidation.forEach(dateElement => {
      const errors = validateFull(
        inputControllerElements[dateElement.start as keyof IInputControllerSecondStage],
        inputControllerElements[dateElement.end as keyof IInputControllerSecondStage],
        formik.values[dateElement.start as keyof IInputControllerSecondStage] as DateType,
        formik.values[dateElement.end as keyof IInputControllerSecondStage] as DateType
      );

      if (inputControllerElements[dateElement.start as keyof IInputControllerSecondStage] || inputControllerElements[dateElement.end as keyof IInputControllerSecondStage]) {
        customErrorsNew[dateElement.start as keyof ICustomErrorsSecondStage] = errors.dateStart;
        customErrorsNew[dateElement.end as keyof ICustomErrorsSecondStage] = formik.values[dateElement.check] ? false : formik.values[dateElement.end as keyof IInputControllerSecondStage] === null;
      }
    });

    isCustomErrors = Object.values(customErrorsNew).some(customError => customError);
    setCustomErrors(customErrorsNew);

    if (!isCustomErrors && Object.keys(errors).length === 0) {
      formik.handleSubmit();
    }
  };

  const handleDraftSave = async (action: Action) => {
    const newValues = convertDate();
    await draftCaseSecondStageSaved(newValues, Number(id), type as DiseaseType);
    progressBarStore.setSecondStage(newValues, action);
  };


  const handleChangeSwitchAndCheckbox = (name: (keyof ISecondStageValues), value: string) => {
    formik.setFieldValue(name, value);
  };

  const handleResetFieldset = () => {
    setActiveFieldset('');
  };

  const convertDate = () => {
    let newValues = { ...formik.values };

    datesValidation.forEach(dateElement => {
      const getDateStart = formik.values[dateElement.start as keyof IInputControllerSecondStage];
      newValues[dateElement.start] = dateIsStill(getDateStart as DateType) as never;
      const getDateEnd = formik.values[dateElement.end as keyof IInputControllerSecondStage];
      newValues[dateElement.end] = dateIsStill(getDateEnd as DateType) as never;
    });

    return newValues;
  };

  return {
    activeFieldset,
    setActiveFieldset,
    adenomaFieldsActive,
    inputControllerElements,
    customErrors,
    setActive,
    formik,
    handleSave,
    handleDraftSave,
    handleChangeSwitchAndCheckbox,
    handleResetFieldset,
    cardiovascularComplicationsCheckbox: inputControllerElements.cardiovascularComplicationsDateStart || inputControllerElements.cardiovascularComplicationsDateEnd,
    carbohydrateMetabolismDisordersCheckbox: inputControllerElements.carbohydrateMetabolismDisordersType || inputControllerElements.carbohydrateMetabolismDisordersDateStart || inputControllerElements.carbohydrateMetabolismDisordersDateEnd,
    glucoseToleranceTestCheckbox: inputControllerElements.glucoseToleranceTestGH0 || inputControllerElements.glucoseToleranceTestGH60 || inputControllerElements.glucoseToleranceTestGH120,
    profileGHCheckbox: inputControllerElements.profileGHConcentration || inputControllerElements.profileGHHowMuch
  };
};