import styled from 'styled-components';
import { RadioGroup } from '@mui/material';
import {themeMUI} from "../../../../../../../theme/theme";

export const RadioGroupStyled = styled(RadioGroup)`
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  display: flex;

  .MuiRadio-colorError {
    color: ${themeMUI.palette.error.main};

    ~ .MuiTypography-root {
      color: ${themeMUI.palette.error.main};
    }
  }
`

export const Paragraph = styled.p`
    width: 100%;
`