import React, { FC } from 'react';
import { Button, Grid } from '@mui/material';
import { DateInput } from '../../../../../../../shared/ui/form/date/Date';
import {
  ISteroidogenesisInhibitor,
  ISteroidogenesisInhibitorProps
} from './SteroidogenesisInhibitor.types';
import { Input } from '../../../../../../../shared/ui/form/input/Input';
import { SelectCustom } from '../../../../../../../shared/ui/form/select/Select';
import { IOption } from '../../../../../../../shared/ui/form/select/Select.types';
import { GenericIcon } from '../../../../../../../shared/genericIcon/GenericIcon';
import { themeMUI } from '../../../../../../../../theme/theme';
import { useArraySection } from '../../../../useArraySection';
import { useErrorEndDate } from '../../../acromegaly/useErrorEndDate';
import { initialTreatmentBeforeOperationSTeroidogenesisInhibitor } from './SteroidogenesisInhibitor.helper';

export const SteroidogenesisInhibitor: FC<ISteroidogenesisInhibitorProps> = ({
                                                                               id,
                                                                               initial,
                                                                               number,
                                                                               handleAddSteroidogenesisInhibitor,
                                                                               options,
                                                                               editable,
                                                                               handleDeleteSteroidogenesisInhibitor
                                                                             }) => {
  const addOption: IOption[] = initial ? [{ label: initial.type, value: initial.type }] : [];
  const optionsCompleted = [...options, ...addOption];


  const {formik, handleOnClick, disabledAdd} = useArraySection<ISteroidogenesisInhibitor>(initial ?? initialTreatmentBeforeOperationSTeroidogenesisInhibitor, handleAddSteroidogenesisInhibitor)

  const errorDate = useErrorEndDate(formik, formik.values.dateStart, formik.values.dateEnd)

  return <>
    <Grid item xs={12} pr={1} mt={1} mb={editable ? 0: 1} >
      <Grid container display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        {!editable && number && <Grid item xs={0.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          {number}.
        </Grid>}
        <Grid item xs={2.5} pr={1}>
          <SelectCustom
            disabled={!editable}
            error={Boolean(formik.errors.type)}
            name={'type'}
            placeholder={'jaki?'}
            label={'Jaki?'}
            onChange={formik.handleChange}
            value={formik.values.type}
            options={optionsCompleted} />
        </Grid>
        <Grid item xs={2.5} pr={1}>
          <Input
            disabled={!editable}
            name={'dose'}
            type={'text'}
            onChange={formik.handleChange}
            value={formik.values.dose}
            placeholder={'dawka'}
            label={'Dawka'}
          />
        </Grid>
        <Grid item xs={3} pr={1}>
          <DateInput
            disabled={!editable}
            label={'stosowanie - OD'}
            required={true}
            views={['year', 'month']}
            openTo={'year'}
            name={'dateStart'}
            inputFormat={'mm.yyyy'}
            value={formik.values.dateStart}
            onBlur={() => formik.setFieldTouched('dateStart', true)}
            onChange={(e) => formik.setFieldValue('dateStart', e)} />
        </Grid>
        <Grid item xs={3} pr={1}>
          <DateInput
            disabled={!editable}
            label={'stosowanie - DO'}
            required={true}
            views={['year', 'month']}
            openTo={'year'}
            name={'dateEnd'}
            inputFormat={'mm.yyyy'}
            value={formik.values.dateEnd}
            isActive={formik.values.dateEndIsActive}
            setIsActive={() => formik.setFieldValue('dateEndIsActive', !formik.values.dateEndIsActive)}
            isStill={true}
            error={errorDate}
            onBlur={() => formik.setFieldTouched('dateEnd', true)}
            onChange={(e) => formik.setFieldValue('dateEnd', e)} />
        </Grid>
        {handleDeleteSteroidogenesisInhibitor &&
          <Grid item xs={0.5} display={'flex'} flexDirection={'column'} alignItems={'center'} margin={'auto'}>
            <div onClick={() => {
              if (typeof id === 'number') {
                handleDeleteSteroidogenesisInhibitor(id);
              }
            }}>
              <GenericIcon
                iconName='Close'
                style={{
                  color: themeMUI.palette.primary.main,
                  cursor: 'pointer'
                }} />
            </div>
          </Grid>}
        {handleAddSteroidogenesisInhibitor &&
          <Grid item xs={1} display={'flex'} justifyContent={'center'} alignItems={'center'} pl={1}>
            <Button disabled={disabledAdd || errorDate} variant='contained' color={'primary'}
                    onClick={handleOnClick}>ZAPISZ</Button>
          </Grid>}
      </Grid>
    </Grid>

  </>;
};