import { Button, FormControl, FormControlLabel, Grid, Switch } from '@mui/material';
import React from 'react';
import { CardComponent } from '../../../../shared/card/cardOther/CardOther';
import { useChpl } from './useChpl.vm';
import { observer } from 'mobx-react-lite';
import { Add } from '@mui/icons-material';
import { useModal } from '../../../../shared/modal/useModal';
import { ChplModal } from './modal/ChplModal';
import { checkRoles } from '../../../../../utils/checkRoles';
import { RoleFunction } from '../../../../../constants/projectUsers';

export const Chpl = observer(() => {
  const { handleClose, open, handleOpen, typeModal, id } = useModal();
  const {
    articles,
    roleFunction,
    handleDelete,
    handleChange,
    checked,
    handleEdit,
    handleClickByGuest
  } = useChpl(handleClose, handleOpen);

  return (
    <Grid container flexDirection={'row'} pt={1}>
      {checkRoles(roleFunction, RoleFunction.ROLE_ACCEPTING) &&
        <Grid item mt={2} mb={2} alignItems={'flex-end'} ml={'auto'}>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleChange}
                />
              }
              label='Dostęp tylko dla PWZ'
            />
          </FormControl>

          <Button disabled={false} startIcon={<Add />} variant='contained' color={'primary'}
                  onClick={() => handleOpen('add')}>DODAJ</Button>
        </Grid>}
      <Grid container>
        {articles && articles.length > 0 && articles.map(el => (
          <Grid item xs={12} md={6} p={1} key={el.id} minHeight={'298px'}>
            <CardComponent
              isActive={el.categoryIsActive}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              id={el.id}
              type={el.categoryMedicaments?.toLowerCase()}
              image={el.nameImg}
              imgHeight={'200px'}
              date={el.date || ''}
              link={process.env.REACT_APP_IMAGE_URL + `${el.pathFile}${el.namePDF}`}
              title={el.title}
              handleClickByGuest={handleClickByGuest}
            />
          </Grid>
        ))}
      </Grid>
      {open && <ChplModal id={id} typeModal={typeModal} open={open} handleClose={handleClose} />}
    </Grid>
  );
});