import styled from 'styled-components';

export const StyledGrid = styled.div`
  background: url('/assets/img/404layout.png') no-repeat top left;

  & h1, p {
    color: rgba(0, 0, 0, 0.6)
  }
`;

export const Logo = styled.img`
  width: 260px;
  margin-bottom: 48px;
`;