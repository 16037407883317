import { useApiCall } from './useApiCall';
import { useCallback } from 'react';
import { useNotificationsStore } from '../store/hooks';

export const useNotificationsApi = () => {
  const { api } = useApiCall();
  const notificationsStore = useNotificationsStore();

  const getNotifications = useCallback(
    async () => {
      const res = await api.apiInstance.get('notifications/my', true);

      if (res) {
        notificationsStore.setNotifications(res);
      }
    },
    [api.apiInstance, notificationsStore]
  );

  const editStatus = useCallback(
    async (id: number) => {
      const res = await api.apiInstance.put(`notifications/${id}`, {}, true);

      if (res) {
        return !!res;
      }
    },
    [api.apiInstance]
  );

  const getNotificationsCount = useCallback(
    async () => {
      const res = await api.apiInstance.get('notifications/count', false);
      if (res) {
        notificationsStore.setNotificationsCount(res);
      }
    },
    [api.apiInstance, notificationsStore]
  );

  const clearCaseNotification = useCallback(async (type: string, id: number) => {
      const res = await api.apiInstance.put(`notifications/cases/${type}/${id}`, {}, true);
      if (res) {
        await getNotificationsCount();
      }
    },
    [api.apiInstance, getNotificationsCount]
  );

  const clearQANotification = useCallback(async () => {
      const res = await api.apiInstance.put('notifications/status/qa', {}, true);
      if (res) {
        await getNotificationsCount();
      }
    },
    [api.apiInstance, getNotificationsCount]
  );

  return {
    getNotifications,
    editStatus,
    getNotificationsCount,
    clearCaseNotification,
    clearQANotification
  };
};