import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';

import { Stages } from '../components/pages/user/caseDraft/CaseDraft.helper';
import {
  ISecondStageValues as ISecondStageValuesAcromegaly
} from '../components/pages/user/caseDraft/stages/acromegaly/secoundStage/SecondStage.types';
import {
  ISecondStageValues as ISecondStageValuesCushing
} from '../components/pages/user/caseDraft/stages/cushing/secondStage/SecondStage.types';
import {
  IThirdStageValues as IThirdStageValuesAcromegaly
} from '../components/pages/user/caseDraft/stages/acromegaly/thirdStage/ThirdStage.types';
import {
  IThirdStageValues as IThirdStageValuesCushing
} from '../components/pages/user/caseDraft/stages/cushing/thirdStage/ThirdStage.types';
import { IFourthStageValues } from '../components/pages/user/caseDraft/stages/common/fourthStage/FourthStage.types';
import { IZeroStageValues } from '../components/pages/user/caseDraft/stages/common/zeroStage/ZeroStages.types';
import { IFirstStageValues } from '../components/pages/user/caseDraft/stages/common/firstStage/FirstStage.types';

const INIT_STAGE = Stages.ZERO
export type Action = 'previous' | 'current' | 'next'
export class ProgressBarStore {
  isLoaded: boolean = false;
  isValid: boolean = false;
  isDraft: boolean | null = null;

  zeroStage: IZeroStageValues | null = null;
  firstStage: IFirstStageValues | null = null;
  secondStage: ISecondStageValuesCushing | ISecondStageValuesAcromegaly | null = null;
  thirdStage: IThirdStageValuesAcromegaly | IThirdStageValuesCushing | null = null;
  fourthStage: IFourthStageValues | null = null;

  currentStage = INIT_STAGE;
  maxEditStage = INIT_STAGE;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  addStages(
    stage: number,
    zeroStage: IZeroStageValues,
    firstStage: IFirstStageValues | null,
    secondStage : ISecondStageValuesCushing | ISecondStageValuesAcromegaly | null,
    thirdStage: IThirdStageValuesAcromegaly | IThirdStageValuesCushing | null,
    fourthStage: IFourthStageValues | null
  ) {
    this.maxEditStage = stage;
    this.currentStage = stage;

    this.zeroStage = zeroStage;
    this.firstStage = firstStage;
    this.secondStage = secondStage;
    this.thirdStage = thirdStage;
    this.fourthStage = fourthStage;
  }

  setIsDraft(isDraft: boolean){
    this.isDraft = isDraft;
  }

  resetProgressBar() {
    this.currentStage = INIT_STAGE;
    this.maxEditStage = INIT_STAGE;

    this.zeroStage = null;
    this.firstStage = null;
    this.thirdStage = null;
    this.fourthStage = null;

    this.setIsLoaded(false);
  }

  setIsLoaded(isLoaded: boolean) {
    this.isLoaded = isLoaded;
  }

  setZeroStage(zeroStage: IZeroStageValues | null) {
    this.zeroStage = zeroStage;
    this.isValid = true;
    this.currentStage = Stages.FIRST;
    this.maxEditStage = Stages.FIRST;
  }


  setFirstStage(firstStage: IFirstStageValues | null, action: Action = 'next') {
    this.firstStage = firstStage;
    this.isValid = true;
    this.maxEditStage = Stages.SECOND;
    this.setCurrentStage([Stages.ZERO, Stages.FIRST, Stages.SECOND], action);
  }

  setSecondStage(secondStage: ISecondStageValuesCushing | ISecondStageValuesAcromegaly | null, action: Action = 'next') {
    this.secondStage = secondStage;
    this.isValid = true;
    this.maxEditStage = Stages.THIRD;
    this.setCurrentStage([Stages.FIRST, Stages.SECOND, Stages.THIRD], action);
  }

  setThirdStage(thirdStage: IThirdStageValuesAcromegaly | IThirdStageValuesCushing | null, action: Action = 'next') {
    this.thirdStage = thirdStage;
    this.isValid = true;
    this.maxEditStage = Stages.FOURTH;
    this.setCurrentStage([Stages.SECOND, Stages.THIRD, Stages.FOURTH], action);
  }

  setFourthStage(fourthStage: IFourthStageValues | null, action: Action = 'next') {
    this.fourthStage = fourthStage;
    this.isValid = true;
    this.maxEditStage = Stages.FOURTH;
    this.setCurrentStage([Stages.THIRD, Stages.FOURTH, Stages.FOURTH], action);
  }

  setCurrentStage([previous, current, next] : [Stages, Stages, Stages], action: Action = 'next') {
    this.currentStage = action === 'next' ? next : (action === 'current' ? current : previous);
  }

  clearStore(){
    this.isLoaded= false;
    this.isValid = false;

    this.zeroStage = null;
    this.firstStage = null;
    this.secondStage = null;
    this.thirdStage = null;
    this.fourthStage = null;

    this.currentStage = INIT_STAGE;
    this.maxEditStage = INIT_STAGE;

    this.isDraft = null;
  }
}