import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { ICategory, ILibraryData } from '../interfaces/library/library';

export class LibraryStore {
  categories: any[] = [];
  medicamentCategories: any[] = [];
  articles: ILibraryData[] = [];
  selectedCategory: string = 'chpl';
  selectedCategoryObj: ICategory | null = null;
  osilodrostadCurrentPage: number = 1;
  pasyreotydCurrentPage: number = 1;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setCategories(categories: any[]) {
    this.categories = categories;
  }

  setMedicamentCategories(medicamentCategories: any[]) {
    this.medicamentCategories = medicamentCategories;
  }

  setSelectedCategory(selectedCategory: string) {
    this.selectedCategory = selectedCategory;
    this.selectedCategoryObj = this.categories.find(el => el.label === selectedCategory);
  }

  get selectedCategoryObject() {
    if (!this.selectedCategoryObj) {
      return this.categories[0];
    } else {
      return this.selectedCategoryObj;
    }
  }

  setArticles(articles: ILibraryData[]) {
    this.articles = articles;
  }

  get isturisaArticles() {
    return this.articles?.filter(el => el.categoryMedicaments === 'Isturisa');
  }

  get isturisaCheckbox() {
    return this.medicamentCategories?.find(el => el.title === 'Isturisa');
  }

  get signiforArticles() {
    return this.articles?.filter(el => el.categoryMedicaments === 'Signifor');
  }

  get signiforCheckbox() {
    return this.medicamentCategories?.find(el => el.title === 'Signifor');
  }

  get pasyreotydArticles() {
    return this.articles?.filter(el => el.categoryMedicaments === 'Pasyreotyd');
  }

  get pasyreotydCheckbox() {
    return this.medicamentCategories?.find(el => el.title === 'Pasyreotyd');
  }

  get osilodrostadArticles() {
    return this.articles?.filter(el => el.categoryMedicaments === 'Osilodrostad');
  }

  get osilodrostadCheckbox() {
    return this.medicamentCategories?.find(el => el.title === 'Osilodrostad');
  }

  get cushingCheckbox() {
    return this.medicamentCategories?.find(el => el.title === 'Cushing');
  }

  get acromegalyCheckbox() {
    return this.medicamentCategories?.find(el => el.title === 'Akromegalia');
  }

  get linkArticles() {
    return this.articles?.filter(el => el.category === 'linki').map(el => ({
      linkDate: el.dateWithHour || '' as string,
      title: el.title as string,
      link: el.linkPDF as string,
      id: el.id as number,
      isActive: el.categoryIsActive as boolean
    }));
  };

  clearArticles() {
    this.articles = [];
  }

  setOsilodrostadCurrentPage = (value: number) => {
    this.osilodrostadCurrentPage = value;
  };

  setPasyreotydCurrentPage = (value: number) => {
    this.pasyreotydCurrentPage = value;
  };
}