import { useApiCall } from './useApiCall';
import { useCallback } from 'react';
import { useUsersStore } from '../store/hooks';
import { UserType } from '../interfaces/users/usersInfo';

export const useUsersApi = (handleClose?: () => void) => {
  const { api } = useApiCall();
  const usersStore = useUsersStore();
  const { users } = usersStore;

  const getRoles = useCallback(async () => {
    const res = await api.apiInstance.get('roles', true);
    if (res) {
      usersStore.setRoles(res);
    }
  }, [api.apiInstance, usersStore]);

  const addUser = useCallback(async (data: { role: number, email: string, typeCase: string }) => {
    const res = await api.apiInstance.post('users', data);
    if (res) {
      if (users) {
        usersStore.setUsers([...users, res.data]);
        handleClose && handleClose();
      }
    }
  }, [api.apiInstance, usersStore, users, handleClose]);

  const getUsers = useCallback(async (type: UserType) => {
    const res = await api.apiInstance.get(type === 'users' ? 'users' : 'users/admin', true);
    if (res) {
      usersStore.setUsers(res);
    }
  }, [api.apiInstance, usersStore]);

  const deleteUser = useCallback(async (id: number) => {
    const res = await api.apiInstance.delete(`users/${id}`, true);
    if (res) {
      if (users) {
        const newUsers = users?.filter(el => el.id !== id);
        usersStore.setUsers(newUsers);
        handleClose && handleClose();
      }
    }
  }, [api.apiInstance, usersStore, users, handleClose]);

  const activateEmail = useCallback(async (email: string) => {
    const res = await api.apiInstance.post('users/send-activate', { email }, true);
    if (res) {
      return !!res;
    }
  }, [api.apiInstance]);

  const getUserProfile = useCallback(async () => {
    const res = await api.apiInstance.get('users/profile', true);
    if (res) {
      usersStore.setProfile(res);
    }
  }, [api.apiInstance, usersStore]);

  const editUserProfile = useCallback(async (data: any) => {
    const res = await api.apiInstance.put('users/profile/my', data, true);
    if (res) {
      handleClose && handleClose();
    } else {
      usersStore.setPasswordErrorMessage('Wpisano nieprawidłowe hasło.');
    }
  }, [api.apiInstance, usersStore, handleClose]);

  return {
    getRoles,
    addUser,
    getUsers,
    deleteUser,
    activateEmail,
    getUserProfile,
    editUserProfile
  };
};
