import React from 'react';

import { observer } from 'mobx-react-lite';

import { useRootStore } from '../store/RootStateContext';
import { AppLayer } from './shared/appLayer/AppLayer';

export const App = observer(() => {
  const rootStore = useRootStore();

  const isStoreReady = !!rootStore;

  return isStoreReady ? <AppLayer /> : null;
});
