import { Link } from './link.types';
import { AcceptAdminLink } from '../../../../../utils';
import { Library } from '../../../../pages/library/Library';
import { generalLinks } from './generalLinks';
import Home from '../../../../pages/home/Home';
import { MyCases } from '../../../../pages/myCases/MyCases';
import { Notifications } from '../../../../pages/notifications/Notifications';
import { CaseView } from '../../../../pages/caseView/CaseView';
import { HomeRedirect } from '../../../../pages/homeRedirect/HomeRedirect';
import { QUPages } from '../../../../pages/qaPage/QAPage';

export const onlyAcceptingAdminLinks: Link[] = [
  {
    path: AcceptAdminLink.REDIRECT_HOME,
    element: <HomeRedirect />
  },
  {
    path: AcceptAdminLink.HOME,
    element: <Home />
  },
  {
    path: AcceptAdminLink.CASES,
    element: <MyCases />
  },
  {
    path: AcceptAdminLink.CASE_VIEW,
    element: <CaseView />
  },
  {
    path: AcceptAdminLink.NOTIFICATIONS,
    element: <Notifications />
  },
  {
    path: AcceptAdminLink.QA,
    element: <QUPages />
  },
  {
    path: AcceptAdminLink.CASE_VIEW,
    element: <CaseView />
  },
  {
    path: AcceptAdminLink.LIBRARY,
    element: <Library />
  }
];

export const acceptingAdminLinks = generalLinks.concat(onlyAcceptingAdminLinks);