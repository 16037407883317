import { Button, FormControl, FormControlLabel, Grid, Link, Radio, Typography } from '@mui/material';
import { RadioGroupStyled } from '../../../../../shared/ui/form/radioGroupStyled/RadioGroupStyled.styled';
import { Input } from '../../../../../shared/ui/form/input/Input';
import { FileUploadLayout } from '../../../../../shared/fileUploadLayer/FileUploadLayer';
import { ModalRoot } from '../../../../../shared/modal/ModalRoot';
import React from 'react';
import { useChplModal } from './useChplModal.vm';
import { IModalType } from '../../../../../../interfaces/library/library';
import { titleType } from '../../../libraryData';
import { observer } from 'mobx-react-lite';
import { Cap } from '../../../Library.styled';
import { themeMUI } from '../../../../../../theme/theme';

interface IProps {
  open: boolean,
  handleClose: () => void;
  typeModal: string | null
  id: number | null
}

export const ChplModal: React.FC<IProps> = observer(({ typeModal, id, open, handleClose }) => {
  const {
    checkboxes,
    formik,
    handleOnClick,
    setFileHandler,
    pdfFile,
    pdfLink,
    pdfFileError,
    handleDeleteItem,
    titleErrorMessage
  } = useChplModal(handleClose, id);

  return (
    <ModalRoot open={open} handleClose={handleClose} title={titleType[typeModal as keyof IModalType]}
    >
      <Grid container pt={2} pb={2} pl={3} pr={3} flexDirection={'column'} width={'100%'}>
        {typeModal !== 'delete' && <>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroupStyled
                row
                name='isturisaSignifor'>
                {checkboxes.map(el => (
                  <FormControlLabel
                    checked={el.id === +formik.values.isturisaSignifor}
                    onChange={formik.handleChange}
                    value={el.id}
                    key={el.id}
                    control={<Radio
                      color={formik.touched.isturisaSignifor && Boolean(formik.errors.isturisaSignifor) ? 'error' : 'primary'} />}
                    label={el.title} />
                ))}
              </RadioGroupStyled>
            </FormControl>
          </Grid>
          <Grid item xs={12} pt={1} pb={1}>
            <Input rows={2} multiline fullWidth={true} name={'title'} label={'*Tytuł'} type={'text'}
                   onChange={formik.handleChange} value={formik.values.title}
                   error={formik.touched.title && Boolean(formik.errors.title)} />
          </Grid>
          {titleErrorMessage && <Grid item xs={12}>
            <Typography variant={'caption'}
                        style={{ color: themeMUI.palette.error.main }}>{titleErrorMessage}</Typography>
          </Grid>}
          <Grid item xs={12} alignSelf='stretch' height='152px' pt={1} sx={{ position: 'relative' }}>
            <>
              <FileUploadLayout
                htmlFor={'chlp'}
                setFileHandler={setFileHandler}
                maxFileSize={8}
                extensions={['pdf']}
                fileType={'pdf'}
              />
              {pdfFileError && <p style={{ color: 'red', fontSize: '12px' }}>Wybierz plik</p>}</>
            {typeModal === 'edit' && <Cap />}
          </Grid>
          <Grid item xs={12} mb={1} pt={2} sx={{ wordBreak: 'break-all' }}>
            {pdfLink &&
              <Link href={pdfLink ?? undefined} target={'_blank'} rel='noreferrer'>{pdfFile?.name || pdfLink}</Link>}
          </Grid>
        </>}
        {typeModal === 'delete' &&
          <Typography variant={'body1'}>Czy jesteś pewien/pewna że chcesz usunąć plik?</Typography>}
        <Grid container width={'693px'} justifyContent={'flex-end'} mt={2}>
          <Grid item>
            <Button disabled={false} variant='outlined' color={'primary'}
                    onClick={handleClose}>Anuluj</Button>
          </Grid>
          <Grid item ml={2}>
            <Button disabled={false} variant='contained' color={'primary'}
                    onClick={typeModal === 'delete' ? handleDeleteItem : handleOnClick}>{typeModal === 'delete' ? 'Usuń' : 'Zapisz'}</Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalRoot>
  );
});