import { AuthController } from '../../shared/appLayer/authController/AuthController';
import React from 'react';
import { Layer } from '../../shared/layer/Layer';
import libraryBcg from '../../../assets/img/libraryBackground.png';
import { SelectCustom } from '../../shared/ui/form/select/Select';
import { AlertColor, Grid, Pagination } from '@mui/material';
import { useNotifications } from './useNotifications';
import { ListItem } from './listItem/ListItem';
import { observer } from 'mobx-react-lite';

export const Notifications = observer(() => {
  const {
    currentPage,
    totalPage,
    handlePageChange,
    items,
    options,
    status,
    handleSelect,
    isLoaded,
    generateLink
  } = useNotifications();

  return (
    <AuthController>
      <Layer image={libraryBcg} isSearch={false} visibility={false}>
        <Grid container display={'flex'} mt={2}>
          <SelectCustom style={{ width: '180px' }} label={'Typ'} name={'type'} value={status!} options={options}
                        onChange={handleSelect} />
          <Grid container flexDirection={'column'} display={'flex'} flexWrap={'nowrap'}>
            {isLoaded && items && items.map(el => (
              <Grid item xs={12} mt={1} mb={1} key={el.id}>
                <ListItem
                  status={el.status}
                  id={el.id}
                  link={generateLink(el.typeTitleSort, el.idCaseCushingTitle ? 'cushing' : 'acromegaly', el.idCaseCushing || el.idCaseAcromegaly, el.typeTitleSortQuestionType, el.typeTitleSortQuestionTypeQA)}
                  caseTitle={el.idCaseCushingTitle || el.idCaseAcromegalyTitle} title={el.title}
                  type={el.type?.toLowerCase() as AlertColor}
                  data={el.dateWithHour}
                  />
              </Grid>
            ))}
          </Grid>
          {!!totalPage && <Grid item xs={12} display={'flex'} justifyContent={'right'}>
            <Pagination
              color={'primary'}
              size='small'
              count={totalPage}
              page={currentPage}
              onChange={(e, value: number) => {
                handlePageChange(value);
              }} showFirstButton showLastButton /> </Grid>}
        </Grid>
      </Layer>
    </AuthController>
  );
});