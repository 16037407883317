export const specializationOptions = [
  {
    id: 1,
    value: 'Endokrynolog',
    label: 'Endokrynolog'
  },
  {
    id: 2,
    value: 'Choroby wewnętrzne',
    label: 'Choroby wewnętrzne'
  },
  {
    id: 3,
    value: 'Onkologia',
    label: 'Onkologia'
  },
  {
    id: 4,
    value: 'W trakcie specjalizacji',
    label: 'W trakcie specjalizacji'
  },
  {
    id: 5,
    value: 'Inne',
    label: 'Inne'
  }
];