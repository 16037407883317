import { useEffect } from 'react';
import { useAuthStore, useLibraryStore } from '../../../../../store/hooks';
import { useLibraryApi } from '../../../../../apiHooks';
import { useSwitchActive } from '../../useSwitchActive.vm';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GeneralLink, generateLink } from '../../../../../utils';
import { useCookies } from 'react-cookie';
import { usePagination } from '../../../../../hooks/usePagination';

export const useMaterials = (handleOpen: (type: string, id?: number) => void) => {
  const libraryStore = useLibraryStore();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { libType } = params;
  const {
    categories,
    isturisaArticles,
    signiforArticles
  } = libraryStore;
  const { getArticles } = useLibraryApi();
  const { roleFunction } = useAuthStore();
  const { checked, handleChange } = useSwitchActive();
  const [, setCookie] = useCookies(['redirectLink']);
  const id = categories && categories.find(el => el.label === libType)?.id;
  useEffect(() => {
    return () => libraryStore.clearArticles();
  }, [libraryStore]);
  useEffect(() => {
    if (libType && id) {
      void getArticles(id);
    }

  }, [getArticles, libType, libraryStore, id]);

  const handleDelete = (id: number) => {
    handleOpen('delete', id);
  };
  const handleEdit = (id: number) => {
    handleOpen('edit', id);
  };

  const handleClickByGuest = () => {
    const link = generateLink(roleFunction, GeneralLink.LOGIN_USER);
    setCookie('redirectLink', location.pathname);
    navigate(link);
  };

  const {
    currentPage: isturisaCurrentPage,
    totalPage: isturisaPageCount,
    handlePageChange: isturisaHandlePageChange,
    items: isturisaArticlesDrawArray
  } = usePagination(isturisaArticles, 9);

  const {
    currentPage: signiforCurrentPage,
    totalPage: signiforPageCount,
    handlePageChange: signiforHandlePageChange,
    items: signiforArticlesDrawArray
  } = usePagination(signiforArticles, 9);
  return {
    handleClickByGuest,
    isturisaArticles,
    signiforArticles,
    isturisaPageCount,
    signiforPageCount,
    isturisaCurrentPage,
    signiforCurrentPage,
    isturisaHandlePageChange,
    signiforHandlePageChange,
    isturisaArticlesDrawArray,
    signiforArticlesDrawArray,
    roleFunction,
    handleDelete,
    handleChange,
    handleEdit,
    checked
  };
};