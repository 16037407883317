import React, { FC } from 'react';

import {  IModalAddQuestionProps } from './ModalAddQuestion.types';
import { ModalAddQuestionType } from './modalAddQuestionType/ModalAddQuestionType';
import { ModalAddQuestionSimple } from './modalAddQuestionSimple/ModalAddQuestionSimple';

export const ModalAddQuestion: FC<IModalAddQuestionProps> = ({ handleClose, handleConfirmation, values, type }) => {
  if(type === 'qa' || type === 'forum'){
    return <ModalAddQuestionType handleConfirmation={handleConfirmation} values={values} type={type} handleClose={handleClose} />
  }

  return <ModalAddQuestionSimple handleConfirmation={handleConfirmation} type={type} handleClose={handleClose} />
};