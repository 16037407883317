import styled from 'styled-components';
import { themeMUI } from '../../../theme/theme';

export const MenuContainer = styled.div`
  width: 100%;
  height: 40px;

  display: flex;


  & a {
    display: flex;
    padding: 6px 8px;
    line-height: 28px;
    text-decoration: none;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    gap: 8px;
    color: ${themeMUI.palette.text.primary};
  }

  & a.active {
    border-bottom: 2px solid ${themeMUI.palette.primary.main};
  }
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: ${themeMUI.palette.primary.main};
  border-radius: 50%;
  font-size: 12px;
  color: white;
  font-weight: 500;
  padding: 0 7px;
`;

export const NavContainer = styled.div`
  display: flex;

`;