import { FC } from 'react';

import { AuthLayout } from '../../../../shared/auth/layout/AuthLayout';
import { ILoginLayoutProps } from './LoginLayout.interfaces';
import { ExtraLink, HeaderBox } from './LoginLayout.styled';

export const LoginLayout: FC<ILoginLayoutProps> = ({ children, header , extraLink }) => {
  return (
    <AuthLayout>
      {header && <HeaderBox>
        {header}
      </HeaderBox>}
      {children}
      {extraLink && <ExtraLink to={extraLink.link}>{extraLink.text}</ExtraLink>}
    </AuthLayout>
  );
};