import { useCallback, useState } from 'react';

export function useInputController<T extends object>(inputControllerInitialElements: T) {
  const [inputControllerElements, setInputControllerElements] = useState<T>(inputControllerInitialElements);

  const setActive = useCallback((name: (keyof T) | (keyof T)[], isActive: boolean) => {
    setInputControllerElements(prevElements => {
      const updatedElements = { ...prevElements };

      if (Array.isArray(name)) {
        name.forEach(nameElement => {
          if (updatedElements[nameElement] !== isActive) {
            updatedElements[nameElement] = isActive as T[keyof T];
          }
        });
      } else if (typeof updatedElements[name] === 'boolean' && updatedElements[name] !== isActive) {
        updatedElements[name] = isActive as T[keyof T];
      }

      return updatedElements;
    });

    return isActive;
  }, []);

  const setActiveBroadcast = (name: (keyof T) | (keyof T) [], broadcastFields: (keyof T)[], active?: boolean) => {
    const updatedElements = { ...inputControllerElements };

    if (Array.isArray(name)) {

      let active = false;

      broadcastFields && broadcastFields.forEach(nameElement => {
        updatedElements[nameElement] = false as T[keyof T];
      });

      name.forEach(nameElement => {
        updatedElements[nameElement] = activatedBroadcast(nameElement);
        active = Boolean(updatedElements[nameElement]);
      });

      setInputControllerElements(updatedElements);

      return active;
    } else if (typeof updatedElements[name] === 'boolean') {
      broadcastFields && broadcastFields.forEach(nameElement => {
        updatedElements[nameElement] = false as T[keyof T];
      });

      updatedElements[name] = typeof active !== 'undefined' ? !active as T[keyof T] : activatedBroadcast(name);

      setInputControllerElements(updatedElements);
      return Boolean(updatedElements[name]);
    }
    return false;
  };


  const activatedBroadcast = (name: keyof T) => {
    const updatedElements = { ...inputControllerElements };
    const isActive = Boolean(updatedElements[name]);
    return isActive ? isActive as T[keyof T] : !isActive as T[keyof T];
  };

  return {
    inputControllerElements,
    setActive,
    setActiveBroadcast
  };
};