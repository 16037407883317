import { FormikValues } from 'formik/dist/types';

import { IFormField } from '../interfaces/common/form';
import { Input } from '../components/shared/ui/form/input/Input';
import { IRegisterForm } from '../components/pages/auth/register/Register.interfaces';
import { SelectCustom } from '../components/shared/ui/form/select/Select';
import { specializationOptions } from '../components/shared/auth/specialization.helper';
import React from 'react';

export const generateInputs = <T extends FormikValues>(values: IFormField[], formik: T) => {
  return <>
    {
      values.map(element => (
        element.name === 'specialization' ?
          <SelectCustom
            key={element.name}
            name={element.name}
            fullWidth={true}
            required={element.required}
            label={element.label}
            placeholder={element.placeholder}
            onChange={formik.handleChange}
            error={formik.touched[element.name as keyof IRegisterForm] && Boolean(formik.errors[element.name as keyof IRegisterForm])}
            value={formik.values[element.name as keyof IRegisterForm] || ''}
            options={specializationOptions} /> :
          <Input
            autoComplete='new-password'
            onBlur={formik.handleBlur}
            key={element.name}
            readOnly={element.readOnly}
            type={element.type}
            fullWidth={true}
            required={element.required}
            name={element.name}
            label={element.label}
            placeholder={element.placeholder}
            onChange={formik.handleChange}
            disabled={element.disabled}
            errorMessage={formik.errors[element.name as keyof IRegisterForm]}
            error={formik.touched[element.name as keyof IRegisterForm] && Boolean(formik.errors[element.name as keyof IRegisterForm])}
            value={formik.values[element.name as keyof IRegisterForm] || ''} />

      ))

    }
  </>;
};