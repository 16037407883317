import { useApiCall } from './useApiCall';
import { useCallback } from 'react';
import { useCaseSListStore } from '../store/hooks/useCasesListStore';
import { ICardItem } from '../components/shared/caseItem/CardItem.types';
import { DiseaseType } from '../interfaces/common/common';
import { useNavigate } from 'react-router-dom';

export const useCasesListApi = (handleClose?: () => void) => {
  const { api } = useApiCall();
  const casesListStore = useCaseSListStore();
  const navigate = useNavigate();
  const getPublishedCases = useCallback(async (loader: boolean, type: DiseaseType) => {
      const res: ICardItem[] = await api.apiInstance.get(`medical-case/${type}/all/published`, loader);
      if (res) {
        if(type=== 'acromegaly'){
          casesListStore.setPublishedCasesAcromegaly(res);
        }
        else{
          casesListStore.setPublishedCasesCushing(res);
        }

      }
    },
    [casesListStore, api.apiInstance]
  );

  const getMyCases = useCallback(async (loader: boolean, type: DiseaseType) => {
      const res: ICardItem[] = await api.apiInstance.get(`medical-case/${type}/my/cases`, loader);
      if (res) {
        if(type=== 'acromegaly'){
          casesListStore.setMyCasesAcromegaly(res);
        }
        else{
          casesListStore.setMyCasesCushing(res);
        }

      }
    },
    [casesListStore, api.apiInstance]
  );

  const getAdminCases = useCallback(async (loader: boolean, type: DiseaseType) => {
      const res: ICardItem[] = await api.apiInstance.get(`medical-case/${type}/all/admin`, loader);

      if (res) {
        if(type=== 'acromegaly'){
          casesListStore.setMyCasesAcromegaly(res);
        }
        else{
          casesListStore.setMyCasesCushing(res);
        }

      }
    },
    [casesListStore, api.apiInstance]
  );

  const changeStatus = useCallback(async (type: DiseaseType, id: number, status: string, navigateNeeded?: boolean) => {
    const res = await api.apiInstance.put(`medical-case/${type}/status/${id}`, { status }, true);
    if (res) {
      if (navigateNeeded) {
        navigate(`/cases/${type}`);
        return;
      }
      if (type === 'acromegaly' && casesListStore.myCasesAcromegaly) {
        const editedCases: ICardItem[] = casesListStore.myCasesAcromegaly.map(el => el.id === id ? res.data : el);
        casesListStore.setMyCasesAcromegaly(editedCases);
        handleClose && handleClose();
      }
      else if(type === 'cushing' && casesListStore.myCasesCushing) {
        const editedCases: ICardItem[] = casesListStore.myCasesCushing.map(el => el.id === id ? res.data : el);
        casesListStore.setMyCasesCushing(editedCases);
        handleClose && handleClose();
      }

    }
  }, [api.apiInstance, casesListStore, handleClose, navigate]);

  const deleteDraft = useCallback(async (type: DiseaseType, id: number) => {
      const res = await api.apiInstance.delete(`medical-case/${type}/${id}`, true);
      if (res) {
        if (type === 'acromegaly' && casesListStore.myCasesAcromegaly) {
          const newList = casesListStore.myCasesAcromegaly.filter(el => el.id !== id);
          casesListStore.setMyCasesAcromegaly(newList);
          handleClose && handleClose();
        }
        else if(type === 'cushing' && casesListStore.myCasesCushing) {
          const newList = casesListStore.myCasesCushing.filter(el => el.id !== id);
          casesListStore.setMyCasesCushing(newList);
          handleClose && handleClose();
        }
      }
    },
    [casesListStore, api.apiInstance, handleClose]);


  return {
    getPublishedCases,
    getMyCases,
    getAdminCases,
    changeStatus,
    deleteDraft
  };
};