import { useFormik } from 'formik';

import { IFirstStageValues, IInputControllerFirstStage } from './FirstStage.types';
import {
  checkboxInputControllerConfig,
  convertInputControllerFirstStage,
  initialFirstStage,
  validationFirstStageSchema
} from './FirstStage.helper';
import { useProgressBarStore } from '../../../../../../../store/hooks/useProgressBarStore';
import { useEffect, useState } from 'react';
import { useInputController } from '../../../../../../shared/ui/form/inputController/useInputController';
import { useDraftCaseApi } from '../../../../../../../apiHooks';
import { useParams } from 'react-router-dom';
import { DiseaseType } from '../../../../../../../interfaces/common/common';
import { Action } from '../../../../../../../store/progressBarStore';

export const useFirstStageVm = () => {
  const { id, type } = useParams();
  const { draftCaseFirstStage, draftCaseFirstStageSaved } = useDraftCaseApi();
  const [activeFieldset, setActiveFieldset] = useState('');
  const progressBarStore = useProgressBarStore();

  const {
    inputControllerElements,
    setActive
  } = useInputController<IInputControllerFirstStage>(progressBarStore.firstStage ? convertInputControllerFirstStage(progressBarStore.firstStage) : checkboxInputControllerConfig);

  const formik = useFormik<IFirstStageValues>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    initialValues: progressBarStore.firstStage ?? initialFirstStage,
    validationSchema: validationFirstStageSchema,
    onSubmit: async (values) => {
      await draftCaseFirstStage(formik.values, Number(id), type as DiseaseType);
      progressBarStore.setFirstStage(values);
    }
  });

  const { setFieldValue } = formik;

  useEffect(() => {
    if (Number(formik.values.weight) > 0 && Number(formik.values.height) > 0) {
      const countBMI = Math.round(Number(formik.values.weight) / Math.pow(formik.values.height / 100, 2));
      const bmiValue = Number.isNaN(countBMI) ? '' : countBMI;
      const bmi = (Number.isNaN(bmiValue) && bmiValue < 0) ? '' : bmiValue;
      setFieldValue('bmi', bmi);
    } else {
      setFieldValue('bmi', -1);
    }
  }, [formik.values.height, formik.values.weight, setFieldValue]);
  const { metabolicDiseasesDiabetes } = inputControllerElements;

  useEffect(() => {
    if (!metabolicDiseasesDiabetes) {
      setFieldValue('metabolicDiseasesDiabetesMedicines', []);
      setFieldValue('metabolicDiseasesAnotherMedicines', '');
      setFieldValue('metabolicDiseasesDiabetesMedicinesAnother', '');
      setActive(['metabolicDiseasesAnotherMedicines', 'metabolicDiseasesDiabetesMedicinesAnother'], false);
    }
  }, [metabolicDiseasesDiabetes, setFieldValue, setActive]);

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      setActiveFieldset('valid-fieldset-1');
    }

  }, [formik.errors]);

  const handleChangeArray = (name: (keyof IFirstStageValues), value: string) => {
    let newValues = [...formik.values[name] as string[]];
    if (newValues.find(element => element === value)) {
      newValues = newValues.filter(element => element !== value);
    } else {
      newValues.push(value);
    }

    formik.setFieldValue(name, newValues);
  };

  const handleSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      formik.handleSubmit();
    }
  };

  const handleDraftSave = async (action: Action) => {
    await draftCaseFirstStageSaved(formik.values, Number(id), type as DiseaseType);
    progressBarStore.setFirstStage(formik.values, action);
  };

  const handleResetFieldset = () => {
    setActiveFieldset('');
  };

  return {
    type: type as DiseaseType,
    activeFieldset,
    handleChangeArray,
    setActiveFieldset,
    inputControllerElements,
    setActive,
    formik,
    handleSave,
    handleDraftSave,
    handleResetFieldset
  };
};