import styled from 'styled-components';
import { Grid } from '@mui/material';

export const ButtonsActionsContainer = styled(Grid)`
  padding-top:20px;
  padding-bottom:10px;
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 100;
`