import { SortDirection } from '../interfaces/common/sort';
import { DateType } from '../components/pages/user/caseDraft/CaseDraft.types';

export function customSort<T>(items: T[] | null, keys: (keyof T)[], direction: SortDirection = 'ASC') {
  const itemsAll = items ? [...items] : [];

  return itemsAll.sort((a, b) => {
    for (const key of keys) {
      const aValue = a[key];
      const bValue = b[key];

      if (aValue === null && bValue !== null) return direction === 'ASC' ? -1 : 1;
      if (aValue !== null && bValue === null) return direction === 'ASC' ? 1 : -1;

      if (aValue! < bValue!) return direction === 'ASC' ? -1 : 1;
      if (aValue! > bValue!) return direction === 'ASC' ? 1 : -1;
    }

    return 0;
  });

}

export const getDate = (date: DateType) => {
  return date === null ? 'nadal' : date.slice(0, 7);
}