import { IOption } from '../../../shared/ui/form/select/Select.types';

export const optionsAddQuestionForum : IOption[] = [
  {
    value: 'AUTHOR',
    label: 'Pytanie do autora'
  },
  {
    value: 'EXPERT',
    label: 'Pytanie do administratora merytorycznego',
  }
]