import { useAuthStore, useCaseStore } from '../../../../store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useCaseApi } from '../../../../apiHooks';
import { useEffect, useState } from 'react';
import { useSort } from '../../../../hooks/useSort';
import { generateLink, PublishUserLink } from '../../../../utils';
import { RoleFunction } from '../../../../constants/projectUsers';
import { DiseaseType } from '../../../../interfaces/common/common';

export const useComments = () => {
  const navigate = useNavigate();
  const {comments, status} = useCaseStore();
  const {roleFunction} = useAuthStore();
  const {type, id}  = useParams()
  const {getComments} = useCaseApi();

  const [idComment, setIdComment] = useState<number | null>(null);

  const {
    items
  } = useSort(comments, ["dateWithHour"], 'DESC');

  const isCommentNull = comments === null;

  useEffect(() => {
    void getComments(String(type), Number(id), isCommentNull);
  }, [type, id, getComments, isCommentNull]);


  useEffect(()=> {
    if(items?.length> 0){
      setIdComment(items[0].id)
    }
  }, [items])

  const handleEdit = () => {
    navigate(generateLink(RoleFunction.ROLE_PUBLISHING, PublishUserLink.CASE_EDIT, [[':type', type as DiseaseType], [':id', Number(id)]]));
  }

  const emptyMessage = (comments !== null && items.length === 0) ? 'Brak uwag do opisu przypadku medycznego.' : null;
  return {
    items, idComment, type, roleFunction, handleEdit, status, emptyMessage
  }
}