import { generalLinks } from './generalLinks';

import { ExpertAdminLink } from '../../../../../utils';
import { Link } from './link.types';
import Home from '../../../../pages/home/Home';
import { MyCases } from '../../../../pages/myCases/MyCases';
import { Notifications } from '../../../../pages/notifications/Notifications';
import { Library } from '../../../../pages/library/Library';
import { HomeRedirect } from '../../../../pages/homeRedirect/HomeRedirect';
import { QUPages } from '../../../../pages/qaPage/QAPage';
import { CaseView } from '../../../../pages/caseView/CaseView';

export const onlyExpertAdminLinks: Link[] = [
  {
    path: ExpertAdminLink.REDIRECT_HOME,
    element: <HomeRedirect />
  },
  {
    path: ExpertAdminLink.HOME,
    element: <Home />
  },
  {
    path: ExpertAdminLink.CASES,
    element: <MyCases />
  },
  {
    path: ExpertAdminLink.NOTIFICATIONS,
    element: <Notifications />
  },
  {
    path: ExpertAdminLink.QA,
    element: <QUPages />
  },
  {
    path: ExpertAdminLink.CASE_VIEW,
    element: <CaseView />
  },
  {
    path: ExpertAdminLink.LIBRARY,
    element: <Library />
  }
];

export const expertAdminLinks = generalLinks.concat(onlyExpertAdminLinks);