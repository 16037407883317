import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { DateType } from './CaseDraft.types';
import { DATE_IS_STILL } from '../../../shared/ui/form/date/Date.helper';

export type AddFunction<T> = (element: T) => void;

export interface IDateEnd{
  dateEndIsActive: boolean;
  dateEnd: DateType;
  dateStart: DateType;
}
export const useArraySection = <T extends IDateEnd>(initial: T, handleAdd?: AddFunction<T>) => {
  const [disabledAdd, setDisabledAdd] = useState(true);

  const formik = useFormik<T>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: initial,
    onSubmit: async (values) => {}
  })

  const {setFieldValue} = formik;


  useEffect(()=> {
    setDisabledAdd(Object.values(formik.values).some(value => {
      if(formik.values.dateStart === null || (formik.values.dateEnd === null && !formik.values.dateEndIsActive)){
        return true;
      }
      else {
         return  value === ""
      }
    }));
  }, [formik.values])

  const handleOnClick = () => {
    if(handleAdd){
      const newValues  = {...formik.values} as T;

      if(newValues.dateEnd === DATE_IS_STILL){
        setFieldValue('dateEnd', null)
        setFieldValue('dateEndIsActive', true)
        newValues.dateEndIsActive = true;
      }

      handleAdd(newValues);
      formik.resetForm();
    }
  }

  return {formik, handleOnClick, disabledAdd }
}