import { useAuthStore } from '../../../../store/hooks';
import { useAccordion } from '../../../shared/accordion/useAccordion';
import { useModal } from '../../../shared/modal/useModal';
import { QUPageModal, QUPageTab } from '../QUPage.types';
import { usePagination } from '../../../../hooks/usePagination';
import { IAccordionElement } from '../../../../interfaces/quPage/quPage';
import React, { useState } from 'react';
import { ModalType } from '../../../shared/modal/ModalRoot.types';
import { ModalAddQuestion } from '../../../shared/modal/common/modalAddQuestion/ModalAddQuestion';
import {
  ModalAddQuestionConfirmation
} from '../../../shared/modal/common/modalAddQuestionConfirmation/ModalAddQuestionConfirmation';
import { getModalSelectedByList } from '../../../shared/modal/ModalRoot.helper';
import { optionsAddQuestionQA } from '../QAPage.helper';
import { useQAElementsApi } from '../../../../apiHooks';

export const useQAElementsVm = (type: QUPageTab, elements: IAccordionElement[], reset?: () => void) => {
  const [answer, setAnswer] = useState('');

  const { postQAAnswer, putQAAnswerVisibility, putQAAnswerCategory } = useQAElementsApi();
  const { roleFunction } = useAuthStore();
  const { expandedIds, handleChange } = useAccordion(true);

  const {
    open,
    handleClose,
    handleOpen,
    typeModal
  } = useModal<QUPageModal>();

  const {
    items,
    pageSize,
    currentPage,
    totalPage,
    handlePageChange
  } = usePagination(elements, 5);

  const handleConfirmation = () => {
    handleOpen('add-question-confirmation');
  };

  const handleOpenModal = () => {
    handleOpen('add-question');
  };

  const handleHiddenQuestion = (id: number, visibility: boolean) => {
    void putQAAnswerVisibility(id, visibility, type);
  };

  const handleAddAnswer = async (id: number) => {
    if (answer !== '') {
      await postQAAnswer(id, answer, type);
      handleChange(id);
      setAnswer('');
    }
    reset && reset();
  };

  const handleChangeCategory = async (id: number) => {
    await putQAAnswerCategory(id, type);
  };

  const types: ModalType<QUPageModal>[] = [
    {
      key: 'add-question',
      element: <ModalAddQuestion type={'qa'} values={optionsAddQuestionQA}
                                 handleClose={handleClose}
                                 handleConfirmation={handleConfirmation} />,
      title: 'Zadaj pytanie:'
    },
    {
      key: 'add-question-confirmation',
      element: <ModalAddQuestionConfirmation handleClose={handleClose} />,
      title: 'Pytanie wysłane',
      titleJustify: 'center'
    }
  ];

  const modalSelected = getModalSelectedByList<QUPageModal>(typeModal, types);

  return {
    items,
    pageSize,
    currentPage,
    totalPage,
    handlePageChange,
    roleFunction,
    expandedIds,
    open,
    handleClose,
    handleOpen,
    handleChange,
    handleOpenModal,
    handleHiddenQuestion,
    setAnswer,
    answer,
    modalSelected,
    handleAddAnswer,
    handleChangeCategory
  };

};