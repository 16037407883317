import { IModalType } from '../../../../interfaces/library/library';
import { useUsersApi } from '../../../../apiHooks';
import { useUsersStore } from '../../../../store/hooks';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { UserType } from '../../../../interfaces/users/usersInfo';
import { RoleNames } from '../../../../constants/projectUsers';

export const useUserModal = (handleClose: () => void, typeModal: string | null, id: number | null, filter: UserType) => {
  const { getRoles, addUser, deleteUser } = useUsersApi(handleClose);
  const { roles } = useUsersStore();

  useEffect(() => {
    if (typeModal === 'add') {
      void getRoles();
    }
  }, [getRoles, typeModal]);

  const formik = useFormik({
    initialValues: {
      role: '',
      email: '',
      diseaseType: ''
    },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: () => {
      const baseSchema = yup.object().shape({
        role: yup
          .string()
          .required()
          .min(1),
        email: yup
          .string()
          .required()
      });

      if (roles && roles.find(el => el.id === formik?.values.role)?.role === 'Administrator merytoryczny') {
        return baseSchema.shape({
          diseaseType: yup.string().required()
        });
      }
      return baseSchema;
    },
    onSubmit: async (values) => {
      await addUser({ email: values.email, role: +values.role, typeCase: values.diseaseType });
    }
  });
  
  const { setFieldValue } = formik
  
  useEffect(() => {
    if (filter === 'users' && roles?.length) {
      setFieldValue('role', roles.find(el => el.role === RoleNames.USER_PUBLISHING).id)
    }
  }, [filter, setFieldValue, roles])

  const isExpert = roles && roles.find(el => el.id === formik.values.role)?.role === RoleNames.ADMIN_EXPERT;

  const checkboxes = [{
    id: 'acromegaly',
    title: 'Akromegalia'
  }, {
    id: 'cushing',
    title: 'Zespół Cushinga'
  }];

  const modalTitle: IModalType = {
    add: 'Dodaj nowego użytkownika',
    delete: 'Usuwanie użytkownika'
  };

  const deleteUserHandler = () => {
    if (id) {
      void deleteUser(id);
    }
  };

  return {
    modalTitle,
    formik,
    roles,
    deleteUserHandler,
    checkboxes,
    isExpert
  };
};