import styled from 'styled-components';
import { InputAdornment } from '@mui/material';

export const InputStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  div {
    width: 100%;
  }

  p {
    margin-top: 5px;
    left: 10px;
    color: #D32F2F;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
  }

  textarea.Mui-disabled {
    -webkit-text-fill-color: black;
  }
  
`;

export const InputAdornmentStyled = styled(InputAdornment)`
  button {
    position: absolute;
    top: 0;
    right: 0px;
  }
`;

