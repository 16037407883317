import styled from 'styled-components';

export const StyledRight = styled.div<{ status: string }>`
  height: calc(100% - 16px);
  position: absolute;
  right: 0;
  top: 8px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  & button {
    width: 32px;
    height: 32px;
  }

  & svg {
    fill: ${props => props.status === 'NOT_READ' ? 'white' : 'rgba(0,0,0,0.87)'}
  }
`;

export const AlertContent = styled.div`
  display: flex;
  gap: 16px;
  width: calc(100% - 42px);
  justify-content: space-between;
  align-items: center;
`;

