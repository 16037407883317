import {
  ITreatmentOfChronicAcromegallyPasireotide
} from './treatmentOfChronicAcromegallyPasireotide.types';

export const initialTreatmentOfChronicAcromegallyPasireotide: ITreatmentOfChronicAcromegallyPasireotide = {
  dose: '',
  frequency:  '',
  dateStart: null,
  dateEnd: null,
  dateEndIsActive: false
}