import { FC } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ActionBarStyled } from './ActionBar.styled';
import { ActionBarProps } from './ActionBar.types';

export const ActionBar: FC<ActionBarProps> = ({isDisabled, handleIsDisabled}) => {

  return (
    <ActionBarStyled>
      <FormGroup>
        <FormControlLabel control={<Checkbox checked={isDisabled ?? false} onChange={handleIsDisabled} />} label="Trwa nadal" />
      </FormGroup>
    </ActionBarStyled>
  )
}