import {Button} from '@mui/material';

import {LoginLayout} from '../loginLayout/LoginLayout';
import {useForm} from '../../../../../hooks/useForm';
import {ILoginUserForm} from './LoginUser.interfaces';
import {initialLoginUserForm, loginUserFormConfig, validationLoginUserSchema} from './LoginUser.helper';
import { GeneralLink, generateInputs } from '../../../../../utils';
import {ErrorMessage} from '../../../../shared/ui/form/errorMessage/ErrorMessage';
import {useAuthApi} from '../../../../../apiHooks';
import {observer} from "mobx-react-lite";
import { Header } from '../loginLayout/LoginLayout.styled';
import { SubHeader } from './LoginUser.styled';
import { useNavigate } from 'react-router-dom';

const LoginUser = observer(() => {
  const navigate = useNavigate();
  const { loginUserPWZ} = useAuthApi();

  const onSubmit = async (values: ILoginUserForm) => {
    const isLogin = await loginUserPWZ(values);
    if (isLogin ) {
      navigate('/');
    }
  };

  const {
    formik,
    errorMessage
  } = useForm<ILoginUserForm>(initialLoginUserForm, validationLoginUserSchema, onSubmit);

  return (
    <LoginLayout header={<><Header>Nie masz konta w serwisie?</Header><SubHeader>Podaj swój numer PWZ aby przejść do serwisu.</SubHeader></>} extraLink={{text: 'Masz konto? Przejdź do logowania', link: GeneralLink.LOGIN_PUBLISHING }}>
      <>
        {
          generateInputs(loginUserFormConfig, formik)
        }
        {errorMessage && formik.submitCount > 0 && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Button onClick={() => formik.handleSubmit()} variant='contained'>przejdź do serwisu</Button>
      </>
    </LoginLayout>
  );
});

export default LoginUser;