import React from 'react';
import { Grid } from '@mui/material';
import { BackgroundStyled, ImageHomeLayout, Section } from './Layer.styled';
import { Header } from '../header/Header';
import { ILayerProps } from './Layer.types';
import { Footer } from '../appLayer/footer/Footer';


export const Layer: React.FC<ILayerProps> = ({ children, image, isSearch = true, visibility = true }) => {
  return (
    <>
      <Header />
      <Section>
        {visibility && <BackgroundStyled isSearch={isSearch} />}
        <Grid container pr={4} pl={4}>
          <Grid item xs={4}>
            <ImageHomeLayout image={image} />
          </Grid>
          <Grid item xs={8} position={'relative'}>
            {children}
          </Grid>
        </Grid>
      </Section>
      <Footer />
    </>
  );
};