import { LoginLayout } from '../login/loginLayout/LoginLayout';
import { generateInputs } from '../../../../utils';
import { Button, Grid } from '@mui/material';
import { changePasswordFormConfig } from './ChangePassword.helper';
import { useChangePassword } from './useChangePassword.vm';

const ChangePassword = () => {
  const { formik } = useChangePassword();

  return (<LoginLayout>
    <>
      {
        generateInputs(changePasswordFormConfig, formik)
      }
      <Grid container gap={2} flexWrap={'nowrap'} display={'flex'} flexDirection={'row'}>
        <Grid item xs={12}>
          <Button fullWidth={true} onClick={() => formik.handleSubmit()} variant='contained'>Zapisz</Button>
        </Grid>
      </Grid>
    </>
  </LoginLayout>);
};

export default ChangePassword;