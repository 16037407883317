export enum RoleFunction {
  ROLE_GUEST = 'GUEST',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_PUBLISHING = 'ROLE_PUBLISHING_USER',
  ROLE_VIEWING = 'ROLE_VIEWING_USER',
  ROLE_EXPERT = 'ROLE_EXPERT_ADMIN',
  ROLE_ACCEPTING = 'ROLE_ACCEPTING_ADMIN'
}

export enum RoleNames  {
  USER_PUBLISHING = 'Użytkownik publikujący',
  USER_VIEWING = 'Użytkownik oglądający',
  ADMIN_MAIN = 'Admin główny',
  ADMIN_ACCEPTING = 'Administrator akceptujący',
  ADMIN_EXPERT = 'Administrator merytoryczny'
}
