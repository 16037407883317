import { Button, FormControlLabel, Grid, Radio } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Add } from '@mui/icons-material';

import { Fieldset } from '../../../../../../shared/ui/form/fieldset/FieldsetContainer';
import { useThirdStageVm } from './useThirdStage.vm';
import { Divider } from '../../../CaseDraft.styled';
import { Treatment } from './treatment/Treatment';
import { InputController } from '../../../../../../shared/ui/form/inputController/InputController';
import { Input } from '../../../../../../shared/ui/form/input/Input';
import { DateInput } from '../../../../../../shared/ui/form/date/Date';
import { IInputControllerThirdStage, IThirdStageValues } from './ThirdStage.types';
import { CheckboxesGroup } from '../../../../../../shared/ui/form/checkboxesGroup/CheckboxesGroup';
import {
  complicationsOfSurgicalProceduresCheckboxesConfig,
  neurosurgicalTreatmentOptions,
  otherComplicationsCheckboxesConfig,
  qualificationCheckboxesConfig,
  supportTreatmentCheckboxesConfig,
  tolerationCheckboxesConfig
} from './ThirdStage.helper';
import { SimpleUrinaryIncontinenceLabel } from './ThirdStage.styled';
import { SelectCustom } from '../../../../../../shared/ui/form/select/Select';
import { Intervention } from './intervention/Intervention';
import { SupportTreatment } from './supportTreatment/SupportTreatment';
import { RadioGroupStyled } from '../../common/fourthStage/FourthStage.styled';
import { ButtonsActions } from '../../common/buttonsActions/ButtonsActions';
import { radioValueOptional } from '../../../CaseDraft.helper';
import {
  TreatmentBeforeOperationPasireotide
} from './treatmentBeforeOperationPasireotide/TreatmentBeforeOperationPasireotide';
import {
  TreatmentBeforeOperationCabergoline
} from './treatmentBeforeOperationCabergoline/TreatmentBeforeOperationCabergoline';
import { SteroidogenesisInhibitor } from './steroidogenesisInhibitor/SteroidogenesisInhibitor';
import {
  HypercortisolaemiaPasireotide
} from '../../acromegaly/thirdStage/hypercortisolaemiaPasireotide/HypercortisolaemiaPasireotide';
import {
  HypercortisolaemiaCabergoline
} from '../../acromegaly/thirdStage/hypercortisolaemiaPasireotideCabergoline/HypercortisolaemiaCabergoline';
import { SteroidogenesisInhibitorOther } from './steroidogenesisInhibitorOther/SteroidogenesisInhibitorOther';

export const ThirdStage = observer(() => {
  const {
    customErrors,
    inhibitorSteroidogenesis,
    mainTypeOptions,
    handleChangeCheckboxes,
    handleEditableAddIntervention,
    editableAddIntervention,
    handleAddIntervention,
    handleDeleteIntervention,
    setActive,
    inputControllerElements,
    formik,
    handleSave,
    editableAddTreatment,
    handleEditableAddTreatment,
    handleAddTreatment,
    handleDeleteTreatment,
    editableAddTreatmentBeforeOperationInhibitor,
    handleEditableAddTreatmentBeforeOperationInhibitor,
    handleAddSteroidogenesisInhibitor,
    handleDeleteTreatmentBeforeOperationInhibitor,
    handleEditableAddSupportTreatment,
    editableAddSupportTreatment,
    handleAddSupportTreatment,
    handleDeleteSupportTreatment,
    editableAddTreatmentBeforeOperationPasireotide,
    handleEditableAddTreatmentBeforeOperationPasireotide,
    handleAddTreatmentBeforeOperationPasireotide,
    handleDeleteTreatmentBeforeOperationPasireotide,
    editableAddTreatmentBeforeOperationCabergoline,
    handleEditableAddTreatmentBeforeOperationCabergoline,
    handleAddTreatmentBeforeOperationCabergoline,
    handleDeleteTreatmentBeforeOperationCabergoline,
    editableAddHypercortisolaemiaPasireotide,
    handleEditableAddHypercortisolaemiaPasireotide,
    handleAddHypercortisolaemiaPasireotide,
    handleDeleteHypercortisolaemiaPasireotide,
    editableAddHypercortisolaemiaCabergoline,
    handleEditableAddHypercortisolaemiaCabergoline,
    handleAddHypercortisolaemiaCabergoline,
    handleDeleteHypercortisolaemiaCabergoline,
    editableAddSteroidogenesisInhibitorOther,
    handleEditableAddSteroidogenesisInhibitorOther,
    handleAddSteroidogenesisInhibitorOther,
    handleDeleteSteroidogenesisInhibitorOther,
    handleDraftSave,
    activeFieldset,
    handleResetFieldset
  } = useThirdStageVm();

  return (<Grid container justifyContent={'space-between'}>

    <Fieldset legend={'Leczenie z wyboru, które zastosowano u pacjenta'}>
      <Grid container mt={3}>
        {formik.values.treatmentOfChoivePatient.map((treatment, index) => <Treatment id={index} key={index}
                                                                                     number={index + 1}
                                                                                     editable={false}
                                                                                     initial={treatment}
                                                                                     handleDeleteTreatment={handleDeleteTreatment} />)}
        {formik.values.treatmentOfChoivePatient.length > 0 && <Divider margin={4} />}
        {editableAddTreatment && <Treatment number={formik.values.treatmentOfChoivePatient.length + 1} editable={true}
                                            handleAddTreatment={handleAddTreatment} />}
        <Grid item xs={12} mt={formik.values.treatmentOfChoivePatient.length === 0 && !editableAddTreatment ? 0 : 2}>
          <Button disabled={editableAddTreatment} startIcon={<Add />} variant='contained' color={'primary'}
                  onClick={handleEditableAddTreatment}>DODAJ</Button>
        </Grid>
      </Grid>
    </Fieldset>

    <Fieldset legend={'Leczenie przygotowawcze przed zabiegiem operacyjnym (jeśli dotyczy)'}>
      <Grid container mt={3}>
        <Grid item xs={12} mb={2}>
          <p>Inhibitor steroidogenezy</p>
        </Grid>
        {formik.values.treatmentBeforeOperationInhibitors.map((treatment, index) =>
          <SteroidogenesisInhibitor
            key={index}
            id={index}
            number={++index}
            options={mainTypeOptions} editable={false} initial={treatment}
            handleDeleteSteroidogenesisInhibitor={handleDeleteTreatmentBeforeOperationInhibitor} />)}

        {formik.values.treatmentBeforeOperationInhibitors.length > 0 && <Divider margin={4} />}

        {editableAddTreatmentBeforeOperationInhibitor &&
          <SteroidogenesisInhibitor options={mainTypeOptions} editable={true}
                                    handleAddSteroidogenesisInhibitor={handleAddSteroidogenesisInhibitor} />}

        <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'}
              mt={formik.values.treatmentBeforeOperationInhibitors.length > 0 || editableAddTreatmentBeforeOperationInhibitor ? 2 : 0}>
          <Button disabled={editableAddTreatmentBeforeOperationInhibitor} startIcon={<Add />} variant='contained'
                  color={'primary'}
                  onClick={handleEditableAddTreatmentBeforeOperationInhibitor}>DODAJ</Button>
        </Grid>


        <Grid item xs={12} mb={2} mt={2}>
          <p>Pasyreotyd LAR co 4 tygodnie</p>
        </Grid>
        {formik.values.treatmentBeforeOperationPasireotides.map((treatmentBeforeOperationPasireotide, index) =>
          <TreatmentBeforeOperationPasireotide id={index} key={index} editable={false}
                                               initial={treatmentBeforeOperationPasireotide}
                                               handleDeleteTreatmentBeforeOperationPasireotide={handleDeleteTreatmentBeforeOperationPasireotide} />)}
        {formik.values.treatmentBeforeOperationPasireotides.length > 0 && <Divider margin={4} />}
        {editableAddTreatmentBeforeOperationPasireotide && <TreatmentBeforeOperationPasireotide editable={true}
                                                                                                handleAddTreatmentBeforeOperationPasireotide={handleAddTreatmentBeforeOperationPasireotide} />}
        <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'}
              mt={formik.values.treatmentBeforeOperationPasireotides.length > 0 || editableAddTreatmentBeforeOperationPasireotide ? 2 : 0}>
          <Button disabled={editableAddTreatmentBeforeOperationPasireotide} startIcon={<Add />} variant='contained'
                  color={'primary'}
                  onClick={handleEditableAddTreatmentBeforeOperationPasireotide}>DODAJ</Button>
        </Grid>

        <Grid item xs={12} mb={2} mt={2}>
          <p>Karbergolina</p>
        </Grid>
        {formik.values.treatmentBeforeOperationCabergolines.map((treatmentBeforeOperationCabergoline, index) =>
          <TreatmentBeforeOperationCabergoline id={index} key={index} editable={false}
                                               initial={treatmentBeforeOperationCabergoline}
                                               handleDeleteTreatmentBeforeOperationCabergoline={handleDeleteTreatmentBeforeOperationCabergoline} />)}
        {formik.values.treatmentBeforeOperationCabergolines.length > 0 && <Divider margin={4} />}
        {editableAddTreatmentBeforeOperationCabergoline && <TreatmentBeforeOperationCabergoline editable={true}
                                                                                                handleAddTreatmentBeforeOperationCabergoline={handleAddTreatmentBeforeOperationCabergoline} />}
        <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'}
              mt={formik.values.treatmentBeforeOperationCabergolines.length > 0 || editableAddTreatmentBeforeOperationCabergoline ? 2 : 0}>
          <Button disabled={editableAddTreatmentBeforeOperationCabergoline} startIcon={<Add />} variant='contained'
                  color={'primary'}
                  onClick={handleEditableAddTreatmentBeforeOperationCabergoline}>DODAJ</Button>
        </Grid>
      </Grid>

    </Fieldset>

    <Fieldset legend={'Zabiegi operacyjne'}>
      <Grid container mt={3}>
        {formik.values.operations.map((operation, index) => <Intervention key={index} editable={false}
                                                                          number={index + 1}
                                                                          initial={operation} id={index}
                                                                          handleDeleteIntervention={handleDeleteIntervention} />)}
        {formik.values.operations.length > 0 && <Divider margin={4} />}
        {editableAddIntervention && <Intervention editable={true} handleAddIntervention={handleAddIntervention} />}
        <Grid item xs={12} mt={formik.values.operations.length === 0 && !editableAddIntervention ? 0 : 2}>
          <Button disabled={editableAddIntervention} startIcon={<Add />} variant='contained' color={'primary'}
                  onClick={handleEditableAddIntervention}>DODAJ</Button>
        </Grid>
      </Grid>
    </Fieldset>

    <Fieldset legend={'Subnormalne stężenie kortyzolu w surowicy w okresie bezpośrednio po operacji (< 2,0 μg/dl)'}>
      <Grid container mt={3}>
        <Grid item xs={12} mb={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
              justifyContent={'center'}>
          <RadioGroupStyled row={true} name={'subnormalCortisolLevels'} value={formik.values.subnormalCortisolLevels}>
            <FormControlLabel onChange={formik.handleChange} value={'Tak'} control={<Radio
              onClick={() => formik.setFieldValue('subnormalCortisolLevels', radioValueOptional(formik.values.subnormalCortisolLevels, 'Tak'))} />}
                              label='Tak' />
            <FormControlLabel onChange={formik.handleChange} value={'Nie'} control={<Radio
              onClick={() => formik.setFieldValue('subnormalCortisolLevels', radioValueOptional(formik.values.subnormalCortisolLevels, 'Nie'))} />}
                              label='Nie' />
          </RadioGroupStyled>
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Konieczność leczenia substytucyjnego hydrokortyzonem'} keyFieldset={'valid-fieldset-1'}
              activeKey={activeFieldset} handleReset={handleResetFieldset}>
      <Grid container mt={3}>
        <Grid item xs={12} mb={1} display={'flex'} flexDirection={'column'} justifyItems={'flex-start'}
              alignItems={'flex-start'}
              justifyContent={'center'}>
          <RadioGroupStyled row={true} name={'hydrocortisolTreatment'}
                            value={formik.values.hydrocortisolTreatment}>

            <Grid container width={'100%'} display={'flex'} flexDirection={'row'} justifyItems={'flex-start'}
                  alignItems={'center'}>
              <Grid item xs={2}>
                <FormControlLabel onChange={formik.handleChange} value={'Tak'} control={<Radio
                  onClick={() => formik.setFieldValue('hydrocortisolTreatment', radioValueOptional(formik.values.hydrocortisolTreatment, 'Tak'))} />}
                                  label='Tak' />
              </Grid>
              <Grid item xs={5} pr={1}>
                <DateInput
                  disabled={formik.values.hydrocortisolTreatment !== 'Tak'}
                  label={'OD'}
                  required={false}
                  views={['year', 'month']}
                  openTo={'year'}
                  name={'hydrocortisolTreatmentDateStart'}
                  inputFormat={'mm.yyyy'}
                  value={formik.values.hydrocortisolTreatmentDateStart}
                  error={Boolean(customErrors.hydrocortisolTreatmentDateStart)}
                  onBlur={() => formik.setFieldTouched('hydrocortisolTreatmentDateStart', true)}
                  onChange={(e) => formik.setFieldValue('hydrocortisolTreatmentDateStart', e)} />
              </Grid>
              <Grid item xs={5}>
                <DateInput
                  disabled={formik.values.hydrocortisolTreatment !== 'Tak'}
                  label={'DO'}
                  required={false}
                  views={['year', 'month']}
                  openTo={'year'}
                  name={'hydrocortisolTreatmentDateEnd'}
                  inputFormat={'mm.yyyy'}
                  value={formik.values.hydrocortisolTreatmentDateEnd}
                  error={Boolean(customErrors.hydrocortisolTreatmentDateEnd)}
                  onBlur={() => formik.setFieldTouched('hydrocortisolTreatmentDateEnd', true)}
                  onChange={(e) => formik.setFieldValue('hydrocortisolTreatmentDateEnd', e)} />
              </Grid>
            </Grid>
            <FormControlLabel onChange={formik.handleChange} value={'Nie'} control={<Radio
              onClick={() => formik.setFieldValue('hydrocortisolTreatment', radioValueOptional(formik.values.hydrocortisolTreatment, 'Nie'))} />}
                              label='Nie' />
          </RadioGroupStyled>
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Powikłania zabiegów operacyjnych (neurochirurgicznych w przypadku ch. Cushinga)'}>
      <Grid container mt={3}>
        <Grid item xs={12} mb={1} display={'flex'} flexDirection={'row'} alignItems={'flex-start'}
              justifyContent={'flex-start'}>
          <SimpleUrinaryIncontinenceLabel>Moczówka prosta</SimpleUrinaryIncontinenceLabel>
          <RadioGroupStyled row={true} name={'complicationsOfSurgicalProcedures'}
                            value={formik.values.complicationsOfSurgicalProcedures}>

            <FormControlLabel onChange={formik.handleChange} value={'Trwała'} control={<Radio
              onClick={() => formik.setFieldValue('complicationsOfSurgicalProcedures', radioValueOptional(formik.values.complicationsOfSurgicalProcedures, 'Trwała'))} />}
                              label='Trwała' />
            <FormControlLabel onChange={formik.handleChange} value={'Przemijająca'} control={<Radio
              onClick={() => formik.setFieldValue('complicationsOfSurgicalProcedures', radioValueOptional(formik.values.complicationsOfSurgicalProcedures, 'Przemijająca'))} />}
                              label='Przemijająca' />
          </RadioGroupStyled>
        </Grid>
        <Grid item xs={12} mt={1}>
          <CheckboxesGroup<IThirdStageValues> full={true} name={'complicationsOfSurgicalProceduresCheck'}
                                              elements={complicationsOfSurgicalProceduresCheckboxesConfig}
                                              onChange={handleChangeCheckboxes}
                                              values={formik.values.complicationsOfSurgicalProceduresCheck} />
        </Grid>
      </Grid>
    </Fieldset>

    <Fieldset legend={'Leczenie farmakologiczne stosowane w przypadku przetrwałej hiperkortyzolemii'}
              keyFieldset={'valid-fieldset-2'} activeKey={activeFieldset} handleReset={handleResetFieldset}>
      <Grid container mt={3}>

        <Grid item xs={12} mb={2} mt={2}>
          <p>Pasyreotyd LAR co 4 tygodnie</p>
        </Grid>
        {formik.values.hypercortisolaemiaPasireotides.map((hypercortisolaemiaPasireotide, index) =>
          <HypercortisolaemiaPasireotide id={index} key={index} editable={false}
                                         initial={hypercortisolaemiaPasireotide}
                                         handleDeleteHypercortisolaemiaPasireotide={handleDeleteHypercortisolaemiaPasireotide} />)}
        {formik.values.hypercortisolaemiaPasireotides.length > 0 && <Divider margin={4} />}
        {editableAddHypercortisolaemiaPasireotide && <HypercortisolaemiaPasireotide editable={true}
                                                                                    handleAddHypercortisolaemiaPasireotide={handleAddHypercortisolaemiaPasireotide} />}
        <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'}
              mt={formik.values.hypercortisolaemiaPasireotides.length > 0 || editableAddHypercortisolaemiaPasireotide ? 2 : 0}>
          <Button disabled={editableAddHypercortisolaemiaPasireotide} startIcon={<Add />} variant='contained'
                  color={'primary'}
                  onClick={handleEditableAddHypercortisolaemiaPasireotide}>DODAJ</Button>
        </Grid>

        <Grid item xs={12} mb={2} mt={2}>
          <p>Kabergolina</p>
        </Grid>
        {formik.values.hypercortisolaemiaCabergolines.map((hypercortisolaemiaCabergoline, index) =>
          <HypercortisolaemiaCabergoline id={index} key={index} editable={false}
                                         initial={hypercortisolaemiaCabergoline}
                                         handleDeleteHypercortisolaemiaCabergoline={handleDeleteHypercortisolaemiaCabergoline} />)}
        {formik.values.hypercortisolaemiaCabergolines.length > 0 && <Divider margin={4} />}
        {editableAddHypercortisolaemiaCabergoline && <HypercortisolaemiaCabergoline editable={true}
                                                                                    handleAddHypercortisolaemiaCabergoline={handleAddHypercortisolaemiaCabergoline} />}
        <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'}
              mt={formik.values.hypercortisolaemiaCabergolines.length > 0 || editableAddHypercortisolaemiaCabergoline ? 2 : 0}>
          <Button disabled={editableAddHypercortisolaemiaCabergoline} startIcon={<Add />} variant='contained'
                  color={'primary'}
                  onClick={handleEditableAddHypercortisolaemiaCabergoline}>DODAJ</Button>
        </Grid>


        <Grid item xs={12} pr={1} mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
              justifyContent={'center'}>
          <InputController<IInputControllerThirdStage>
            setActive={setActive}
            active={inputControllerElements.steroidogenesisInhibitorHow}
            setField={formik.setFieldValue}
            inputName={['steroidogenesisInhibitorHow']}
            checkbox={{
              name: 'steroidogenesisInhibitorHowCheckbox',
              label: 'Inhibitor steroidogenezy'
            }}>
            <RadioGroupStyled row={true} name={'steroidogenesisInhibitorHow'}
                              value={formik.values.steroidogenesisInhibitorHow}>
              <FormControlLabel disabled={!inputControllerElements.steroidogenesisInhibitorHow}
                                onChange={formik.handleChange} value={'Osilodrostat'} control={<Radio />}
                                label='Osilodrostat' />
              <FormControlLabel disabled={!inputControllerElements.steroidogenesisInhibitorHow}
                                onChange={formik.handleChange} value={'Metyrapon'} control={<Radio />}
                                label='Metyrapon' />
              <FormControlLabel disabled={!inputControllerElements.steroidogenesisInhibitorHow}
                                onChange={formik.handleChange} value={'Inny'} control={<Radio />} label='Inny' />
            </RadioGroupStyled>
          </InputController>
        </Grid>
        {inhibitorSteroidogenesis === 1 && <>
          <Grid item xs={6} pr={1} mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                justifyContent={'center'}>
            <Input
              name={'steroidogenesisInhibitorDoseOf'}
              type={'text'}
              onChange={formik.handleChange}
              value={formik.values.steroidogenesisInhibitorDoseOf}
              placeholder={'dawka'}
              label={'Dawka'}
            />
          </Grid>
          <Grid item xs={6} pr={1} mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                justifyContent={'center'}>
            <Input
              name={'steroidogenesisInhibitorHowMuch24h'}
              type={'text'}
              onChange={formik.handleChange}
              value={formik.values.steroidogenesisInhibitorHowMuch24h}
              placeholder={'ile razy na dobę'}
              label={'Ile razy na dobę'}
            />
          </Grid>
          <Grid item xs={12} pr={1} mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                justifyContent={'center'}>
            <Input
              name={'steroidogenesisInhibitorHowEscalatedDose'}
              type={'text'}
              multiline={true}
              rows={3}
              onChange={formik.handleChange}
              value={formik.values.steroidogenesisInhibitorHowEscalatedDose}
              placeholder={'w jaki sposób eskalowano dawkę'}
              label={'W jaki sposób eskalowano dawkę'}
            />
          </Grid>
          <Grid item xs={3} pr={1} mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                justifyContent={'center'}>
            <Input
              name={'steroidogenesisInhibitorDoseOfMax'}
              type={'text'}
              onChange={formik.handleChange}
              value={formik.values.steroidogenesisInhibitorDoseOfMax}
              placeholder={'dawka maksymalna'}
              label={'Dawka maksymalna'}
            />
          </Grid>
          <Grid item xs={3} pr={1} mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                justifyContent={'center'}>
            <Input
              name={'steroidogenesisInhibitorDoseOfMaintenance'}
              type={'text'}
              onChange={formik.handleChange}
              value={formik.values.steroidogenesisInhibitorDoseOfMaintenance}
              placeholder={'dawka podtrzymująca'}
              label={'Dawka podtrzymująca'}
            />
          </Grid>
          <Grid item xs={3} pr={1} mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                justifyContent={'center'}>
            <DateInput
              label={'okres - OD'}
              required={false}
              views={['year', 'month']}
              openTo={'year'}
              name={'steroidogenesisInhibitorDoseOfDateStart'}
              inputFormat={'mm.yyyy'}
              value={formik.values.steroidogenesisInhibitorDoseOfDateStart}
              error={Boolean(customErrors.steroidogenesisInhibitorDoseOfDateStart)}
              onBlur={() => formik.setFieldTouched('steroidogenesisInhibitorDoseOfDateStart', true)}
              onChange={(e) => formik.setFieldValue('steroidogenesisInhibitorDoseOfDateStart', e)} />
          </Grid>
          <Grid item xs={3} pr={1} mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                justifyContent={'center'}>
            <DateInput
              label={'okres - DO'}
              required={false}
              isStill={true}
              views={['year', 'month']}
              openTo={'year'}
              name={'steroidogenesisInhibitorDoseOfDateEndd'}
              inputFormat={'mm.yyyy'}
              isActive={formik.values.steroidogenesisInhibitorDoseOfDateEnddIsActive}
              setIsActive={() => formik.setFieldValue('steroidogenesisInhibitorDoseOfDateEnddIsActive', !formik.values.steroidogenesisInhibitorDoseOfDateEnddIsActive)}
              value={formik.values.steroidogenesisInhibitorDoseOfDateEndd}
              error={Boolean(customErrors.steroidogenesisInhibitorDoseOfDateEndd)}
              onBlur={() => formik.setFieldTouched('steroidogenesisInhibitorDoseOfDateEndd', true)}
              onChange={(e) => formik.setFieldValue('steroidogenesisInhibitorDoseOfDateEndd', e)} />
          </Grid></>}
        {inhibitorSteroidogenesis === 2 && <>
          {formik.values.steroidogenesisInhibitorDoses.map((steroidogenesisInhibitorDose, index) =>
            <SteroidogenesisInhibitorOther id={index} key={index} editable={false}
                                           initial={steroidogenesisInhibitorDose}
                                           handleDeleteSteroidogenesisInhibitorOther={handleDeleteSteroidogenesisInhibitorOther} />)}
          {formik.values.steroidogenesisInhibitorDoses.length > 0 && <Divider margin={4} />}
          {editableAddSteroidogenesisInhibitorOther && <SteroidogenesisInhibitorOther editable={true}
                                                                                      handleAddSteroidogenesisInhibitorOther={handleAddSteroidogenesisInhibitorOther} />}
          <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'}
                mt={formik.values.steroidogenesisInhibitorDoses.length > 0 || editableAddSteroidogenesisInhibitorOther ? 2 : 0}>
            <Button disabled={editableAddSteroidogenesisInhibitorOther} startIcon={<Add />} variant='contained'
                    color={'primary'}
                    onClick={handleEditableAddSteroidogenesisInhibitorOther}>DODAJ</Button>
          </Grid>

        </>}
      </Grid>
    </Fieldset>
    <Fieldset legend={'Kwalifikacja do'}>
      <Grid container mt={3}>
        <InputController<IInputControllerThirdStage>
          setActive={setActive}
          active={inputControllerElements.qualificationsDiagnosisAccess}
          setField={formik.setFieldValue}
          inputName={['qualificationsDiagnosisAccess']}
          checkbox={{
            name: 'qualificationsDiagnosisAccessCheckbox',
            label: 'ponownego leczenia neurochirurgicznego'
          }}>
          <SelectCustom
            disabled={!inputControllerElements.qualificationsDiagnosisAccess}
            error={Boolean(formik.errors.qualificationsDiagnosisAccess)}
            name={'qualificationsDiagnosisAccess'}
            placeholder={'z jakiego dostępu'}
            label={'Z jakiego dostępu'}
            onChange={formik.handleChange}
            value={formik.values.qualificationsDiagnosisAccess}
            options={neurosurgicalTreatmentOptions} />
        </InputController>
        <Grid item mt={2}>
          <InputController<IInputControllerThirdStage>
            setActive={setActive}
            active={inputControllerElements.qualificationsAnotherTreatment}
            setField={formik.setFieldValue}
            inputName={'qualificationsAnotherTreatment'}
            checkbox={{
              name: 'qualificationsAnotherTreatmentCheckbox',
              label: 'innego leczenia operacyjnego'
            }}>
            <Input
              disabled={!inputControllerElements.qualificationsAnotherTreatment}
              name={'qualificationsAnotherTreatment'}
              type={'text'}
              onChange={formik.handleChange}
              value={formik.values.qualificationsAnotherTreatment}
              placeholder={'Jakie'}
              label={'Jakie'}
            />
          </InputController>
        </Grid>
        <CheckboxesGroup<IThirdStageValues> mt={2} full={true} name={'qualificationsDiagnosis'}
                                            elements={qualificationCheckboxesConfig} onChange={handleChangeCheckboxes}
                                            values={formik.values.qualificationsDiagnosis} />
      </Grid>
    </Fieldset>
    <Fieldset legend={'Czy uzyskano normalizację stężenia kortyzolu?'}>
      <Grid container mt={3}>
        <Grid item xs={12} mb={1} display={'flex'} flexDirection={'row'} alignItems={'flex-start'}
              justifyContent={'flex-start'}>
          <RadioGroupStyled row={true} name={'biochemicalNormalization'} value={formik.values.biochemicalNormalization}>
            <FormControlLabel onChange={formik.handleChange} value={'Tak'} control={<Radio
              onClick={() => formik.setFieldValue('biochemicalNormalization', radioValueOptional(formik.values.biochemicalNormalization, 'Tak'))} />}
                              label='Tak' />
            <FormControlLabel onChange={formik.handleChange} value={'Nie'} control={<Radio
              onClick={() => formik.setFieldValue('biochemicalNormalization', radioValueOptional(formik.values.biochemicalNormalization, 'Nie'))} />}
                              label='Nie' />
          </RadioGroupStyled>
        </Grid>
        {formik.values.biochemicalNormalization === 'Tak' && <>

          <Grid item xs={12} pr={1} mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                justifyContent={'center'}>
            <InputController<IInputControllerThirdStage>
              setActive={setActive}
              active={inputControllerElements.biochemicalNormalizationCortisolDate}
              setField={formik.setFieldValue}
              inputName={['biochemicalNormalizationCortisolDate']}
              checkbox={{
                name: 'biochemicalNormalizationCortisolDateCheckbox',
                label: 'kortyzolu w surowicy'
              }}>
              <Grid item xs={4} pr={1}>
                <DateInput
                  disabled={!inputControllerElements.biochemicalNormalizationCortisolDate}
                  label={'OD'}
                  required={false}
                  views={['year', 'month']}
                  openTo={'year'}
                  name={'biochemicalNormalizationCortisolDate'}
                  inputFormat={'mm.yyyy'}
                  value={formik.values.biochemicalNormalizationCortisolDate}
                  error={formik.touched.biochemicalNormalizationCortisolDate && Boolean(formik.errors.biochemicalNormalizationCortisolDate)}
                  onBlur={() => formik.setFieldTouched('biochemicalNormalizationCortisolDate', true)}
                  onChange={(e) => formik.setFieldValue('biochemicalNormalizationCortisolDate', e)} />
              </Grid>

            </InputController>
          </Grid>
          <Grid item xs={12} pr={1} mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                justifyContent={'center'}>
            <InputController<IInputControllerThirdStage>
              setActive={setActive}
              active={inputControllerElements.biochemicalNormalizationSerumCortisolDate}
              setField={formik.setFieldValue}
              inputName={['biochemicalNormalizationSerumCortisolDate']}
              checkbox={{
                name: 'biochemicalNormalizationSerumCortisolDateCheckbox',
                label: 'wolnego kortyzolu w ślinie'
              }}>
              <Grid item xs={4} pr={1}>
                <DateInput
                  disabled={!inputControllerElements.biochemicalNormalizationSerumCortisolDate}
                  label={'OD'}
                  required={false}
                  views={['year', 'month']}
                  openTo={'year'}
                  name={'biochemicalNormalizationSerumCortisolDate'}
                  inputFormat={'mm.yyyy'}
                  value={formik.values.biochemicalNormalizationSerumCortisolDate}
                  error={formik.touched.biochemicalNormalizationSerumCortisolDate && Boolean(formik.errors.biochemicalNormalizationSerumCortisolDate)}
                  onBlur={() => formik.setFieldTouched('biochemicalNormalizationSerumCortisolDate', true)}
                  onChange={(e) => formik.setFieldValue('biochemicalNormalizationSerumCortisolDate', e)} />
              </Grid>
            </InputController>
          </Grid>
          <Grid item xs={12} pr={1} mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                justifyContent={'center'}>
            <InputController<IInputControllerThirdStage>
              setActive={setActive}
              active={inputControllerElements.biochemicalNormalizationDZMDate}
              setField={formik.setFieldValue}
              inputName={['biochemicalNormalizationDZMDate']}
              checkbox={{
                name: 'biochemicalNormalizationDZMDateCheckbox',
                label: 'wolnego kortyzolu w DZM'
              }}>

              <Grid item xs={4} pr={1}>
                <DateInput
                  disabled={!inputControllerElements.biochemicalNormalizationDZMDate}
                  label={'OD'}
                  required={false}
                  views={['year', 'month']}
                  openTo={'year'}
                  name={'biochemicalNormalizationDZMDate'}
                  inputFormat={'mm.yyyy'}
                  value={formik.values.biochemicalNormalizationDZMDate}
                  error={formik.touched.biochemicalNormalizationDZMDate && Boolean(formik.errors.biochemicalNormalizationDZMDate)}
                  onBlur={() => formik.setFieldTouched('biochemicalNormalizationDZMDate', true)}
                  onChange={(e) => formik.setFieldValue('biochemicalNormalizationDZMDate', e)} />
              </Grid>
            </InputController>
          </Grid>
        </>}
      </Grid>
    </Fieldset>
    <Fieldset legend={'Tolerancja stosowanego leczenia'}>
      <Grid container mt={1}>
        <CheckboxesGroup<IThirdStageValues> mt={2} full={true} name={'toleranceOfPharmacologicalTreatment'}
                                            elements={tolerationCheckboxesConfig} onChange={handleChangeCheckboxes}
                                            values={formik.values.toleranceOfPharmacologicalTreatment} />
      </Grid>
    </Fieldset>
    <Fieldset legend={'Inne powikłania stosowanego leczenia'}>
      <Grid container mt={2}>
        <CheckboxesGroup<IThirdStageValues> mt={1} full={true} name={'anotherComplications'}
                                            elements={otherComplicationsCheckboxesConfig}
                                            onChange={handleChangeCheckboxes}
                                            values={formik.values.anotherComplications} />
        <InputController<IInputControllerThirdStage>
          setActive={setActive}
          active={inputControllerElements.anotherComplicationsAnother}
          setField={formik.setFieldValue}
          inputName={'anotherComplicationsAnother'}
          checkbox={{
            name: 'anotherComplicationsAnotherCheckbox',
            label: 'inne (jakie?)'
          }}>
          <Input
            disabled={!inputControllerElements.anotherComplicationsAnother}
            name={'anotherComplicationsAnother'}
            type={'text'}
            onChange={formik.handleChange}
            value={formik.values.anotherComplicationsAnother}
            placeholder={'inne (jakie?)'}
            label={'Inne (jakie?)'}
          />
        </InputController>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Leczenie farmakologiczne chorób współistniejących i powikłań hiperkortyzolemii'}>
      <Grid container>
        {formik.values.supprotiveTreatment.map((supportTreatment, index) => <SupportTreatment id={index} key={index}
                                                                                              number={index + 1}
                                                                                              editable={false}
                                                                                              handleDeleteSupportTreatment={handleDeleteSupportTreatment}
                                                                                              initial={supportTreatment} />)}
        {formik.values.supprotiveTreatment.length > 0 && <Divider margin={4} />}
        {editableAddSupportTreatment &&
          <SupportTreatment number={formik.values.supprotiveTreatment.length + 1} editable={true}
                            handleAddSupportTreatment={handleAddSupportTreatment} />}
        <Grid item xs={12} mt={2}>
          <Button disabled={editableAddSupportTreatment} startIcon={<Add />} variant='contained' color={'primary'}
                  onClick={handleEditableAddSupportTreatment}>DODAJ</Button>
        </Grid>
        <Divider margin={20} />
      </Grid>
    </Fieldset>
    <Fieldset legend={'Uzyskano poprawę w zakresie'} margin={'0px'}>
      <Grid container>
        <CheckboxesGroup<IThirdStageValues> mt={1} full={true} name={'improvementControl'}
                                            elements={supportTreatmentCheckboxesConfig}
                                            onChange={handleChangeCheckboxes}
                                            values={formik.values.improvementControl} />
      </Grid>
    </Fieldset>
    <ButtonsActions
      handleDraftSave={handleDraftSave}
      handleSave={handleSave} />
  </Grid>);
});



