import styled from 'styled-components';
import { Card } from '@mui/material';
import { ICardItemTag, IChipContainer } from './CardItem.types';

export const CardContainer = styled(Card)`
  background: ${props => props.theme.colors.card} !important;

  div:last-child {
    border-top: 1px solid #E0E0E0;
    display: flex;
    justify-content: flex-end;
  }

`;

export const Tag = styled.p<ICardItemTag>`
  color: ${props => props.type === 'acromegaly' ? props.theme.colors.acromegaly1 : props.theme.colors.cushing1};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
  height: 64px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const AuthorText = styled.p`
  font-weight: 400;
  color: #676767;
  letter-spacing: 0.15px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  height: 20px;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #676767;
  letter-spacing: 0.15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  height: 120px;
`;

export const ChipContainer = styled.div<IChipContainer>`
  div {
    padding: 0px;
    height: 24px;
    background: ${props => props.color} !important;

    & span {
      line-height: 23px;
    }
  }
`;
