export const numberConverter = (number: number) => {
  const maxDay = 9;
  const minDay = 0;

  return number <= maxDay && number >= minDay ? `0${number}` : number;
};

export const additionalMonth = 1;

export const dateStringify = (date: string, isDay = true) => {
  const convertDate = new Date(date);

  if (isNaN(convertDate.getDate())) {
    return '';
  }

  return convertDate ? `${isDay ? numberConverter(convertDate.getDate()) + '.' : ''}${numberConverter(
    convertDate.getMonth() + additionalMonth 
  )}.${convertDate.getFullYear()}` : '';
};

export const timeStringify = (date: string) => {
  const convertDate = new Date(date);

  if (isNaN(convertDate.getDate())) {
    return '';
  }

  return convertDate ? convertDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit'
  }) : '';
};


export const convertDate = (dateElement: number[]) => {
  return dateElement[1] + '/' + String(dateElement[0]).slice(2, 4);
};

export const convertCaseDate = (date: string, day?: string) => {
  return date.slice(0, day === 'day' ? 10 : 7).replaceAll('-', '.');
};

export const convertLinkDate = (date: string) => {
  const inputDate = new Date(date);

  const day = inputDate.getUTCDate().toString().padStart(2, '0');
  const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = inputDate.getUTCFullYear();

  return `${year}.${month}.${day}`;
};