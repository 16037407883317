import styled from 'styled-components';

export const ButtonFixedContainer = styled.div`
  position: fixed;
  left: 45px;
  bottom: 60px;
  z-index: 1350;
  
  button {
    border-radius: 64px;
  }
  
  svg {
    fill: white;
  }
  
  path{
    fill: white;
  }
`;