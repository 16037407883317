import { Button, Grid } from '@mui/material';
import { AccordionCase } from '../../../shared/accordion/AccordionCase';
import { findId } from '../../../shared/accordion/AccordionCase.helper';
import { RoleFunction } from '../../../../constants/projectUsers';
import { StyledPagination } from '../../../shared/pagination/StyledPagination';
import React, { FC } from 'react';
import { QUElementsProps } from './QAElements.types';
import { useQAElementsVm } from './useQAElements.vm';
import { observer } from 'mobx-react-lite';
import { generatePermissionElements } from '../../../shared/permissionContainer/PermissionContainer';
import { GridActions } from '../../caseView/CaseView.styled';
import { DiseaseType } from '../../../../interfaces/common/common';
import { Input } from '../../../shared/ui/form/input/Input';
import { checkRoles } from '../../../../utils/checkRoles';

export const QAElements: FC<QUElementsProps> = observer(({ reset, elements, type }) => {
  const {
    items,
    currentPage,
    totalPage,
    handlePageChange,
    roleFunction,
    expandedIds,
    handleChange,
    handleAddAnswer,
    handleChangeCategory,
    handleHiddenQuestion,
    answer,
    setAnswer
  } = useQAElementsVm(type, elements, reset);

  return (
    <Grid container display={'flex'} gap={2} flexDirection={'column'} mt={2}>
      {items && items.map(element => <AccordionCase key={element.id + String(element.answer)} element={element}
                                                    type={'general'}
                                                    id={element.id} isActive={findId(expandedIds, element.id)}
                                                    actions={checkRoles(roleFunction, [RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_ADMIN]) ?
                                                      generatePermissionElements([
                                                        {
                                                          element: <Grid item display={'flex'} justifyContent={'right'}><Button
                                                            onClick={() => handleHiddenQuestion(element.id, !element.visibility)}
                                                            variant={'outlined'}>{element.visibility ? 'Ukryj wątek' : 'Odkryj wątek'}</Button></Grid>,
                                                          permissions: [RoleFunction.ROLE_ACCEPTING]
                                                        },
                                                        {
                                                          element: !element.answer ?
                                                            <GridActions type={type as DiseaseType} item xs={12}
                                                                         display={'flex'} flexDirection={'column'}
                                                                         alignItems={'flex-end'} gap={1}>
                                                              <Input value={answer}
                                                                     onChange={(event) => setAnswer(event.target.value)}
                                                                     variant={'outlined'} name={'answerName'}
                                                                     type={'text'} multiline rows={3} />
                                                              <Grid container display={'flex'}
                                                                    justifyContent={'flex-end'} gap={1}>
                                                                <Button
                                                                  onClick={() => handleAddAnswer(element.id)}
                                                                  variant={'contained'}>Odpowiedz</Button>
                                                              </Grid>
                                                            </GridActions>
                                                            : null,
                                                          permissions: [RoleFunction.ROLE_EXPERT]
                                                        },
                                                        {
                                                          element: !element.answer ?
                                                            <GridActions type={type as DiseaseType} item xs={12}
                                                                         display={'flex'} flexDirection={'column'}
                                                                         alignItems={'flex-end'} gap={1}>
                                                              <Input value={answer}
                                                                     onChange={(event) => setAnswer(event.target.value)}
                                                                     variant={'outlined'} name={'answerName'}
                                                                     type={'text'} multiline rows={3} />
                                                              <Grid container display={'flex'}
                                                                    justifyContent={'flex-end'} gap={1}>
                                                                <Button
                                                                  onClick={() => handleChangeCategory(element.id)}
                                                                  variant={'outlined'}>Zmień kategorię pytania</Button>
                                                                <Button
                                                                  onClick={() => handleAddAnswer(element.id)}
                                                                  variant={'contained'}>Odpowiedz</Button>
                                                              </Grid>
                                                            </GridActions> : null,
                                                          permissions: [RoleFunction.ROLE_ADMIN]
                                                        }
                                                      ], roleFunction) : null}
                                                    handleOnChange={() => handleChange(element.id)} /> ?? null)}
      {!!totalPage && <Grid item xs={12} display={'flex'} justifyContent={'right'} mt={2} mb={2}>
        <StyledPagination
          showFirstButton
          showLastButton
          size='small'
          count={totalPage}
          color='primary'
          page={currentPage}
          onChange={(e, value: number) => {
            handlePageChange(value);
          }}
        />
      </Grid>}
    </Grid>
  );
});