import React, { FC } from 'react';
import { Button, Grid } from '@mui/material';
import { DateInput } from '../../../../../../../shared/ui/form/date/Date';
import { ITreatmentProps } from './Treatment.types';
import { useTreatmentVm } from './useTreatment.vm';
import { Input } from '../../../../../../../shared/ui/form/input/Input';
import { GenericIcon } from '../../../../../../../shared/genericIcon/GenericIcon';
import { themeMUI } from '../../../../../../../../theme/theme';

export const Treatment: FC<ITreatmentProps> = ({
                                                 number,
                                                 id,
                                                 initial,
                                                 handleAddTreatment,
                                                 editable,
                                                 handleDeleteTreatment
                                               }) => {

  const { formik, handleOnClick, disabledAdd } = useTreatmentVm(initial, handleAddTreatment);

  return <>
    {!editable && <Grid item xs={0.5} mt={1} mb={editable ? 0: 1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
      <span>{number}</span>
    </Grid>}
    <Grid item xs={4} pr={1} mt={1} mb={editable ? 0: 1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'}>
      <DateInput
        disabled={!editable}
        label={'Data'}
        required={false}
        views={['year', 'month']}
        openTo={'year'}
        name={'date'}
        inputFormat={'mm.yyyy'}
        value={formik.values.date}
        error={formik.touched.date && Boolean(formik.errors.date)}
        onBlur={() => formik.setFieldTouched('date', true)}
        onChange={(e) => formik.setFieldValue('date', e)} />
    </Grid>
    <Grid item xs={7} mt={1} mb={editable ? 0: 1} pl={1} display={'flex'} flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent={'center'} justifyItems={'center'}>
      <Input
        disabled={!editable}
        fullWidth={true}
        required={false}
        name={'treatment'}
        label={'Leczenie'}
        type={'text'}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.treatment}
        error={Boolean(formik.errors.treatment)} />
    </Grid>
    {handleDeleteTreatment &&
      <Grid item xs={0.5} display={'flex'} flexDirection={'column'} alignItems={'flex-end'} mt={1} mb={editable ? 0: 1}
      >
        <div style={{ margin: 'auto' }} onClick={() => {
          if (typeof id === 'number') {
            handleDeleteTreatment(id);
          }
        }}>
          <GenericIcon iconName='Close'
                       style={{
                         color: themeMUI.palette.primary.main,
                         cursor: 'pointer',
                         verticalAlign: 'middle'
                       }} />
        </div>
      </Grid>
    }
    {
      handleAddTreatment &&
      <Grid item xs={1} display={'flex'} justifyContent={'center'} mt={1} mb={editable ? 0: 1} pl={2}>
        <Button disabled={disabledAdd} variant='contained' color={'primary'}
                onClick={handleOnClick}>ZAPISZ</Button>
      </Grid>
    }
  </>;
};