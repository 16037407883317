import { Button, CardActionArea, CardContent, Chip, Grid, Typography } from '@mui/material';
import {AuthorText, CardContainer, ChipContainer, Tag, Text, Title} from './CaseItem.styled';
import { ICardItemProps } from './CardItem.types';
import React, { FC } from 'react';
import { generateChipData } from './CardItem.helper';
import { CardActionsButtons } from './actions/cardActionsButtons';
import { ICaseStatus } from '../../../interfaces/common/common';
import { convertCaseDate } from '../../../utils/dateStringify';
import { ModalRoot } from '../modal/ModalRoot';
import { useModal } from '../modal/useModal';
import { useCaseItem } from './useCaseItem.vm';
import { useNavigate } from 'react-router-dom';
import { Circle } from '../menu/Menu.styled';

export const CaseItem: FC<ICardItemProps> = ({ item, type, disabledEvent, isActionPublish, isActionAccept }) => {
  const navigate = useNavigate();
  const { open, handleClose, handleOpen } = useModal();
  const { handleOnClick, roleFunction, generateModal, handleAction, status } = useCaseItem(type, item, handleClose);

  return (
    <Grid item xs={4} pl={1} pr={1} pb={2}><CardContainer>
      <CardActionArea sx={{cursor: disabledEvent ? 'default' : 'pointer'}}>
        <CardContent onClick={() => !disabledEvent && handleOnClick()}>
          <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={1}>
            <Grid item flexDirection={'row'} gap={1} display={'flex'} alignItems={'center'}>
              {!!item.notificationsCount && <Circle>{item.notificationsCount}</Circle>}
              <Tag type={type}>{convertCaseDate(item.dateWithHour, 'day')}</Tag>
            </Grid>
            <Grid item alignSelf={'center'}>
              {status &&
                <ChipContainer
                  color={generateChipData(ICaseStatus[status as keyof typeof ICaseStatus], roleFunction, item.notificationsCount)?.color!}>
                  <Chip
                    label={generateChipData(ICaseStatus[status as keyof typeof ICaseStatus], roleFunction, item.notificationsCount)?.label!}
                    color='success' /></ChipContainer>}
            </Grid>
          </Grid>
          {isActionAccept && <AuthorText>{item.author}</AuthorText>}
          <Title>{item.title}</Title>
          <Text>{item.description}</Text>
        </CardContent>
      </CardActionArea>
      {isActionPublish || isActionAccept ? <CardActionsButtons
        handleEdit={(isActionPublish && status === ICaseStatus.DRAFT) ? () => navigate(`/cases/${type}/${item.id}/edit`) : null}
        handleRemove={((isActionPublish && status === ICaseStatus.DRAFT)) ? () => handleOpen('add') : null}
        handleVisible={(isActionAccept && status === ICaseStatus.HIDDEN) ? () => handleOpen('add') : null}
        handleHidden={(isActionAccept && status === ICaseStatus.PUBLISHED) ? () => handleOpen('add') : null}
      /> : null}
    </CardContainer>
      {open &&
        <ModalRoot title={generateModal(isActionAccept, isActionPublish).title} handleClose={handleClose} open={open}>
          <Grid container pb={2} pl={3} pr={3} width={'500px'}>
            <Typography variant={'body1'}
                        textAlign={'center'}>{generateModal(isActionAccept, isActionPublish).content}</Typography>
            <Grid container justifyContent={'center'} mt={3}>
              <Grid item>
                <Button disabled={false} variant='outlined' color={'primary'}
                        onClick={handleClose}>Anuluj</Button>
              </Grid>
              <Grid item ml={1}>
                <Button disabled={false} variant='contained' color={'primary'}
                        onClick={handleAction}>{generateModal(isActionAccept, isActionPublish).button}</Button>
              </Grid>
            </Grid>
          </Grid>
        </ModalRoot>}
    </Grid>);
};