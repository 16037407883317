import { HeadCell } from '../../../../interfaces/library/library';
import { ITableData } from '../../../../interfaces/common/customTable';

export const createTableRows = (data: object[], headCells: HeadCell[]): ITableData[] => {
  const tableRows: ITableData[] = [];
  data && data.length > 0 && data.forEach(row => {
    const tableRow: ITableData = {};

    headCells.forEach(({ id }) => {
      const key = id as keyof typeof row;
      tableRow[key] = row[key];
    });
    if (row.hasOwnProperty('id')) {
      tableRow['id'] = row['id' as keyof typeof row];
    }
    if (row.hasOwnProperty('active')) {
      tableRow['active'] = row['active' as keyof typeof row];
    }
    if (row.hasOwnProperty('typeCase')) {
      tableRow['typeCase'] = row['typeCase' as keyof typeof row];
    }
    tableRows.push(tableRow);
  });

  return tableRows;
};