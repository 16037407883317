import { useApiCall } from './useApiCall';
import { useCallback } from 'react';
import { IAccordionElement } from '../interfaces/quPage/quPage';
import { useCaseStore } from '../store/hooks';
import { IResponse } from '../interfaces/api/common';
import {
  IAddSimpleQuestion,
  IAddTypeQuestion
} from '../components/shared/modal/common/modalAddQuestion/ModalAddQuestion.types';
import { IDraftCaseAcromegaly, IDraftCaseCushing } from '../components/pages/user/caseDraft/CaseDraft.types';
import {
  convertProgressBarAcromegaly,
  convertProgressBarCushing
} from '../components/pages/user/caseDraft/CaseDraft.helper';

export const useCaseApi = (handleClose?: () => void) => {
  const { api } = useApiCall();
  const caseStore = useCaseStore();

  const getForum = useCallback(
    async (idCase: number, type: string, loader: boolean) => {
      const res: IAccordionElement[] = await api.apiInstance.get(`medical-qa/${type}/${idCase}`, loader);
      if (res) {
        caseStore.setForum(res);
      }
    },
    [caseStore, api.apiInstance]
  );

  const postForumElement = useCallback(
    async (idCase: number, type: string, question: IAddTypeQuestion) => {
      const res: IResponse<IAccordionElement> = await api.apiInstance.post(`case/medical-question/${type}/${idCase}`, question);
      await getForum(idCase, type, false);
      return res.code === 200;
    },
    [api.apiInstance, getForum]
  );

  const postForumAnswer = useCallback(
    async (idCase: number, type: string, questionName: number, answerName: string) => {
      const res: IResponse<IAccordionElement> = await api.apiInstance.post('case/medical-answer', {
        questionName,
        answerName
      });
      await getForum(idCase, type, false);

      return res.code === 200;
    },
    [api.apiInstance, getForum]
  );

  const putForumAnswerVisibility = useCallback(
    async (idCase: number, idQuestion: number, type: string, visibility: boolean) => {
      const res: IResponse<IAccordionElement> = await api.apiInstance.put(`medical-qa/visibility/${idQuestion}`, { visibility });

      await getForum(idCase, type, false);

      return res.code === 200;
    },
    [api.apiInstance, getForum]
  );


  const getDiscussion = useCallback(
    async (id: number, type: string, loader: boolean) => {

      const res: IAccordionElement[] = await api.apiInstance.get(`discussion/all/${type}/${id}`, loader);

      if (res) {
        caseStore.setDiscussion(res ?? []);
      }

    },
    [caseStore, api.apiInstance]
  );


  const postDiscussionElement = useCallback(
    async (id: number, type: string, question: IAddSimpleQuestion, forWho: 'AUTHOR' | 'EXPERT') => {
      const res: IResponse<IAccordionElement> = await api.apiInstance.post(`discussion-author/${type}/${id}`, {
        ...question,
        forWho
      });

      await getDiscussion(id, type, false);

      return res.code === 200;
    },
    [api.apiInstance, getDiscussion]
  );

  const postDiscussionAnswer = useCallback(
    async (idCase: number, type: string, questionId: number, answer: string) => {

      const res: IResponse<IAccordionElement> = await api.apiInstance.post('discussion-expert', { questionId, answer });

      await getDiscussion(idCase, type, false);

      return res.code === 200;
    },
    [api.apiInstance, getDiscussion]
  );

  const putDiscussionAnswerVisibility = useCallback(
    async (idCase: number, id: number, type: string) => {
      const res: IResponse<IAccordionElement> = await api.apiInstance.put(`discussion/visibility/${id}`, {});

      await getDiscussion(idCase, type, false);
      if (res.code === 200)

        return res.code === 200;
    },
    [api.apiInstance, getDiscussion]
  );

  const getCase = useCallback(async (type: string, id: number) => {
    const res = await api.apiInstance.get(`medical-case/${type}/${id}`, true);
    if (res) {
      if (type === 'acromegaly') {
        const data: IDraftCaseAcromegaly = res.data;
        const convert = convertProgressBarAcromegaly(data);
        caseStore.addStages(convert.step, convert.status, convert.zeroStage, convert.firstStage, convert.secondStage, convert.thirdStage, convert.fourthStage);
      } else {
        const data: IDraftCaseCushing = res.data;
        const convert = convertProgressBarCushing(data);

        caseStore.addStages(convert.step, convert.status, convert.zeroStage, convert.firstStage, convert.secondStage, convert.thirdStage, convert.fourthStage);
      }
      return true;
    }
    return false;

  }, [api.apiInstance, caseStore]);

  const createComment = useCallback(async (type: string, id: number, comment: string) => {
    const res = await api.apiInstance.post(`medical-case/${type}/notes/${id}`, { comment }, true);
    if (res) {
      return !!res;
    }
  }, [api.apiInstance]);


  const getComments = useCallback(async (type: string, id: number, isLoading: boolean) => {
    const res = await api.apiInstance.get(`medical-case/${type}/notes/${id}`, isLoading);

    if (res) {
      caseStore.setComments(res);
    }
  }, [api.apiInstance, caseStore]);

  const postReportExcel = useCallback(async () => {
    const res = await api.apiInstance.get(
      `medical-cases/excel`,
      true,
      { responseType: 'blob' }
    );

    if (res) {
      const blob = new Blob([res], { type: 'application/xlsx' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Report_' + new Date().toLocaleDateString() + '.xlsx';
      link.click();
    }

  }, [api.apiInstance]);

  const downloadCaseViewPdf = useCallback(async (id: string, type: string) => {
    const res = await api.apiInstance.get(
      `medical-case/${type === 'acromegaly' ? 'acromegally' : type}/${id}/pdf`,
      true,
      { responseType: 'blob' }
    );

    if (res) {
      const blob = new Blob([res], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'download.pdf');
      document.body.appendChild(link);
      link.click();
    }

  }, [api.apiInstance]);

  return {
    getForum,
    postForumElement,
    postForumAnswer,
    putForumAnswerVisibility,
    postReportExcel,
    getDiscussion,
    postDiscussionElement,
    postDiscussionAnswer,
    putDiscussionAnswerVisibility,
    getCase,
    createComment,
    getComments,
    downloadCaseViewPdf
  };
};

