export interface IAccordionElement extends IQUBasic, IDate {
  author: string;
  visibility: boolean;
  receiverId: number;
  receiverEmail: string;
  type?: string;
  status: 'answered';
  answer: IAnswer | null;
  forWho: ForWho
}

export enum ForWho{
  EXPERT = 'EXPERT',
  AUTHOR = 'AUTHOR'
}

export interface IAnswer extends IQUBasic, IDate {
  visibility?: boolean | string;
}

export interface IDate {
  dateWithHour: string;
  date: string;
}

export interface IQUBasic {
  id: number;
  name: string;
}

