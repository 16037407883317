import * as yup from 'yup';

import {
  ICustomErrorsThirdStage,
  IInputControllerThirdStage,
  IThirdStageValues
} from './ThirdStage.types';
import { ICheckboxConfig } from '../../../../../../shared/ui/form/checkboxes/Checkboxes.types';
import { IOption } from '../../../../../../shared/ui/form/select/Select.types';

export const validationThirdStageSchema = yup.object().shape({
});

export const initialThirdStage: IThirdStageValues = {
  treatmentOfChoivePatient: [],
  treatmentBeforeOperationInhibitors: [],
  treatmentBeforeOperationCabergolines: [],
  treatmentBeforeOperationPasireotides: [],
  operations: [],
  subnormalCortisolLevels: '',
  hydrocortisolTreatment: '',
  hydrocortisolTreatmentDateStart: null,
  hydrocortisolTreatmentDateEnd: null,
  complicationsOfSurgicalProcedures: '',
  complicationsOfSurgicalProceduresCheck: [],
  hypercortisolaemiaPasireotides: [],
  hypercortisolaemiaCabergolines: [],
  steroidogenesisInhibitorHow: '',
  steroidogenesisInhibitorDoseOf: '',
  steroidogenesisInhibitorHowMuch24h: '',
  steroidogenesisInhibitorHowEscalatedDose: '',
  steroidogenesisInhibitorDoseOfMax: '',
  steroidogenesisInhibitorDoseOfMaintenance: '',
  steroidogenesisInhibitorDoseOfDateStart: null,
  steroidogenesisInhibitorDoseOfDateEndd: null,
  steroidogenesisInhibitorDoseOfDateEnddIsActive: false,
  steroidogenesisInhibitorIsActive: false,
  steroidogenesisInhibitorDoses: [],
  qualificationsDiagnosis: [],
  qualificationsDiagnosisAccess: '',
  qualificationsAnotherTreatment: '',
  biochemicalNormalization: '',
  toleranceOfPharmacologicalTreatment: [],
  anotherComplications: [],
  anotherComplicationsAnother: '',
  supprotiveTreatment: [],
  improvementControl: [],
  biochemicalNormalizationDZMIsActive: false,
  biochemicalNormalizationDZMDate: null,
  biochemicalNormalizationCortisolIsActive: false,
  biochemicalNormalizationCortisolDate: null,
  biochemicalNormalizationSerumCortisolIsActive: false,
  biochemicalNormalizationSerumCortisolDate: null,


};

export const checkboxInputControllerConfig: IInputControllerThirdStage = {
  hydrocortisolTreatmentDateStart: false,
  hydrocortisolTreatmentDateEnd: false,
  steroidogenesisInhibitorHow: false,
  qualificationsDiagnosisAccess: false,
  qualificationsAnotherTreatment: false,
  anotherComplicationsAnother: false,
  biochemicalNormalizationDZMDate: false,
  biochemicalNormalizationCortisolDate: false,
  biochemicalNormalizationSerumCortisolDate: false,
  steroidogenesisInhibitorDoseOfDateEndd: false,
  steroidogenesisInhibitorDoseOfDateStart: false,
};

export const initialErrorThirdStage: ICustomErrorsThirdStage = {
  hydrocortisolTreatmentDateStart: false,
  hydrocortisolTreatmentDateEnd: false,
  steroidogenesisInhibitorDoseOfDateStart: false,
  steroidogenesisInhibitorDoseOfDateEndd: false,
};

export const complicationsOfSurgicalProceduresCheckboxesConfig: ICheckboxConfig[] = [
  {
    label: 'Gonadotropowa niedoczynność przedniego płata przysadki',
    name: 'Gonadotropowa niedoczynność przedniego płata przysadki'
  },
  {
    label: 'Kortykotropowa niedoczynność przedniego płata przysadki',
    name: 'Kortykotropowa niedoczynność przedniego płata przysadki'
  },
  {
    label: 'Tyreotropowa niedoczynność przedniego płata przysadki',
    name: 'Tyreotropowa niedoczynność przedniego płata przysadki'
  }
];


export const qualificationCheckboxesConfig: ICheckboxConfig[] = [

  {
    label: 'przewlekłego leczenia farmakologicznego',
    name: 'przewlekłego leczenia farmakologicznego'
  },
  {
    label: 'radioterapii',
    name: 'radioterapii'
  },
  {
    label: 'obustronnej adrenalektomii',
    name: 'obustronnej adrenalektomii'
  }
];

export const neurosurgicalTreatmentOptions: IOption[] = [
  {
    label: 'przezklinowy',
    value: 'przezklinowy'
  },
  {
    label: 'przezczaszkowego',
    value: 'przezczaszkowego'
  }

];

export const tolerationCheckboxesConfig: ICheckboxConfig[] = [
  {
    label: 'obserwowano objawy niedoczynności kory nadnerczy',
    name: 'obserwowano objawy niedoczynności kory nadnerczy'
  },
  {
    label: 'obserwowano biochemiczne cechy hipokortyzolemii w trakcie leczenia (spadek kortyzolu poniżej dolnej granicy normy)',
    name: 'obserwowano biochemiczne cechy hipokortyzolemii w trakcie leczenia (spadek kortyzolu poniżej dolnej granicy normy)'
  }
];


export const otherComplicationsCheckboxesConfig: ICheckboxConfig[] = [
  {
    label: 'wzrost aktywności ALT/AST',
    name: 'wzrost aktywności ALT/AST'
  },
  {
    label: 'wzrost aktywności GGTP',
    name: 'wzrost aktywności GGTP'
  },
  {
    label: 'wydłużenie odstępu QT',
    name: 'wydłużenie odstępu QT'
  },
  {
    label: 'kamica żółciowa nieobecna wcześniej',
    name: 'kamica żółciowa nieobecna wcześniej'
  }
];

export const supportTreatmentCheckboxesConfig: ICheckboxConfig[] = [
  {
    label: 'kontroli ciśnienia tętniczego',
    name: 'kontroli ciśnienia tętniczego'
  },
  {
    label: 'kontroli glikemii',
    name: 'kontroli glikemii'
  }
];


export const convertInputControllerThirdStage = (thirdStage: IThirdStageValues) => {
  return {
    hydrocortisolTreatmentDateStart: thirdStage.hydrocortisolTreatmentDateStart !== null,
    hydrocortisolTreatmentDateEnd: thirdStage.hydrocortisolTreatmentDateEnd !== null,
    hypercortisolaemiaPasireotides : thirdStage.hypercortisolaemiaPasireotides.length > 0,
    hypercortisolaemiaCabergolines: thirdStage.hypercortisolaemiaCabergolines.length > 0,
    steroidogenesisInhibitorHow: thirdStage.steroidogenesisInhibitorHow !== '',
    qualificationsDiagnosisAccess: thirdStage.qualificationsDiagnosisAccess !== '',
    qualificationsAnotherTreatment: thirdStage.qualificationsAnotherTreatment !== '',
    anotherComplicationsAnother: thirdStage.anotherComplicationsAnother !== '',
    biochemicalNormalizationDZMDate: thirdStage.biochemicalNormalizationDZMDate !== null,
    biochemicalNormalizationCortisolDate: thirdStage.biochemicalNormalizationCortisolDate !== null,
    biochemicalNormalizationSerumCortisolDate: thirdStage.biochemicalNormalizationSerumCortisolDate !== null,
    steroidogenesisInhibitorDoseOfDateStart: thirdStage.steroidogenesisInhibitorDoseOfDateStart !== null,
    steroidogenesisInhibitorDoseOfDateEndd: thirdStage.steroidogenesisInhibitorDoseOfDateEndd !== null,
  };
};
