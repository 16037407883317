import { DropZone } from './dropZone/DropZone';
import { GenericIcon } from '../genericIcon/GenericIcon';

import { Typography } from '@mui/material';
import { FC } from 'react';
import { themeMUI } from '../../../theme/theme';
import { useFileUpload } from './useFileUpload.vm';


interface IProps {
  setFileHandler: (file: File | null) => void;
  extensions?: string[];
  maxFileSize?: number;
  fileType?: string;
  htmlFor: string;
}

const joinExtensionsLabel = (extensions: string[]) => {
  return extensions.reduce((text, value, i, array) => text.toUpperCase() + (i < array.length - 1 ? ', ' : ' lub ') + value.toUpperCase());
};

export const FileUploadLayout: FC<IProps> = ({
                                               htmlFor,
                                               setFileHandler,
                                               fileType = 'image',
                                               extensions = ['jpg', 'png', 'jpeg'],
                                               maxFileSize = 0.5
                                             }): JSX.Element => {
  const {
    onDragStateChange,
    onFileDrop,
    setIsDropActive,
    isDropActive,
    isFileTooBig,
    isFileNotSupported
  } = useFileUpload(setFileHandler, maxFileSize, fileType);

  return (
    <DropZone
      onDragStateChange={onDragStateChange}
      onFileDrop={onFileDrop}
      style={{
        padding: '32px',
        width: '100%',
        height: '100%',
        borderRadius: '4px',
        border: isDropActive ? '1px dashed #00b0ff' : '1px dashed #e0e0e0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '16px'
      }}>
      <GenericIcon iconName='UploadFile' style={{ color: themeMUI.palette.primary.main }} />
      <Typography>
        <label
          htmlFor={htmlFor}
          style={{
            color: themeMUI.palette.primary.main, textDecoration: 'underline',
            cursor: 'pointer'
          }}>Wybierz
          <input
            id={htmlFor}
            type='file'
            accept={extensions.join(', ')}
            style={{ display: 'none' }}
            onChange={(event) => {
              event.preventDefault();
              if (event.target.files) {
                onFileDrop(event.target.files[0]);
                setIsDropActive(true);
              }
            }}
          />
        </label>&nbsp;lub przeciągnij plik
      </Typography>
      <Typography fontSize='14px'><span
        style={{ color: themeMUI.palette.text.secondary }}>{joinExtensionsLabel(extensions)}</span>
        <span
          style={{ color: themeMUI.palette.text.secondary }}>{` (max. ${maxFileSize}MB)`}</span>
      </Typography>
      {isFileNotSupported || isFileTooBig ?
        <Typography variant={'caption'}
                    style={{ color: themeMUI.palette.error.main }}>{isFileNotSupported ? 'Zły format pliku' : 'Zbyt duży rozmiar pliku'}</Typography> : null}
    </DropZone>);
};