
import { useApiCall } from './useApiCall';
import { useCallback } from 'react';
import { IZeroStageValues } from '../components/pages/user/caseDraft/stages/common/zeroStage/ZeroStages.types';
import { IFirstStageValues } from '../components/pages/user/caseDraft/stages/common/firstStage/FirstStage.types';
import { IDraftCaseAcromegaly, IDraftCaseCushing } from '../components/pages/user/caseDraft/CaseDraft.types';
import { useProgressBarStore } from '../store/hooks';
import {
  ISecondStageValues
} from '../components/pages/user/caseDraft/stages/acromegaly/secoundStage/SecondStage.types';
import {
  ISecondStageValues as ISecondStageValuesCushing
} from '../components/pages/user/caseDraft/stages/cushing/secondStage/SecondStage.types';
import { IThirdStageValues } from '../components/pages/user/caseDraft/stages/acromegaly/thirdStage/ThirdStage.types';
import {
  IThirdStageValues as IThirdStageValuesCushing
} from '../components/pages/user/caseDraft/stages/cushing/thirdStage/ThirdStage.types';

import { DiseaseType, ICaseStatus } from '../interfaces/common/common';
import {
  convertProgressBarAcromegaly,
  convertProgressBarCushing
} from '../components/pages/user/caseDraft/CaseDraft.helper';
import {IFourthStageValues} from "../components/pages/user/caseDraft/stages/common/fourthStage/FourthStage.types";

export const useDraftCaseApi = () => {
  const { api } = useApiCall();
  const progressBarStore = useProgressBarStore();


  const getDraftCaseFirstStage = useCallback(
    async (id: number, type: DiseaseType, isDraft: boolean = false) => {
      const res = await api.apiInstance.get(`medical-case/${type}/${id}`);

      if (res) {
        if(type === 'acromegaly'){
          const data : IDraftCaseAcromegaly = res.data;
          const convert = convertProgressBarAcromegaly(data);

          if(convert.status === ICaseStatus.DRAFT){
            progressBarStore.addStages(convert.step, convert.zeroStage, convert.firstStage, convert.secondStage, convert.thirdStage, convert.fourthStage)
          }

          progressBarStore.setIsDraft(isDraft ? true : convert.status === ICaseStatus.DRAFT)
        }else{
          const data : IDraftCaseCushing = res.data;
          const convert = convertProgressBarCushing(data);

          if(convert.status === ICaseStatus.DRAFT){
            progressBarStore.addStages(convert.step, convert.zeroStage, convert.firstStage, convert.secondStage, convert.thirdStage, convert.fourthStage)
          }

          progressBarStore.setIsDraft(isDraft ? true : convert.status === ICaseStatus.DRAFT)
        }

      }

    },
    [api.apiInstance, progressBarStore]
  );

  const draftCaseZeroStage = useCallback(
    async (zeroStage: IZeroStageValues, type: DiseaseType) => {
      const res = await api.apiInstance.post(`medical-case/${type}/first-step`, zeroStage);

      if (res) {
        return res.data.id;
      }

    },
    [api.apiInstance]
  );


  const draftCaseZeroStageSaved = useCallback(
    async (zeroStage: IZeroStageValues, id: number, type: DiseaseType) => {
      const res = await api.apiInstance.put(`medical-case/${type}/first-step/${id}`, zeroStage);
      await getDraftCaseFirstStage(id, type);

      if (res) {
        return res.data.id;
      }

    },
    [api.apiInstance, getDraftCaseFirstStage]
  );

  const draftCaseFirstStage = useCallback(
    async (firstStage: IFirstStageValues, id: number, type: DiseaseType) => {

      const res = await api.apiInstance.put(`medical-case/${type}/second-step/${id}`, firstStage);

      if (res) {
        return true;
      }

    },
    [api.apiInstance]
  );

  const draftCaseFirstStageSaved = useCallback(
    async (firstStage: IFirstStageValues, id: number, type: DiseaseType) => {

      const res = await api.apiInstance.put(`medical-case/${type}/second-step/draft/${id}`, firstStage);
      await getDraftCaseFirstStage(id, type);

      if (res) {
        return true;
      }

    },
    [api.apiInstance, getDraftCaseFirstStage]
  );

  const draftCaseSecondSage = useCallback(
    async (secondStage: ISecondStageValues | ISecondStageValuesCushing, id: number, type: DiseaseType) => {

      const res = await api.apiInstance.put(`medical-case/${type}/third-step/${id}`, secondStage);

      if (res) {
        return true;
      }

    },
    [api.apiInstance]
  );

  const draftCaseSecondStageSaved = useCallback(
    async (secondStage: ISecondStageValues| ISecondStageValuesCushing, id: number, type: DiseaseType) => {

      const res = await api.apiInstance.put(`medical-case/${type}/third-step/draft/${id}`, secondStage);
      await getDraftCaseFirstStage(id, type);

      if (res) {
        return true;
      }

    },
    [api.apiInstance, getDraftCaseFirstStage]
  );

  const draftCaseThirdSage = useCallback(
    async (thirdStage: IThirdStageValues | IThirdStageValuesCushing, id: number, type: DiseaseType) => {

      const res = await api.apiInstance.put(`medical-case/${type}/fourth-step/${id}`, thirdStage);

      if (res) {
        return true;
      }

    },
    [api.apiInstance]
  );

  const draftCaseThirdStageSaved = useCallback(
    async (thirdStage: IThirdStageValues | IThirdStageValuesCushing, id: number, type: DiseaseType) => {

      const res = await api.apiInstance.put(`medical-case/${type}/fourth-step/draft/${id}`, thirdStage);
      await getDraftCaseFirstStage(id, type);

      if (res) {
        return true;
      }

    },
    [api.apiInstance,getDraftCaseFirstStage]
  );

   const draftCaseFourthStageSaved = useCallback(
        async (thirdStage: IFourthStageValues , id: number, type: DiseaseType, status: 'DRAFT' | 'SENT' ) => {

            const res = await api.apiInstance.put(`medical-case/${type}/fifth-step/${id}`, {...thirdStage, status});
            await getDraftCaseFirstStage(id, type, true);

            if (res) {
                return true;
            }
        },
        [api.apiInstance, getDraftCaseFirstStage]
   );

  return {
    draftCaseFourthStageSaved,
    draftCaseThirdSage,
    draftCaseThirdStageSaved,
    draftCaseSecondSage,
    draftCaseSecondStageSaved,
    getDraftCaseFirstStage,
    draftCaseZeroStageSaved,
    draftCaseFirstStage,
    draftCaseFirstStageSaved,
    draftCaseZeroStage
  }
}