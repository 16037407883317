import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect } from 'react';
import { useUsersStore } from '../../../../store/hooks';
import { useUsersApi } from '../../../../apiHooks';
import { RegexRules, ValidationError } from '../../../../utils';

const baseSchema = {
  fullName: yup.string().required(),
  workPlace: yup.string().required(),
  academicTitle: yup.string().required(),
  specialization: yup.string().required()
};

export const schemaPassword = {
  password: yup
    .string()
    .required(ValidationError.PASSWORD_NOT_CORRECT)
    .matches(RegexRules.PASSWORD, ValidationError.PASSWORD_NOT_CORRECT)
};

export const schemaRepeatNewPassword = {
  repeatNewPassword: yup
    .string()
    .required(ValidationError.PASSWORD_NOT_CORRECT)
    .matches(
      RegexRules.PASSWORD,
      ValidationError.PASSWORD_NOT_CORRECT
    )
    .oneOf([yup.ref('password')], ValidationError.PASSWORDS_NOT_MATCH)
};

export const schemaCurrentPassword = {
  currentPassword: yup
    .string()
    .required()
};

export const useProfileModal = (handleClose: () => void) => {
  const usersStore = useUsersStore();
  const { getUserProfile, editUserProfile } = useUsersApi(handleClose);
  const { profile, passwordErrorMessage } = usersStore;
  const formik = useFormik({
    initialValues: {
      fullName: '',
      currentPassword: '',
      password: '',
      workPlace: '',
      academicTitle: '',
      specialization: '',
      repeatNewPassword: ''
    },
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,


    enableReinitialize: true,
    validationSchema: () => {
      let schemaCP;
      let schemaP;
      let schemaRNP;
      if (formik.values.currentPassword) {
        schemaP = { ...schemaPassword, ...schemaRepeatNewPassword };
      }
      if (formik.values.password) {
        schemaRNP = schemaRepeatNewPassword;
      }
      if (formik.values.password || formik.values.repeatNewPassword) {
        schemaCP = schemaCurrentPassword;
      }
      return yup.object().shape({
        ...baseSchema, ...schemaP, ...schemaRNP, ...schemaCP
      });
    },
    onSubmit: async (values) => {
      await editUserProfile({
        fullName: values.fullName,
        currentPassword: values.currentPassword,
        password: values.password,
        workPlace: values.workPlace,
        academicTitle: values.academicTitle,
        specialization: values.specialization
      });
    }
  });

  const { setFieldValue } = formik;

  useEffect(() => {
    void getUserProfile();
  }, [getUserProfile]);

  useEffect(() => {
    return () => usersStore.setPasswordErrorMessage('');
  }, [usersStore]);

  useEffect(() => {
    if (profile) {
      setFieldValue('fullName', profile.fullName);
      setFieldValue('workPlace', profile.workPlace);
      setFieldValue('academicTitle', profile.academicTitle);
      setFieldValue('specialization', profile.specialization);
    }
  }, [profile, setFieldValue]);

  return {
    formik,
    passwordErrorMessage
  };
};