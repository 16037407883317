import {
  ButtonAddContainer,
  DescriptionContainer,
} from './Description.styled';
import { Button,  Fab, Grid,  } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useDescription } from './useDescription.vm';
import { checkRoles } from '../../../../utils/checkRoles';
import { RoleFunction } from '../../../../constants/projectUsers';
import { Add, ArrowForwardIos, CheckCircleOutlined } from '@mui/icons-material';
import { CommentModal } from './modal/CommentModal';
import { DiseaseType, ICaseStatus } from '../../../../interfaces/common/common';
import { FixedButton } from '../../../shared/user/layout/fixedButton/FixedButton';
import {ReactComponent as Pen }from '../../../../assets/icon/pen.svg';
import { FirstStage } from './stages/common/firstStage/FirstStage';
import { ZeroStage } from './stages/common/zeroStage/ZeroStage';
import {SecondStage as SecondStageAcromegaly} from './stages/acromegaly/secondStage/SecondStage';
import {ThirdStage as ThirdStageAcromegaly} from "./stages/acromegaly/thirdStage/ThirdStage";
import {SecondStage as SecondStageCushing} from './stages/cushing/secondStage/SecondStage';
import {ThirdStage as ThirdStageCushing} from "./stages/cushing/thirdStage/ThirdStage";

import {FourthStage} from "./stages/common/fourthStage/FourthStage";

export const Description = observer(() => {
  const {
    roleFunction,
    comment,
    type,
    sendToAcceptHandler,
    showFabButton,
    handleEdit,
    status,
    open,
    handleOpen,
    handleClose
  } = useDescription();

  return (
    <DescriptionContainer>
      {(status === ICaseStatus.DRAFT && checkRoles(roleFunction, [RoleFunction.ROLE_PUBLISHING])) && <FixedButton icon={<Pen/>} title={'EDYTUJ'} handleOnClick={handleEdit} />}

      {showFabButton && <>
          <Fab
            onClick={() => handleOpen('add')}
            variant='extended'
            color='primary'
            sx={{ display: 'flex', zIndex: 1350, alignItems: 'center', borderRadius: comment ? '4px' : '64px' }}
          >
            {!comment ? (
              <>dodaj uwagi<Add fontSize={'small'} /></>
            ) : (
              <>Kontynuuj wpisywanie uwag<ArrowForwardIos fontSize={'small'} /></>
            )}
          </Fab>

          <Grid item xs={12} alignSelf={'flex-end'}>
            {checkRoles(roleFunction, [RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_EXPERT]) &&
              <ButtonAddContainer type={type as DiseaseType}>
                <Button
                  variant={'contained'}
                  disabled={!!comment}
                  size={'medium'} onClick={sendToAcceptHandler}
                  startIcon={
                    <CheckCircleOutlined />
                  }>{(checkRoles(roleFunction, RoleFunction.ROLE_ACCEPTING) && 'zaakceptuj i wyślij do AKCEPTACJI') ||
                  (checkRoles(roleFunction, RoleFunction.ROLE_EXPERT) && 'zaakceptuj')}
                </Button>
              </ButtonAddContainer>
            }
          </Grid>
        </>}

      <ZeroStage/>
      <FirstStage/>
        {type === 'acromegaly' ?
            <>
                <SecondStageAcromegaly/>
                <ThirdStageAcromegaly/>
            </> :
            <>
              <SecondStageCushing/>
              <ThirdStageCushing/>
            </>
        }
      <FourthStage/>

      {open && <CommentModal open={open} handleClose={handleClose} />}
    </DescriptionContainer>);
});