import styled from 'styled-components';

export const BackgroundStyled = styled.div`
  background: rgb(238, 238, 238);
  position: absolute;
  width: calc(100% + 32px);
  height: 48px;
  top: 100px;
  z-index: 0;
  left: -32px;
`;