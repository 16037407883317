import { DiseaseType } from '../../../../../interfaces/common/common';
import { LibraryType } from '../../../../pages/library/Library.types';
import { isOfType } from '../../../../../utils/isOfType';

export const findNewLocation = (path: string, newValue: DiseaseType) => {
  const pathArray = path.split('/');
  pathArray[pathArray.length - 1] = newValue;
  return pathArray.join('/');
};

export const isOfTypeLibraryType = (value: string): value is LibraryType => {
  return isOfType<LibraryType>(value, ['chpl' , 'programs' , 'materials' , 'publications' , 'links']);
}
