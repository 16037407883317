import { generalLinks } from './generalLinks';
import { ViewUserLink } from '../../../../../utils';
import { Link } from './link.types';
import Home from '../../../../pages/home/Home';
import { CaseView } from '../../../../pages/caseView/CaseView';
import { Library } from '../../../../pages/library/Library';
import { HomeRedirect } from '../../../../pages/homeRedirect/HomeRedirect';
import { QUPages } from '../../../../pages/qaPage/QAPage';

export const onlyViewUserLinks: Link[] = [
  {
    path: ViewUserLink.REDIRECT_HOME,
    element: <HomeRedirect />
  },
  {
    path: ViewUserLink.HOME,
    element: <Home />
  },
  {
    path: ViewUserLink.CASE_VIEW,
    element: <CaseView />
  },
  {
    path: ViewUserLink.QA,
    element: <QUPages />
  },
  {
    path: ViewUserLink.LIBRARY,
    element: <Library />
  }
];

export const viewLinks = generalLinks.concat(onlyViewUserLinks);