import { useCaseSListStore } from '../../../store/hooks/useCasesListStore';
import { useCasesListApi } from '../../../apiHooks';
import { useCallback, useEffect } from 'react';
import { useCaseLayout } from '../../shared/user/layout/caseLayout/useCaseLayout';
import { usePagination } from '../../../hooks/usePagination';
import { checkRoles } from '../../../utils/checkRoles';
import { RoleFunction } from '../../../constants/projectUsers';
import { useAuthStore } from '../../../store/hooks';
import { DiseaseType } from '../../../interfaces/common/common';

export const useHome = () => {
  const casesListStore = useCaseSListStore();
  const { publishedCasesAcromegaly, publishedCasesCushing } = casesListStore;
  const { getPublishedCases } = useCasesListApi();
  const { roleFunction } = useAuthStore();

  const {
    filter,
    handleChange
  } = useCaseLayout();


  const getPublishedCasesFilter = useCallback((loader: boolean, type: DiseaseType) => {
    void getPublishedCases(loader, type);
  }, [getPublishedCases])

  const isAcromegaly = filter === 'acromegaly';

  useEffect(() => {
    if (!checkRoles(roleFunction, RoleFunction.ROLE_ADMIN)) {
      void getPublishedCasesFilter(filter === 'acromegaly' ? casesListStore.publishedCasesAcromegaly === null : casesListStore.publishedCasesCushing === null , filter);
    }
  }, [getPublishedCasesFilter, casesListStore, filter, roleFunction]);


  const {
    currentPage,
    totalPage,
    handlePageChange,
    search,
    handleSearch,
    items
  } = usePagination(isAcromegaly ? publishedCasesAcromegaly: publishedCasesCushing, 15, ['title', 'author', 'description'], undefined, undefined, filter);

  return {
    publishedCases: items ?? [],
    isLoaded: (isAcromegaly ?  (publishedCasesAcromegaly != null) : (publishedCasesCushing != null))  && items !== null,
    filter,
    handleChange,
    currentPage,
    totalPage,
    handlePageChange,
    search,
    handleSearch
  };
};