import { FC, useEffect } from 'react';
import { IChildren } from '../../../../interfaces/common/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore, usePoiStore } from '../../../../store/hooks';
import { RoleFunction } from '../../../../constants/projectUsers';
import { observer } from 'mobx-react-lite';
import { GlobalLoader } from '../globalLoader/GlobalLoader';
import { checkRoles } from '../../../../utils/checkRoles';
import { useNotificationsApi } from '../../../../apiHooks';
import { GeneralLink, generateLink } from '../../../../utils';

export const AuthController: FC<IChildren> = observer(({ children }) => {
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const { isSessionExpired, roleFunction } = authStore;
  const poiStore = usePoiStore();
  const { errorCode } = usePoiStore();
  const { getNotificationsCount } = useNotificationsApi();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (isSessionExpired) {
      navigate(generateLink(RoleFunction.ROLE_GUEST, authStore.roleFunction === RoleFunction.ROLE_VIEWING ? GeneralLink.LOGIN_USER : GeneralLink.LOGIN_PUBLISHING));
    }
  }, [isSessionExpired, navigate, authStore]);

  useEffect(() => {
    if (errorCode && errorCode === 401) {
      authStore.setIsSessionExpired(true);
      poiStore.clearError();
    }
  }, [errorCode, authStore, poiStore]);

  useEffect(() => {
    if (checkRoles(roleFunction, [RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_ADMIN])) {
      void getNotificationsCount();
    }

  }, [pathname, getNotificationsCount, roleFunction]);

  return <>{!isSessionExpired ? children : <GlobalLoader />}</>;
});
