import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { initialDrug } from './Drug.helper';
import { AddDrugFunction, IDrug } from './Drug.types';


export const useDrugVm = (initial?: IDrug, handleAddDrug?: AddDrugFunction ) => {
  const [disabledAdd, setDisabledAdd] = useState(true);

  const formik = useFormik<IDrug>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: initial ?? initialDrug,
    onSubmit: async (values) => {}
  })

  useEffect(()=> {
    setDisabledAdd(!(formik.values.drug!== "" && formik.values.group !== "" && formik.values.quantity !== ""))
  }, [formik.values])

  const handleOnClick = () => {
    if(handleAddDrug){
      handleAddDrug(formik.values);
      formik.resetForm();
    }
  }

  return {formik, handleOnClick, disabledAdd
  }
}