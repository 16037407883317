import { Divider, Grid, Typography } from '@mui/material';
import { useCaseStore } from '../../../../../../../store/hooks';
import { CheckedIconContainer } from '../../../checkedIcon/CheckedIcon';
import { Text } from '../../../Description.styled';
import React from 'react';
import { Input } from '../../../../../../shared/ui/form/input/Input';
import { IThirdStageValues } from '../../../../../user/caseDraft/stages/acromegaly/thirdStage/ThirdStage.types';
import { getDate } from '../../../../../../../utils/dateSort';
import { observer } from 'mobx-react-lite';

export const ThirdStage = observer(() => {

  const caseStore = useCaseStore();
  const {
    thirdStage
  } = caseStore;

  const thirdStageStageAcromegaly = thirdStage as IThirdStageValues;

  if (thirdStageStageAcromegaly) {

    const treatmentSection = Boolean(thirdStageStageAcromegaly.numberOfMonthsFromDiagnosisToTreatment) || thirdStageStageAcromegaly.surgeries.length > 0 || Boolean(thirdStageStageAcromegaly.reasonForNotTreating);

    const somastatinAnalogSection = thirdStageStageAcromegaly.somastatinAnalogs.length > 0;

    const cabergolineSection = thirdStageStageAcromegaly.cabergolines.length > 0;

    const preparationSection = Boolean(thirdStageStageAcromegaly.withoutPharmacologicalPreparation) || cabergolineSection || somastatinAnalogSection;

    const complicationsDiabetesInsipidusSection = Boolean(thirdStageStageAcromegaly.complicationsDiabetesInsipidus) || thirdStageStageAcromegaly.complicationsDiabetesInsipidusHow?.length > 0;

    const treatmentOfChronicAcromegallyAnaloguesSection = thirdStageStageAcromegaly.treatmentOfChronicAcromegallyAnalogues.length > 0;

    const treatmentOfChronicAcromegallyPasireotidesSection = thirdStageStageAcromegaly.treatmentOfChronicAcromegallyPasireotides.length > 0;

    const treatmentOfChronicAcromegallyPegvisomantsSection = thirdStageStageAcromegaly.treatmentOfChronicAcromegallyPegvisomants.length > 0;

    const treatmentOfChronicAcromegallyCabergolinesSection = thirdStageStageAcromegaly.treatmentOfChronicAcromegallyCabergolines.length > 0;

    const confirmationOfPersistentSection = treatmentOfChronicAcromegallyAnaloguesSection || treatmentOfChronicAcromegallyPasireotidesSection || treatmentOfChronicAcromegallyPegvisomantsSection || treatmentOfChronicAcromegallyCabergolinesSection;

    const qualificationForSurgicalTreatmentSection = thirdStageStageAcromegaly.qualificationForRadiotherapyTreatmentType;

    const achievingHormonalNormalizationSection = Boolean(thirdStageStageAcromegaly.achievingHormonalNormalizationGh) || Boolean(thirdStageStageAcromegaly.achievingHormonalNormalizationIgf1)
      || Boolean(thirdStageStageAcromegaly.achievingHormonalNormalizationIgf1Rate);

    const toleranceOfTheTreatmentUsedSection = thirdStageStageAcromegaly.toleranceOfTheTreatmentUsed?.length > 0 || thirdStageStageAcromegaly.toleranceOfTheTreatmentUsedType;

    const treatmentOfComorbiditiesCheckSection = thirdStageStageAcromegaly.treatmentOfComorbidities?.length > 0 || thirdStageStageAcromegaly.treatmentOfComorbiditiesCheck?.length > 0;

    const initiationOfDrugTreatmentWithPasireotideSection = thirdStageStageAcromegaly.initiationOfDrugTreatmentWithPasireotideLarReason?.length > 0 ||
      Boolean(thirdStageStageAcromegaly.initiationOfDrugTreatmentWithPasireotideLarConcentration) || Boolean(thirdStageStageAcromegaly.initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1);

    const startTreatmentWithPegvisomantNormalizationSection = thirdStageStageAcromegaly.startTreatmentWithPegvisomantNormalizationUg?.length > 0 || Boolean(thirdStageStageAcromegaly.startTreatmentWithPegvisomantNormalizationIGF1);

    return (
      <Grid container justifyContent={'space-between'} width={'100%'} mt={6}>
        <Typography variant={'h4'} pb={2}>Leczenie akromegalii</Typography>
        <Divider sx={{ width: '100%' }} />

        {treatmentSection && <Grid container display={'flex'} alignItems={'center'} mt={2}>
          <Grid item xs={12} mt={2} mb={4}>
            <Typography variant={'h6'}>Leczenie z wyboru, które zastosowano u pacjenta:</Typography>
          </Grid>
          {thirdStageStageAcromegaly.numberOfMonthsFromDiagnosisToTreatment && <>
            <Grid item xs={4} mb={1} display={'flex'} flexDirection={'row'} alignItems={'center'}
                  justifyItems={'center'}>
              <CheckedIconContainer /><Text>{'Leczenie operacyjne'}</Text>
            </Grid>
            <Grid item xs={8} mb={1}>
              <Input multiline={true} disabled variant={'standard'}
                     label={'Liczba miesięcy od rozpoznania do zastosowanego leczenia'} required={true}
                     name={'numberOfMonthsFromDiagnosisToTreatment'} type={'number'}
                     value={thirdStageStageAcromegaly.numberOfMonthsFromDiagnosisToTreatment} />
            </Grid> </>}
          {thirdStageStageAcromegaly.surgeries.length > 0 && thirdStageStageAcromegaly.surgeries.map((element, index) => (
            <Grid container display={'flex'} alignItems={'center'} mb={1} mt={1} key={element.type + index}>
              <Grid item xs={0.5} pl={2}>
                <Text>{index + 1}</Text>
              </Grid>
              <Grid item xs={5.5} pl={2}>
                <Input multiline disabled variant={'standard'} fullWidth={true} required={true} name={'element.group'}
                       label={'data'}
                       type={'text'} value={getDate(element.date)} />
              </Grid>
              <Grid item xs={6} pl={2}>
                <Input multiline disabled variant={'standard'} fullWidth={true} required={true} name={'element.type'}
                       label={'dostęp'}
                       type={'text'} value={element.type} />
              </Grid>
            </Grid>
          ))}
          {thirdStageStageAcromegaly.reasonForNotTreating && <>
            <Grid item xs={4} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
              <CheckedIconContainer /><Text>{'Brak leczenia operacyjnego'}</Text>
            </Grid>
            <Grid item xs={8}>
              <Input multiline={true} disabled variant={'standard'} label={'Przyczyna'} required={true}
                     name={'reasonForNotTreating'} type={'number'}
                     value={thirdStageStageAcromegaly.reasonForNotTreating} />
            </Grid> </>}
        </Grid>}

        {preparationSection &&
          <Grid container>
            <Typography mt={4} variant={'h6'}>Leczenie przygotowawcze przed leczeniem operacyjnym (jeśli
              dotyczy):</Typography>
            {somastatinAnalogSection && <Grid container display={'flex'} alignItems={'flex-start'}>
              <>
              <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                <Text>Analog somatostatyny I generacji:</Text>
              </Grid>
              {thirdStageStageAcromegaly.somastatinAnalogs.map((somastatinAnalog, index) => (
                <React.Fragment key={somastatinAnalog.dose + index}>
                  <Grid item xs={2.4} pl={2} pt={1} alignSelf={'stretch'}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true} name={'type'}
                           label={'jaki?'}
                           type={'text'} maxLength={2} value={somastatinAnalog.type} />
                  </Grid>
                  <Grid item xs={2.4} pl={2} pt={1} alignSelf={'stretch'}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true} name={'dose'}
                           label={'dawka (mg)'}
                           type={'text'} maxLength={2} value={somastatinAnalog.dose} />
                  </Grid>
                  <Grid item xs={2.4} pl={2} pt={1} alignSelf={'stretch'}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                           name={'weekIntervals'} label={'odstępy (tyg)'}
                           type={'text'} maxLength={2} value={somastatinAnalog.weekIntervals} />
                  </Grid>
                  <Grid item xs={2.4} pl={2} pt={1} alignSelf={'stretch'}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                           name={'dateStart'} label={'okres - OD'}
                           type={'text'} maxLength={2}
                           value={getDate(somastatinAnalog.dateStart)} />
                  </Grid>
                  <Grid item xs={2.4} pl={2} pt={1} alignSelf={'stretch'}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                           name={'dateEnd'} label={'okres - DO'}
                           type={'text'} maxLength={2}
                           value={getDate(somastatinAnalog.dateEnd)} />
                  </Grid>
                </React.Fragment>
              ))}
              </>
            </Grid>}
            {cabergolineSection && <Grid container display={'flex'} alignItems={'flex-start'}>
              {somastatinAnalogSection && <><Grid item xs={12} display={'flex'} alignItems={'center'}
                                                  justifyItems={'center'} mt={2}>
                <Text>Kabergolina:</Text>
              </Grid>

              {thirdStageStageAcromegaly.cabergolines.map((cabergoline, index) => (
                <React.Fragment key={cabergoline.dose + index}>
                  <Grid item xs={4} pl={2} pt={1} alignSelf={'stretch'}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true} name={'dose'}
                           label={'dawka (mg/tydzień)'}
                           type={'text'} maxLength={2} value={cabergoline.dose} />
                  </Grid>
                  <Grid item xs={4} pl={2} pt={1} alignSelf={'stretch'}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                           name={'dateStart'} label={'okres - OD'}
                           type={'text'} maxLength={2}
                           value={getDate(cabergoline.dateStart)} />
                  </Grid>
                  <Grid item xs={4} pl={2} pt={1} alignSelf={'stretch'}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true} name={'dateEnd'}
                           label={'okres - DO'}
                           type={'text'} maxLength={2}
                           value={getDate(cabergoline.dateEnd)} />
                  </Grid>
                </React.Fragment>
              ))}</>}
            </Grid>}
            {thirdStageStageAcromegaly.withoutPharmacologicalPreparation &&
              <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                <CheckedIconContainer /><Text>{thirdStageStageAcromegaly.withoutPharmacologicalPreparation}</Text>
              </Grid>
            }
          </Grid>}

        {complicationsDiabetesInsipidusSection && <Grid container display={'flex'} alignItems={'flex-start'} mt={2}>
          <Typography mt={2} variant={'h6'}>Powikłania leczenia neurochirurgicznego:</Typography>
          <Grid container mt={2} alignItems={'center'}>
            {thirdStageStageAcromegaly.complicationsDiabetesInsipidus && <>
              <Grid item xs={3}>
                <Text>Moczówka prosta</Text>
              </Grid>
              <Grid item xs={9} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                <CheckedIconContainer /><Text>{thirdStageStageAcromegaly.complicationsDiabetesInsipidus}</Text>
              </Grid>
            </>}
            {thirdStageStageAcromegaly.complicationsDiabetesInsipidusHow.map((element, index) => (
              <Grid key={element + index} item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                <CheckedIconContainer /><Text>{element}</Text>
              </Grid>
            ))}
          </Grid>
        </Grid>}


        {confirmationOfPersistentSection &&
          <Grid container>
            <Typography mt={4} variant={'h6'}>Potwierdzenie przetrwałej akromegalii:</Typography>
            <Grid container display={'flex'} alignItems={'flex-start'}>
            {treatmentOfChronicAcromegallyAnaloguesSection && <>
              <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                <Text>Analog somatostatyny I generacji:</Text>
              </Grid>

              {thirdStageStageAcromegaly.treatmentOfChronicAcromegallyAnalogues.map((treatmentOfChronicAcromegallyAnalogue, index) =>
                (<React.Fragment key={treatmentOfChronicAcromegallyAnalogue.dose + index}>
                  <Grid item xs={2.4} pl={2} alignSelf={'stretch'} mt={2}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                           name={'type'} label={'jaki?'}
                           type={'text'} maxLength={2}
                           value={treatmentOfChronicAcromegallyAnalogue.type} />
                  </Grid>
                  <Grid item xs={2.4} pl={2} alignSelf={'stretch'} mt={2}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                           name={'frequency'} label={'częstotliwość (tyg)'}
                           type={'text'} maxLength={2}
                           value={treatmentOfChronicAcromegallyAnalogue.frequency} />
                  </Grid>
                  <Grid item xs={2.4} pl={2} alignSelf={'stretch'} mt={2}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                           name={'dose'} label={'dawka (mg)'}
                           type={'text'} maxLength={2}
                           value={treatmentOfChronicAcromegallyAnalogue.dose} />
                  </Grid>
                  <Grid item xs={2.4} pl={2} alignSelf={'stretch'} mt={2}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                           name={'dateStart'} label={'okres - OD'}
                           type={'text'} maxLength={2}
                           value={getDate(treatmentOfChronicAcromegallyAnalogue.dateStart)} />
                  </Grid>
                  <Grid item xs={2.4} pl={2} alignSelf={'stretch'} mt={2}>
                    <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                           name={'dateEnd'} label={'okres - DO'}
                           type={'text'} maxLength={2}
                           value={getDate(treatmentOfChronicAcromegallyAnalogue.dateEnd)} />
                  </Grid>
              </React.Fragment>))}</>}
              {treatmentOfChronicAcromegallyPasireotidesSection && <>
                <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                  <Text>Pasyreotyd LAR:</Text>
                </Grid>

                {thirdStageStageAcromegaly.treatmentOfChronicAcromegallyPasireotides.map((treatmentOfChronicAcromegallyPasireotide, index) =>
                  (<React.Fragment key={treatmentOfChronicAcromegallyPasireotide.dose + index}>
                    <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                             name={'dose'} label={'dawka (mg)'}
                             type={'text'} maxLength={2}
                             value={treatmentOfChronicAcromegallyPasireotide.dose} />
                    </Grid>
                    <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                             name={'frequency'} label={'częstotliwość (tyg)'}
                             type={'text'} maxLength={2}
                             value={treatmentOfChronicAcromegallyPasireotide.frequency} />
                    </Grid>
                    <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                             name={'dateStart'} label={'okres - OD'}
                             type={'text'} maxLength={2}
                             value={getDate(treatmentOfChronicAcromegallyPasireotide.dateStart)} />
                    </Grid>
                    <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                             name={'dateEnd'} label={'okres - DO'}
                             type={'text'} maxLength={2}
                             value={getDate(treatmentOfChronicAcromegallyPasireotide.dateEnd)} />
                    </Grid>
              </React.Fragment>))}</>}
              {treatmentOfChronicAcromegallyPegvisomantsSection && <>
                <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                  <Text>Pegvisomant:</Text>
                </Grid>

                {thirdStageStageAcromegaly.treatmentOfChronicAcromegallyPegvisomants.map((treatmentOfChronicAcromegallyPegvisomant, index) =>
                  (<React.Fragment key={treatmentOfChronicAcromegallyPegvisomant.dose + index}>
                    <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                             name={'dose'} label={'dawka (mg)'}
                             type={'text'} maxLength={2}
                             value={treatmentOfChronicAcromegallyPegvisomant.dose} />
                    </Grid>
                    <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                             name={'frequency'} label={'częstotliwość (tyg)'}
                             type={'text'} maxLength={2}
                             value={treatmentOfChronicAcromegallyPegvisomant.frequency} />
                    </Grid>
                    <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                             name={'dateStart'} label={'okres - OD'}
                             type={'text'} maxLength={2}
                             value={getDate(treatmentOfChronicAcromegallyPegvisomant.dateStart)} />
                    </Grid>
                    <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                             name={'dateEnd'} label={'okres - DO'}
                             type={'text'} maxLength={2}
                             value={getDate(treatmentOfChronicAcromegallyPegvisomant.dateEnd)} />
                    </Grid>
               </React.Fragment>))}</>}
              {treatmentOfChronicAcromegallyCabergolinesSection && <>
                <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                  <Text>Kabergolina:</Text>
                </Grid>

                {thirdStageStageAcromegaly.treatmentOfChronicAcromegallyCabergolines.map((treatmentOfChronicAcromegallyCabergoline, index) =>
                  (<React.Fragment key={treatmentOfChronicAcromegallyCabergoline.dose + index}>
                    <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                             name={'dose'} label={'dawka (mg)'}
                             type={'text'} maxLength={2}
                             value={treatmentOfChronicAcromegallyCabergoline.dose} />
                    </Grid>
                    <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                             name={'frequency'} label={'częstotliwość (tyg)'}
                             type={'text'} maxLength={2}
                             value={treatmentOfChronicAcromegallyCabergoline.frequency} />
                    </Grid>
                    <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                             name={'dateStart'} label={'okres - OD'}
                             type={'text'} maxLength={2}
                             value={getDate(treatmentOfChronicAcromegallyCabergoline.dateStart)} />
                    </Grid>
                    <Grid item xs={3} pl={2} alignSelf={'stretch'} mt={2}>
                      <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                             name={'dateEnd'} label={'okres - DO'}
                             type={'text'} maxLength={2}
                             value={getDate(treatmentOfChronicAcromegallyCabergoline.dateEnd)} />
                    </Grid>
               </React.Fragment>))}</>}
            </Grid>
        </Grid>}

        {qualificationForSurgicalTreatmentSection &&
          <Grid container>
            <Typography mt={4} variant={'h6'}>Kwalifikacja do ponownego leczenia:</Typography>
            {thirdStageStageAcromegaly.qualificationForSurgicalTreatmentHowAccess &&
              <Grid container display={'flex'} alignItems={'center'}>
                <Grid item xs={3} display={'flex'} alignItems={'center'} justifyItems={'center'}  mt={2}>
                  <CheckedIconContainer /><Text>{'neurochirurgicznego'}</Text>
                </Grid>
                <Grid item xs={9} pl={2}>
                  <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                         name={'qualificationForSurgicalTreatmentHowAccess'} label={'dostęp'}
                         type={'text'} value={thirdStageStageAcromegaly.qualificationForSurgicalTreatmentHowAccess} />
                </Grid>
              </Grid>}
            {thirdStageStageAcromegaly.qualificationForRadiotherapyTreatmentType &&
              <Grid container display={'flex'} alignItems={'center'}>
                <Grid item xs={3} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                  <CheckedIconContainer /><Text>{'radioterapią'}</Text>
                </Grid>
                <Grid item xs={9} pl={2}>
                  <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                         name={'qualificationForRadiotherapyTreatmentType'} label={'typ radioterapii'}
                         type={'text'} maxLength={2}
                         value={thirdStageStageAcromegaly.qualificationForRadiotherapyTreatmentType} />
                </Grid>
              </Grid>}
          </Grid>}


        {achievingHormonalNormalizationSection &&
          <Grid container>
            <Typography mt={4} mb={2} variant={'h6'}>Ocena hormonalna w trakcie leczenia farmakologicznego:</Typography>
            {thirdStageStageAcromegaly.achievingHormonalNormalizationGh &&
              <Grid container pl={2} display={'flex'} alignItems={'center'}>
                <Text>*GH</Text>
                <Grid ml={2} item display={'flex'} alignItems={'center'} justifyItems={'center'}>
                  <CheckedIconContainer /><Text>{thirdStageStageAcromegaly.achievingHormonalNormalizationGh}</Text>
                </Grid>
              </Grid>}
            {thirdStageStageAcromegaly.achievingHormonalNormalizationIgf1 &&
              <Grid container pl={2} mt={2} display={'flex'} alignItems={'center'}>
                <Text>*stężenie IGF-1</Text>
                <Grid ml={2} item display={'flex'} alignItems={'center'} justifyItems={'center'}>
                  <CheckedIconContainer /><Text>{thirdStageStageAcromegaly.achievingHormonalNormalizationIgf1}</Text>
                </Grid>
              </Grid>}
            {thirdStageStageAcromegaly.achievingHormonalNormalizationIgf1Rate && <Grid item xs={12} pl={2} mt={2}>
              <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                     name={'achievingHormonalNormalizationIgf1Rate'} label={'ocena IGF-1 w stosunku do ULN'}
                     type={'text'} value={thirdStageStageAcromegaly.achievingHormonalNormalizationIgf1Rate} />
            </Grid>}
            {thirdStageStageAcromegaly.serumPrlGrade && <Grid item xs={12} pl={2} mt={2}>
              <Grid item xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'}>
                <CheckedIconContainer /><Text>{thirdStageStageAcromegaly.serumPrlGrade}</Text>
              </Grid>
            </Grid>}
          </Grid>}


        {toleranceOfTheTreatmentUsedSection &&
          <Grid container>
            <Typography mt={4} mb={2} variant={'h6'}>Tolerancja stosowanego leczenia (czy obserwowano powikłania /
              jakie?)</Typography>
            {thirdStageStageAcromegaly.toleranceOfTheTreatmentUsed.map((element, index) => (
              <Grid key={index + element} item xs={12} pl={2} display={'flex'} alignItems={'center'}
                    justifyItems={'center'}>
                <CheckedIconContainer /><Text>{element}</Text>
              </Grid>
            ))}
            {thirdStageStageAcromegaly.toleranceOfTheTreatmentUsedType && <>
              <Grid item xs={8} pl={2} display={'flex'} flexDirection={'row'} alignItems={'center'}
                    justifyItems={'center'}>
                <CheckedIconContainer /><Text>zaburzenia gospodarki węglowodanowej nieobecne wcześniej</Text>
              </Grid>
              <Grid item xs={4} pl={2}>
                <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                       name={'qualificationForRadiotherapyTreatmentType'} label={'typ/rodzaj'}
                       type={'text'} value={thirdStageStageAcromegaly.toleranceOfTheTreatmentUsedType}/>
              </Grid>
            </>}
          </Grid>}


        {treatmentOfComorbiditiesCheckSection &&
          <Grid container>
            <Typography mt={4} mb={2} variant={'h6'}>Leczenie chorób współistniejących/powikłań:</Typography>

            {thirdStageStageAcromegaly.treatmentOfComorbidities.map((element, index) => (
              <Grid key={element.drug + index} container display={'flex'} alignItems={'flex-start'} mb={2}>
                <Grid item xs={0.5} pl={2}>
                  <Text>{index + 1}</Text>
                </Grid>
                <Grid item xs={3.5} pl={2} alignSelf={'stretch'}>
                  <Input multiline disabled variant={'standard'} fullWidth={true} required={true} name={'element.group'}
                         label={'grupa leków'}
                         type={'text'} value={element.group} />
                </Grid>
                <Grid item xs={4} pl={2} alignSelf={'stretch'}>
                  <Input multiline disabled variant={'standard'} fullWidth={true} required={true} name={'element.drug'}
                         label={'lek'}
                         type={'text'} value={element.drug} />
                </Grid>
                <Grid item xs={4} pl={2} alignSelf={'stretch'}>
                  <Input multiline disabled variant={'standard'} fullWidth={true} required={true} name={'element.quantity'}
                         label={'dawka'}
                         type={'text'} value={element.quantity} />
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12} mb={2} mt={2}>
              <Divider sx={{ width: '100%' }} />
            </Grid>
            {thirdStageStageAcromegaly.treatmentOfComorbiditiesCheck.map((element, index) => (
              <Grid key={element + index} item xs={12} pl={2} display={'flex'} alignItems={'center'}
                    justifyItems={'center'}>
                <CheckedIconContainer /><Text>{element}</Text>
              </Grid>
            ))}
          </Grid>}


        {initiationOfDrugTreatmentWithPasireotideSection &&
          <Grid container>
            <Typography mt={4} mb={2} variant={'h6'}>Rozpoczęcie leczenia farmakologicznego pasyreotydem
              LAR:</Typography>

            { thirdStageStageAcromegaly.initiationOfDrugTreatmentWithPasireotideLarReason.map((element, index) => (
              <Grid key={element + index} item xs={12} pl={2} display={'flex'} alignItems={'center'}
                    justifyItems={'center'}>
                <CheckedIconContainer /><Text>{element}</Text>
              </Grid>
            ))}
            <Grid item xs={12} mb={2} mt={2}>
              <Divider sx={{ width: '100%' }} />
            </Grid>
            {thirdStageStageAcromegaly.initiationOfDrugTreatmentWithPasireotideLarConcentration && <>
              <Grid item xs={5} pl={2} display={'flex'} flexDirection={'row'} alignItems={'center'}
                    justifyItems={'center'}>
                <CheckedIconContainer /><Text>osiągnięto normalizację GH w surowicy</Text>
              </Grid>
              <Grid item xs={7} pl={2}>
                <Input multiline disabled variant={'standard'} fullWidth={true} required={true}
                       name={'initiationOfDrugTreatmentWithPasireotideLarConcentration'} label={'stężenie (μg/l)'}
                       type={'text'}
                       value={thirdStageStageAcromegaly.initiationOfDrugTreatmentWithPasireotideLarConcentration} />
              </Grid>
            </>}
            {thirdStageStageAcromegaly.initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1 &&
              <Grid item xs={12} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                <CheckedIconContainer /><Text>{thirdStageStageAcromegaly.initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1}</Text>
              </Grid>
            }
          </Grid>}


        {startTreatmentWithPegvisomantNormalizationSection &&
          <Grid container>
            <Typography mt={4} mb={2} variant={'h6'}>Rozpoczęcie leczenia farmakologicznego pegvisomantem (jeśli
              dotyczy):</Typography>

            {thirdStageStageAcromegaly.startTreatmentWithPegvisomantNormalizationUg.map((element, index) => (
              <Grid key={element + index} item xs={12} pl={2} display={'flex'} alignItems={'center'}
                    justifyItems={'center'}>
                <CheckedIconContainer /><Text>{element}</Text>
              </Grid>
            ))}
            <Grid item xs={12} mb={2} mt={2}>
              <Divider sx={{ width: '100%' }} />
            </Grid>
            {thirdStageStageAcromegaly.startTreatmentWithPegvisomantNormalizationIGF1 &&
              <Grid item xs={12} pl={2} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                <CheckedIconContainer /><Text>{thirdStageStageAcromegaly.startTreatmentWithPegvisomantNormalizationIGF1}</Text>
              </Grid>
            }
          </Grid>}

      </Grid>
    );
  }
  return <></>;
});