import { useFormik } from 'formik';
import { useEffect, useState } from 'react';


import { initialSupportTreatment } from './SupportTreatment.helper';
import { AddSupportTreatmentFunction, ISupportTreatment } from './SupportTreatment.types';

export const useSupportTreatmentVm = (initial?: ISupportTreatment, handleAddTreatment?: AddSupportTreatmentFunction) => {
  const [disabledAdd, setDisabledAdd] = useState(true);

  const formik = useFormik<ISupportTreatment>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: initial ?? initialSupportTreatment,
    onSubmit: async (values) => {}
  })

  useEffect(()=> {
    setDisabledAdd(!(formik.values.drug !== ""  && formik.values.dose !== ""))
  }, [formik.values])

  const handleOnClick = () => {
    if(handleAddTreatment){
      handleAddTreatment(formik.values);
      formik.resetForm();
    }
  }


  return {formik, handleOnClick, disabledAdd
  }
}