import * as yup from 'yup'

import { IFormField } from '../../../../../interfaces/common/form';
import { ILoginUserForm } from './LoginUser.interfaces';
import { ValidationError } from '../../../../../utils/messageValidation';


// login user form configuration
export const loginUserFormConfig: IFormField[] = [
  {
    name: 'pwz',
    label: 'numer PWZ',
    required: true,
    type: 'text'
  }
]


// validation schema for login user form
export const validationLoginUserSchema = yup.object().shape({
  pwz: yup.string().required(ValidationError.PWZ_NOT_CORRECT),
})


// initial data for login user form
export const initialLoginUserForm : ILoginUserForm = {
  pwz: ""
}
