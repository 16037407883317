import styled from 'styled-components';
import { FormControl } from '@mui/material';

export const SelectStyled = styled(FormControl)`
  position: relative;
  width:100%;
  min-width: 100%;
  
  div {
    width:100%;

  }
  
`
