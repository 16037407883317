import { useRootStore } from '../RootStateContext';

export const useQAPageStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.quPageStore) {
    throw new Error('quPageStore store should be defined');
  }

  return rootStore.quPageStore;
};
