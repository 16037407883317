import { Button, FormControl, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import isturisa from './../../../../../assets/img/isturisa.png';
import signifor from './../../../../../assets/img/signifor.png';
import { CardComponent } from '../../../../shared/card/cardMaterials/Card';
import { Add } from '@mui/icons-material';
import React from 'react';
import { useMaterials } from './useMaterials.vm';
import { observer } from 'mobx-react-lite';
import { StyledPagination } from '../../../../shared/pagination/StyledPagination';
import { useModal } from '../../../../shared/modal/useModal';
import { MaterialsModal } from './modal/MaterialsModal';
import { checkRoles } from '../../../../../utils/checkRoles';
import { RoleFunction } from '../../../../../constants/projectUsers';

export const Materials = observer(() => {
  const { handleClose, open, handleOpen, typeModal, id } = useModal();
  const {
    handleClickByGuest,
    isturisaArticles,
    signiforArticles,
    isturisaPageCount,
    signiforPageCount,
    isturisaCurrentPage,
    signiforCurrentPage,
    isturisaHandlePageChange,
    signiforHandlePageChange,
    isturisaArticlesDrawArray,
    signiforArticlesDrawArray,
    roleFunction,
    handleDelete,
    handleChange,
    handleEdit,
    checked
  } = useMaterials(handleOpen);

  return (
    <Grid container pt={1}>
      {checkRoles(roleFunction, RoleFunction.ROLE_ACCEPTING) &&
        <Grid item mt={2} mb={2} alignItems={'flex-end'} ml={'auto'}>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleChange}
                />
              }
              label='Dostęp tylko dla PWZ'
            />
          </FormControl>
          <Button disabled={false} startIcon={<Add />} variant='contained' color={'primary'}
                  onClick={() => handleOpen('add')}>DODAJ</Button>
        </Grid>}
      <Grid container>
        {isturisaArticles && isturisaArticles.length > 0 && <>
          <Typography variant='h4' component={'div'}>ISTURISA</Typography>
          <Grid container>
            {isturisaArticlesDrawArray?.map(el => (
              <Grid item xs={12} md={4} p={1} key={el.id} minHeight={'144px'}>
                <CardComponent
                  handleClickByGuest={handleClickByGuest}
                  isActive={el.categoryIsActive}
                  id={el.id}
                  style={el.nameImg ? 'left' : 'right'}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  link={process.env.REACT_APP_IMAGE_URL + `${el.pathFile}${el.namePDF}`}
                  title={el.title}
                  image={el.nameImg ? process.env.REACT_APP_IMAGE_URL + `${el.pathFile}${el.nameImg}` : isturisa}
                  imgHeight={'180'} />
              </Grid>
            ))}
            {isturisaArticles && isturisaArticles.length > 0 &&
              <Grid item xs={12} display={'flex'} justifyContent={'right'} mt={2} mb={2}>
                <StyledPagination
                  showFirstButton
                  showLastButton
                  size='small'
                  count={isturisaPageCount}
                  color='primary'
                  page={isturisaCurrentPage}
                  onChange={(e, value: number) => {
                    isturisaHandlePageChange(value);
                  }}
                />
              </Grid>
            }
          </Grid>
        </>
        }
        {signiforArticles && signiforArticles.length > 0 && <>
          <Typography variant='h4' component={'div'}>SIGNIFOR</Typography>
          <Grid container>
            {signiforArticlesDrawArray?.map(el => (
              <Grid item xs={12} md={4} p={1} key={el.id}>
                <CardComponent
                  handleClickByGuest={handleClickByGuest}
                  handleEdit={handleEdit}
                  isActive={el.categoryIsActive}
                  style={el.nameImg ? 'left' : 'right'}
                  id={el.id}
                  handleDelete={handleDelete}
                  link={process.env.REACT_APP_IMAGE_URL + `${el.pathFile}${el.namePDF}`} title={el.title}
                  image={el.nameImg ? process.env.REACT_APP_IMAGE_URL + `${el.pathFile}${el.nameImg}` : signifor}
                  imgHeight={'180'} />
              </Grid>
            ))}
          </Grid>
        </>
        }
        {signiforArticles && signiforArticles.length > 0 &&
          <Grid item xs={12} display={'flex'} justifyContent={'right'} mt={2} mb={2}>
            <StyledPagination
              showFirstButton showLastButton
              size='small'
              count={signiforPageCount}
              color='primary'
              page={signiforCurrentPage}
              onChange={(e, value: number) => {
                signiforHandlePageChange(value);
              }}
            />
          </Grid>
        }
      </Grid>
      {open && <MaterialsModal id={id} typeModal={typeModal} open={open} handleClose={handleClose} />}
    </Grid>
  );
});