import React, { FC } from 'react';
import { useArraySection } from '../../../../useArraySection';
import { useErrorEndDate } from '../../useErrorEndDate';
import { Button, Grid } from '@mui/material';
import { SelectCustom } from '../../../../../../../shared/ui/form/select/Select';
import {
  oktreotydDoseOptions
} from '../ThirdStage.helper';
import { DateInput } from '../../../../../../../shared/ui/form/date/Date';
import { GenericIcon } from '../../../../../../../shared/genericIcon/GenericIcon';
import { themeMUI } from '../../../../../../../../theme/theme';
import {
  IHypercortisolaemiaPasireotide,
  IHypercortisolaemiaPasireotideProps
} from './HypercortisolaemiaPasireotide.types';
import { initialHypercortisolaemiaPasireotide } from './HypercortisolaemiaPasireotide.helper';

export const HypercortisolaemiaPasireotide: FC<IHypercortisolaemiaPasireotideProps> = ({id, initial, handleAddHypercortisolaemiaPasireotide, editable, handleDeleteHypercortisolaemiaPasireotide}) => {
  const {formik, handleOnClick, disabledAdd} = useArraySection<IHypercortisolaemiaPasireotide>(initial ?? initialHypercortisolaemiaPasireotide, handleAddHypercortisolaemiaPasireotide)

  const errorDate = useErrorEndDate(formik, formik.values.dateStart, formik.values.dateEnd)

  return <>
    {!editable &&  <Grid item xs={0.5} mt={1} mb={editable ? 0: 1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                         justifyContent={'center'}>
      {Number(id) + 1}.
    </Grid>}

    <Grid item xs={3.6} pr={1} mt={1} mb={editable ? 0: 1}>
      <SelectCustom
        disabled={!editable}
        name={'dose'}
        required={true}
        placeholder={'dawka (mg)'}
        label={'Dawka (mg)'}
        onChange={formik.handleChange}
        value={formik.values.dose}
        options={oktreotydDoseOptions} />
    </Grid>
    <Grid item xs={3.6} pr={1} mt={1} mb={editable ? 0: 1}>
      <DateInput
        disabled={!editable}
        label={'okres - OD'}
        required={true}
        views={['year', 'month']}
        openTo={'year'}
        name={'dateStart'}
        inputFormat={'mm.yyyy'}
        value={formik.values.dateStart}
        onBlur={() => formik.setFieldTouched('dateStart', true)}
        onChange={(e) => formik.setFieldValue('dateStart', e)} />
    </Grid>
    <Grid item xs={3.6} mt={1} mb={editable ? 0: 1}>
      <DateInput
        disabled={!editable}
        label={'okres - DO'}
        isStill={true}
        required={true}
        views={['year', 'month']}
        openTo={'year'}
        name={'dateEnd'}
        error={errorDate}
        inputFormat={'mm.yyyy'}
        isActive={formik.values.dateEndIsActive}
        setIsActive={() => formik.setFieldValue('dateEndIsActive', !formik.values.dateEndIsActive)}
        value={formik.values.dateEnd}
        onBlur={() => formik.setFieldTouched('dateEnd', true)}
        onChange={(e) => formik.setFieldValue('dateEnd', e)} />
    </Grid>

    {handleDeleteHypercortisolaemiaPasireotide &&
      <Grid item xs={0.5} pl={1} display={'flex'} justifyContent={'center'}>
        <div style={{ margin: 'auto' }} onClick={() => {
          if (typeof id === 'number') {
            handleDeleteHypercortisolaemiaPasireotide(id);
          }
        }}>
          <GenericIcon
            iconName='Close'
            style={{
              color: themeMUI.palette.primary.main,
              cursor: 'pointer',
              verticalAlign: 'middle'
            }} />
        </div>
      </Grid>
    }
    {handleAddHypercortisolaemiaPasireotide &&
      <Grid item xs={1} pl={1} pt={1} display={'flex'} justifyContent={'center'}>
        <Button disabled={disabledAdd || errorDate} variant='contained' color={'primary'}
                onClick={handleOnClick}>ZAPISZ</Button>
      </Grid>
    }
  </>;
}