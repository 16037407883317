import { useLibraryStore, usePoiStore } from '../../../../../../store/hooks';
import { useLibraryApi } from '../../../../../../apiHooks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect } from 'react';

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(),
  link: yup
    .string()
    .matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi, 'Podany format URL jest nieprawidłowy')
    .required()
});

export const useLinksModal = (handleClose: () => void, id: number | null) => {
  const libraryStore = useLibraryStore();
  const { selectedCategoryObj } = libraryStore;
  const { addArticleLinks, deleteArticle } = useLibraryApi(handleClose);
  const poiStore = usePoiStore();
  const { errorMessage, errorCode } = poiStore;
  const formik = useFormik({
    initialValues: {
      title: '',
      link: ''
    },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (selectedCategoryObj) {
        const data = {
          title: values.title,
          link: values.link,
          category: selectedCategoryObj.id
        };
        await addArticleLinks(data);
      }
    }
  });

  useEffect(() => {
    return () => poiStore.clearError();
  }, [poiStore]);

  const titleErrorMessage = (errorCode === 404 || errorCode === 400) && errorMessage;

  const handleOnClick = () => {
    formik.handleSubmit();
  };

  const handleDeleteItem = () => {
    if (id) {
      void deleteArticle(id);
    }
  };

  return {
    formik,
    handleOnClick,
    handleDeleteItem,
    titleErrorMessage
  };
};