import { Button, Grid, Typography } from '@mui/material';
import { Input } from '../../../../../shared/ui/form/input/Input';
import { ModalRoot } from '../../../../../shared/modal/ModalRoot';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLinksModal } from './useLinksModal.vm';
import { titleTypeLink } from '../../../libraryData';
import { IModalType } from '../../../../../../interfaces/library/library';
import { themeMUI } from '../../../../../../theme/theme';

interface IProps {
  open: boolean,
  handleClose: () => void;
  typeModal: string | null
  id: number | null
}

export const LinksModal: React.FC<IProps> = observer(({ typeModal, id, open, handleClose }) => {
  const {
    formik,
    handleOnClick,
    handleDeleteItem,
    titleErrorMessage
  } = useLinksModal(handleClose, id);

  return (
    <ModalRoot open={open} handleClose={handleClose} title={titleTypeLink[typeModal as keyof IModalType]}>
      <Grid container pb={2} pl={3} pr={3} flexDirection={'column'}>
        {typeModal === 'add' && <>
          <Grid item xs={12}>
            <Input rows={2} multiline fullWidth={true} name={'title'} label={'*Tytuł'} type={'text'}
                   onChange={formik.handleChange} value={formik.values.title}
                   error={formik.touched.title && Boolean(formik.errors.title)} />
          </Grid>
          {titleErrorMessage && <Grid item xs={12}>
            <Typography variant={'caption'}
                        style={{ color: themeMUI.palette.error.main }}>{titleErrorMessage}</Typography>
          </Grid>}
          <Grid item xs={12} alignSelf='stretch' height='152px' pt={2} pb={2}>
            <Typography variant={'h6'} pb={2}>Dodaj link:</Typography>
            <Input fullWidth={true} name={'link'} label={'Link'} type={'text'}
                   onChange={formik.handleChange} value={formik.values.link}
                   errorMessage={formik.errors.link}
                   error={formik.touched.link && Boolean(formik.errors.link)} />
          </Grid>
        </>}
        {typeModal === 'delete' &&
          <Typography variant={'body1'}>Czy jesteś pewien/pewna że chcesz usunąć element?</Typography>}
        <Grid container width={'693px'} justifyContent={'flex-end'} mt={2}>
          <Grid item>
            <Button disabled={false} variant='outlined' color={'primary'}
                    onClick={handleClose}>Anuluj</Button>
          </Grid>
          <Grid item ml={2}>
            <Button disabled={false} variant='contained' color={'primary'}
                    onClick={typeModal === 'delete' ? handleDeleteItem : handleOnClick}>{typeModal === 'delete' ? 'Usuń' : 'Zapisz'}</Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalRoot>
  );
});