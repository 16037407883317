import React from 'react';
import { AuthController } from '../../shared/appLayer/authController/AuthController';
import { CaseLayout } from '../../shared/user/layout/caseLayout/CaseLayout';
import { Grid, Pagination } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CaseItem } from '../../shared/caseItem/CaseItem';
import { useMyCases } from './useMyCases.vm';
import { CaseFilter } from '../../shared/user/layout/caseFilter/CaseFilter';
import { checkRoles } from '../../../utils/checkRoles';
import { RoleFunction } from '../../../constants/projectUsers';
import { EmptyList } from '../../shared/customTable/emptyList/EmptyList';
import { ICaseStatus } from '../../../interfaces/common/common';

export const MyCases = observer(() => {
  const {
    myCases,
    isLoaded,
    emptyMessage,
    currentPage,
    totalPage,
    handlePageChange,
    search,
    handleSearch,
    status,
    handleSelect,
    handleChange,
    filter,
    expertType,
    roleFunction
  } = useMyCases();

  return (
    <AuthController>
      <CaseLayout tabsActive={expertType === ''} filter={filter} handleChange={handleChange}>
        <CaseFilter filter={filter} isStatus={true} status={status} handleSelect={handleSelect} search={search}
                    handleSearch={handleSearch} />
        <Grid container display={'flex'} flexWrap={'wrap'} mt={2}>
          {isLoaded && (myCases.length > 0 ?
            myCases.map(item => <CaseItem key={item.id} item={item} type={filter}
                                          disabledEvent={checkRoles(roleFunction, RoleFunction.ROLE_ACCEPTING) && item.status === ICaseStatus.TO_ACCEPTING}
                                          isActionAccept={checkRoles(roleFunction, RoleFunction.ROLE_ACCEPTING)}
                                          isActionPublish={checkRoles(roleFunction, RoleFunction.ROLE_PUBLISHING)} />) :
            <EmptyList>{emptyMessage}
            </EmptyList>)}
          {!!totalPage && <Grid item xs={12} display={'flex'} justifyContent={'right'}>
            <Pagination
              color={'primary'}
              size='small'
              count={totalPage}
              page={currentPage}
              onChange={(e, value: number) => {
                handlePageChange(value);
              }} showFirstButton showLastButton />
          </Grid>}
        </Grid>
      </CaseLayout>
    </AuthController>
  );
});