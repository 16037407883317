import { Grid, Typography } from '@mui/material';
import { Logo, StyledGrid } from './Page404.styled';
import logo from '../../../assets/img/logo-nav.png';
import { usePage404 } from './usePage404.vm';

const Page404 = () => {
  usePage404();
  return (
    <StyledGrid>
      <Grid maxWidth={'447px'}
            container height={'100vh'} justifyContent={'center'} alignItems={'center'} margin={'auto'}>
        <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'} alignContent={'flex-start'}
              maxWidth={'447px'} width={'100%'}
              item>
          <Logo src={logo} alt={'logo'} />
          <Grid width={'100%'} display={'flex'} mb={2} flexDirection={'column'} justifyContent={'center'}
                alignContent={'flex-start'} gap={'16px'}>
            <Typography variant={'h1'}>Strona 404</Typography>
          </Grid>
          <Grid width={'100%'} display={'flex'} mt={2} flexDirection={'column'} justifyContent={'center'}
                alignContent={'flex-start'} gap={'16px'}>
            <Typography variant={'body1'}>Niestety nie udało nam się znaleźć tej strony. Za 3 sekundy nastąpi
              przekierowanie na stronę główną.</Typography>
          </Grid>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

export default Page404;