import { useParams } from 'react-router-dom';
import { useCaseApi } from '../../../../../../apiHooks';
import { IAddSimpleQuestion, IModalAddQuestionProps } from '../ModalAddQuestion.types';
import { useForm } from '../../../../../../hooks/useForm';
import { initialAddQuestion, validationAddQuestionSchema } from '../ModalAddQuestion.helper';
import { Button, DialogActions, Grid } from '@mui/material';
import { SubHeader } from '../ModalAddQuestion.styled';
import { Input } from '../../../../ui/form/input/Input';
import React, { FC } from 'react';
import { useAuthStore } from '../../../../../../store/hooks';
import { RoleFunction } from '../../../../../../constants/projectUsers';

export const ModalAddQuestionSimple: FC<IModalAddQuestionProps> = ({ handleClose, handleConfirmation }) => {
  const { id, type } = useParams();
  const { postDiscussionElement } = useCaseApi();
  const {roleFunction} = useAuthStore()

  const onSubmit = async (values: IAddSimpleQuestion) => {
    let isCompleted = await postDiscussionElement(Number(id), String(type), values, roleFunction === RoleFunction.ROLE_EXPERT ? 'AUTHOR' : 'EXPERT');

    if (isCompleted) {
      handleConfirmation();
    }
  };

  const {
    formik
  } = useForm<IAddSimpleQuestion>(initialAddQuestion, validationAddQuestionSchema, onSubmit);

  return (
    <Grid container pb={2} pl={3} pr={3}>
      <Grid container maxWidth={'693px'} width={'95vw'} justifyContent={'flex-end'} mt={2}>
        <Grid item xs={12}>
          <SubHeader>Po udzieleniu odpowiedzi pytanie zostanie opublikowane i będzie widoczne na tej stronie dla
            wszystkich użytkowników.</SubHeader>
        </Grid>
        <Grid item xs={12} mt={2} mb={2}>
          <Input error={Boolean(formik.errors.name)} name={'name'} type={'text'} multiline={true}
                 onChange={formik.handleChange}
                 maxLength={255}
                 value={formik.values.name} rows={3} placeholder={'Treść pytania'} />
        </Grid>
        <DialogActions>
          <Button disabled={false} variant='outlined' color={'primary'}
                  onClick={handleClose}>Anuluj</Button>
          <Button disabled={false} variant='contained' color={'primary'}
                  onClick={() => formik.handleSubmit()}>Wyślij</Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};