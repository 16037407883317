
import { SortDirection } from '../interfaces/common/sort';
import { customSort } from '../utils/dateSort';
import { useEffect, useState } from 'react';

export function useSort<T extends object>(list: T[] | null, keys: (keyof T)[], sortDirectionInit: SortDirection = 'ASC') {
  const [reset, setReset] = useState(true);
  const [sortedList, setSortedList] = useState<T[] | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection>(sortDirectionInit);

  useEffect(()=> {
    if(reset && list){
      setSortedList(customSort<T>(list, keys, sortDirection));
      setReset(false);
    }
  }, [list, keys, sortDirection, sortedList, reset])

  const handleChangeSortDirection = (sortDirection: SortDirection) => {
    setSortDirection(sortDirection);
    setSortedList(customSort<T>(list, keys, sortDirection))
  }

  const resetSort = () => {
    setReset(true);
  }

  return {
    items: sortedList ?? [],
    sortDirection,
    handleChangeSortDirection,
    resetSort
  };
}