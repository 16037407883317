import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCaseStore } from '../../../../../store/hooks';
import { useEffect } from 'react';
import { useCaseApi } from '../../../../../apiHooks';
import { useNavigate, useParams } from 'react-router-dom';

export const validationSchema = yup.object().shape({
  comment: yup
    .string()
    .required()
});

export const useCommentModal = (handleClose?: () => void) => {
  const caseStore = useCaseStore();
  const { createComment } = useCaseApi();
  const params = useParams();
  const { type, id } = params;
  const { comment } = caseStore;
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      comment: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      if (type && id) {
        const res = await createComment(type, +id, values.comment);
        if (res) {
          handleClose && handleClose();
          await formik.setFieldValue('comment', '');
          caseStore.saveComment('');
          navigate(`/cases/${type}`);
        }
      }
    }
  });

  const { setFieldValue } = formik;

  useEffect(() => {
    if (comment) {
      setFieldValue('comment', comment);
    }
  }, [comment, setFieldValue]);

  const handleHide = (comment: string) => {
    caseStore.saveComment(comment);
    handleClose && handleClose();
  };


  return {
    formik,
    handleHide
  };
};