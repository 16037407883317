import {useParams} from 'react-router-dom';
import {useModal} from '../../../shared/modal/useModal';
import {QUPageModal} from '../../qaPage/QUPage.types';
import React, {useEffect} from 'react';
import {useCaseApi} from '../../../../apiHooks';
import {useAuthStore, useCaseStore} from '../../../../store/hooks';
import {ModalType} from '../../../shared/modal/ModalRoot.types';
import {ModalAddQuestion} from '../../../shared/modal/common/modalAddQuestion/ModalAddQuestion';
import {
  ModalAddQuestionConfirmation
} from '../../../shared/modal/common/modalAddQuestionConfirmation/ModalAddQuestionConfirmation';
import {getModalSelectedByList} from '../../../shared/modal/ModalRoot.helper';
import {useSort} from '../../../../hooks/useSort';
import {useAnswer} from '../../../../hooks/useAnswer';
import {checkRoles} from "../../../../utils/checkRoles";
import {RoleFunction} from "../../../../constants/projectUsers";

export const useDiscussionVm = () => {
  const {getDiscussion, postDiscussionAnswer,  putDiscussionAnswerVisibility} = useCaseApi();
  const caseStore = useCaseStore();
  const {roleFunction} = useAuthStore();
  const { id, type } = useParams();

  const {
    items,
    sortDirection,
    handleChangeSortDirection,
    resetSort
  } = useSort(caseStore.discussion, ['answer', 'dateWithHour']);

  const {
    open,
    handleClose,
    handleOpen,
    typeModal
  } = useModal<QUPageModal>();

  const {
    getAnswer,
    handleAddAnswer,
    handleChangeAnswer,
    resetAnswers
  } = useAnswer(caseStore.discussion,
    async (idAnswer: number, answer: string) => {
      await postDiscussionAnswer(Number(id), String(type), idAnswer, answer);
      resetSort();
      resetAnswers();
    }
  )

  useEffect( () => {
    void getDiscussion(Number(id), String(type), caseStore.discussion === null);
  }, [type, id, getDiscussion, caseStore]);


  const handleOpenModal = () => {
    handleOpen('add-question');
  };

  const handleConfirmation = () => {
    handleOpen('add-question-confirmation');
    resetAnswers();
    resetSort();
  };

  const handleSort = () => {
    handleChangeSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
  }

  const handleHiddenQuestion = async (idCase: number) => {
    await putDiscussionAnswerVisibility(Number(id), idCase, String(type));
    resetSort();
    resetAnswers();
  }

  const types: ModalType<QUPageModal>[] = [
    {
      key: 'add-question',
      element: <ModalAddQuestion type={'discussion'}
                                 handleClose={handleClose}
                                 handleConfirmation={handleConfirmation}
      />,
      title: 'Zadaj pytanie:'
    },
    {
      key: 'add-question-confirmation',
      element: <ModalAddQuestionConfirmation handleClose={handleClose} />,
      title: 'Pytanie wysłane',
      titleJustify: 'center'
    }
  ];

  const modalSelected = getModalSelectedByList<QUPageModal>(typeModal, types);

  const emptyMessage = (caseStore.discussion  !== null && items.length === 0) ?
      `Dyskusja między autorem opisu przypadku medycznego, a ekspertem merytorycznym nie została rozpoczęta.${checkRoles(roleFunction, [RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_EXPERT]) ? ' Możesz ją rozpocząć używając przycisku “Zadaj pytanie?” znajdującego się w lewej dolnej części ekranu.': ''}`
      : null;

  return {
    isAuthor : caseStore.firstStage ? caseStore.firstStage?.isAuthor : false,
    roleFunction,
    items,
    type,
    modalSelected,
    open,
    getAnswer,
    handleChangeAnswer,
    handleOpenModal,
    handleConfirmation,
    handleClose,
    handleOpen,
    handleSort,
    handleAddAnswer,
    handleHiddenQuestion,
    typeModal,
    emptyMessage,
    resetAnswers
  };
};