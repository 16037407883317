import styled from 'styled-components';

export const TabsContainer = styled.div`
  .MuiPaginationItem-root {
    padding: 0;
  }
`;

export const Cap = styled.div`
  width: 100%;
  height: 164px;
  top: 8px;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute
`;
