import styled, { keyframes } from 'styled-components';
import { IDiseaseType } from '../../../../../interfaces/common/common';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const Section = styled.main`
  padding-top: 100px;
  color: black;
  padding-bottom: 32px;
  max-width: 1280px;
  margin: auto;
  min-height: calc(100vh - 86px);
`;

export const ImageHomeLayout = styled.div<IDiseaseType>`
  background: url('/assets/img/home-layout-${props => props.type}.png');
  width: 100%;
  height: calc(100% - 100px);
  position: absolute;
  left: -35px;
  background-repeat: no-repeat;
  animation: 1s ${fadeIn} ease-out;
`;

export const TabsContainer = styled.div`
  display: flex;

  .tabs-acromegaly {
    .Mui-selected {
      color: ${props => props.theme.colors.acromegaly1};
    }

    .MuiTabs-indicator {
      background: ${props => props.theme.colors.acromegaly1};
    }
  }

  .tabs-cushing {
    .Mui-selected {
      color: ${props => props.theme.colors.cushing1};
    }

    .MuiTabs-indicator {
      background: ${props => props.theme.colors.cushing1};
    }
  }
`;
