import { useAuthStore, useQAPageStore } from '../../../store/hooks';
import { useNotificationsApi, useQAElementsApi } from '../../../apiHooks';
import { useModal } from '../../shared/modal/useModal';
import { QUPageModal, QUPageTab } from './QUPage.types';
import React, { useEffect, useState } from 'react';
import { ModalType } from '../../shared/modal/ModalRoot.types';
import { ModalAddQuestion } from '../../shared/modal/common/modalAddQuestion/ModalAddQuestion';
import {
  ModalAddQuestionConfirmation
} from '../../shared/modal/common/modalAddQuestionConfirmation/ModalAddQuestionConfirmation';
import { getModalSelectedByList } from '../../shared/modal/ModalRoot.helper';
import { SortDirection } from '../../../interfaces/common/sort';
import { isOfTypeQUPageTab, optionsAddQuestionQA } from './QAPage.helper';
import { useSort } from '../../../hooks/useSort';
import { useNavigate, useParams } from 'react-router-dom';
import { RoleFunction } from '../../../constants/projectUsers';
import { GeneralLink } from '../../../utils';

export const useQAPageVm = () => {
  const { roleFunction } = useAuthStore();
  const { clearQANotification } = useNotificationsApi();
  const quPageStore = useQAPageStore();
  const { getQAMedicalElements, getQATechnicalElements } = useQAElementsApi();
  const navigate = useNavigate();
  const params = useParams();
  const { qaTab } = params;

  useEffect(()=> {
    if(!isOfTypeQUPageTab(String(qaTab))){
      navigate(GeneralLink.PAGE_404);
    }
  }, [navigate, qaTab])

  const [tab, setTab] = useState<QUPageTab>(qaTab as QUPageTab ?? 'medical');
  const {
    items: medicalItems,
    handleChangeSortDirection: medicalHandleChangeSortDirection,
    resetSort: medicalResetSort
  } = useSort(quPageStore.quMedicalElements, ['answer', 'dateWithHour']);

  const {
    items: technicalItems,
    handleChangeSortDirection: technicalHandleChangeSortDirection,
    resetSort: technicalResetSort
  } = useSort(quPageStore.quTechnicalElements, ['answer', 'dateWithHour']);


  const {
    open,
    handleClose,
    handleOpen,
    typeModal
  } = useModal<QUPageModal>();

  useEffect(() => {
    if(roleFunction !== RoleFunction.ROLE_VIEWING){
      void clearQANotification();
    }

  }, [clearQANotification, roleFunction]);

  useEffect(() => {
    if (tab === 'medical') {
      void getQAMedicalElements(quPageStore.quMedicalElements === null);
    } else {
      void getQATechnicalElements(quPageStore.quTechnicalElements === null);
    }
  }, [tab, getQAMedicalElements, getQATechnicalElements, quPageStore]);

  useEffect(() => {
    if (quPageStore.quMedicalElements === null) {
      medicalResetSort();
    }
  }, [quPageStore.quMedicalElements, medicalResetSort]);

  useEffect(() => {
    if (quPageStore.quTechnicalElements === null) {
      technicalResetSort();
    }
  }, [quPageStore.quTechnicalElements, technicalResetSort]);

  const handleConfirmation = () => {
    handleOpen('add-question-confirmation');
  };

  const handleOpenModal = () => {
    handleOpen('add-question');
  };

  const handleSortClick = (sortDirection: SortDirection) => {
    medicalHandleChangeSortDirection(sortDirection);
    technicalHandleChangeSortDirection(sortDirection);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: QUPageTab) => {
    setTab(newValue);
    navigate(`/qa/${newValue}`);
  };

  useEffect(() => {
    if (qaTab) {
      setTab(qaTab as QUPageTab);
    }
  }, [qaTab]);

  const types: ModalType<QUPageModal>[] = [
    {
      key: 'add-question',
      element: <ModalAddQuestion type={'qa'} values={optionsAddQuestionQA}
                                 handleClose={handleClose}
                                 handleConfirmation={handleConfirmation} />,
      title: 'Zadaj pytanie:'
    },
    {
      key: 'add-question-confirmation',
      element: <ModalAddQuestionConfirmation handleClose={handleClose} />,
      title: 'Pytanie wysłane',
      titleJustify: 'center'
    }
  ];

  const modalSelected = getModalSelectedByList<QUPageModal>(typeModal, types);

  return {
    technicalItems,
    medicalItems,
    tab,
    roleFunction,
    open,
    handleClose,
    handleOpenModal,
    handleTabChange,
    modalSelected,
    handleSortClick,
    technicalResetSort,
    medicalResetSort
  };
};