import { Button, Checkbox, FormControlLabel, Grid, Radio } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Fieldset } from '../../../../../../shared/ui/form/fieldset/FieldsetContainer';
import { useThirdStageVm } from './useThirdStage.vm';
import { Input } from '../../../../../../shared/ui/form/input/Input';
import { InputController } from '../../../../../../shared/ui/form/inputController/InputController';
import { IInputControllerThirdStage, IThirdStageValues } from './ThirdStage.types';
import { SelectCustom } from '../../../../../../shared/ui/form/select/Select';
import {
  carbohydrateMetabolismDisordersTypeOptions,
  complicationsOfNeurosurgicalSurgariesCheckboxesConfig,
  qualificationForTreatmentNeurosurgicalOptions,
  startOfTreatmentPasireotideLARNormalizationLevelOptions,
  startOfTreatmentPasireotideLARReasonsCheckboxesConfig,
  startOfTreatmentPegvisomantReasonsCheckboxesConfig,
  supportiveTreatmentOfCoexistingDiseasesCheckboxesConfig,
  toleranceTreatmentCheckboxesConfig
} from './ThirdStage.helper';
import { Surgary } from './surgary/Surgary';
import { Divider, LabelRadioGroup } from '../../../CaseDraft.styled';
import { Drug } from './drug/Drug';
import { RadioGroupStyled } from '../../../../../../shared/ui/form/radioGroupStyled/RadioGroupStyled.styled';
import { CheckboxesGroup } from '../../../../../../shared/ui/form/checkboxesGroup/CheckboxesGroup';
import { ButtonsActions } from '../../common/buttonsActions/ButtonsActions';
import { radioValueOptional } from '../../../CaseDraft.helper';
import { Add } from '@mui/icons-material';
import { SomastatinAnalog } from './somastatinAnalog/SomastatinAnalog';
import { Cabergoline } from './cabergoline/Cabergoline';
import {
  TreatmentOfChronicAcromegallyAnalogue
} from './treatmentOfChronicAcromegallyAnalogue/TreatmentOfChronicAcromegallyAnalogue';
import {
  TreatmentOfChronicAcromegallyPasireotide
} from './treatmentOfChronicAcromegallyPasireotide/treatmentOfChronicAcromegallyPasireotide';
import {
  TreatmentOfChronicAcromegallyPegvisomant
} from './treatmentOfChronicAcromegallyPegvisomant/TreatmentOfChronicAcromegallyPegvisomant';
import {
  TreatmentOfChronicAcromegallyCabergoline
} from './treatmentOfChronicAcromegallyCabergoline/TreatmentOfChronicAcromegallyCabergoline';

export const ThirdStage = observer(() => {
  const {
    setActive,
    setActiveBroadcast,
    inputControllerElements,
    activeFieldset,
    formik,
    editableAddSomastatinAnalog,
    handleEditableAddSomastatinAnalog,
    handleAddSomastatinAnalog,
    handleDeleteSomastatinAnalog,
    editableAddCabergoline,
    handleEditableAddCabergoline,
    handleAddCabergoline,
    handleDeleteCabergoline,
    handleEditableAddSurgery,
    editableAddSurgery,
    handleAddSurgery,
    handleDeleteSurgery,
    handleAddDrug,
    handleEditableAddDrug,
    editableAddDrug,
    handleDeleteDrug,
    editableAddTreatmentOfChronicAcromegally,
    handleEditableAddTreatmentOfChronicAcromegallyAnalogue,
    handleAddTreatmentOfChronicAcromegallyAnalogue,
    handleDeleteTreatmentOfChronicAcromegalyAnalogue,
    editableAddTreatmentOfChronicAcromegallyPasireotide,
    handleDeleteTreatmentOfChronicAcromegallyPasireotide,
    handleEditableAddTreatmentOfChronicAcromegallyPasireotide,
    handleAddTreatmentOfChronicAcromegallyPasireotide,
    editableAddTreatmentOfChronicAcromegallyPegvisomant,
    handleDeleteTreatmentOfChronicAcromegallyPegvisomant,
    handleEditableAddTreatmentOfChronicAcromegallyPegvisomant,
    handleAddTreatmentOfChronicAcromegallyPegvisomant,
    editableAddTreatmentOfChronicAcromegallyCabergoline,
    handleDeleteTreatmentOfChronicAcromegallyCabergoline,
    handleEditableAddTreatmentOfChronicAcromegallyCabergoline,
    handleAddTreatmentOfChronicAcromegallyCabergoline,
    handleSave,
    handleDraftSave,
    handleChangeArray,
    handleChangeSwitchAndCheckbox,
    handleResetFieldset
  } = useThirdStageVm();


  return (<Grid container justifyContent={'space-between'}>
    <Fieldset legend={'Leczenie operacyjne'}>
      <Grid container mt={3}>
        <Grid item xs={12} mb={1} display={'flex'} flexDirection={'row'} alignItems={'flex-start'}
              justifyContent={'center'}>
          <InputController<IInputControllerThirdStage>
            setBroadcast={setActiveBroadcast}
            active={inputControllerElements.numberOfMonthsFromDiagnosisToTreatment}
            broadcastFields={['numberOfMonthsFromDiagnosisToTreatment', 'reasonForNotTreating']}
            setField={formik.setFieldValue}
            radio={{
              name: 'numberOfMonthsFromDiagnosisToTreatmentRadio',
              label: 'Leczenie operacyjne'
            }}
            inputName={'numberOfMonthsFromDiagnosisToTreatment'}>
            <Input
              disabled={!inputControllerElements.numberOfMonthsFromDiagnosisToTreatment}
              name={'numberOfMonthsFromDiagnosisToTreatment'}
              type={'text'}
              onChange={formik.handleChange}
              value={formik.values.numberOfMonthsFromDiagnosisToTreatment}
              placeholder={'Liczba miesięcy od rozpoznania do zastosowanego leczenia'} />
          </InputController>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {formik.values.surgeries.map((surgery, index) => <Surgary id={index} key={index} editable={false}
                                                                      initial={surgery}
                                                                      handleDeleteSurgery={handleDeleteSurgery} />)}
            {formik.values.surgeries.length > 0 && <Divider margin={4}/>}
            {editableAddSurgery && <Surgary editable={true}
                     handleAddSurgery={handleAddSurgery} />}
            <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} mt={2}>
              <Button disabled={inputControllerElements.numberOfMonthsFromDiagnosisToTreatment ? editableAddSurgery : true} startIcon={<Add />} variant='contained' color={'primary'}
                      onClick={handleEditableAddSurgery}>DODAJ</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={1} mt={2}>
          <InputController<IInputControllerThirdStage>
            setBroadcast={setActiveBroadcast}
            active={inputControllerElements.reasonForNotTreating}
            broadcastFields={['numberOfMonthsFromDiagnosisToTreatment', 'reasonForNotTreating']}
            setField={formik.setFieldValue}
            radio={{
              name: 'reasonForNotTreatingRadio',
              label: 'Brak leczenia operacyjnego'
            }}
            inputName={'reasonForNotTreating'}>
            <Input
              disabled={!inputControllerElements.reasonForNotTreating}
              name={'reasonForNotTreating'}
              type={'text'}
              onChange={formik.handleChange}
              value={formik.values.reasonForNotTreating}
              placeholder={'Przyczyna'} />
          </InputController>
        </Grid>

      </Grid>
    </Fieldset>
    <Fieldset legend={'Leczenie przygotowawcze przed leczeniem operacyjnym (jeśli dotyczy)'}
              activeKey={activeFieldset}
              keyFieldset={'valid-fieldset-1'}
              handleReset={handleResetFieldset}>
      <Grid container mt={3}>
        <Grid item xs={12} mb={2}>
          <p>Analog somatostatyny I generacji</p>
        </Grid>
        {formik.values.somastatinAnalogs.map((somastatinAnalog, index) => <SomastatinAnalog key={index} id={index}
                                                                           editable={false}
                                                                           initial={somastatinAnalog}
                                                                           handleDeleteSomastatinAnalog={handleDeleteSomastatinAnalog} />)}
        {formik.values.somastatinAnalogs.length > 0 && <Divider margin={4}/>}
        {editableAddSomastatinAnalog && <SomastatinAnalog editable={true} handleAddSomastatinAnalog={handleAddSomastatinAnalog} />}
        <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} mt={formik.values.somastatinAnalogs.length > 0 || editableAddSomastatinAnalog ? 2 : 0}>
          <Button disabled={editableAddSomastatinAnalog} startIcon={<Add />} variant='contained' color={'primary'}
                  onClick={handleEditableAddSomastatinAnalog}>DODAJ</Button>
        </Grid>

        <Grid item xs={12} mb={2} mt={2}>
          <p>Kabergolina</p>
        </Grid>
        {formik.values.cabergolines.map((cabergoline, index) => <Cabergoline key={index} id={index}
                                                                                            editable={false}
                                                                                            initial={cabergoline}
                                                                                            handleDeleteCabergoline={handleDeleteCabergoline} />)}
        {formik.values.cabergolines.length > 0 && <Divider margin={4}/>}
        {editableAddCabergoline && <Cabergoline editable={true} handleAddCabergoline={handleAddCabergoline} />}
        <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} mt={formik.values.cabergolines.length > 0 || editableAddCabergoline ? 2 : 0}>
          <Button disabled={editableAddCabergoline} startIcon={<Add />} variant='contained' color={'primary'}
                  onClick={handleEditableAddCabergoline}>DODAJ</Button>
        </Grid>

        <Grid item xs={12} mt={2} mb={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(formik.values.withoutPharmacologicalPreparation)}
                onChange={(event) => handleChangeSwitchAndCheckbox('withoutPharmacologicalPreparation', event.target.checked ? 'Bez przygotowania farmakologicznego' : '')}
                name={'withoutPharmacologicalPreparation'} />
            }
            label={'Bez przygotowania farmakologicznego'}
          />
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Powikłania leczenia neurochirurgicznego'}>
      <Grid container mt={3}>
        <Grid display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'} gap={3}>
          <span>Moczówka prosta</span>
          <RadioGroupStyled row={true} name={'complicationsDiabetesInsipidus'}
                            value={formik.values.complicationsDiabetesInsipidus}>
            <FormControlLabel onChange={formik.handleChange} value={'Trwała'} control={<Radio onClick={() => formik.setFieldValue('complicationsDiabetesInsipidus', radioValueOptional(formik.values.complicationsDiabetesInsipidus, 'Trwała'))} />} label='Trwała' />
            <FormControlLabel onChange={formik.handleChange} value={'Przemijająca'} control={<Radio onClick={() => formik.setFieldValue('complicationsDiabetesInsipidus', radioValueOptional(formik.values.complicationsDiabetesInsipidus, 'Przemijająca'))}/>}
                              label='Przemijająca' />
          </RadioGroupStyled>
        </Grid>
        <Grid item xs={12} mt={2}>
          <CheckboxesGroup<IThirdStageValues>
            singleField={true}
            full={true}
            name={'complicationsDiabetesInsipidusHow'}
            elements={complicationsOfNeurosurgicalSurgariesCheckboxesConfig}
            onChange={handleChangeArray}
            values={formik.values.complicationsDiabetesInsipidusHow} />
        </Grid>
      </Grid>
    </Fieldset>


    <Fieldset
      legend={'Potwierdzenie przetrwałej akromegalii'}
      activeKey={activeFieldset}
      keyFieldset={'valid-fieldset-2'}
      handleReset={handleResetFieldset}>
      <Grid container mt={3}>
        <Grid item xs={12} mb={2}>
          <p>Analog somatostatyny I generacji</p>
        </Grid>
        {formik.values.treatmentOfChronicAcromegallyAnalogues.map((treatmentOfChronicAcromegallyAnalogue, index) => <TreatmentOfChronicAcromegallyAnalogue id={index} key={index} editable={false}
                                                                                                                                                         initial={treatmentOfChronicAcromegallyAnalogue}
                                                                                                                                                         handleDeleteTreatmentOfChronicAcromegallyAnalogue={handleDeleteTreatmentOfChronicAcromegalyAnalogue} />)}
        {formik.values.treatmentOfChronicAcromegallyAnalogues.length > 0 && <Divider margin={4}/>}
        {editableAddTreatmentOfChronicAcromegally && <TreatmentOfChronicAcromegallyAnalogue editable={true}
                                                                                           handleAddTreatmentOfChronicAcromegallyAnalogue={handleAddTreatmentOfChronicAcromegallyAnalogue} />}
        <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} mt={formik.values.treatmentOfChronicAcromegallyAnalogues.length > 0 || editableAddTreatmentOfChronicAcromegally ? 2 : 0}>
          <Button disabled={editableAddTreatmentOfChronicAcromegally} startIcon={<Add />} variant='contained' color={'primary'}
                  onClick={handleEditableAddTreatmentOfChronicAcromegallyAnalogue}>DODAJ</Button>
        </Grid>

        <Grid item xs={12} mb={2} mt={2}>
          <p>Pasyreotyd LAR</p>
        </Grid>
        {formik.values.treatmentOfChronicAcromegallyPasireotides.map((treatmentOfChronicAcromegallyPasireotide, index) => <TreatmentOfChronicAcromegallyPasireotide id={index} key={index} editable={false}
                                                                                                                                                        initial={treatmentOfChronicAcromegallyPasireotide}
                                                                                                                                                        handleDeleteTreatmentOfChronicAcromegallyPasireotide={handleDeleteTreatmentOfChronicAcromegallyPasireotide} />)}
        {formik.values.treatmentOfChronicAcromegallyPasireotides.length > 0 && <Divider margin={4}/>}
        {editableAddTreatmentOfChronicAcromegallyPasireotide && <TreatmentOfChronicAcromegallyPasireotide editable={true}
                                                                                          handleAddTreatmentOfChronicAcromegallyPasireotide={handleAddTreatmentOfChronicAcromegallyPasireotide} />}
        <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} mt={formik.values.treatmentOfChronicAcromegallyPasireotides.length > 0 || editableAddTreatmentOfChronicAcromegallyPasireotide ? 2 : 0}>
          <Button disabled={editableAddTreatmentOfChronicAcromegallyPasireotide} startIcon={<Add />} variant='contained' color={'primary'}
                  onClick={handleEditableAddTreatmentOfChronicAcromegallyPasireotide}>DODAJ</Button>
        </Grid>

        <Grid item xs={12} mb={2} mt={2}>
          <p>Pegvisomant</p>
        </Grid>
        {formik.values.treatmentOfChronicAcromegallyPegvisomants.map((treatmentOfChronicAcromegallyPegvisomant, index) => <TreatmentOfChronicAcromegallyPegvisomant id={index} key={index} editable={false}
                                                                                                                                                                  initial={treatmentOfChronicAcromegallyPegvisomant}
                                                                                                                                                                  handleDeleteTreatmentOfChronicAcromegallyPegvisomant={handleDeleteTreatmentOfChronicAcromegallyPegvisomant} />)}
        {formik.values.treatmentOfChronicAcromegallyPegvisomants.length > 0 && <Divider margin={4}/>}
        {editableAddTreatmentOfChronicAcromegallyPegvisomant && <TreatmentOfChronicAcromegallyPegvisomant editable={true}
                                                                                                         handleAddTreatmentOfChronicAcromegallyPegvisomant={handleAddTreatmentOfChronicAcromegallyPegvisomant} />}
        <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} mt={formik.values.treatmentOfChronicAcromegallyPegvisomants.length > 0 || editableAddTreatmentOfChronicAcromegallyPegvisomant ? 2 : 0}>
          <Button disabled={editableAddTreatmentOfChronicAcromegallyPegvisomant} startIcon={<Add />} variant='contained' color={'primary'}
                  onClick={handleEditableAddTreatmentOfChronicAcromegallyPegvisomant}>DODAJ</Button>
        </Grid>


        <Grid item xs={12} mb={2} mt={2}>
          <p>Kabergolina</p>
        </Grid>

        {formik.values.treatmentOfChronicAcromegallyCabergolines.map((treatmentOfChronicAcromegallyCabergoline, index) => <TreatmentOfChronicAcromegallyCabergoline id={index} key={index} editable={false}
                                                                                                                                                                 initial={treatmentOfChronicAcromegallyCabergoline}
                                                                                                                                                                 handleDeleteTreatmentOfChronicAcromegallyCabergoline={handleDeleteTreatmentOfChronicAcromegallyCabergoline} />)}
        {formik.values.treatmentOfChronicAcromegallyCabergolines.length > 0 && <Divider margin={4}/>}
        {editableAddTreatmentOfChronicAcromegallyCabergoline && <TreatmentOfChronicAcromegallyCabergoline editable={true}
                                                                                                        handleAddTreatmentOfChronicAcromegallyCabergoline={handleAddTreatmentOfChronicAcromegallyCabergoline} />}
        <Grid item xs={12} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} mt={formik.values.treatmentOfChronicAcromegallyCabergolines.length > 0 || editableAddTreatmentOfChronicAcromegallyCabergoline ? 2 : 0}>
          <Button disabled={editableAddTreatmentOfChronicAcromegallyCabergoline} startIcon={<Add />} variant='contained' color={'primary'}
                  onClick={handleEditableAddTreatmentOfChronicAcromegallyCabergoline}>DODAJ</Button>

      </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Kwalifikacja do ponownego leczenia'}>
      <Grid container mt={3}>
        <Grid item xs={12} mb={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
              justifyContent={'center'}>
          <InputController<IInputControllerThirdStage>
            setActive={setActive}
            active={inputControllerElements.qualificationForSurgicalTreatmentHowAccess}
            setField={formik.setFieldValue}
            checkbox={{
              name: 'qualificationForSurgicalTreatmentHowAccessCheckbox',
              label: 'neurochirurgicznego'
            }}
            inputName={'qualificationForSurgicalTreatmentHowAccess'}>
            <SelectCustom
              disabled={!inputControllerElements.qualificationForSurgicalTreatmentHowAccess}
              name={'qualificationForSurgicalTreatmentHowAccess'}
              placeholder={'Dostęp'}
              label={'Dostęp'}
              onChange={formik.handleChange}
              value={formik.values.qualificationForSurgicalTreatmentHowAccess}
              options={qualificationForTreatmentNeurosurgicalOptions} />

          </InputController>
        </Grid>
        <Grid item xs={12} mb={1}>
          <InputController<IInputControllerThirdStage>
            setActive={setActive}
            active={inputControllerElements.qualificationForRadiotherapyTreatmentType}
            setField={formik.setFieldValue}
            checkbox={{
              name: 'qualificationForRadiotherapyTreatmentTypeCheckbox',
              label: 'radioterapią'
            }}
            inputName={'qualificationForRadiotherapyTreatmentType'}>
            <Input
              disabled={!inputControllerElements.qualificationForRadiotherapyTreatmentType}
              name={'qualificationForRadiotherapyTreatmentType'}
              type={'text'}
              onChange={formik.handleChange}
              value={formik.values.qualificationForRadiotherapyTreatmentType}
              placeholder={'typ radioterapii'}
              label={'Typ radioterapii'}
            />
          </InputController>

        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset
      legend={'Ocena hormonalna w trakcie leczenia farmakologicznego'}
      activeKey={activeFieldset}
      keyFieldset={'valid-fieldset-3'}
      handleReset={handleResetFieldset}>
      <Grid container mt={3}>
        <Grid display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'} gap={3}>
          <LabelRadioGroup error={Boolean(formik.errors.achievingHormonalNormalizationGh)}>*GH</LabelRadioGroup>
          <RadioGroupStyled row={true} name={'achievingHormonalNormalizationGh'}
                            value={formik.values.achievingHormonalNormalizationGh}>
            <FormControlLabel onChange={formik.handleChange} value={'< 0,4 μg/l'} control={<Radio
              color={Boolean(formik.errors.achievingHormonalNormalizationGh) ? 'error' : 'primary'} />}
                              label='< 0,4 μg/l' />
            <FormControlLabel onChange={formik.handleChange} value={'< 1,0 μg/l'} control={<Radio
              color={Boolean(formik.errors.achievingHormonalNormalizationGh) ? 'error' : 'primary'} />}
                              label='< 1,0 μg/l' />
            <FormControlLabel onChange={formik.handleChange} value={'≥ 1,0 μg/l'} control={<Radio
              color={Boolean(formik.errors.achievingHormonalNormalizationGh) ? 'error' : 'primary'} />}
                              label='≥ 1,0 μg/l' />
          </RadioGroupStyled>
        </Grid>
        <Divider />
        <Grid item xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'} gap={2}>
          <LabelRadioGroup error={Boolean(formik.errors.achievingHormonalNormalizationIgf1)}>*stężenie
            IGF-1</LabelRadioGroup>
          <RadioGroupStyled row={true} name={'achievingHormonalNormalizationIgf1'}
                            value={formik.values.achievingHormonalNormalizationIgf1}>
            <FormControlLabel onChange={formik.handleChange} value={'W normie dla płci i wieku'} control={<Radio
              color={Boolean(formik.errors.achievingHormonalNormalizationIgf1) ? 'error' : 'primary'} />}
                              label='W normie dla płci i wieku' />
            <FormControlLabel onChange={formik.handleChange} value={'Powyżej normy dla płci i wieku'} control={<Radio
              color={Boolean(formik.errors.achievingHormonalNormalizationIgf1) ? 'error' : 'primary'} />}
                              label='Powyżej normy dla płci i wieku' />
          </RadioGroupStyled>
        </Grid>
        <Divider />
        <Grid item xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'} gap={2}>
          <Input
            disabled={formik.values.achievingHormonalNormalizationIgf1 !== 'Powyżej normy dla płci i wieku'}
            name={'achievingHormonalNormalizationIgf1Rate'}
            type={'text'}
            onChange={formik.handleChange}
            value={formik.values.achievingHormonalNormalizationIgf1Rate}
            placeholder={'ocena IGF-1 w stosunku do ULN'}
            label={'Ocena IGF-1 w stosunku do ULN'}
          />
        </Grid>
        <Divider />
        <Grid item xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyItems={'center'} gap={2}>
          <RadioGroupStyled row={true} name={'serumPrlGrade'} value={formik.values.serumPrlGrade}>
            <FormControlLabel onChange={formik.handleChange} value={'PRL w surowicy w normie'} control={<Radio onClick={() => formik.setFieldValue('serumPrlGrade', radioValueOptional(formik.values.serumPrlGrade, 'PRL w surowicy w normie'))} />}
                              label='PRL w surowicy w normie' />
            <FormControlLabel onChange={formik.handleChange} value={'PRL w surowicy powyżej normy'} control={<Radio onClick={() => formik.setFieldValue('serumPrlGrade', radioValueOptional(formik.values.serumPrlGrade, 'PRL w surowicy powyżej normy'))} />}
                              label='PRL w surowicy powyżej normy' />
          </RadioGroupStyled>
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Tolerancja stosowanego leczenia (czy obserwowano powikłania / jakie?)'}>
      <Grid container mt={3}>
        <Grid item xs={12} mt={1} mb={2}>
          <CheckboxesGroup<IThirdStageValues> name={'toleranceOfTheTreatmentUsed'}
                                              elements={toleranceTreatmentCheckboxesConfig}
                                              onChange={handleChangeArray}
                                              values={formik.values.toleranceOfTheTreatmentUsed} />

        </Grid>
        <Grid item xs={12}>
          <InputController<IInputControllerThirdStage>
            setActive={setActive}
            isHalf={true}
            active={inputControllerElements.toleranceOfTheTreatmentUsedType}
            setField={formik.setFieldValue}
            checkbox={{
              name: 'toleranceOfTheTreatmentUsedTypeCheckbox',
              label: 'zaburzenia gospodarki węglowodanowej nieobecne wcześniej'
            }}
            inputName={'toleranceOfTheTreatmentUsedType'}>
            <SelectCustom
              disabled={!inputControllerElements.toleranceOfTheTreatmentUsedType}
              name={'toleranceOfTheTreatmentUsedType'}
              placeholder={'Typ/rodzaj'}
              label={'Typ/rodzaj'}
              onChange={formik.handleChange}
              value={formik.values.toleranceOfTheTreatmentUsedType}
              options={carbohydrateMetabolismDisordersTypeOptions} />
          </InputController>
        </Grid>
      </Grid>
    </Fieldset>
    <Fieldset legend={'Leczenie chorób współistniejących/powikłań'}>
      <Grid container mt={3}>
        {formik.values.treatmentOfComorbidities.map((drug, index) => <Drug key={index} id={index} number={++index}
                                                                           editable={false}
                                                                           initial={drug}
                                                                           handleDeleteDrug={handleDeleteDrug} />)}
        {formik.values.treatmentOfComorbidities.length > 0 && <Divider margin={4}/>}
        {editableAddDrug && <Drug number={formik.values.treatmentOfComorbidities.length + 1} editable={true}
              handleAddDrug={handleAddDrug} />}
        <Grid item xs={12} mt={formik.values.treatmentOfComorbidities.length === 0 && !editableAddDrug ? 0 : 2}>
          <Button disabled={editableAddDrug} startIcon={<Add />} variant='contained' color={'primary'}
                  onClick={handleEditableAddDrug}>DODAJ</Button>
        </Grid>
        <Divider margin={20}/>
        <Grid item xs={12} mb={1}>
          <CheckboxesGroup<IThirdStageValues> name={'treatmentOfComorbiditiesCheck'} full={true}
                                              elements={supportiveTreatmentOfCoexistingDiseasesCheckboxesConfig}
                                              onChange={handleChangeArray}
                                              values={formik.values.treatmentOfComorbiditiesCheck} />
        </Grid>
      </Grid>
    </Fieldset>

    <Fieldset legend={'Rozpoczęcie leczenia farmakologicznego pasyreotydem LAR'}>
      <Grid container mt={3}>
        <Grid item xs={12} mb={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
              justifyContent={'center'}>

          <CheckboxesGroup<IThirdStageValues>
            singleField={true}
            full={true}
            name={'initiationOfDrugTreatmentWithPasireotideLarReason'}
            elements={startOfTreatmentPasireotideLARReasonsCheckboxesConfig}
            onChange={handleChangeArray}
            values={formik.values.initiationOfDrugTreatmentWithPasireotideLarReason} />
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <InputController<IInputControllerThirdStage>
            setActive={setActive}
            active={inputControllerElements.initiationOfDrugTreatmentWithPasireotideLarConcentration}
            setField={formik.setFieldValue}
            inputName={'initiationOfDrugTreatmentWithPasireotideLarConcentration'}
            checkbox={{
              name: 'initiationOfDrugTreatmentWithPasireotideLarConcentrationCheckbox',
              label: 'osiągnięto normalizację GH w surowicy'
            }}>
            <SelectCustom
              disabled={!inputControllerElements.initiationOfDrugTreatmentWithPasireotideLarConcentration}
              name={'initiationOfDrugTreatmentWithPasireotideLarConcentration'}
              placeholder={'stężenie (μg/l)'}
              label={'Stężenie (μg/l)'}
              onChange={formik.handleChange}
              value={formik.values.initiationOfDrugTreatmentWithPasireotideLarConcentration}
              options={startOfTreatmentPasireotideLARNormalizationLevelOptions} />
          </InputController>
          <RadioGroupStyled $alignItems={'left'} row={false}
                            name={'initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1'}
                            gap={'8px'} value={formik.values.initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1}>
            <FormControlLabel onChange={formik.handleChange} value={'osiągnięto normalizację IGF-1 w surowicy'}
                              control={<Radio onClick={() => formik.setFieldValue('initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1', radioValueOptional(formik.values.initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1, 'osiągnięto normalizację IGF-1 w surowicy'))}/>} label='osiągnięto normalizację IGF-1 w surowicy' />
            <FormControlLabel onChange={formik.handleChange}
                              value={'nie osiągnięto normalizacji IGF-1, ale osiągnięto spadek o ponad 50% wartości wyjściowej'}
                              control={<Radio onClick={() => formik.setFieldValue('initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1', radioValueOptional(formik.values.initiationOfDrugTreatmentWithPasireotideLarConcentrIgf1, 'nie osiągnięto normalizacji IGF-1, ale osiągnięto spadek o ponad 50% wartości wyjściowej'))}/>}
                              label='nie osiągnięto normalizacji IGF-1, ale osiągnięto spadek o ponad 50% wartości wyjściowej' />
          </RadioGroupStyled>
        </Grid>
      </Grid>
    </Fieldset>

    <Fieldset legend={'Rozpoczęcie leczenia farmakologicznego pegvisomantem (jeśli dotyczy)'}>
      <Grid container mt={3}>
        <Grid item xs={12} mb={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
              justifyContent={'center'}>
          <CheckboxesGroup<IThirdStageValues>
            singleField={true}
            full={true}
            name={'startTreatmentWithPegvisomantNormalizationUg'}
            elements={startOfTreatmentPegvisomantReasonsCheckboxesConfig}
            onChange={handleChangeArray}
            values={formik.values.startTreatmentWithPegvisomantNormalizationUg} />
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <RadioGroupStyled $alignItems={'left'} row={false} name={'startTreatmentWithPegvisomantNormalizationIGF1'}
                            value={formik.values.startTreatmentWithPegvisomantNormalizationIGF1}>
            <FormControlLabel onChange={formik.handleChange} value={'osiągnięto normalizację IGF-1'} control={<Radio onClick={() => formik.setFieldValue('startTreatmentWithPegvisomantNormalizationIGF1', radioValueOptional(formik.values.startTreatmentWithPegvisomantNormalizationIGF1, 'osiągnięto normalizację IGF-1'))}/>}
                              label='osiągnięto normalizację IGF-1' />
            <FormControlLabel onChange={formik.handleChange}
                              value={'nie osiągnięto normalizacji IGF-1 (spadek o powyżej 50%)'} control={<Radio onClick={() => formik.setFieldValue('startTreatmentWithPegvisomantNormalizationIGF1', radioValueOptional(formik.values.startTreatmentWithPegvisomantNormalizationIGF1, 'nie osiągnięto normalizacji IGF-1 (spadek o powyżej 50%)'))}/>}
                              label='nie osiągnięto normalizacji IGF-1 (spadek o powyżej 50%)' />
          </RadioGroupStyled>

        </Grid>
      </Grid>
    </Fieldset>
    <ButtonsActions
      handleDraftSave={handleDraftSave}
      handleSave={handleSave} />
  </Grid>);
});



