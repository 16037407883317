import styled from 'styled-components';

export const SimpleUrinaryIncontinenceLabel = styled.div`
  width: 200px;
  display: flex;
  align-self: center;
`

export const InterventionLabel = styled.div`
  width: 270px;
  display: flex;
  align-self: center;
`
