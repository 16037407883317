import React, {FC, useEffect, useRef} from 'react';

import { FieldsetContainer, FieldsetLegend, FieldsetParagraph } from './FieldsetContainer.styled';
import { IFieldsetProps } from './FieldsetContainer.types';


export const Fieldset : FC<IFieldsetProps> = ({children, legend, keyFieldset, activeKey, subtitle, margin, size='m', color, handleReset }) => {
  const ref = useRef<HTMLFieldSetElement>(null);

  useEffect(()=> {
    if(keyFieldset === activeKey){
      if(ref.current && handleReset){
        ref.current.scrollIntoView({block: "start", behavior: "smooth"})
        handleReset();
      }
    }
  }, [keyFieldset, activeKey, handleReset])


  return (
    <FieldsetContainer margin={margin} ref={ref}>
      <FieldsetLegend color={color} size={size}>
        {legend}:
        {subtitle && <FieldsetParagraph>{subtitle}</FieldsetParagraph>}
      </FieldsetLegend>
      {children}
    </FieldsetContainer>
  )
}
