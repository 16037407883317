
import { observer } from 'mobx-react-lite';

import { HeaderCaseLayout, RequiredFieldsInfo } from './CaseDraft.styled';
import { CaseLayout } from '../../../shared/user/layout/caseLayout/CaseLayout';
import { useCaseDraftVm } from './useCaseDraft.vm';
import { AuthController } from '../../../shared/appLayer/authController/AuthController';

const CaseDraft = observer(() => {
  const {
    component,
    name,
    filter,
    isTitle,
    handleChange,
    startViewing
  } = useCaseDraftVm();

  return (<AuthController><CaseLayout filter={filter} handleChange={handleChange} tabsActive={false}>
    {isTitle && <><HeaderCaseLayout>{name}</HeaderCaseLayout>
      <RequiredFieldsInfo>Pola oznaczone gwiazdką (*) są wymagane.</RequiredFieldsInfo></>}
    {startViewing && component}
  </CaseLayout></AuthController>);
});

export default CaseDraft;