import { CardContainer } from './Card.styled';
import { Card, CardActions, CardContent, Divider, Paper, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as Pen } from '../../../../assets/icon/pen.svg';
import { ReactComponent as Bin } from '../../../../assets/icon/bin.svg';
import { observer } from 'mobx-react-lite';
import { checkRoles } from '../../../../utils/checkRoles';
import { RoleFunction } from '../../../../constants/projectUsers';
import { useAuthStore } from '../../../../store/hooks';

interface IProps {
  title: string;
  author: string | null;
  publisher: string | null;
  link: string;
  date: string;
  handleDelete: (id: number) => void;
  handleEdit: (id: number) => void;
  handleClickByGuest?: () => void;
  id: number;
  isActive: boolean;

}

export const CardComponent: React.FC<IProps> = observer(({
                                                           isActive,
                                                           id,
                                                           handleDelete,
                                                           handleEdit,
                                                           date,
                                                           link,
                                                           title,
                                                           author,
                                                           publisher,
                                                           handleClickByGuest
                                                         }) => {
  const { roleFunction } = useAuthStore();

  const content = <>
    <CardContent className={'card__content'}>
      <>
        {author && <Typography variant='caption'>
          {author}
        </Typography>}
        <br />
        {publisher && <Typography variant='caption'>
          {publisher}
        </Typography>}
      </>
    </CardContent>
    <Divider />
    <CardContent>
      <>
        <Typography variant='subtitle2'>
          {date}
        </Typography>
        <Typography variant='h6'>
          {title}
        </Typography>
      </>
    </CardContent>
  </>;
  return (
    <Card>
      <CardContainer>
        <Paper elevation={1}>
          {checkRoles(roleFunction, RoleFunction.ROLE_GUEST) && isActive ?
            <div onClick={handleClickByGuest}>{content}</div> :
            <a href={link} target='_blank' rel='noreferrer'>
              {content}
            </a>}
          {checkRoles(roleFunction, RoleFunction.ROLE_ACCEPTING) && <>
            <Divider />
            <CardActions>
              <Pen onClick={() => handleEdit(id)} />
              <Bin onClick={() => handleDelete(id)} />
            </CardActions>
          </>}
        </Paper>
      </CardContainer>
    </Card>
  );
});