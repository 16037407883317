import { useProgressBarStore } from '../../../../../../../store/hooks/useProgressBarStore';
import { useFormik } from 'formik';

import {
  checkboxInputControllerConfig,
  convertInputControllerThirdStage, initialErrorThirdStage,
  initialThirdStage,
  validationThirdStageSchema
} from './ThirdStage.helper';
import { useInputController } from '../../../../../../shared/ui/form/inputController/useInputController';
import { ITreatment } from './treatment/Treatment.types';
import { ISteroidogenesisInhibitor } from './steroidogenesisInhibitor/SteroidogenesisInhibitor.types';
import { useEffect, useState } from 'react';
import { IIntervention } from './intervention/Intervention.types';
import { ISupportTreatment } from './supportTreatment/SupportTreatment.types';
import { typeOptions } from './steroidogenesisInhibitor/SteroidogenesisInhibitor.helper';
import {
  ICustomErrorsThirdStage,
  IInputControllerThirdStage,
  IThirdStageValues
} from './ThirdStage.types';

import { DiseaseType } from '../../../../../../../interfaces/common/common';
import { useDraftCaseApi } from '../../../../../../../apiHooks';
import { useParams } from 'react-router-dom';
import { dateIsStill, validateFull } from '../../../../../../../utils/validateDateRange';
import { DATE_IS_STILL } from '../../../../../../shared/ui/form/date/Date.helper';
import {
  ITreatmentBeforeOperationPasireotide
} from './treatmentBeforeOperationPasireotide/TreatmentBeforeOperationPasireotide.types';
import {
  ITreatmentBeforeOperationCabergoline
} from './treatmentBeforeOperationCabergoline/TreatmentBeforeOperationCabergoline.types';
import {
  IHypercortisolaemiaPasireotide
} from '../../acromegaly/thirdStage/hypercortisolaemiaPasireotide/HypercortisolaemiaPasireotide.types';
import {
  IHypercortisolaemiaCabergoline
} from '../../acromegaly/thirdStage/hypercortisolaemiaPasireotideCabergoline/HypercortisolaemiaCabergoline.types';
import { ISteroidogenesisInhibitorOther } from './steroidogenesisInhibitorOther/SteroidogenesisInhibitorOther.types';
import { Action } from '../../../../../../../store/progressBarStore';

export const useThirdStageVm = () => {
  const { id, type } = useParams();
  const progressBarStore = useProgressBarStore();
  const [activeFieldset, setActiveFieldset] = useState<string>('');

  const [customErrors, setCustomErrors] = useState<ICustomErrorsThirdStage>(initialErrorThirdStage);
  const [editableAddTreatment, setEditableAddTreatment] = useState(false);
  const [editableAddIntervention, setEditableAddIntervention] = useState(false);
  const [editableAddSupportTreatment, setEditableAddSupportTreatment] = useState(false);
  const [editableAddTreatmentBeforeOperationInhibitor, setEditableAddTreatmentBeforeOperationInhibitor] = useState(false);
  const [editableAddTreatmentBeforeOperationCabergoline, setEditableAddTreatmentBeforeOperationCabergoline] = useState(false);
  const [editableAddTreatmentBeforeOperationPasireotide, setEditableAddTreatmentBeforeOperationPasireotide] = useState(false);
  const [editableAddHypercortisolaemiaPasireotide, setEditableAddHypercortisolaemiaPasireotide] = useState(false);
  const [editableAddHypercortisolaemiaCabergoline, setEditableAddHypercortisolaemiaCabergoline] = useState(false);
  const [editableAddSteroidogenesisInhibitorOther, setEditableAddSteroidogenesisInhibitorOther] = useState(false);

  const [inhibitorSteroidogenesis, setInhibitorSteroidogenesis] = useState(0);
  const { draftCaseThirdSage, draftCaseThirdStageSaved } = useDraftCaseApi();
  const [mainTypeOptions, setMainTypeOptions] = useState(typeOptions);

  const {
    inputControllerElements,
    setActive,
    setActiveBroadcast
  } = useInputController<IInputControllerThirdStage>(progressBarStore.thirdStage ? convertInputControllerThirdStage(progressBarStore.thirdStage as IThirdStageValues) : checkboxInputControllerConfig);

  const formik = useFormik<IThirdStageValues>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    initialValues: progressBarStore.thirdStage as IThirdStageValues ?? initialThirdStage,
    validationSchema: validationThirdStageSchema,
    onSubmit: async (values) => {
      const newValues = convertDate();
      await draftCaseThirdSage(newValues, Number(id), type as DiseaseType);
      progressBarStore.setThirdStage(values);
    }
  });

  const { setFieldValue, values } = formik;

  useEffect(() => {
    if (values.biochemicalNormalization === 'Nie') {
      setFieldValue('biochemicalNormalizationSerumCortisolDate', null);
      setFieldValue('biochemicalNormalizationCortisolDate', null);
      setFieldValue('biochemicalNormalizationDZMDate', null);
      //setActive(['biochemicalNormalizationDZMDate', 'biochemicalNormalizationSerumCortisolDate', 'biochemicalNormalizationCortisolDate'], false);
    }
  }, [values.biochemicalNormalization, setFieldValue, setActive]);

  useEffect(() => {
    if (values.subnormalCortisolLevels === 'Nie') {
      setFieldValue('subnormalCortisolLevelsConcentration', '');
    }
  }, [values.subnormalCortisolLevels, setFieldValue]);

  useEffect(() => {
    if (values.hydrocortisolTreatment === 'Nie') {
      setFieldValue('hydrocortisolTreatmentDateStart', null);
      setFieldValue('hydrocortisolTreatmentDateEnd', null);
    }

  }, [setFieldValue, values.hydrocortisolTreatment]);

  useEffect(() => {
    if (formik.values.steroidogenesisInhibitorHow === 'Osilodrostat' || formik.values.steroidogenesisInhibitorHow === 'Metyrapon') {
      setInhibitorSteroidogenesis(1);
      setFieldValue('steroidogenesisInhibitorDoses', []);
    } else if (formik.values.steroidogenesisInhibitorHow === 'Inny') {
      setInhibitorSteroidogenesis(2);
      setFieldValue('steroidogenesisInhibitorDoseOf', '');
      setFieldValue('steroidogenesisInhibitorHowMuch24h', '');
      setFieldValue('steroidogenesisInhibitorHowEscalatedDose', '');
      setFieldValue('steroidogenesisInhibitorDoseOfMax', '');
      setFieldValue('steroidogenesisInhibitorDoseOfMaintenance', '');
      setFieldValue('steroidogenesisInhibitorDoseOfDateStart', null);
      setFieldValue('steroidogenesisInhibitorDoseOfDateEndd', null);
      setFieldValue('steroidogenesisInhibitorIsActive', false);
    } else {
      setInhibitorSteroidogenesis(0);
      setFieldValue('steroidogenesisInhibitorDoses', []);
    }
  }, [formik.values.steroidogenesisInhibitorHow, setFieldValue]);

  useEffect(() => {
    const optionsFiltered = typeOptions.filter(option => {
      const isFind = formik.values.treatmentBeforeOperationInhibitors.find(element => element.type === option.value);
      return !isFind;
    });
    setMainTypeOptions(optionsFiltered);
  }, [formik.values.treatmentBeforeOperationInhibitors]);

  const handleSave = async () => {
    const errors = await formik.validateForm();

    const customErrorsNew = { ...customErrors };
    let isCustomErrors = false;

    const checkHydrocortisolTreatment = values.hydrocortisolTreatment === 'Tak';
    const errorsHydrocortisolTreatment = validateFull(
      checkHydrocortisolTreatment,
      checkHydrocortisolTreatment,
      formik.values.hydrocortisolTreatmentDateStart,
      formik.values.hydrocortisolTreatmentDateEnd
    );

    customErrorsNew.hydrocortisolTreatmentDateStart = errorsHydrocortisolTreatment.dateStart;
    customErrorsNew.hydrocortisolTreatmentDateEnd = errorsHydrocortisolTreatment.dateEnd;

    const checkSteroidogenesisInhibitorDose = formik.values.steroidogenesisInhibitorHow === 'Osilodrostat' || formik.values.steroidogenesisInhibitorHow === 'Metyrapon';
    const errorsSteroidogenesisInhibitorDose = validateFull(
      checkSteroidogenesisInhibitorDose,
      checkSteroidogenesisInhibitorDose,
      formik.values.steroidogenesisInhibitorDoseOfDateStart,
      formik.values.steroidogenesisInhibitorDoseOfDateEndd
    );

    customErrorsNew.steroidogenesisInhibitorDoseOfDateStart = errorsSteroidogenesisInhibitorDose.dateStart;
    customErrorsNew.steroidogenesisInhibitorDoseOfDateEndd = errorsSteroidogenesisInhibitorDose.dateEnd;

    const isErrorHydrocortisolTreatment = errorsHydrocortisolTreatment.dateStart || errorsHydrocortisolTreatment.dateEnd;
    const isErrorSteroidogenesisInhibitorDose = errorsSteroidogenesisInhibitorDose.dateStart || errorsSteroidogenesisInhibitorDose.dateEnd;

    if (isErrorHydrocortisolTreatment) {
      setActiveFieldset('valid-fieldset-1');
    } else if (isErrorSteroidogenesisInhibitorDose) {
      setActiveFieldset('valid-fieldset-2');
    } else {
      setActiveFieldset('');
    }

    isCustomErrors = isErrorHydrocortisolTreatment || isErrorSteroidogenesisInhibitorDose;
    setCustomErrors(customErrorsNew);

    if (!isCustomErrors && Object.keys(errors).length === 0) {
      formik.handleSubmit();
    }
  };

  const handleEditableAddTreatment = () => {
    setEditableAddTreatment(true);
  };

  const handleAddTreatment = (treatment: ITreatment) => {
    const treatments = [...formik.values.treatmentOfChoivePatient];
    treatments.push(treatment);
    setEditableAddTreatment(false);
    formik.setFieldValue('treatmentOfChoivePatient', treatments);
  };

  const handleDeleteTreatment = (id: number) => {
    const treatments = [...formik.values.treatmentOfChoivePatient].filter((obj, index) => index !== id);
    formik.setFieldValue('treatmentOfChoivePatient', treatments);
  };


  const handleEditableAddTreatmentBeforeOperationInhibitor = () => {
    setEditableAddTreatmentBeforeOperationInhibitor(true);
  };

  const handleAddSteroidogenesisInhibitor = (treatmentBeforeOperationInhibitor: ISteroidogenesisInhibitor) => {
    const treatmentBeforeOperationInhibitors = [...formik.values.treatmentBeforeOperationInhibitors];
    treatmentBeforeOperationInhibitors.push(treatmentBeforeOperationInhibitor);
    setEditableAddTreatmentBeforeOperationInhibitor(false);
    formik.setFieldValue('treatmentBeforeOperationInhibitors', treatmentBeforeOperationInhibitors);
  };

  const handleDeleteTreatmentBeforeOperationInhibitor = (id: number) => {
    const treatmentBeforeOperationInhibitors = [...formik.values.treatmentBeforeOperationInhibitors].filter((obj, index) => index !== id);
    formik.setFieldValue('treatmentBeforeOperationInhibitors', treatmentBeforeOperationInhibitors);
  };

  const handleEditableAddIntervention = () => {
    setEditableAddIntervention(true);
  };

  const handleAddIntervention = (intervention: IIntervention) => {
    const operations = [...formik.values.operations];
    operations.push(intervention);
    setEditableAddIntervention(false);
    formik.setFieldValue('operations', operations);
  };

  const handleDeleteIntervention = (id: number) => {
    const operations = [...formik.values.operations].filter((obj, index) => index !== id);
    formik.setFieldValue('operations', operations);
  };

  const handleEditableAddSupportTreatment = () => {
    setEditableAddSupportTreatment(true);
  };

  const handleAddSupportTreatment = (supportTreatment: ISupportTreatment) => {
    const supprotiveTreatments = [...formik.values.supprotiveTreatment];
    supprotiveTreatments.push(supportTreatment);
    setEditableAddSupportTreatment(false);
    formik.setFieldValue('supprotiveTreatment', supprotiveTreatments);
  };

  const handleDeleteSupportTreatment = (id: number) => {
    const supprotiveTreatments = [...formik.values.supprotiveTreatment].filter((obj, index) => index !== id);
    formik.setFieldValue('supprotiveTreatment', supprotiveTreatments);
  };

  const handleEditableAddTreatmentBeforeOperationPasireotide = () => {
    setEditableAddTreatmentBeforeOperationPasireotide(true);
  };

  const handleAddTreatmentBeforeOperationPasireotide = (treatmentBeforeOperationPasireotide: ITreatmentBeforeOperationPasireotide) => {
    const treatmentBeforeOperationPasireotides = [...formik.values.treatmentBeforeOperationPasireotides];
    treatmentBeforeOperationPasireotides.push(treatmentBeforeOperationPasireotide);
    setEditableAddTreatmentBeforeOperationPasireotide(false);
    formik.setFieldValue('treatmentBeforeOperationPasireotides', treatmentBeforeOperationPasireotides);
  };

  const handleDeleteTreatmentBeforeOperationPasireotide = (id: number) => {
    const treatmentBeforeOperationPasireotides = [...formik.values.treatmentBeforeOperationPasireotides].filter((obj, index) => index !== id);
    formik.setFieldValue('treatmentBeforeOperationPasireotides', treatmentBeforeOperationPasireotides);
  };


  const handleEditableAddTreatmentBeforeOperationCabergoline = () => {
    setEditableAddTreatmentBeforeOperationCabergoline(true);
  };

  const handleAddTreatmentBeforeOperationCabergoline = (treatmentBeforeOperationCabergoline: ITreatmentBeforeOperationCabergoline) => {
    const treatmentBeforeOperationCabergolines = [...formik.values.treatmentBeforeOperationCabergolines];
    treatmentBeforeOperationCabergolines.push(treatmentBeforeOperationCabergoline);
    setEditableAddTreatmentBeforeOperationCabergoline(false);
    formik.setFieldValue('treatmentBeforeOperationCabergolines', treatmentBeforeOperationCabergolines);
  };

  const handleDeleteTreatmentBeforeOperationCabergoline = (id: number) => {
    const treatmentBeforeOperationCabergolines = [...formik.values.treatmentBeforeOperationCabergolines].filter((obj, index) => index !== id);
    formik.setFieldValue('treatmentBeforeOperationCabergolines', treatmentBeforeOperationCabergolines);
  };


  const handleEditableAddHypercortisolaemiaPasireotide = () => {
    setEditableAddHypercortisolaemiaPasireotide(true);
  };

  const handleAddHypercortisolaemiaPasireotide = (hypercortisolaemiaPasireotide: IHypercortisolaemiaPasireotide) => {
    const hypercortisolaemiaPasireotides = [...formik.values.hypercortisolaemiaPasireotides];
    hypercortisolaemiaPasireotides.push(hypercortisolaemiaPasireotide);
    setEditableAddHypercortisolaemiaPasireotide(false);
    formik.setFieldValue('hypercortisolaemiaPasireotides', hypercortisolaemiaPasireotides);
  };

  const handleDeleteHypercortisolaemiaPasireotide = (id: number) => {
    const hypercortisolaemiaPasireotide = [...formik.values.hypercortisolaemiaPasireotides].filter((obj, index) => index !== id);
    formik.setFieldValue('hypercortisolaemiaPasireotides', hypercortisolaemiaPasireotide);
  };

  const handleEditableAddHypercortisolaemiaCabergoline = () => {
    setEditableAddHypercortisolaemiaCabergoline(true);
  };

  const handleAddHypercortisolaemiaCabergoline = (hypercortisolaemiaCabergoline: IHypercortisolaemiaCabergoline) => {
    const hypercortisolaemiaCabergolines = [...formik.values.hypercortisolaemiaCabergolines];
    hypercortisolaemiaCabergolines.push(hypercortisolaemiaCabergoline);
    setEditableAddHypercortisolaemiaCabergoline(false);
    formik.setFieldValue('hypercortisolaemiaCabergolines', hypercortisolaemiaCabergolines);
  };

  const handleDeleteHypercortisolaemiaCabergoline = (id: number) => {
    const hypercortisolaemiaCabergolines = [...formik.values.hypercortisolaemiaCabergolines].filter((obj, index) => index !== id);
    formik.setFieldValue('hypercortisolaemiaCabergolines', hypercortisolaemiaCabergolines);
  };

  const handleEditableAddSteroidogenesisInhibitorOther = () => {
    setEditableAddSteroidogenesisInhibitorOther(true);
  };

  const handleAddSteroidogenesisInhibitorOther = (steroidogenesisInhibitorOther: ISteroidogenesisInhibitorOther) => {
    const steroidogenesisInhibitorOthers = [...formik.values.steroidogenesisInhibitorDoses];
    steroidogenesisInhibitorOthers.push(steroidogenesisInhibitorOther);
    setEditableAddSteroidogenesisInhibitorOther(false);
    formik.setFieldValue('steroidogenesisInhibitorDoses', steroidogenesisInhibitorOthers);
  };

  const handleDeleteSteroidogenesisInhibitorOther = (id: number) => {
    const steroidogenesisInhibitorOthers = [...formik.values.steroidogenesisInhibitorDoses].filter((obj, index) => index !== id);
    formik.setFieldValue('steroidogenesisInhibitorDoses', steroidogenesisInhibitorOthers);
  };


  const handleChangeCheckboxes = (name: (keyof IThirdStageValues), value: string) => {
    let newValues = [...formik.values[name] as string[]];
    if (newValues.find(element => element === value)) {
      newValues = newValues.filter(element => element !== value);
    } else {
      newValues.push(value);
    }

    formik.setFieldValue(name, newValues);
  };

  const handleDraftSave = async (action: Action) => {
    const newValues = convertDate();
    await draftCaseThirdStageSaved(newValues, Number(id), type as DiseaseType);
    progressBarStore.setThirdStage(newValues, action);
  };

  const convertDate = () => {
    let newValues = { ...formik.values };

    const steroidogenesisInhibitorDoseOfDateStart = formik.values.steroidogenesisInhibitorDoseOfDateStart;
    newValues.steroidogenesisInhibitorDoseOfDateStart = steroidogenesisInhibitorDoseOfDateStart ?? null;
    const steroidogenesisInhibitorDoseOfDateEndd = formik.values.steroidogenesisInhibitorDoseOfDateEndd;
    newValues.steroidogenesisInhibitorDoseOfDateEndd = dateIsStill(steroidogenesisInhibitorDoseOfDateEndd);
    newValues.steroidogenesisInhibitorDoseOfDateEnddIsActive = (steroidogenesisInhibitorDoseOfDateEndd === DATE_IS_STILL);

    const newTreatmentBeforeOperationInhibitors = formik.values.treatmentBeforeOperationInhibitors.map(treatmentBeforeOperationInhibitor => {
      const newTreatmentBeforeOperationInhibitor = { ...treatmentBeforeOperationInhibitor };
      newTreatmentBeforeOperationInhibitor.dateEnd = dateIsStill(newTreatmentBeforeOperationInhibitor.dateEnd);
      return newTreatmentBeforeOperationInhibitor;
    });

    newValues.treatmentBeforeOperationInhibitors = [...newTreatmentBeforeOperationInhibitors];

    const newTreatmentBeforeOperationPasireotides = formik.values.treatmentBeforeOperationPasireotides.map(treatmentBeforeOperationPasireotide => {
      const newTreatmentBeforeOperationPasireotide = { ...treatmentBeforeOperationPasireotide };
      newTreatmentBeforeOperationPasireotide.dateEnd = dateIsStill(newTreatmentBeforeOperationPasireotide.dateEnd);
      return newTreatmentBeforeOperationPasireotide;
    });

    newValues.treatmentBeforeOperationPasireotides = [...newTreatmentBeforeOperationPasireotides];

    const newTreatmentBeforeOperationCabergolines = formik.values.treatmentBeforeOperationCabergolines.map(treatmentBeforeOperationCabergoline => {
      const newTreatmentBeforeOperationCabergoline = { ...treatmentBeforeOperationCabergoline };
      newTreatmentBeforeOperationCabergoline.dateEnd = dateIsStill(newTreatmentBeforeOperationCabergoline.dateEnd);
      return newTreatmentBeforeOperationCabergoline;
    });

    newValues.treatmentBeforeOperationCabergolines = [...newTreatmentBeforeOperationCabergolines];

    const newHypercortisolaemiaPasireotides = formik.values.hypercortisolaemiaPasireotides.map(hypercortisolaemiaPasireotide => {
      const newHypercortisolaemiaPasireotides = { ...hypercortisolaemiaPasireotide };
      newHypercortisolaemiaPasireotides.dateEnd = dateIsStill(newHypercortisolaemiaPasireotides.dateEnd);
      return newHypercortisolaemiaPasireotides;
    });

    newValues.hypercortisolaemiaPasireotides = [...newHypercortisolaemiaPasireotides];

    const newHypercortisolaemiaCabergolines = formik.values.hypercortisolaemiaCabergolines.map(hypercortisolaemiaCabergoline => {
      const newHypercortisolaemiaCabergoline = { ...hypercortisolaemiaCabergoline };
      newHypercortisolaemiaCabergoline.dateEnd = dateIsStill(newHypercortisolaemiaCabergoline.dateEnd);
      return newHypercortisolaemiaCabergoline;
    });

    newValues.hypercortisolaemiaCabergolines = [...newHypercortisolaemiaCabergolines];


    newValues.biochemicalNormalizationSerumCortisolDate = dateIsStill(newValues.biochemicalNormalizationSerumCortisolDate);
    newValues.biochemicalNormalizationDZMDate = dateIsStill(newValues.biochemicalNormalizationDZMDate);
    newValues.biochemicalNormalizationCortisolDate = dateIsStill(newValues.biochemicalNormalizationCortisolDate);

    return newValues;
  };

  const handleResetFieldset = () => {
    setActiveFieldset('');
  };

  return {
    customErrors,
    inhibitorSteroidogenesis,
    mainTypeOptions,
    handleEditableAddIntervention,
    editableAddIntervention,
    handleAddIntervention,
    handleDeleteIntervention,
    handleChangeCheckboxes,
    inputControllerElements,
    editableAddTreatment,
    handleEditableAddTreatment,
    handleAddTreatment,
    handleDeleteTreatment,
    editableAddTreatmentBeforeOperationInhibitor,
    handleEditableAddTreatmentBeforeOperationInhibitor,
    handleAddSteroidogenesisInhibitor,
    handleDeleteTreatmentBeforeOperationInhibitor,
    handleEditableAddSupportTreatment,
    editableAddSupportTreatment,
    handleAddSupportTreatment,
    handleDeleteSupportTreatment,
    editableAddTreatmentBeforeOperationPasireotide,
    handleEditableAddTreatmentBeforeOperationPasireotide,
    handleAddTreatmentBeforeOperationPasireotide,
    handleDeleteTreatmentBeforeOperationPasireotide,
    editableAddTreatmentBeforeOperationCabergoline,
    handleEditableAddTreatmentBeforeOperationCabergoline,
    handleAddTreatmentBeforeOperationCabergoline,
    handleDeleteTreatmentBeforeOperationCabergoline,
    editableAddHypercortisolaemiaPasireotide,
    handleEditableAddHypercortisolaemiaPasireotide,
    handleAddHypercortisolaemiaPasireotide,
    handleDeleteHypercortisolaemiaPasireotide,
    editableAddHypercortisolaemiaCabergoline,
    handleEditableAddHypercortisolaemiaCabergoline,
    handleAddHypercortisolaemiaCabergoline,
    handleDeleteHypercortisolaemiaCabergoline,
    editableAddSteroidogenesisInhibitorOther,
    handleEditableAddSteroidogenesisInhibitorOther,
    handleAddSteroidogenesisInhibitorOther,
    handleDeleteSteroidogenesisInhibitorOther,
    handleDraftSave,
    setActive,
    setActiveBroadcast,
    formik,
    handleSave,
    activeFieldset,
    handleResetFieldset
  };
};