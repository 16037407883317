import { makeAutoObservable } from 'mobx';

import { AuthStore } from './authStore';
import { PoiStore } from './poiStore';
import { ProgressBarStore } from './progressBarStore';
import { LibraryStore } from './libraryStore';
import { CaseStore } from './caseStore';
import { QUPageStore } from './quPageStore';
import { NavStore } from './navStore';
import { CasesListStore } from './casesListStore';
import { UsersStore } from './usersStore';
import { NotificationsStore } from './notificationsStore';

export class RootStore {
  public authStore = new AuthStore(this);
  public poiStore = new PoiStore(this);
  public progressBarStore = new ProgressBarStore(this);
  public libraryStore = new LibraryStore(this);
  public caseStore = new CaseStore(this);
  public casesListStore = new CasesListStore(this);
  public quPageStore = new QUPageStore(this);
  public navStore = new NavStore(this);
  public usersStore = new UsersStore(this);
  public notificationsStore = new NotificationsStore(this);

  constructor() {
    makeAutoObservable(this, {
      authStore: false,
      poiStore: false,
      progressBarStore: false,
      libraryStore: false,
      caseStore: false,
      casesListStore: false,
      quPageStore: false,
      navStore: false,
      usersStore: false,
      notificationsStore: false
    });
  }
}
