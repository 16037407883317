import styled from 'styled-components';
import { RadioGroup } from '@mui/material';
import { IRadioGroupStyled } from '../../../../pages/user/caseDraft/CaseDraft.types';
import { themeMUI } from '../../../../../theme/theme';

export const RadioGroupStyled = styled(RadioGroup)<IRadioGroupStyled>`
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  display: flex;
  ${props => props.gap && `gap: ${props.gap};`}
  align-items: ${props => props.$alignItems ?? 'center'};

  .MuiRadio-colorError {
    color: ${themeMUI.palette.error.main};

    ~ .MuiTypography-root {
      color: ${themeMUI.palette.error.main};
    }
  }
`;