import { useRootStore } from '../RootStateContext';

export const useUsersStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.usersStore) {
    throw new Error('usersStore store should be defined');
  }

  return rootStore.usersStore;
};
