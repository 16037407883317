
import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';

export class NavStore {
  casesCount: number | null = null;
  qaCount: number | null = null;
  notificationsCount: number | null = null;
  libraryCount: number | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

}