import { useCaseSListStore } from '../../../store/hooks/useCasesListStore';
import { useCasesListApi } from '../../../apiHooks';
import { useCallback, useEffect } from 'react';
import { useCaseLayout } from '../../shared/user/layout/caseLayout/useCaseLayout';
import { usePagination } from '../../../hooks/usePagination';
import { ICardItem } from '../../shared/caseItem/CardItem.types';
import { ICaseStatus } from '../../../interfaces/common/common';
import { checkRoles } from '../../../utils/checkRoles';
import { RoleFunction } from '../../../constants/projectUsers';
import { useAuthStore } from '../../../store/hooks';

export const useMyCases = () => {
  const casesListStore = useCaseSListStore();
  const { roleFunction, expertType } = useAuthStore();
  const { myCasesCushing, myCasesAcromegaly } = casesListStore;
  const { getMyCases, getAdminCases } = useCasesListApi();

  const {
    filter,
    handleChange
  } = useCaseLayout();

  useEffect(() => {
    if (checkRoles(roleFunction, [RoleFunction.ROLE_PUBLISHING])) {
      void getMyCases(filter === 'acromegaly' ? casesListStore.myCasesAcromegaly === null : casesListStore.myCasesCushing === null, filter);
    } else if (checkRoles(roleFunction, [RoleFunction.ROLE_EXPERT, RoleFunction.ROLE_ACCEPTING])) {
      void getAdminCases(filter === 'acromegaly' ? casesListStore.myCasesAcromegaly === null : casesListStore.myCasesCushing === null, filter);
    }

  }, [getMyCases, roleFunction, getAdminCases, filter, casesListStore]);

  const filterData = useCallback((list: ICardItem[], status?: ICaseStatus | 'wszystkie') => {
    if (list) {
      if (status === 'wszystkie') {
        return list;
      } else if (status === ICaseStatus.SENT && checkRoles(roleFunction, [RoleFunction.ROLE_PUBLISHING])) {
        return list.filter(el => el.status === status || el.status === ICaseStatus.TO_ACCEPTING);
      } else {
        return list.filter(el => el.status === status);
      }
    }
    return [];
  }, [roleFunction]);
  
  const isAcromegaly = filter === 'acromegaly';

  const {
    currentPage,
    totalPage,
    handlePageChange,
    search,
    handleSearch,
    items,
    status,
    handleSelect
  } = usePagination(isAcromegaly ? myCasesAcromegaly : myCasesCushing, 15, ['title', 'author', 'description'], filterData, undefined, filter, checkRoles(roleFunction, [RoleFunction.ROLE_ACCEPTING]) ? ICaseStatus.SENT : 'wszystkie');

  const emptyMessage =
    (isAcromegaly ? (myCasesAcromegaly && myCasesAcromegaly.length === 0) : (myCasesCushing && myCasesCushing?.length === 0)) ?
      (checkRoles(roleFunction, [RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_EXPERT]) ? 'Nie masz żadnych opisów przypadków do oceny w wybranej jednostce chorobowej.' : `Nie dodałeś jeszcze żadnego opisu przypadku medycznego dotyczącego ${filter === 'acromegaly' ? 'akromegalii' : 'zespołu cushinga'}. Zrobisz to używając przycisku "+ Dodaj".`) :
      'Brak opisów przypadków medycznych dla wybranych kryteriów.';


  return {
    myCases: items ?? [],
    isLoaded: (isAcromegaly ? (myCasesAcromegaly != null) : (myCasesCushing != null)) && items !== null,
    emptyMessage,
    currentPage,
    totalPage,
    handlePageChange,
    search,
    handleSearch,
    status,
    handleSelect,
    filter,
    handleChange,
    expertType,
    roleFunction
  };
};
