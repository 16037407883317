import { useRootStore } from '../RootStateContext';

export const useCaseSListStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.casesListStore) {
    throw new Error('casesListStore store should be defined');
  }

  return rootStore.casesListStore;
};
