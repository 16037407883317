import { Grid } from '@mui/material';
import React from "react";
import {observer} from "mobx-react-lite";

import { Fieldset } from '../../../../../../shared/ui/form/fieldset/FieldsetContainer';
import { useFourthStageVm } from './useFourthStage.vm';
import { Input } from '../../../../../../shared/ui/form/input/Input';
import { TextareaContainer } from '../../../CaseDraft.styled';
import { ButtonsActions } from '../buttonsActions/ButtonsActions';
import {ModalPublishedCase} from "./modalPublishedCase/ModalPublishedCase";

export const FourthStage = observer(() => {

  const {
    formik,
    handleDraftSave,
    handleSave,
    handleModalOpen,
    open,
    handleClose,
    finishMessage
  } = useFourthStageVm();

  return (<Grid container justifyContent={'space-between'}>
    <Fieldset size={'l'} legend={"Podsumowania / wnioski"}>
      <TextareaContainer>
        <Input
          multiline={true}
          rows={5}
          name={"summary"}
          type={"text"}
          onChange={formik.handleChange}
          value={formik.values.summary}
          placeholder={"wpisz wnioski / podsumowanie"}
          label={"Wpisz wnioski / podsumowanie"}
        />
      </TextareaContainer>
     </Fieldset>
    <ButtonsActions
      handleDraftSave={handleDraftSave}
      handleSave={handleModalOpen}
      nextStep={'Zapisz i przejdź dalej'}
    />
    {open && <ModalPublishedCase
        finishMessage={finishMessage}
        open={open}
        handleClose={handleClose}
        handleDraftSave={handleDraftSave}
        handleSave={handleSave}/>}
  </Grid>)
});



