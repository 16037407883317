import styled from 'styled-components';

export const ActionButton = styled.button<{ notVisible?: boolean }>`
  padding: 4px;
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  cursor: ${props => !props.notVisible && 'pointer'};

  & svg {
    opacity: ${props => props.notVisible && '0.5'}
  }
`;