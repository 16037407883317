import { useAuthApi } from '../../../../../apiHooks';
import { ILoginPublishingForm } from './LoginPublishing.interfaces';
import { useForm } from '../../../../../hooks/useForm';
import {
  initialLoginPublishingForm,
  initialLoginPublishingFormProd,
  messagesLoginPublishingQueue,
  validationLoginPublishingSchema
} from './LoginPublishing.helper';
import { useModal } from '../../../../shared/modal/useModal';
import { useAuthStore, usePoiStore } from '../../../../../store/hooks';
import { useNavigate } from 'react-router-dom';

export const useLoginPublishing = () => {
  const { loginUser } = useAuthApi();
  const authStore = useAuthStore();
  const { handleClose, handleOpen, open } = useModal();
  const navigate = useNavigate();
  const poiStore = usePoiStore();
  const { errorCode, isGlobalError } = poiStore;
  const onSubmit = async (values: ILoginPublishingForm) => {
    const isLogin = await loginUser(values);
    if (isLogin && authStore.isLoggedIn) {
      navigate('/');
    }
  };

  const {
    formik
  } = useForm<ILoginPublishingForm>(process.env.REACT_APP_ENV === 'PROD' ? initialLoginPublishingFormProd : initialLoginPublishingForm, validationLoginPublishingSchema, onSubmit, messagesLoginPublishingQueue);

  const errorMessage = isGlobalError && errorCode === 401 ? 'Dane logowania nie zgadzają się. Sprawdź swoje dane i upewnij się, że nie masz włączonego CAPS LOCK.' : '';

  return {
    formik,
    handleClose, handleOpen, open,
    errorMessage

  };
};