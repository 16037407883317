import { Fieldset } from '../../../../../../shared/ui/form/fieldset/FieldsetContainer';
import { Input } from '../../../../../../shared/ui/form/input/Input';
import React from 'react';
import { useZeroStageVm } from './useZeroStage.vm';
import { Button, Grid } from '@mui/material';

export const ZeroStage = () => {

  const {formik, handleSave} = useZeroStageVm();

  return <Fieldset legend={"Tytuł i opis przypadku"}>
    <Grid container>
      <Grid item xs={12} mt={1}>
        <Input
          name={"title"}
          type={"text"}
          onChange={formik.handleChange}
          required={true}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.title)}
          value={formik.values.title}
          placeholder={"Tytuł przypadku"}
          label={"Tytuł przypadku"}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Input
          name={"description"}
          type={"text"}
          multiline={true}
          rows={3}
          onChange={formik.handleChange}
          required={true}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.description)}
          value={formik.values.description}
          placeholder={"Opis przypadku"}
          label={"Opis przypadku"}
        />
      </Grid>
      <Grid item xs={12} mt={4}>
        <Button fullWidth={true} onClick={handleSave} size={'large'} color={'primary'} variant={'contained'}>Zapisz i przejdź dalej</Button>
      </Grid>
    </Grid>

  </Fieldset>
}