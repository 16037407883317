import { Button, FormControlLabel, Grid, Radio } from '@mui/material';
import { DateInput } from '../../../../../../../shared/ui/form/date/Date';

import React, { FC } from 'react';
import { useSurgaryVm } from './useSurgary.vm';
import { ISurgeryProps } from './Surgary.types';
import { RadioGroupStyled } from '../../../../../../../shared/ui/form/radioGroupStyled/RadioGroupStyled.styled';
import { GenericIcon } from '../../../../../../../shared/genericIcon/GenericIcon';
import { themeMUI } from '../../../../../../../../theme/theme';
import { radioValueOptional } from '../../../../CaseDraft.helper';

export const Surgary: FC<ISurgeryProps> = ({ id, initial, handleAddSurgery, editable, handleDeleteSurgery }) => {
  const {
    formik,
    handleOnClick,
    disabledAdd
  } = useSurgaryVm(initial, handleAddSurgery);

  return <>
    <Grid item xs={3} pr={1} mt={1} mb={editable ? 0: 1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
          justifyContent={'center'} maxWidth={'220px'}>
      <DateInput
        disabled={!editable}
        label={'Data'}
        required={false}
        views={['year', 'month']}
        openTo={'year'}
        name={'date'}
        inputFormat={'mm.yyyy'}
        value={formik.values.date}
        error={formik.touched.date && Boolean(formik.errors.date)}
        onBlur={() => formik.setFieldTouched('date', true)}
        onChange={(e) => formik.setFieldValue('date', e)} />
    </Grid>
    <Grid item xs={8} mt={1} mb={editable ? 0: 1} pl={2} display={'flex'} flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent={'center'} justifyItems={'center'}>
      <RadioGroupStyled row={true} name={'type'} value={formik.values.type} >
        <FormControlLabel onChange={formik.handleChange} value={'dostęp przezklinowy'}
                          control={<Radio disabled={!editable} onClick={() => formik.setFieldValue('type', radioValueOptional(formik.values.type, 'dostęp przezklinowy'))} />} label='dostęp przezklinowy' />
        <FormControlLabel onChange={formik.handleChange} value={'dostęp przezczaszkowy (kraniotomia)'}
                          control={<Radio disabled={!editable} onClick={() => formik.setFieldValue('type', radioValueOptional(formik.values.type, 'dostęp przezczaszkowy (kraniotomia)'))} />} label='dostęp przezczaszkowy (kraniotomia)' />
      </RadioGroupStyled>
    </Grid>
    {handleDeleteSurgery && <Grid item xs={1} alignSelf={'center'} mt={1} mb={1}>
      <div onClick={() => {
        if (typeof id === 'number') {
          handleDeleteSurgery(id);
        }

      }}>
        <GenericIcon iconName='Close'
                     style={{
                       color: themeMUI.palette.primary.main,
                       cursor: 'pointer'
                     }} /></div>
    </Grid>}
    {
      handleAddSurgery &&
      <Grid item xs={1} mt={1} mb={0} pl={1}>
        <Button disabled={disabledAdd} variant='contained' color={'primary'}
                onClick={handleOnClick}>ZAPISZ</Button>
      </Grid>
    }
  </>;
};