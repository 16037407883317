import { useFormik } from 'formik';

import * as yup from 'yup';
import { ValidationError } from '../../../../../../utils';
import { useAuthApi } from '../../../../../../apiHooks';

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(ValidationError.EMAIL_NOT_CORRECT)
    .required(ValidationError.EMAIL_NOT_CORRECT)

});
export const useResetPasswordModal = (handleClose: () => void) => {
  const { resetPassword } = useAuthApi(handleClose);
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await resetPassword(values.email);
    }
  });

  return {
    formik
  };
};